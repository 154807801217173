@if (user && building) {
  <nav class="or-site-menu">
    <ul class="or-inline-items">
      @for (section of menuItems; track section.info.Id) {
        <ng-container>
          @if (isAuthorizedForMenuItemWhenBuildingHasFloors(section)) {
            <li class="or-inline-item">
              <a [attr.data-cy]="'site-menu-link-' + section.info?.Id" class="or-button or-site-menu-link" [ngClass]="{ 'or-active': section.isActive() }" (click)="navigateTo(section)">
                @if (section.info.IconClass) {
                  <span [attr.data-cy]="'site-menu-icon-' + section.info?.Id" class="or-icon or-small or-inverse" [ngClass]="section.info.IconClass"></span>
                }
                <span [attr.data-cy]="'site-menu-text-' + section.info?.Id" class="or-button-label" [textContent]="section.info.name"></span>
              </a>
            </li>
          }
        </ng-container>
      }
    </ul>
  </nav>
}

