<div>
  @if (bacnetForm){
  <form [formGroup]="bacnetForm" class="or-form or-form-bacnet-area-tile" [ngClass]="{'or-form-bacnet-area-tile-enable-labels':enableLabels }"
        novalidate="novalidate">
    <div class="or-form-items">
      <div class="or-form-item or-form-bacnet-area-tile-id">
        <span class="or-label or-label-above">Area Id</span>
        <input matInput class="or-input" maxlength="256" formControlName="areaId" placeholder="AUTO" type="text" />
      </div>
      <div class="or-form-item or-form-bacnet-area-tile-name">
        <span class="or-label or-label-above">Name (BACnet Group)</span>
        <input matInput class="or-input" maxlength="256" [disabled]="isBusy()" formControlName="areaName" type="text" data-cy="bacnet-area-name" />
      </div>
      <div class="or-form-item or-form-bacnet-area-tile-description">
        <span class="or-label or-label-above">Description</span>
        <input matInput class="or-input" maxlength="256" [disabled]="isBusy()" formControlName="areaDescription" type="text" data-cy="bacnet-area-description"/>
      </div>
      <div class="or-form-item or-form-bacnet-area-tile-gateway-info">
      <span class="or-label or-label-above">Gateway Information ( Address [ and Name if available ] )</span>
        <mat-select class="or-select" maxlength="256" [disabled]="isBusy()" formControlName="gatewayId"
                type="text" data-cy="bacnet-area-gateway">
          @for (gatewayOption of gatewayOptions; track gatewayOption.id ){
            <mat-option [value]="gatewayOption.id">{{gatewayOption.label}}</mat-option>
          }
        </mat-select>
      </div>
      <div class="or-form-item or-form-bacnet-area-tile-portal-id">
        <span class="or-label-portal-id or-label-above" appIsAuthorized="VIEW_HIDDEN_COMPONENTS">Portal Id</span>
        <input matInput class="or-input" appIsAuthorized="VIEW_HIDDEN_COMPONENTS" maxlength="256"
          formControlName="id" placeholder="AUTO" type="text" />
      </div>
      <div class="or-form-item or-form-bacnet-area-tile-actions">
        <div class="or-inline-items">
          <div class="or-inline-item">
            <button
              mat-raised-button
              color="primary"
              [matTooltip]="isNew ? 'Create Area': 'Update Area'"
              (click)="submit()"
              [disabled]="(isBusy() || bacnetForm.pristine) || bacnetForm.invalid"
              [attr.data-cy]="isNew ? 'create-bacnet-area':'update-bacnet-area'">
              <mat-icon [fontIcon]="isNew ? 'add_circle' : 'task_alt'" >{{ isNew ? 'Save' : 'Update' }}</mat-icon>
              <span>{{ isNew ? 'Save' : 'Update' }}</span>
            </button>
          </div>
          <div class="or-inline-item">
            <button
              mat-raised-button
              matTooltip="Reset"
              (click)="discardChanges()"
              [disabled]="isBusy() || bacnetForm.pristine"
              [attr.data-cy]="'reset'">
              <mat-icon>refresh</mat-icon>
              <span>Reset</span>
            </button>
          </div>
          @if(!isNew) {
            <div class="or-inline-item">
              <button
                class="delete"
                mat-raised-button
                matTooltip="Delete"
                (click)="deleteArea()"
                [disabled]="isBusy()"
                [attr.data-cy]="'delete-bacnet-area'">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  </form>
  }
</div>
