import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  signal,
  TemplateRef,
  viewChild,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderService } from '@services/header.service';
import { UserService } from '@services/user/user.service';
import { map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatestWith, Observable, Subject } from 'rxjs';
import { BuildingTileComponent } from '@app/buildings/building-tile/building-tile.component';
import { Building } from '@app/shared/models/building.interface';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import { GlobalAuthority } from '@app/shared/models/global-authority';
import { BuildingMetadataService } from '@services/building-metadata/building-metadata.service';
import { StatusClass } from '@components/notification-block/notification-block.component';
import { User } from '@app/shared/models/user.interface';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HotToastRef, HotToastService } from '@ngneat/hot-toast';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CreateHotToastRef } from '@ngneat/hot-toast/lib/hot-toast.model';

const DEFAULT_TILE_WIDTH = 250;
const MARGIN_OFFSET = 16;

@Component({
  selector: 'app-buildings',
  standalone: true,
  imports: [
    CommonModule,
    BuildingTileComponent,
    AuthorizationModule,
    MatTooltipModule,
    MatFormField,
    ReactiveFormsModule,
    MatIcon,
    MatButton,
    MatInput,
    MatProgressSpinner
  ],
  templateUrl: './buildings.component.html',
  styleUrl: './buildings.component.scss'
})
export class BuildingsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('container') element: ElementRef;
  elementWidth = 250;
  loading$ = new BehaviorSubject<boolean>(true);
  allBuildings: Building[] = [];
  filteredBuildings: Building[] = [];
  user: User;
  statusClass = StatusClass;
  searchForm: FormGroup;
  buildingNameWithNoFloors = signal('');

  private onDestroy$ = new Subject<void>();
  private toastTemplate = viewChild<TemplateRef<unknown>>('toastTemplate');
  private currentToastRef: CreateHotToastRef<any> | null = null;

  @HostListener('window:resize')
  listenToResizeWindow(): void {
    this.resizeBuildingTile();
  }

  constructor(
    private headerService: HeaderService,
    private userService: UserService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private detector: ChangeDetectorRef,
    private buildingMetaService: BuildingMetadataService,
    private toastService: HotToastService
  ) {}

  ngOnInit(): void {
    this.initSearchForm();
    this.headerService.hideFloorsMenu();
    this.headerService.showUserMenu();
    this.headerService.showSessionMenu();
    this.loadBuildings();
    this.initBuildingSearch();
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.buildingNameWithNoFloors) {
        this.buildingNameWithNoFloors.set(params.buildingNameWithNoFloors);

        if (this.currentToastRef) {
          this.currentToastRef.close();
        }

        this.currentToastRef = this.toastService.warning(this.toastTemplate(), {
          duration: 5000,
          attributes: {
            'data-cy': 'buildings-without-floors'
          },
          id: `buildings-without-floors-${params.buildingNameWithNoFloors}`
        });

        this.currentToastRef?.afterClosed.subscribe((_val) => {
          this.router.navigate([], { queryParams: null });
        });
      }
    });
  }

  ngAfterViewInit(): void {
    this.resizeBuildingTile();
    this.detector.detectChanges();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  resizeBuildingTile(): void {
    const width = this.element.nativeElement.offsetWidth;
    const elementCount = Math.floor(width / DEFAULT_TILE_WIDTH);
    this.elementWidth = DEFAULT_TILE_WIDTH - MARGIN_OFFSET + (width - DEFAULT_TILE_WIDTH * elementCount) / elementCount;
  }

  initSearchForm(): void {
    this.searchForm = this.fb.group({
      search: ''
    });
  }

  initBuildingSearch(): void {
    this.searchForm.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.filteredBuildings = this.filterBuildings(value.search);
    });
  }

  loadBuildings(): void {
    this.userService
      .getCurrentUser()
      .pipe(combineLatestWith(this.getBuildings()), takeUntil(this.onDestroy$))
      .subscribe({
        next: (value) => {
          this.user = value[0];
          const buildings = value[1];
          this.loading$.next(false);
          this.allBuildings = buildings;
          this.filteredBuildings = buildings;
          if (
            this.allBuildings.length === 1 &&
            // tslint:disable-next-line:triple-equals - The global authorities are string at this point so we can't use the === and not coerce the value
            !this.user.globalAuthorities.some((glob) => glob == GlobalAuthority.ADD_BUILDINGS)
          ) {
            this.buildingMetaService
              .produceBuildingUrl(this.allBuildings[0])
              .subscribe((url) => this.router.navigateByUrl(url));
          }
        },
        error: (err) => {
          console.error('Error loading buildings', err);
          // so that we don't see this toast when logging in
          if (err.status !== 401) {
            this.toastService.error(`Error loading buildings ${err.statusCode}`, {
              attributes: { 'data-cy': 'error-loading-buildings' }
            });
          }
          this.loading$.next(false);
        }
      });
  }

  filterBuildings(queryValue: string): Building[] {
    return this.allBuildings.filter(
      (building) =>
        building.name.toLowerCase().includes(queryValue.toLowerCase()) ||
        building.id.toString().includes(queryValue.toLowerCase())
    );
  }

  getBuildings(): Observable<Building[]> {
    return this.userService.getBuildings().pipe(map((buildings) => buildings.sort((b1, b2) => b1.id - b2.id)));
  }

  openNewBuildingPage(): void {
    this.router.navigateByUrl('buildings/new').then(() => {});
  }
}
