import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, merge, Observable, of, Subject, switchMap } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BarChartModule, Color, GaugeModule, LineChartModule, PieChartModule } from '@swimlane/ngx-charts';
import { SeriesChartData, WidgetItem } from '@app/dashboard/model/widget-item';
import { RefreshPageService } from '@app/shared/services/refresh-page/refresh-page.service';
import { NumericUtils } from '@app/shared/utils/numeric';

export type ChartType = 'bar' | 'bar-vertical-grouped' | 'pie' | 'gauge' | 'stacked' | 'line';

@Component({
  selector: 'app-chart-widget',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  imports: [CommonModule, MatProgressSpinnerModule, BarChartModule, GaugeModule, PieChartModule, LineChartModule],
  templateUrl: './chart-widget.component.html',
  styleUrl: './chart-widget.component.scss'
})
export class ChartWidgetComponent implements OnInit, OnDestroy {
  @Input({ required: true }) item: WidgetItem;
  @Input({ required: true }) refreshEvent: EventEmitter<WidgetItem>;
  @Input({ required: true }) chartType: ChartType;
  @ViewChild('chartContainer') chartContainer: ElementRef;
  isLoadingSource = new BehaviorSubject(true);
  chartData: any;
  tagColors: Array<{ name: string | number; value: string }>;
  view = [];
  colorScheme: Color = {
    domain: ['#58d6d7', '#094367', '#efe8e4', '#0093E9', '#13f2df'],
    name: '',
    group: null,
    selectable: false
  };
  horizontalBarScheme: Color = { domain: ['#094367'], name: '', group: null, selectable: false };
  verticalGroupedBarScheme: Color = { domain: ['#094367', '#008080'], name: '', group: null, selectable: false };
  gaugeScheme: Color = { domain: ['#49a638'], name: '', group: null, selectable: false };
  private destroy$ = new Subject<void>();

  constructor(private refreshPageService: RefreshPageService) {}

  ngOnInit(): void {
    merge(
      this.item.getData().pipe(finalize(() => this.isLoadingSource.next(false))),
      this.refreshEvent.pipe(
        switchMap((item) => {
          if (!item || item.id === this.item.id) {
            this.isLoadingSource.next(true);
            return this.item.getData().pipe(finalize(() => this.isLoadingSource.next(false)));
          }
          return of();
        })
      ),
      this.refreshPageService.pageRefresh$.pipe(
        switchMap(() => {
          this.isLoadingSource.next(true);
          return this.item.getData().pipe(finalize(() => this.isLoadingSource.next(false)));
        })
      )
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: SeriesChartData[]) => {
          this.chartData = data;
          this.useChartMetaToSetAxisLabels(data);
          this.useChartMetaData(data);
          this.calculateChartView();
        },
        error: (err) => console.log(err)
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get isLoading$(): Observable<boolean> {
    return this.isLoadingSource.asObservable();
  }

  calculateChartView(): void {
    const width = this.chartContainer?.nativeElement.offsetWidth - 30;
    const height = this.chartData.length * 30; // no of tags * height of each tag
    this.view = [width, height];
    if (height < this.chartContainer.nativeElement.offsetHeight) {
      this.view = null;
    }
  }

  formatValue(value: string | number, meta: Record<string, string>): string {
    const valueWithUnit = value + ' ' + meta.unit;
    return NumericUtils.fixDigitsInValue(valueWithUnit);
  }

  comparisonYearLabel(data: { name: string; series: string }): string {
    if (
      this.chartData &&
      this.chartData.length > 0 &&
      this.chartData[0].meta.xAxisLabel === 'Months of the year' &&
      data.name?.includes(' - ')
    ) {
      const yearValues = data.name.split(' - ');
      const chartYearNames = this.chartData.map((c) => c.name);
      return chartYearNames.indexOf(data.series) >= chartYearNames.indexOf('January') ? yearValues[1] : yearValues[0];
    }
    return data.name;
  }

  private useChartMetaData(data: SeriesChartData[]): void {
    if (this.item.chartType !== 'line') {
      return;
    }
    if ('meta' in data[0]) {
      this.tagColors = data.map((datum: SeriesChartData) => ({
        name: datum.name,
        value: '#' + datum.meta.color
      }));
    }
  }

  private useChartMetaToSetAxisLabels(data: SeriesChartData[]): void {
    const metaRecord: any = data.find((record: any) => record.meta);
    if (metaRecord && metaRecord.meta) {
      this.item.xAxisLabel = metaRecord.meta.xAxisLabel;
      this.item.yAxisLabel = metaRecord.meta.yAxisLabel;
    }
  }
}
