import { AfterViewInit, Component, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-json-comparison',
  templateUrl: './json-comparison.component.html',
  styleUrls: ['./json-comparison.component.scss'],
  imports: [JsonPipe]
})
export class JsonComparisonComponent implements OnDestroy, AfterViewInit {
  @Input() leftTree: any;
  @Input() rightTree: any;
  constructor(private renderer2: Renderer2) {}

  ngAfterViewInit(): void {
    this.addDiffFiles();
  }

  addDiffFiles(): void {
    this.addCssToElement('assets/static/jdd-resources/styles/jdd.css');
    new Observable((res) => {
      this.addJsToElement('assets/static/jdd-resources/js-files/jQuery.min.js').onload = (test) => {
        return res.next();
      };
    }).subscribe((data) => {
      this.addJsToElement('assets/static/jdd-resources/js-files/jsl.format.js').onload = (test) => {
        this.addJsToElement('assets/static/jdd-resources/js-files/jsl.parser.js');
        this.addJsToElement('assets/static/jdd-resources/js-files/jdd.js');
      };
    });
  }

  addCssToElement(src: string): void {
    const headID = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.media = 'screen';
    link.href = src;
    headID.appendChild(link);
  }

  addJsToElement(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.charset = 'utf-8';
    script.src = src;
    this.renderer2.appendChild(document.body, script);
    return script;
  }

  deleteDiffFiles(): void {
    const scripts = document.getElementsByTagName('script');
    let i = scripts.length;
    while (i--) {
      if (
        scripts[i].src.includes('assets/static/jdd-resources/js-files/jQuery.min.js') ||
        scripts[i].src.includes('assets/static/jdd-resources/js-files/jsl.format.js') ||
        scripts[i].src.includes('assets/static/jdd-resources/js-files/jsl.parser.js') ||
        scripts[i].src.includes('assets/static/jdd-resources/js-files/jdd.js')
      ) {
        scripts[i].parentNode.removeChild(scripts[i]);
      }
    }
  }

  ngOnDestroy(): void {
    this.deleteDiffFiles();
  }
}
