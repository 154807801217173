<form class="or-form or-form-schedule" name="form" [formGroup]='scheduleForm' novalidate="novalidate">
  <div class="or-form-body">
    <ol class="or-form-items or-form-schedule-details">
      <div>
        <mat-form-field appearance='fill'>
          <mat-label>Schedule Name</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>
      </div>
      <div class="space-x-4">
        <mat-form-field appearance='fill' class='schedule-form-date'>
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [min]="minSelectableStartDate"
            [max]="maxSelectableStartDate"
            [placeholder]="'DD/MM/YYYY'"
            formControlName="startDate"
            [matDatepicker]='startDatepicker'
            (dateChange)="onChangeStartDate()">
          <mat-datepicker-toggle matSuffix [for]='startDatepicker'></mat-datepicker-toggle>
          <mat-datepicker #startDatepicker></mat-datepicker>
          @if (startDate?.errors?.required) {
            <mat-error>Start date is required</mat-error>
          }
          @if (startDate?.errors?.matDatepickerMin) {
            <mat-error>Start date cannot be in the past</mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance='fill'>
          <mat-label>Start Time</mat-label>
          <mat-select
            [ngClass]="'mat-select-arrow'"
            formControlName='startTime'
            (selectionChange)="onChangeStartTime()">
            @for (time of timeOptions; track time) {
              <ng-container>
                <mat-option [disabled]="time.disabled" [value]='time'>{{time.timeString}}</mat-option>
              </ng-container>
            }
          </mat-select>
          @if (scheduleForm.controls.startTime?.errors?.required) {
            <mat-error>
              Start time is required
            </mat-error>
          }
        </mat-form-field>
      </div>
      <div class="space-x-4">
        <mat-form-field appearance='fill' class='schedule-form-date'>
          <mat-label>End Date</mat-label>
          <input
            matInput
            [placeholder]="'DD/MM/YYYY'"
            formControlName="endDate"
            [min]="minSelectableEndDate"
            [max]="maxSelectableEndDate"
            [matDatepicker]='endDatepicker'
            (dateChange)="onChangeEndDate()">
          <mat-datepicker-toggle matSuffix [for]='endDatepicker'></mat-datepicker-toggle>
          <mat-datepicker #endDatepicker></mat-datepicker>
          @if (scheduleForm.controls.startDate?.errors?.required) {
            <mat-error>Start date is required</mat-error>
          }
          @if (scheduleForm.controls.startDate?.errors?.matDatepickerMin) {
            <mat-error>Start date cannot be in the past</mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance='fill'>
          <mat-label>End Time</mat-label>
          <mat-select
            [ngClass]="'mat-select-arrow'"
            formControlName='endTime'
            (selectionChange)="onChangeEndTime()">
            @for (time of timeOptions; track time) {
              <ng-container>
                <mat-option [disabled]="time.disabled" [value]='time'>{{time.timeString}}</mat-option>
              </ng-container>
            }
          </mat-select>
          @if (scheduleForm.controls.startTime?.errors?.required) {
            <mat-error>
              Start time is required
            </mat-error>
          }
        </mat-form-field>
      </div>
    </ol>
  </div>
  <div class="or-form-header">
    <h2 class="or-form-title">Rules</h2>
  </div>

  @if(schedule.rules?.length == 0) {
    <app-notification-block
      [header]="'No rules for schedule!'"
      [statusClass]="StatusClass.ERROR">
      There are no rules. You may add some rules to this schedule!
    </app-notification-block>
  } @else  {
    <div class="or-form-body">
      <div class="or-form-schedule-ruleset">
        @for(rule of schedule.rules; track $index) {
          <div class="or-form-schedule-ruleset-rule" >
            <app-schedule-rule
              [buildingId]="building.id"
              [active]="schedule.active"
              [rule]="rule"
              (onRemoveRule)="removeRule($index)"
              (onChange)="validateRules()"></app-schedule-rule>
          </div>
        }
      </div>
    </div>
  }
  <div class="flex flex-row w-full pt-4 space-x-4">
    <button
      mat-raised-button
      matTooltip="Save Schedule"
      color="primary"
      (click)="save()"
      [disabled]="scheduleForm.invalid || !validateRules() || !validateDateAndTime()"
      data-cy="btn-save-schedule">
      <mat-icon class="text-xl-center" fontIcon="task_alt" />
      <span class="text-lg">{{saveLabel}}</span>
    </button>
    <button
      mat-raised-button
      matTooltip="Add Rule"
      (click)="addRule()"
      data-cy="btn-add-schedule-rule">
      <mat-icon class="text-xl-center" fontIcon="add_circle" />
      <span class="text-lg">{{schedule.rules?.length ? 'Add another rule' : 'Add a rule'}}</span>
    </button>
    <button
      mat-raised-button
      matTooltip="Reset and Close"
      (click)="close()"
      data-cy="btn-reset-schedule">
      <mat-icon class="text-xl-center">refresh</mat-icon>
      <span class="text-lg">Reset and close</span>
    </button>
    @if (!isNew) {
      <button
        class='delete'
        mat-raised-button
        matTooltip="Delete Schedule"
        (click)="delete()"
        data-cy="btn-delete-schedule">
        <mat-icon class="text-xl-center">delete</mat-icon>
        <span class="text-lg">Delete</span>
      </button>
    }
  </div>
</form>
