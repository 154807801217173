import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Building } from '@app/shared/models/building.interface';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BleKey } from '@app/shared/models/ble-key.interface';
import { BleKeysService } from '@services/ble-keys/ble-keys.service';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { ToastService } from '@services/toast/toast.service';
import { ConfirmDialogData } from '@components/dialogs/confirm/confirm.component';
import { NgClass, NgIf } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-form-ble-key-details',
  templateUrl: './form-ble-key-details.component.html',
  styleUrls: ['./form-ble-key-details.component.scss'],
  imports: [ReactiveFormsModule, NgClass, NgIf, MatButton]
})
export class FormBleKeyDetailsComponent implements OnInit, OnChanges {
  @Input() key: BleKey;
  @Input() index: number;
  @Input() isBusy = true;
  @Input() isNew = false;
  @Input() isDeactivated = false;
  @Input() building: Building;

  keyForm: FormGroup;

  @Output() valueChanged = new EventEmitter<void>();

  constructor(
    private fb: FormBuilder,
    private toast: ToastService,
    private bleKeysService: BleKeysService,
    private dialogService: ConfirmationDialogService
  ) {}

  ngOnInit(): void {
    this.initForm(this.key);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isNew) {
      this.initForm(null);
    } else if (changes.key) {
      this.initForm(this.key);
    }
  }

  initForm(key: BleKey): void {
    this.keyForm = this.fb.group({
      id: [key ? key.id : ''],
      sequence: [{ value: key ? key.sequence : '', disabled: true }, Validators.required],
      description: [
        { value: key ? key.description : '', disabled: this.isBusy || this.isDeactivated },
        Validators.required
      ],
      badge: [{ value: key ? key.badge : '', disabled: true }]
    });
  }

  createKey(): void {
    this.bleKeysService.createKey(this.building.id, this.keyForm.value).subscribe({
      next: (res: BleKey) => {
        this.toast.success({
          message: `Successfully created ble-key: ${res.sequence}`,
          dataCy: `create-success-toast`
        });
        this.valueChanged.emit();
      },
      error: () => this.handleError('Failed to create the ble key. please try again.')
    });
  }
  updateKey(): void {
    this.bleKeysService.updateKey(this.building.id, this.keyForm.value).subscribe({
      next: () => {
        this.toast.success({
          message: `Successfully updated ble-key: ${this.key.sequence}`,
          dataCy: `update-success-toast`
        });
        this.valueChanged.emit();
      },
      error: () => this.handleError(`Failed to update the ble key: ${this.key.sequence}. please try again.`)
    });
  }
  resetKey(): void {
    const data = new ConfirmDialogData(`Are you sure you want to reset the ble-key?`);
    this.dialogService.open(data).subscribe((confirm) => {
      if (confirm) {
        this.keyForm.reset(this.key);
      }
    });
  }
  sendKey(): void {
    const data = new ConfirmDialogData(`The BLE key is going to be sent to the sensor nodes.`);
    this.dialogService.open(data).subscribe((confirm) => {
      if (confirm) {
        this.bleKeysService.sendKey(this.building.id, this.key).subscribe({
          next: () => {
            this.toast.success({
              message: 'The BLE key is being sent and may take a while to apply',
              dataCy: `send-success-toast`
            });
            this.valueChanged.emit();
          },
          error: () => this.handleError('Failed to send ble-key to the nodes. please try again')
        });
      }
    });
  }

  handleError(message: string): void {
    this.toast.error({ message, dataCy: `send-error-toast` });
  }
}
