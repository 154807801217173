import { Injectable } from '@angular/core';
import { Dates } from '@app/analytics/metric-widget/query/outline/Dates';
import { TimeScale } from '@app/shared/time/time-scale';
import { Resolution } from '@app/analytics/metric-widget/data-objects/resolution';
import { createAllScales } from '@app/shared/time/time-navigator-scales';
import { format } from 'date-fns';
import { TimeUtils } from '@app/shared/utils/time.utils';

@Injectable({
  providedIn: 'root'
})
export class TimeNavigatorService {
  private scaleIndex: number;
  private scales = createAllScales();

  constructor() {
    this.reset();
  }

  public resetTo(now?: Date): void {
    this.scaleIndex = 0;
    this.scales.forEach((scale, i) => {
      scale.reset();
      if (scale.isUsedToInitialize && now != null) {
        scale.setSingleValue(now);
        this.scaleIndex = i;
      }
    });
  }

  public reset(zoneId?: string): void {
    this.resetTo(zoneId != null ? TimeUtils.adjustDateToTimezone(new Date(), zoneId) : new Date());
  }

  public getScale(): TimeScale {
    return this.scales[this.scaleIndex];
  }

  private getNextScale(): TimeScale {
    return this.scales[this.scaleIndex + 1];
  }

  public canZoom(amount: number): boolean {
    return (
      this.scaleIndex + amount >= 0 &&
      this.scaleIndex + amount < this.scales.length - 1 &&
      this.scales[this.scaleIndex + amount].hasValues()
    );
  }

  public zoom(amount: number): void {
    if (!this.canZoom(amount)) {
      return;
    }
    this.scaleIndex += amount;
  }

  public canShift(amount?: number): boolean {
    return this.isSingleDate(amount ?? this.scaleIndex);
  }

  public shift(amount: number): void {
    if (!this.canShift(amount)) {
      return;
    }
    this.setSingleDate(this.getScale().shiftDate(this.getSingleDate(), amount));
  }

  public canSelect(): boolean {
    return this.scaleIndex < this.scales.length - 1;
  }

  public select(blocks: number[]): void {
    if (!this.canSelect()) {
      return;
    }
    this.getNextScale().select(blocks);
  }

  public isSingleDate(endIndex?: number): boolean {
    const scales = endIndex != null ? this.scales.slice(0, endIndex + 1) : this.scales;
    return scales.every((scale) => !scale.hasValues() || scale.isSingleValue());
  }

  public getSingleDate(): Date {
    let time = new Date();
    this.scales
      .filter((scale) => scale.isSingleValue())
      .forEach((scale) => {
        time = scale.updateDate(time);
      });
    return time;
  }

  public setSingleDate(time: Date): void {
    this.scales.filter((scale) => scale.isSingleValue()).forEach((scale) => scale.setSingleValue(time));
  }

  public setDates(dates: Dates): void {
    this.scales.forEach((scale) => scale.fromDates(dates));
  }

  public getDates(): Dates {
    const dates = new Dates();
    const includeCurrentScale = 1;
    const includeScaleDisplayed = 1;
    this.scales
      .slice(0, this.scaleIndex + includeCurrentScale + includeScaleDisplayed)
      .forEach((scale) => scale.toDates(dates));
    return dates;
  }

  public getResolution(): Resolution {
    return this.getScale().resolution;
  }

  public setResolution(resolution: Resolution): void {
    if (resolution != null) {
      this.scaleIndex = this.scales.map((scale) => scale.resolution).indexOf(resolution);
    }
  }

  public getPointLabel(value: number): string {
    return this.getScale().getPointLabel(value);
  }

  public isActive(value: number): boolean {
    return this.getNextScale().isActive(value);
  }

  public getLabel(): string {
    if (this.isSingleDate(this.scaleIndex)) {
      return format(this.getSingleDate(), this.getScale().longFormat);
    }

    return this.getScale().getLabel(this.getSingleDate());
  }

  public addScale(scale: TimeScale): void {
    this.scales.push(scale);
  }

  public toString(): string {
    return this.scales.map((scale) => scale.resolution.toString() + ': ' + scale.getValues().join(', ')).join(' ');
  }
}
