export interface Selectable {
  id?: number;
  selected?: boolean;
  expanded?: boolean;
  x: number;
  y: number;
  discriminator: DISCRIMINATOR;
  isChanged: boolean;
  update(newX: number, newY: number): void;
}

export enum DISCRIMINATOR {
  SN3 = 'SN3',
  EM_DRIVER = 'EM_DRIVER',
  LUMINAIRE = 'LUMINAIRE',
  PN = 'PN',
  HIM84 = 'HIM84',
  HCD405 = 'HCD405',
  HCD038 = 'HCD038',
  FUJITSU_ENV = 'FUJITSU_ENV',
  FUJITSU_ENV_CO2 = 'FUJITSU_ENV_C02'
}

export enum UserFriendlyDiscriminator {
  SN3 = 'SN3',
  PN = 'PN',
  HIM84 = 'HB',
  HCD405 = 'MW',
  HCD038 = 'MW'
}

export function getDistinctUserFriendlyDiscriminators(): string[] {
  return Array.from(new Set(Object.values(UserFriendlyDiscriminator)));
}
