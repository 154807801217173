import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RepeatUnitString } from '@app/shared/models/schedule-recurrence/repeat-unit-string';
import { IScheduleRecurrenceForm } from '@app/shared/models/schedule-recurrence/schedule-recurrence-form.interface';
import { REPEAT_ENDS_OPTIONS, RepeatEndsOption } from '@app/shared/models/schedule-recurrence/repeat-ends-option';
import { ScheduleRecurrence } from '@app/shared/models/schedule-recurrence/schedule-recurrence';
import { RepeatUnit } from '@app/shared/models/schedule-recurrence/repeat-unit';
import { IScheduleRecurrenceDialogInput } from '@app/shared/models/schedule-recurrence/schedule-recurrence-dialog-input.interface';
import { Weekday } from '@app/shared/models/weekday';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { NgFor, NgIf } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-schedule-recurrence-dialog',
  templateUrl: './schedule-recurrence-dialog.component.html',
  styleUrls: ['./schedule-recurrence-dialog.component.scss'],
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    NgFor,
    MatOption,
    NgIf,
    MatButtonToggleGroup,
    MatButtonToggle,
    MatRadioGroup,
    MatRadioButton,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    MatButton
  ]
})
export class ScheduleRecurrenceDialogComponent {
  public customRecurrenceForm = new FormGroup<IScheduleRecurrenceForm>({
    repeatEvery: new FormControl(1),
    repeatUnitString: new FormControl(RepeatUnitString.DAY),
    monthlyRepeatUnit: new FormControl(RepeatUnit.MONTHLY_WEEK_DATE),
    weekdaySelection: new FormControl([]),
    repeatEndsOption: new FormControl(RepeatEndsOption.NEVER),
    repeatEndsOn: new FormControl(null),
    repeatEndsAfter: new FormControl(null)
  });
  public repeatUnitStrings = RepeatUnitString.getAll();
  public weekdays = Weekday.getAll();
  public repeatEndsOptions = REPEAT_ENDS_OPTIONS;
  public monthlyChoices: ScheduleRecurrence[];
  public startDate: Date;

  constructor(
    public dialogRef: MatDialogRef<ScheduleRecurrenceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IScheduleRecurrenceDialogInput
  ) {
    this.startDate = data.startDate;
    this.customRecurrenceForm.valueChanges.subscribe((form) => {
      this.monthlyChoices = [
        new ScheduleRecurrence(data.startDate, form.repeatEvery, RepeatUnit.MONTHLY_CALENDAR_DATE),
        new ScheduleRecurrence(data.startDate, form.repeatEvery, RepeatUnit.MONTHLY_WEEK_DATE)
      ];
    });
    this.monthlyChoices = [
      new ScheduleRecurrence(data.startDate, 1, RepeatUnit.MONTHLY_CALENDAR_DATE),
      new ScheduleRecurrence(data.startDate, 1, RepeatUnit.MONTHLY_WEEK_DATE)
    ];
    this.customRecurrenceForm.controls.repeatEndsAfter.disable();
    this.customRecurrenceForm.controls.repeatEndsOn.disable();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public save(): void {
    if (!this.hasAnyRequiredError()) {
      let repeatUnit = RepeatUnit.fromRepeatUnitString(this.customRecurrenceForm.value.repeatUnitString);
      if (this.customRecurrenceForm.value.repeatUnitString.value === 'MONTH') {
        repeatUnit = this.customRecurrenceForm.value.monthlyRepeatUnit;
      }
      this.customRecurrenceForm.value.repeatEndsOption;
      const scheduleRecurrence = new ScheduleRecurrence(
        this.startDate,
        this.customRecurrenceForm.value.repeatEvery,
        repeatUnit,
        this.customRecurrenceForm.value.weekdaySelection,
        this.isRepeatEndsOptionSelected(this.repeatEndsOptions[1])
          ? this.customRecurrenceForm.value.repeatEndsOn
          : null,
        this.isRepeatEndsOptionSelected(this.repeatEndsOptions[2])
          ? this.customRecurrenceForm.value.repeatEndsAfter
          : null
      );
      this.dialogRef.close(scheduleRecurrence);
    }
  }

  public isWeekSelected(): boolean {
    return this.customRecurrenceForm.value.repeatUnitString === RepeatUnitString.WEEK;
  }

  public isMonthSelected(): boolean {
    return this.customRecurrenceForm.value.repeatUnitString === RepeatUnitString.MONTH;
  }

  public isRepeatEndsOptionSelected(repeatEndsOption: RepeatEndsOption): boolean {
    return this.customRecurrenceForm.value.repeatEndsOption === repeatEndsOption;
  }

  public onEndsRadioButtonChange(repeatEndsOption: RepeatEndsOption): void {
    switch (repeatEndsOption) {
      case RepeatEndsOption.ON:
        this.customRecurrenceForm.controls.repeatEndsOn.enable();
        this.customRecurrenceForm.controls.repeatEndsAfter.disable();
        this.customRecurrenceForm.controls.repeatEndsAfter.setValue(null);
        break;
      case RepeatEndsOption.AFTER:
        this.customRecurrenceForm.controls.repeatEndsAfter.enable();
        this.customRecurrenceForm.controls.repeatEndsOn.disable();
        this.customRecurrenceForm.controls.repeatEndsOn.setValue(null);
        break;
      case RepeatEndsOption.NEVER:
        this.customRecurrenceForm.controls.repeatEndsAfter.disable();
        this.customRecurrenceForm.controls.repeatEndsOn.disable();
        this.customRecurrenceForm.controls.repeatEndsOn.setValue(null);
        this.customRecurrenceForm.controls.repeatEndsAfter.setValue(null);
        break;
    }
  }

  hasAnyRequiredError(): boolean {
    return (
      Object.values(this.customRecurrenceForm.controls).filter((control) => control.hasError('required')).length > 0
    );
  }
}
