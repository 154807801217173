import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { User } from '@app/shared/models/user.interface';
import { NgClass, NgStyle } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import { MatInput } from '@angular/material/input';
import { BuildingAuthorityType, BuildingAuthorityType_ } from '@app/shared/models/building-authority-type';
import { Building } from '@app/shared/models/building.interface';
import { BuildingMetadataService } from '@services/building-metadata/building-metadata.service';
import { BuildingAuthorization } from '@app/shared/models/building-authorization.interface';
import { AdministrationModule } from '@app/administration/administration.module';
import { UserService } from '@services/user/user.service';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatChip } from '@angular/material/chips';
import { ToastService } from '@services/toast/toast.service';
import { forkJoin, of } from 'rxjs';
import { BuildingNotificationPreference } from '@app/shared/models/user-info.interface';

@Component({
  selector: 'app-user-info',
  standalone: true,
  imports: [
    FormsModule,
    NgStyle,
    NgClass,
    AuthorizationModule,
    MatInput,
    AdministrationModule,
    ReactiveFormsModule,
    MatButton,
    MatIcon,
    MatTooltip,
    MatChip
  ],
  templateUrl: './user-info.component.html',
  styleUrl: './user-info.component.scss'
})
export class UserInfoComponent implements OnInit, OnChanges {
  @Input() user: User;

  isEditable = false;
  isBusy = false;
  form: FormGroup;

  constructor(
    private building: BuildingMetadataService,
    private readonly formBuilder: FormBuilder,
    private userService: UserService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.userService.isLocal().subscribe((isLocal) => (this.isEditable = !isLocal));
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.form = this.formBuilder.group({
      emailAddress: [this.user?.emailAddress, Validators.required],
      ...this.createBuildingControls()
    });
  }

  createBuildingControls(): { [key: string]: FormControl } {
    const controls = {};
    this.user?.buildingAuthorizations.forEach((authorization: BuildingAuthorization) => {
      controls[authorization.buildingId] = authorization.maintenanceUpdates;
    });
    return controls;
  }

  checkboxControl(id: number): FormControl {
    return this.form.get(`${id}`) as FormControl;
  }

  checked(id: number): boolean {
    return this.checkboxControl(id).getRawValue();
  }

  toggleCheckbox(id: number): void {
    this.checkboxControl(id).setValue(!this.checked(id));
    this.form.markAsDirty();
  }

  humanizeAuthorities(types: BuildingAuthorityType[]): string[] {
    return (types as unknown as BuildingAuthorityType_[])
      .map((t) => BuildingAuthorityType.fromString(t))
      .filter((t) => t != null)
      .map((t) => t.getName())
      .sort();
  }

  produceBuildingThumbnailImageStyle(building: Building): any {
    const url = building ? this.building.thumbnailUrl(building) : undefined;
    return { 'background-image': url ? 'url(' + url + ')' : 'none' };
  }

  showNotificationWarning(permission: BuildingAuthorization): boolean {
    const hasMaintenanceUpdateRole = this.hasBuildingAuthority(
      permission,
      BuildingAuthorityType.MANAGE_MAINTENANCE_UPDATES
    );
    const hasEmergencyLightingRole = this.hasBuildingAuthority(
      permission,
      BuildingAuthorityType.MANAGE_EMERGENCY_LIGHTING
    );

    return (!hasMaintenanceUpdateRole || !this.checked(permission.buildingId)) && hasEmergencyLightingRole;
  }

  private hasBuildingAuthority(permission: BuildingAuthorization, authority: BuildingAuthorityType): boolean {
    return permission.authorities.some((auth) => auth.toString() === authority.toString());
  }

  submit(): void {
    this.isBusy = true;
    const isFieldDirty = this.form.get('emailAddress').dirty;
    const preferences: BuildingNotificationPreference[] = this.user.buildingAuthorizations
      .filter(
        (ba) => ba.authorities.filter((authority) => 'MANAGE_MAINTENANCE_UPDATES' === authority.toString()).length > 0
      )
      .map((ba) => {
        return { buildingId: ba.building.id, maintenanceUpdates: this.form.get(`${ba.building.id}`).getRawValue() };
      });

    this.user.emailAddress = this.form.get('emailAddress').getRawValue();
    this.user.buildingAuthorizations.forEach((authorization: BuildingAuthorization) => {
      authorization.maintenanceUpdates = this.checked(authorization.buildingId);
    });

    const updateUser = isFieldDirty ? this.userService.updateUser(this.user) : of(null);
    const updateBuildingPreference = this.userService.updateBuildingPreference(preferences);

    forkJoin([updateUser, updateBuildingPreference]).subscribe({
      next: () => {
        this.isEditable = true;
        this.isBusy = false;
        this.toastService.success({
          message: 'Your user information was successfully saved!!',
          dataCy: 'user-info-save-success-toast'
        });
      },
      error: () => {
        this.isEditable = true;
        this.isBusy = false;
        this.toastService.error({
          message: 'Error saving user notification preferences',
          dataCy: 'user-preference-error-toast'
        });
      }
    });
  }

  reset(): void {
    this.isBusy = true;
    this.form.reset();
    this.user?.buildingAuthorizations.forEach((authorization: BuildingAuthorization) => {
      this.checkboxControl(authorization.buildingId).setValue(authorization.maintenanceUpdates);
    });
    this.form.get('emailAddress').setValue(this.user.emailAddress);
    this.isBusy = false;
  }
}
