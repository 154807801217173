import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { User } from '@app/shared/models/user.interface';
import { GlobalAuthority } from '@app/shared/models/global-authority';
import { ManagingCompanyService } from '@services/managing-company.service';
import { Observable } from 'rxjs';
import { ManagingCompany } from '@app/shared/models/building.interface';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { AsyncPipe, NgFor } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss'],
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    MatOption,
    NgFor,
    MatDialogActions,
    MatButton,
    AsyncPipe
  ]
})
export class UserDialogComponent implements OnInit {
  form: FormGroup;
  user: User;
  managingCompanies$: Observable<ManagingCompany[]>;
  globalAuthorities: GlobalAuthority[];

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) user: User,
    private dialogRef: MatDialogRef<UserDialogComponent>,
    private managingCompanyService: ManagingCompanyService
  ) {
    this.user = user;
    this.managingCompanies$ = this.managingCompanyService.getManagingCompanies();
    this.globalAuthorities = GlobalAuthority.all();

    this.form = this.fb.group({
      emailAddress: [{ value: user.emailAddress, disabled: true }],
      authMethod: [{ value: user.authMethod, disabled: true }],
      managingCompany: [user.managingCompanyId],
      globalAuthorities: [user.globalAuthorities]
    });
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }

  save() {
    console.log('save');
    console.log(this.form.value);
    this.dialogRef.close(this.form.value);
  }
}
