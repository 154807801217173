import { Component, Input } from '@angular/core';
import { SharedComponentsModule } from '@app/shared/shared-components.module';

@Component({
  selector: 'app-panel',
  imports: [SharedComponentsModule],
  templateUrl: './panel.component.html',
  styleUrl: './panel.component.scss'
})
export class PanelComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() disabled = false;
  @Input() tooltip: string;

  constructor() {}
}
