import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICircadianCurve } from '@app/shared/models/circadian-curve.interface';
import { CircadianCurveService } from '@app/shared/services/circadian-curve.service';
import { NgClass, NgIf } from '@angular/common';
import { CircadianCurveTileComponent } from '../circadian-curve-tile/circadian-curve-tile.component';
import { FormCircadianCurveComponent } from '../form-circadian-curve/form-circadian-curve.component';

@Component({
  selector: 'app-circadian-curve-summary',
  templateUrl: './circadian-curve-summary.component.html',
  styleUrls: ['./circadian-curve-summary.component.scss'],
  imports: [NgClass, NgIf, CircadianCurveTileComponent, FormCircadianCurveComponent]
})
export class CircadianCurveSummaryComponent implements OnInit {
  @Input() public isNew = false;
  @Input() public details: ICircadianCurve;
  @Input() public curvesList: ICircadianCurve[];
  @Output() public refreshListEmitter = new EventEmitter<ICircadianCurve>();
  @Output() public closeNewFormEmitter = new EventEmitter<{}>();
  @Output() public collapseEmitter = new EventEmitter<{}>();
  @Output() public editRankEmitter = new EventEmitter<{}>();

  private isBusy: boolean;
  private isControlActionsMenuActive: boolean;
  public curveId: number;
  public curveIndex: number;

  constructor(private circadianService: CircadianCurveService) {}

  ngOnInit(): void {
    if (this.isNew) {
      this.circadianService.getStandardCurve().subscribe((curve) => {
        this.details = curve;
      });
    }
    this.curveId = this.curvesList.indexOf(this.details);
  }

  public deleteCurve(): void {
    this.refreshListEmitter.emit();
  }

  public getActiveActionIndex(): number {
    if (this.details.enabled) {
      return 0;
    }
    return 1;
  }

  public getControlActionsClass(): string {
    return 'action-' + this.getActiveActionIndex();
  }

  public getControlActionClass(index: number): string {
    return this.isControlActionsMenuActive || this.getActiveActionIndex() === index ? 'active' : '';
  }

  public getStatusLabelClass(): string {
    if (this.isBusy) {
      return 'or-pulsate';
    } else {
      return '';
    }
  }

  public getSummaryStateClass(): string {
    let style = '';
    if (this.details.active) {
      style = 'or-schedule-summary-header-running';
    }
    return style;
  }

  public saveCurve(): void {
    this.refreshListEmitter.emit(this.details);
  }

  public getEditingStatus(): boolean {
    return this.details.editingRank;
  }

  public toggleCollapse(): void {
    if (this.details.editable) {
      this.details.isCollapsed = !this.details.isCollapsed;
    }
  }

  public closeNewForm(): void {
    this.closeNewFormEmitter.emit();
  }

  public emitEditRankEvent(): void {
    this.editRankEmitter.emit();
  }

  /**
   * Activate/deactivate the curve
   */
  public toggleActiveState(isActive: boolean): void {
    const curve = this.details;
    curve.active = isActive;

    if (!curve.enabled) {
      curve.active = false;
    }
    this.updateCurve(curve);
    this.isControlActionsMenuActive = isActive;
  }

  public toggleControlActionsMenu(isActive: boolean): void {
    this.isControlActionsMenuActive = isActive;
  }

  private updateCurve(curve: ICircadianCurve): void {
    this.isBusy = true;
    this.circadianService.updateCurve(curve).subscribe((updatedCurve) => {
      this.details = updatedCurve;
      this.isBusy = false;
      this.refreshListEmitter.emit();
    });
  }

  public moveUp(): void {
    if (!this.isMovePossible()) {
      return;
    }
    this.curveIndex = this.curvesList.indexOf(this.details);
    if (this.curveIndex === 0) {
      return;
    }

    [this.curvesList[this.curveIndex], this.curvesList[this.curveIndex - 1]] = [
      this.curvesList[this.curveIndex - 1],
      this.curvesList[this.curveIndex]
    ];
    this.curvesList[this.curveIndex - 1].rank = this.curveIndex;
    this.curvesList[this.curveIndex].rank = this.curveIndex + 1;
    this.curvesList.forEach((curve) => (curve.rank = this.curvesList.indexOf(curve) + 1));
    this.emitEditRankEvent();
  }

  public moveDown(): void {
    if (!this.isMovePossible()) {
      return;
    }
    this.curveIndex = this.curvesList.indexOf(this.details);
    if (this.curveIndex === this.curvesList.length - 1) {
      return;
    }

    [this.curvesList[this.curveIndex + 1], this.curvesList[this.curveIndex]] = [
      this.curvesList[this.curveIndex],
      this.curvesList[this.curveIndex + 1]
    ];
    this.curvesList[this.curveIndex + 1].rank = this.curveIndex + 2;
    this.curvesList[this.curveIndex].rank = this.curveIndex + 1;
    this.curvesList.forEach((curve) => (curve.rank = this.curvesList.indexOf(curve) + 1));
    this.emitEditRankEvent();
  }

  private isMovePossible(): boolean {
    if (this.curvesList.find((curve) => curve.isCollapsed === false) === undefined) {
      return true;
    }
    if (confirm('Changing the order of curves will discard unsaved changes in the open curve(s). Continue anyway?')) {
      this.collapseEmitter.emit();
      return true;
    }
    return false;
  }

  public isFirst(): boolean {
    const lowestRank = this.curvesList.map((curve) => curve.rank).sort();
    return this.details.rank === lowestRank[0];
  }

  public isLast(): boolean {
    const highestRank = this.curvesList
      .map((curve) => curve.rank)
      .sort()
      .reverse();
    return this.details.rank === highestRank[0];
  }
}
