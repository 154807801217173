import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { Gateway } from '@app/api/building/gateway/Gateway';
import { NgIf } from '@angular/common';
import { JsonComparisonComponent } from '../json-comparison/json-comparison.component';
import { MatButton } from '@angular/material/button';

export interface DialogData {
  gateway: Gateway;
  left: any;
  right: any;
  isEqual: boolean;
  confirmCallback: () => void;
}

@Component({
  selector: 'app-compare-config-dialog',
  templateUrl: './compare-config-dialog.component.html',
  styleUrls: ['./compare-config-dialog.component.scss'],
  imports: [NgIf, MatDialogTitle, MatDialogContent, JsonComparisonComponent, MatDialogActions, MatButton]
})
export class CompareConfigDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CompareConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  public onCloseClick(): void {
    this.dialogRef.close();
  }

  public onConfirmClick(): void {
    this.dialogRef.close();
    this.data.confirmCallback();
  }
}
