import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, from, mergeMap, Observable, of, throwError } from 'rxjs';
import { SelectableNode, SensorNode, SensorNodeDTO } from '@app/shared/models/sensor-node';
import { SensorNodeResource } from '@app/shared/resources/sensor-node.resource';
import { NodeSelectorType } from '@app/shared/models/node-selector';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { EmDriverResource } from '@app/shared/resources/em-driver.resource';
import { LuminaireDriverResource } from '@app/shared/resources/luminaire-driver.resource';
import { DISCRIMINATOR, Selectable } from '@app/shared/models/selectable.interface';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SensorNodeBleResource } from '@app/shared/resources/sensor-node-ble.resource';
import { EmDriver } from '@app/shared/models/em-driver';
import { FloorResource } from '@app/shared/resources/floor.resource';
import { DuplicateMappingResource } from '@app/shared/resources/duplicate-mapping.resource';
import { Coordinate } from '@app/shared/utils/coordinate';
import { SensorNodeChangeHistory } from '@app/shared/models/sensor-node-change-history';
import { LampTypeService } from '@services/lamp-type.service';
import { LuminaireDriver } from '@app/shared/models/luminaire-driver';
import { LampType } from '@app/shared/models/lamp-type.interface';
import { BeaconSettingService } from '@services/beacon-setting.service';
import { BeaconSetting } from '@app/shared/models/beacons-setting.interface';
import { LightingConfigurationService } from '@services/lighting-configuration.service';
import { LightingConfiguration } from '@app/shared/models/lighting-configuration';

export interface SelectionBox {
  top: number;
  left: number;
  width: number;
  height: number;
}

@Injectable({
  providedIn: 'root'
})
export class SensorNodeService {
  private selectedEntitiesSource = new BehaviorSubject<Selectable[]>([]);
  private floorSensorNodesSource = new BehaviorSubject<SensorNode[]>([]);
  private floorSensorNodes$ = this.floorSensorNodesSource.asObservable();
  private radius1 = 22;
  private radius2 = this.radius1 + 16; // (12 / 2) + 10
  private radius3 = this.radius2 + 16; // (12 / 2) + 10;
  private radiusForPN = 65;
  private readonly ONLY_MAPPED_CONNECTED_MSG = 'Please only select mapped and connected nodes';
  private readonly ONLY_SN3_FOR_BLE =
    'Ensure only connected nodes are selected. Please note: BLE scanning messages are not supported on Passive Node devices';
  private nodesWithTags: SensorNode[] = null;
  private currentBuildingId: number = null;
  private isFetchingNodesWithTagsInProgress = false;

  constructor(
    private readonly sensorNodeResource: SensorNodeResource,
    private readonly emDriverResource: EmDriverResource,
    private readonly luminaireDriverResource: LuminaireDriverResource,
    private readonly floorResource: FloorResource,
    private readonly duplicateMappingResource: DuplicateMappingResource,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly bleResource: SensorNodeBleResource,
    private readonly lampTypeService: LampTypeService,
    private readonly beaconSettingService: BeaconSettingService,
    private readonly lightingConfigurationService: LightingConfigurationService
  ) {}

  private toMap(values: { key: number; value: number }[]): Record<string, number> {
    return values.reduce((acc, curr) => {
      acc[curr.key.toString()] = curr.value;
      return acc;
    }, {});
  }

  get selectedEntities(): Selectable[] {
    return this.selectedEntitiesSource.getValue();
  }

  get currentFloorNodes(): Selectable[] {
    return this.floorSensorNodesSource.getValue();
  }

  getScaledNodesCloseToPosition(position: Coordinate, scaleModifier: number, distanceThreshold = 25): SensorNode[] {
    const closeNodes: SensorNode[] = [];
    const selectedNodes = this.selectedEntities;
    const floorNodes = this.floorSensorNodesSource.getValue();
    selectedNodes.forEach((selectedNode) => {
      floorNodes.forEach((originalNode) => {
        const distance = position.distance(
          new Coordinate(originalNode.x / scaleModifier, originalNode.y / scaleModifier)
        );
        if (distance < distanceThreshold) {
          closeNodes.push(originalNode);
        }
      });
    });
    return closeNodes;
  }

  moveAllNodesRelativeToDraggedNode(
    draggedNode: Selectable,
    diff: Coordinate,
    nodeMoveHistoryLastChange: Map<number, SensorNodeChangeHistory>
  ): void {
    this.floorSensorNodesSource
      .getValue()
      .filter((node) => node.id !== draggedNode.id)
      .forEach((node) => {
        const lastChangeNode = nodeMoveHistoryLastChange.get(node.id) || new SensorNodeChangeHistory(node);
        lastChangeNode.node.update(node.x + diff.x, node.y + diff.y);
        nodeMoveHistoryLastChange.set(node.id, lastChangeNode);
      });
  }

  updateNodesAfterMapping(buildingId: number): void {
    const nodesOnFloor = this.floorSensorNodesSource.getValue();
    const unmappedNodeIds = nodesOnFloor
      .filter((node) => !node.properlyMapped || node.address == null)
      .map((node) => node.id);
    const nodeIds = nodesOnFloor.map((node) => node.id);
    forkJoin([
      this.sensorNodeResource.getNodesByIds(unmappedNodeIds),
      this.duplicateMappingResource.getDuplicateAddressMappingsByNodeIds(buildingId, nodeIds)
    ]).subscribe(([fetchedUnmappedNodes, duplicatesMappings]) => {
      const unmappedSensorNodes = fetchedUnmappedNodes.map((node) => new SensorNode(node));
      const nodesOnFloorWithoutUnmappedNodes = nodesOnFloor.filter((node) => !unmappedNodeIds.includes(node.id));
      Object.entries(duplicatesMappings).forEach(([nodeIdString, nodeDuplicateMappings]) => {
        const nodeId = Number(nodeIdString);
        const idx1 = unmappedSensorNodes.findIndex((node) => node.id === nodeId);
        const idx2 = nodesOnFloorWithoutUnmappedNodes.findIndex((node) => node.id === nodeId);
        if (idx1 !== -1) {
          unmappedSensorNodes[idx1].duplicateAddressMappings = nodeDuplicateMappings;
        }
        if (idx2 !== -1) {
          nodesOnFloorWithoutUnmappedNodes[idx2].duplicateAddressMappings = nodeDuplicateMappings;
        }
      });
      this.floorSensorNodesSource.next([...unmappedSensorNodes, ...nodesOnFloorWithoutUnmappedNodes]);
    });
  }

  getCurrentFloorSelectedEntities$(): Observable<Selectable[]> {
    return this.selectedEntitiesSource.asObservable();
  }

  // deprecated
  // FIXME: this method is used by old floorplan sensor nodes
  isNodeSelected(nodeId: number): boolean {
    return this.selectedEntities.find((e) => e.id === nodeId) != null;
  }

  getCurrentFloorNodes$(): Observable<SensorNode[]> {
    return this.floorSensorNodes$;
  }

  getNodeDataForElmtLogs(nodeSelector: NodeSelectorType): Observable<SensorNode[]> {
    return this.sensorNodeResource.getNodeDataForElmtLogs(nodeSelector).pipe(
      map((nodes) => {
        const sns: SensorNode[] = [];
        nodes.forEach((node) => {
          const sensorNode = SensorNode.from(node);
          return sns.push(sensorNode);
        });
        return sns;
      })
    );
  }

  fetchNodes(floorId: number, buildingId: number): void {
    this.sensorNodeResource
      .getNodesForFloor(floorId)
      .pipe(
        mergeMap((nodes) => {
          const nodeIds = nodes.map((n) => n.id);
          const emDrivers$ = this.emDriverResource.getByNodeIds(nodeIds).pipe(catchError(() => of([] as EmDriver[])));
          const luminaireDrivers$ = this.luminaireDriverResource
            .getByNodeIds(nodeIds)
            .pipe(catchError(() => of([] as LuminaireDriver[])));
          const tagsForNodes$ = this.sensorNodeResource.getTagsForNodes(nodeIds).pipe(catchError(() => of({})));
          const duplicateMappings$ = this.duplicateMappingResource
            .getDuplicateAddressMappingsByNodeIds(buildingId, nodeIds)
            .pipe(catchError(() => of({})));
          const duplicateMappingsByGroup$ = this.duplicateMappingResource
            .getDuplicateGroupMappings(buildingId, nodeIds)
            .pipe(catchError(() => of({})));
          const lampTypes$ = this.lampTypeService.getLampTypes(buildingId).pipe(catchError(() => of([] as LampType[])));
          const beaconsSetting$ = this.beaconSettingService
            .getBeaconSettingsForFloorAndNodeIds(floorId, nodeIds)
            .pipe(catchError(() => of([] as BeaconSetting[])));
          const lightingConfigs$ = this.lightingConfigurationService
            .getLightingConfigsForFloor(floorId)
            .pipe(catchError(() => of([] as LightingConfiguration[])));
          return forkJoin([
            of(nodes),
            emDrivers$,
            luminaireDrivers$,
            tagsForNodes$,
            duplicateMappings$,
            duplicateMappingsByGroup$,
            lampTypes$,
            beaconsSetting$,
            lightingConfigs$
          ]);
        }),
        map(
          ([
            nodes,
            emDrivers,
            lumeDrivers,
            tagsForNodes,
            duplicateMap,
            duplicateMappingsByGroup,
            lampTypes,
            beaconSettings,
            lightingConfigs
          ]) => {
            nodes.forEach((node: SensorNode) => {
              node.emDrivers = emDrivers.filter((emDriver) => emDriver.nodeId === node.id);
              node.luminaireDrivers = lumeDrivers
                .filter((lumes) => lumes.nodeId === node.id)
                .map((v) => {
                  v.lampType = lampTypes.find((lt) => lt.lampTypeId === v.lampTypeId);
                  return v;
                });
              node.tags = tagsForNodes[node.id] || [];
              node.duplicateAddressMappings = duplicateMap[node.id] || [];
              node.duplicateGroupMappings = duplicateMappingsByGroup[node.id] || [];
              node.beaconSetting = beaconSettings.find((beaconSetting) => beaconSetting.sensorNodeId === node.id);
              let lightingConfig = lightingConfigs.find((lightingConfig) => lightingConfig.nodeId === node.id);
              if (lightingConfig) {
                lightingConfig = LightingConfiguration.fromResponse(lightingConfig);
                lightingConfig.setScene(node.scene);
                node.lightingConfiguration = lightingConfig;
              }
            });
            return this.prepareNodes(nodes);
          }
        ),
        shareReplay({ refCount: true })
      )
      .subscribe((fetchedNodes) => {
        this.floorSensorNodesSource.next(fetchedNodes);
      });
  }

  isNodesAvailableForFloor(floorId: number): Observable<boolean> {
    return this.sensorNodeResource.getNodesForFloor(floorId).pipe(map((value) => value && value.length > 0));
  }

  /**
   * Based on the total number of elements to be rendered we calculate the coordinates
   * of subscribers (driver/inverter/PN) around a publisher (SN3)
   * Complexity of current implementation
   * O(n): one run through all nodes to partition them into publishers and subscribers
   * O(x * y): x is number of publishers, y is number of subscribers
   * i.e. O(n) + O(x * y) for drawing SN3s and PNs on floor
   * @param {SensorNodeDTO[]} nodes
   * @returns {SensorNodeDTO[]}
   * @private
   */
  private prepareNodes(nodes: SensorNodeDTO[]): SensorNode[] {
    // Partition the nodes into publishers and subscribers
    const [mappedNodes, unmappedNodesWhichAreSubscribers] = nodes.reduce(
      (result: [SensorNode[], SensorNode[]], element) => {
        /**
         * draw the subscriber only when
         * - it is of type PN
         * - it doesn't have x and y
         */
        const isSubscriber = element.nodeType === 'PN' && (element.x == null || element.y == null);
        // if subscriber, push result to second array, else into first array
        const item = SensorNode.from(element);
        result[isSubscriber ? 1 : 0].push(item);
        return result;
      },
      [[], []]
    );

    // iterate through each publisher to draw its drivers on floor
    mappedNodes.forEach((node) => {
      // A mapped node can be SN3 or a PN (publisher or a subscriber). We only want to draw PNs around SN3s
      // and not have a situation where PNs surround a PN
      const subscribersToThisNode =
        node.nodeType === 'PN' ? [] : unmappedNodesWhichAreSubscribers.filter((sub) => sub.groupId === node.groupId);
      // keeping track of driver/inverter count, this variable gets updated in updateDriverXandY
      // tslint:disable-next-line
      let currentDriverCount = 0;
      // keeping track of subscriber count, this variable gets updated in updateSubscriberXandY
      // tslint:disable-next-line
      let currentSubscriberCount = 0;
      this.placeDriversAroundNode(node, currentDriverCount);
      // iterate through all subscribers to draw them around their publisher
      // tslint:disable-next-line
      subscribersToThisNode.forEach(
        this.updateSubscriberXandY(currentSubscriberCount, subscribersToThisNode.length / 2, node)
      );
    });
    // draw drivers/em inverters around subscriber too
    unmappedNodesWhichAreSubscribers.forEach((subscriber) => {
      // keeping track of driver/inverter count, this variable gets updated in updateDriverXandY
      // tslint:disable-next-line
      let currentDriverCount = 0;
      this.placeDriversAroundNode(subscriber, currentDriverCount);
    });
    return mappedNodes.concat(unmappedNodesWhichAreSubscribers);
  }

  private placeDriversAroundNode(node: SensorNode, currentDriverCount: number): void {
    const { radian1, radian2, radian3 } = this.generateRadiansForDrivers(node);
    node.emDrivers?.forEach(this.updateDriverXandY(currentDriverCount, radian1, radian2, radian3, node));
    // tslint:disable-next-line
    node.luminaireDrivers?.forEach(
      this.updateDriverXandY(currentDriverCount + node.emDrivers?.length, radian1, radian2, radian3, node)
    );
  }

  deleteSelectedNodes(nodes: number[]): Observable<{}> {
    return this.sensorNodeResource.deleteNodes(nodes);
  }

  /**
   * Generating 3 different angles based on the number of drivers that will be drawn
   * @param {SensorNode} node
   * @returns {{radian1: number, radian2: number, radian3: number}}
   * @private
   */
  private generateRadiansForDrivers(node: SensorNode): {
    radian1: number;
    radian2: number;
    radian3: number;
  } {
    // TODO: in future parameter node can be generalized to a "publisher"
    //  which will allow us to add any type of driver to it
    const twoPi = 2 * Math.PI;
    let radian1 = twoPi / 8;
    let radian2 = radian1 / 2;
    let radian3 = radian2;
    const total =
      (node.emDrivers == null ? 0 : node.emDrivers.length) +
      (node.luminaireDrivers == null ? 0 : node.luminaireDrivers.length);
    // Max can support 8+16+16=40 nodes without overlap
    if (total < 8) {
      radian1 = twoPi / total;
    } else if (total < 24) {
      radian2 = twoPi / (total - 8);
    } else {
      radian3 = twoPi / (total - 24);
    }
    return {
      radian1,
      radian2,
      radian3
    };
  }

  /**
   * Render a subscriber around the passed publisher (SN3) only if the subscriber (PN) doesn't have x,y coordinates
   * i.e. the subscriber hasn't been manually mapped<br/>
   * Calculating the radian at which the PN should be drawn in clockwise fashion (-&pi;/2 phase)<br/>
   * Based on the calculation below, max number of PNs that can be drawn without overlap is 20<br/>
   * @param {number} currentCount - the current count of the subscriber being drawn
   * @param {number} factor - Higher the factor, lesser the gap between 2 consecutive subscribers.
   * In current implementation the factor is set to total number of subscriber &divide; 2
   * @param {SensorNodeDTO} publisher - The publisher (SN3) around with the PN will be drawn
   * @private
   */
  private updateSubscriberXandY(
    currentCount: number,
    factor: number,
    publisher: Selectable
  ): (subscriber: Selectable) => void {
    return (subscriber) => {
      const radian = (Math.PI / factor) * currentCount - Math.PI / 2;
      subscriber.x = publisher.x + this.radiusForPN * Math.cos(radian);
      subscriber.y = publisher.y + this.radiusForPN * Math.sin(radian);
      currentCount++;
    };
  }

  private updateDriverXandY(
    count: number,
    radian1: number,
    radian2: number,
    radian3: number,
    node: Selectable
  ): (driver: Selectable) => void {
    const piBy2 = Math.PI / 2;
    return (driver) => {
      let radius;
      let radian;
      if (count < 8) {
        radian = radian1 * count - piBy2;
        radius = this.radius1;
      } else if (count < 24) {
        radian = radian2 * (count - 8) - piBy2;
        radius = this.radius2;
      } else {
        radian = radian3 * (count - 24) - piBy2;
        radius = this.radius3;
      }

      driver.x = node.x + radius * Math.cos(radian);
      driver.y = node.y + radius * Math.sin(radian);
      count++;
    };
  }

  /**
   * This is used to populate "value" prop of the sensor node to be used by
   * analytics page to show heat map. This will need improving once the old floorplan is gotten rid of
   * @param {SensorNode[]} nodes
   * @param {{key: number, value: number}[]} values
   * @param valueSuffix
   */
  populateHeatMapValues(nodes: SensorNode[], values: { key: number; value: number }[], valueSuffix: string): void {
    const valueMap = this.toMap(values);
    if (Array.isArray(nodes) && nodes.length > 0) {
      nodes.forEach((node) => {
        node.value = valueMap[node.address];
        node.valueSuffix = valueSuffix;
      });
    }
  }

  /**
   * duplicate of method in old sensor node service
   * @param {SensorNode[]} nodes
   * @param {{key: number, value: number}[]} values
   */
  updateNodesLightLevel(nodes: SensorNode[], values: { key: number; value: number }[]): void {
    const valueMap = this.toMap(values);
    nodes.forEach((node) => {
      node.lightLevel = valueMap[node.address];
    });
  }

  /**
   * duplicate of method in old sensor node service
   * @param {SensorNode[]} nodes
   * @param {{key: number, value: number}[]} values
   */
  updateNodesPresence(nodes: SensorNode[], values: { key: number; value: number }[]): void {
    const valueMap = this.toMap(values);
    nodes.forEach((node) => {
      node.presence = valueMap[node.address];
    });
  }

  // tslint:disable-next-line
  getNodeCountsForElmtSchedules(
    nodeSelectors: { id: number; selector: NodeSelectorType }[]
  ): Observable<Record<number, number>> {
    return this.sensorNodeResource.getNodeCountsForElmtSchedules(nodeSelectors);
  }

  private removeEntityFromSelection(entityId: number): void {
    const currentSelection = this.selectedEntities;
    const idxOfEntityToBeRemoved = this.getEntityPosition(entityId, currentSelection);
    // mark the entity as unselected to change its style on the floorplan
    currentSelection[idxOfEntityToBeRemoved].selected = false;
    currentSelection.splice(idxOfEntityToBeRemoved, 1);
    this.selectedEntitiesSource.next([...currentSelection]);
  }

  private getEntityPosition(id: number, selection: Selectable[]): number {
    return selection.findIndex((e) => e.id === id);
  }

  private addEntityToSelection(entity: Selectable, cumulative = false): void {
    // mark the entity as selected
    entity.selected = true;
    if (cumulative) {
      // when cumulative mode is on, existing entities and new entity are marked as selected
      this.selectedEntitiesSource.next([...this.selectedEntities, entity]);
    } else {
      // when cumulative mode is off, only the new entity will be marked as selected
      this.selectedEntities.filter((e) => e.id !== entity.id).forEach((e) => (e.selected = false));
      this.selectedEntitiesSource.next([entity]);
    }
  }

  clearSelection(): void {
    this.removeEntitiesFromSelection();
  }

  selectAll(entities: Selectable[]): void {
    entities.forEach((entity) => {
      entity.selected = true;
    });
    this.selectedEntitiesSource.next([...entities]);
  }

  getAllNodes(isElmtPage: boolean): Selectable[] {
    let nodesToBeSelected: Selectable[] = this.floorSensorNodesSource
      .getValue()
      .filter((n) => (isElmtPage ? n.emDrivers?.length > 0 : true));
    if (isElmtPage) {
      const allEmDrivers = nodesToBeSelected.flatMap((n: SelectableNode) => n.emDrivers as Selectable[]);
      nodesToBeSelected = nodesToBeSelected.concat(allEmDrivers);
    }
    return nodesToBeSelected;
  }

  updateQueryParams(): void {
    const queryParams: Params = { em: [], n: [], ld: [] };
    this.selectedEntities.forEach((item) => {
      switch (item.discriminator) {
        case DISCRIMINATOR.EM_DRIVER:
          queryParams.em.push(item.id);
          break;
        case DISCRIMINATOR.LUMINAIRE:
          queryParams.ld.push(item.id);
          break;
        default:
          queryParams.n.push(item.id);
      }
    });
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge' // remove to replace all query params by provided
    });
  }

  toggleEntitySelection(entity: Selectable, cumulative = false): void {
    if (entity.selected && cumulative) {
      this.removeEntityFromSelection(entity.id);
    } else {
      this.addEntityToSelection(entity, cumulative);
    }
  }

  updateEntitySelection(entity: SensorNode): void {
    const newSelection = this.selectedEntities.map((e) => {
      if (e.id === entity.id) {
        e = entity;
      }
      return e;
    });
    this.selectedEntitiesSource.next(newSelection);
  }

  updateCurrentFloorNodes(node: SensorNode): void {
    const newFloorNodes = this.currentFloorNodes.map((n) => {
      if (n.id === node.id) {
        n = node;
      }
      return n as SensorNode;
    });
    this.floorSensorNodesSource.next(newFloorNodes);
  }

  getNodesInSelectedArea(area: SelectionBox, isElmtPage: boolean): Selectable[] {
    const fetchedNodes = this.floorSensorNodesSource.getValue();
    const emDriversOnFloor = fetchedNodes.flatMap((n) => n.emDrivers);
    let selectableNodes: Selectable[] = [...fetchedNodes];
    if (isElmtPage) {
      selectableNodes = selectableNodes
        .concat(emDriversOnFloor)
        .filter(
          (x) =>
            x instanceof EmDriver ||
            (x instanceof SensorNode && (x as SensorNode).emDrivers != null && (x as SensorNode).emDrivers.length > 0)
        );
    }
    return this.getEntitiesInArea(selectableNodes, area);
  }

  private getEntitiesInArea(nodes: Selectable[], area: SelectionBox): Selectable[] {
    const nodesInArea = [];
    let currentNode: Selectable;
    let isInBoundsX: boolean;
    let isInBoundsY: boolean;
    for (const node of nodes) {
      currentNode = node;
      isInBoundsX = currentNode.x >= area.left && currentNode.x <= area.left + area.width;
      isInBoundsY = currentNode.y >= area.top && currentNode.y <= area.top + area.height;
      if (isInBoundsX && isInBoundsY) {
        nodesInArea.push(currentNode);
      }
    }
    return nodesInArea;
  }

  selectEntitiesInArea(nodesInArea: Selectable[], cumulativeMode = false): void {
    this.addEntitiesToSelection(nodesInArea, cumulativeMode);
  }

  /**
   * If cumulative mode is not set: Unselect the currently selected nodes
   * and then add only the new entities to selection
   * If cumulative mode is set: Add the new entities to the current selection
   * @param entitiesToSelect
   * @param cumulativeMode
   * @private
   */
  private addEntitiesToSelection(entitiesToSelect: Selectable[], cumulativeMode: boolean): void {
    entitiesToSelect.forEach((entity) => {
      entity.selected = true;
    });
    const currentlySelected = this.selectedEntities;
    if (cumulativeMode) {
      this.selectedEntitiesSource.next([...currentlySelected, ...entitiesToSelect]);
    } else {
      currentlySelected.forEach((entity) => {
        entity.selected = false;
      });
      this.selectedEntitiesSource.next([...entitiesToSelect]);
    }
    this.selectedEntities.forEach((e) => (e.selected = true));
  }

  private removeEntitiesFromSelection(): void {
    // if selected entities are not empty, unselect them
    if (this.selectedEntities && this.selectedEntities.length > 0) {
      this.selectedEntities.forEach((entity) => (entity.selected = false));
      this.selectedEntitiesSource.next([]);
    }
  }

  queryBleScanning(buildingId: number): Observable<void> {
    const nodeIds = this.selectedEntities
      .filter(
        (entity: SelectableNode) =>
          (entity.discriminator === DISCRIMINATOR.SN3 ||
            entity.discriminator === DISCRIMINATOR.HIM84 ||
            entity.discriminator === DISCRIMINATOR.HCD405 ||
            entity.discriminator === DISCRIMINATOR.HCD038) &&
          entity.connected
      )
      .map((entity) => entity.id);
    if (nodeIds.length > 0) {
      return this.bleResource
        .queryBleScanningForNodesAndBuilding(buildingId, nodeIds)
        .pipe(catchError(() => throwError(() => this.ONLY_SN3_FOR_BLE)));
    }
    return throwError(() => this.ONLY_SN3_FOR_BLE);
  }

  queryBleScanningForBuilding(buildingId: number): Observable<void> {
    return this.bleResource.queryBleScanningForBuilding(buildingId);
  }

  enableBleScanning(buildingId: number): Observable<void | string> {
    const nodeIds = this.selectedEntities
      .filter(
        (entity: SelectableNode) =>
          (entity.discriminator === DISCRIMINATOR.SN3 ||
            entity.discriminator === DISCRIMINATOR.HIM84 ||
            entity.discriminator === DISCRIMINATOR.HCD405 ||
            entity.discriminator === DISCRIMINATOR.HCD038) &&
          entity.connected
      )
      .map((entity) => entity.id);
    if (nodeIds.length > 0) {
      return this.bleResource
        .enableBleScanning(nodeIds, buildingId)
        .pipe(catchError(() => throwError(() => this.ONLY_SN3_FOR_BLE)));
    }
    return throwError(() => this.ONLY_SN3_FOR_BLE);
  }

  enableBleScanningForBuilding(buildingId: number): Observable<void | string> {
    return this.bleResource.enableBleScanningForBuilding(buildingId);
  }

  disableBleScanning(buildingId: number): Observable<void | string> {
    const nodeIds = this.selectedEntities
      .filter(
        (entity: SelectableNode) =>
          (entity.discriminator === DISCRIMINATOR.SN3 ||
            entity.discriminator === DISCRIMINATOR.HIM84 ||
            entity.discriminator === DISCRIMINATOR.HCD405 ||
            entity.discriminator === DISCRIMINATOR.HCD038) &&
          entity.connected
      )
      .map((entity) => entity.id);
    if (nodeIds.length > 0) {
      return this.bleResource
        .disableBleScanning(nodeIds, buildingId)
        .pipe(catchError(() => throwError(() => this.ONLY_SN3_FOR_BLE)));
    }
    return throwError(() => this.ONLY_SN3_FOR_BLE);
  }

  disableBleScanningForBuilding(buildingId: number): Observable<void | string> {
    return this.bleResource.disableBleScanningForBuilding(buildingId);
  }

  tagNodes(multiTag: { tagIds: number[]; nodeIds: number[] }): Observable<{}> {
    return this.sensorNodeResource.tagNodes(multiTag);
  }

  updateNode(node: SensorNode): Observable<SensorNode> {
    return this.sensorNodeResource.updateNode(node);
  }

  unTagNodes(multiTag: { tagIds: number[]; nodeIds: number[] }): Observable<{}> {
    return this.sensorNodeResource.unTagNodes(multiTag);
  }

  blinkNode(nodeId: number, buildingId: number): Observable<{}> {
    return this.sensorNodeResource.issueBlinkCommand(nodeId, buildingId);
  }

  saveNodes(floorId: number, pendingNodes: SensorNode[]): Observable<{}> {
    return this.floorResource.addNodes(floorId, pendingNodes);
  }

  createTemporaryNode(): SensorNode {
    return SensorNode.fromPosition({ x: null, y: null });
  }

  clearDriversForNodes(nodeIds: number[]): Observable<number> {
    return this.sensorNodeResource.clearDriversForNodes(nodeIds);
  }

  resetDriversForSelectedNodes(): Observable<string | number> {
    const selectedNodes = this.selectedEntities;
    const mappedAndConnectedNodes = selectedNodes.filter(
      (node: SelectableNode) => node.connected && node.properlyMapped
    );
    if (mappedAndConnectedNodes.length > 0) {
      return this.clearDriversForNodes(mappedAndConnectedNodes.map((n) => n.id));
    }
    return throwError(() => this.ONLY_MAPPED_CONNECTED_MSG);
  }

  saveChangedSensorNodes(): Observable<SensorNode[]> {
    return forkJoin(
      this.floorSensorNodesSource
        .getValue()
        .filter((node) => node.isChanged)
        .map((node) => this.updateNode(node))
    );
  }

  resetNodesWithTags(): void {
    this.nodesWithTags = null;
    this.currentBuildingId = null;
  }

  fetchNodesWithTagsForBuilding(buildingId: number): Observable<SensorNode[]> {
    return from(this.synchronizedFetchNodesWithTags(buildingId));
  }

  private async synchronizedFetchNodesWithTags(buildingId: number): Promise<SensorNode[]> {
    // If a task is already in progress, wait until it finishes
    if (this.isFetchingNodesWithTagsInProgress) {
      return new Promise((resolve) => {
        const checkInterval = setInterval(() => {
          if (!this.isFetchingNodesWithTagsInProgress) {
            clearInterval(checkInterval);
            resolve(this.internalFetchNodesWithTags(buildingId)); // Call the task again once the flag is cleared
          }
        }, 100); // Check every 100ms until the task is finished
      });
    }

    // Set the flag and execute the task
    this.isFetchingNodesWithTagsInProgress = true;
    try {
      return await this.internalFetchNodesWithTags(buildingId);
    } finally {
      this.isFetchingNodesWithTagsInProgress = false; // Reset the flag once the task is finished
    }
  }

  private internalFetchNodesWithTags(buildingId: number): Promise<SensorNode[]> {
    if (this.nodesWithTags && this.currentBuildingId == buildingId) {
      return of(this.nodesWithTags).toPromise();
    } else {
      return this.sensorNodeResource
        .getNodesForBuilding(buildingId)
        .pipe(
          switchMap((nodes) => {
            const nodeMap: Record<number, SensorNode> = {};
            nodes.forEach((node) => (nodeMap[node.id] = node));
            return this.sensorNodeResource.getTagsForNodes(nodes.map((node) => node.id)).pipe(
              map((nodeTags) => {
                Object.keys(nodeTags).forEach((nodeId) => {
                  nodeMap[nodeId].tags = nodeTags[nodeId];
                });
                this.nodesWithTags = Object.values(nodeMap);
                this.currentBuildingId = buildingId;
                return this.nodesWithTags;
              })
            );
          })
        )
        .toPromise();
    }
  }
}
