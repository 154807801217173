import { Component, Input } from '@angular/core';
import { OtapUpgradeTaskDTO, OtapUpgradeTaskInputParametersDTO } from '@app/shared/models/otap-upgrade-task.interface';
import { OtapUpgradeService } from '@services/otap-upgrade/otap.upgrade.service';
import { OtapUpgradeDTO } from '@app/shared/models/otap.upgrade.interface';
import { ToastService } from '@services/toast/toast.service';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatButton } from '@angular/material/button';
import { JobTasksTileComponent } from '../job-tasks-tile/job-tasks-tile.component';
import { FormJobTaskComponent } from '../form-job-task/form-job-task.component';

@Component({
  selector: 'app-job-tasks-summary',
  templateUrl: './job-tasks-summary.component.html',
  styleUrls: ['./job-tasks-summary.component.scss'],
  imports: [
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatButton,
    MatCardContent,
    JobTasksTileComponent,
    FormJobTaskComponent
  ]
})
export class JobTasksSummaryComponent {
  @Input() task: OtapUpgradeTaskDTO;
  @Input() job: OtapUpgradeDTO;
  @Input() index: number;
  @Input() jobStartEnabled: boolean;
  updatedTaskData: OtapUpgradeTaskInputParametersDTO[] | null = null;

  constructor(private otapUpgradeService: OtapUpgradeService, private toast: ToastService) {}

  onTaskDataChanged(updatedData: OtapUpgradeTaskInputParametersDTO[]) {
    this.updatedTaskData = updatedData;
  }

  onSend(task: OtapUpgradeTaskDTO) {
    const sendData = this.updatedTaskData || task.inputParameters;
    this.otapUpgradeService.startTask(task.jobId, task.id, sendData).subscribe({
      next: (upgradetask) => {
        if (upgradetask !== null) {
          this.jobStartEnabled = false;
        }
      },
      error: (error) => {
        this.toast.error({
          message: `${error.error}`,
          dataCy: 'task-cannot-be-started'
        });
      }
    });
  }
}
