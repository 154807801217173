import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { orderByTagName, Tag, TagType } from '@app/shared/models/tag.interface';
import { MultiselectOption as SelectOptionInterface } from '@app/shared/components/multiselect/multiselect-option';
import { MultiselectOption } from '@app/shared/models/multiselect-option';
import { SecurityService } from '@services/security.service';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { Observable, of } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { AsyncPipe, JsonPipe, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-inline-tags',
  templateUrl: './inline-tags.component.html',
  styleUrls: ['./inline-tags.component.scss'],
  imports: [MatSelect, MatOption, NgFor, NgIf, AsyncPipe, JsonPipe]
})
export class InlineTagsComponent implements OnInit {
  @Input() isEditable: boolean;
  @Input() selectedTagIds: number[] = []; // tags which are part of this curve
  @Input() isSelectionDisabled = false;
  @Input() tags: Tag[];
  @Input() displayTags: Tag[];
  @Output() updatedSelection = new EventEmitter<Tag[]>(); // this will inform parent about updated tag selection

  availableOptions: SelectOptionInterface<Tag>[] = []; // this is the updated list of tag options after a tag has been added or removed
  currentSelection: Tag[] = []; // tags that are linked to the entity
  private options: MultiselectOption<Tag>[] = []; // create options from the tags received

  private tagsAdded: number[];

  constructor(private securityService: SecurityService) {
    // add any component creation logic here
  }

  ngOnInit(): void {
    this.tagsAdded = [];
    const sortedTags = [...(this.tags || [])].sort(orderByTagName);
    const sortedDisplayTags = [...(this.displayTags || [])].sort(orderByTagName);
    sortedDisplayTags.forEach((tag) => {
      if (this.selectedTagIds?.includes(tag.id)) {
        this.currentSelection.push(tag);
      }
    });
    sortedTags.forEach((tag) => {
      this.options.push(new MultiselectOption<Tag>(tag.name, tag));
    });
    // populate dropdown if the component is editable
    if (this.isEditable) {
      // with all tag options received, only keep those which refer to tags that are not part of the curve
      this.availableOptions = this.getAvailableOptions();
    }
  }

  removeTag($index): void {
    this.currentSelection.splice($index, 1);
    this.updatedSelection.emit(this.currentSelection);
    this.updateAvailableOptions();
  }

  addActiveTag(value): void {
    const selectedTag = JSON.parse(value);
    this.tagsAdded.push(selectedTag.id);
    this.currentSelection.push(selectedTag);
    this.currentSelection.sort(orderByTagName);
    this.updatedSelection.emit(this.currentSelection);
    this.updateAvailableOptions();
  }

  private getAvailableOptions(): SelectOptionInterface<Tag>[] {
    return this.options.filter((tag) => {
      let check = true;
      if (tag.value.tagType === TagType.TENANT) {
        check = this.currentSelection.filter((t) => t.tagType === TagType.TENANT).length === 0;
      }
      return check && this.currentSelection.map((item) => item.id).indexOf(tag.value.id) < 0;
    });
  }

  private updateAvailableOptions(): void {
    this.availableOptions = this.getAvailableOptions();
  }

  public canEdit(tag: Tag): Observable<boolean> {
    if (this.isEditable && this.isOfTenantType(tag)) {
      if (this.tagsAdded.includes(tag.id)) {
        return of(this.isEditable);
      } else {
        return this.securityService.isAuthorizedForBuilding(BuildingAuthorityType.MANAGE_TENANT.value, tag.buildingId);
      }
    } else if (this.isEditable && this.isOfTenantMadeType(tag)) {
      return this.securityService.canSeeTenantMadeTags(tag.buildingId);
    } else {
      return of(this.isEditable);
    }
  }

  public isOfTenantType(tag: Tag): boolean {
    return tag.tagType === TagType.TENANT;
  }

  public isOfTenantMadeType(tag: Tag): boolean {
    return tag.tagType === TagType.TENANT_MADE;
  }
}
