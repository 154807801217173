<form class="or-form or-form-schedule" [formGroup]="form">
  <div class="or-form-items">
    <div>
      <mat-form-field>
        <mat-label>Schedule Name</mat-label>
        <input matInput type="text" maxlength="64" formControlName="name" data-cy="schedule-name" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label class="or-label">Start Time</mat-label>
        <mat-select formControlName="startTime" (selectionChange)="updateStartTime()" data-cy="schedule-start-time">
          @for(time of times; track $index) {
            <mat-option [value]="time">{{ time }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field class="ml-4">
        <mat-label class="or-label">End time</mat-label>
        <mat-select  formControlName="endTime" (selectionChange)="updateEndTime()" data-cy="schedule-end-time">
          @for(time of times; track $index) {
            <mat-option [value]="time">{{ time }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="pt-4 pb-4">
      <mat-label class="or-label">On</mat-label>
      <div class="or-inline-items or-days-of-week" formArrayName="days">
        @for(day of daysOfTheWeekControl; track $index) {
          <span class="or-inline-item or-day-of-week">
            <button (click)="setDays($index)" class="or-button or-day-of-week-toggle"
                    [attr.data-cy]="'day-of-the-week-'+ $index"
                    [ngClass]='{ "or-active": schedule.days.isActive($index+1)}'>
                <input type="checkbox" [formControl]="day" hidden>
              {{daysOfTheWeek[$index].shortName()}}
            </button>
          </span>
        }
      </div>
    </div>
  </div>
  <div class="pt-4 pb-4 or-form-title"><h2>Rules</h2></div>
  @if(schedule.rules?.length == 0) {
    <app-notification-block
      [header]="'No rules for schedule!'"
      [statusClass]="StatusClass.ERROR">
      There are no rules. Please add rules to this schedule!
    </app-notification-block>
  } @else  {
    <div class="pb-2">
      @for(rule of schedule.rules; track $index) {
        <div class="or-form-schedule-ruleset-rule" >
          <app-schedule-rule
            [buildingId]="building.id"
            [active]="schedule.active"
            [rule]="rule"
            (onRemoveRule)="removeRule($index)"
            (onChange)="validateRules()"></app-schedule-rule>
        </div>
      }
    </div>
  }
  <div class="flex flex-row w-full space-x-4 pt-4">
    <button
      mat-raised-button
      matTooltip="Save Schedule"
      color="primary"
      (click)="save()"
      [disabled]="!validateRules() || !validateActiveWeekDays() || !validateWeekDayTimeSchedule()"
      data-cy="btn-save-schedule">
      <mat-icon class="text-xl-center" fontIcon="task_alt" />
      <span class="text-lg">{{saveLabel}}</span>
    </button>
    <button
      mat-raised-button
      matTooltip="Add Rule"
      (click)="addRule()"
      data-cy="btn-add-schedule-rule">
      <mat-icon class="text-xl-center" fontIcon="add_circle" />
      <span class="text-lg">{{schedule.rules?.length ? 'Add another rule' : 'Add a rule'}}</span>
    </button>
    <button
      mat-raised-button
      matTooltip="Reset and Close"
      (click)="close()"
      data-cy="btn-reset-schedule">
      <mat-icon class="text-xl-center">refresh</mat-icon>
      <span class="text-lg">Reset and Close</span>
    </button>
    @if (!isNew) {
      <button
        class="delete"
        mat-raised-button
        matTooltip="Delete Schedule"
        (click)="delete()"
        data-cy="btn-delete-schedule">
        <mat-icon class="text-xl-center">delete</mat-icon>
        <span class="text-lg">Delete</span>
    </button>
    }
  </div>
</form>
