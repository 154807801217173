import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { BeaconSetting } from '@app/shared/models/beacons-setting.interface';
import { SavedEntity } from '@app/api/SavedEntity';

@Injectable({
  providedIn: 'root'
})
export class BeaconSettingResource {
  private readonly baseUrl: string;

  constructor(private httpClient: HttpClient, @Inject(environmentToken) private environment: Environment) {
    this.baseUrl = `${this.environment.apiUrl + this.environment.apiPath}`;
  }

  public activate(nodeIds: number[], enabled: boolean, buildingId: number): Observable<BeaconSetting[]> {
    return this.httpClient.put<BeaconSetting[]>(
      `${this.baseUrl}/beacon-setting/send/${buildingId}?enabled=${enabled}`,
      { nodeIds }
    );
  }

  public activateTag(
    tagIds: number[],
    enabled: boolean,
    buildingId: number,
    floorId: any
  ): Observable<BeaconSetting[]> {
    return this.httpClient.put<BeaconSetting[]>(
      `${this.baseUrl}/beacon-setting/send/tags/${buildingId}/${floorId}?enabled=${enabled}`,
      { tagIds }
    );
  }

  public updatePowerLevel(nodeIds: number[], powerLevel: number, buildingId: number): Observable<BeaconSetting[]> {
    return this.httpClient.put<BeaconSetting[]>(
      `${this.baseUrl}/beacon-setting/send/${buildingId}?powerLevel=${powerLevel}`,
      { nodeIds }
    );
  }

  public updatePowerLevelByTag(
    tagIds: number[],
    powerLevel: number,
    buildingId: number,
    floorId: number
  ): Observable<BeaconSetting[]> {
    return this.httpClient.put<BeaconSetting[]>(
      `${this.baseUrl}/beacon-setting/send/tags/${buildingId}/${floorId}?powerLevel=${powerLevel}`,
      { tagIds }
    );
  }

  public updateContent(nodeIds: number[], content: number, buildingId: number): Observable<BeaconSetting[]> {
    return this.httpClient.put<BeaconSetting[]>(
      `${this.baseUrl}/beacon-setting/send/${buildingId}?content=${content}`,
      { nodeIds }
    );
  }

  public updateContentByTag(
    tagIds: number[],
    content: number,
    buildingId: number,
    floorId: number
  ): Observable<BeaconSetting[]> {
    return this.httpClient.put<BeaconSetting[]>(
      `${this.baseUrl}/beacon-setting/send/tags/${buildingId}/${floorId}?content=${content}`,
      { tagIds }
    );
  }

  public updateInterval(nodeIds: number[], interval: number, buildingId: number): Observable<BeaconSetting[]> {
    return this.httpClient.put<BeaconSetting[]>(
      `${this.baseUrl}/beacon-setting/send/${buildingId}?interval=${interval}`,
      { nodeIds }
    );
  }

  public updateIntervalByTag(
    tagIds: number[],
    interval: number,
    buildingId: number,
    floorId: number
  ): Observable<BeaconSetting[]> {
    return this.httpClient.put<BeaconSetting[]>(
      `${this.baseUrl}/beacon-setting/send/tags/${buildingId}/${floorId}?interval=${interval}`,
      { tagIds }
    );
  }

  public updateUuid(nodeIds: number[], uuid: string, buildingId: number): Observable<BeaconSetting[]> {
    return this.httpClient.put<BeaconSetting[]>(`${this.baseUrl}/beacon-setting/send/${buildingId}?uuid=${uuid}`, {
      nodeIds
    });
  }

  public updateUuidByTag(
    tagIds: number[],
    uuid: string,
    buildingId: number,
    floorId: number
  ): Observable<BeaconSetting[]> {
    return this.httpClient.put<BeaconSetting[]>(
      `${this.baseUrl}/beacon-setting/send/tags/${buildingId}/${floorId}?uuid=${uuid}`,
      { tagIds }
    );
  }

  public updateMajor(nodeIds: number[], major: string, buildingId: number): Observable<BeaconSetting[]> {
    return this.httpClient.put<BeaconSetting[]>(`${this.baseUrl}/beacon-setting/send/${buildingId}?major=${major}`, {
      nodeIds
    });
  }

  public updateMajorByTag(
    tagIds: number[],
    major: string,
    buildingId: number,
    floorId: number
  ): Observable<BeaconSetting[]> {
    return this.httpClient.put<BeaconSetting[]>(
      `${this.baseUrl}/beacon-setting/send/tags/${buildingId}/${floorId}?major=${major}`,
      { tagIds }
    );
  }

  public updateMinor(nodeIds: number[], minor: number, buildingId: number): Observable<BeaconSetting[]> {
    return this.httpClient.put<BeaconSetting[]>(`${this.baseUrl}/beacon-setting/send/${buildingId}?minor=${minor}`, {
      nodeIds
    });
  }

  public updateMinorByTag(
    tagIds: number[],
    minor: number,
    buildingId: number,
    floorId: number
  ): Observable<BeaconSetting[]> {
    return this.httpClient.put<BeaconSetting[]>(
      `${this.baseUrl}/beacon-setting/send/tags/${buildingId}/${floorId}?minor=${minor}`,
      { tagIds }
    );
  }

  public querySensorNodes(nodeIds: number[], buildingId: any): Observable<SavedEntity<BeaconSetting, number>> {
    return this.httpClient.post<SavedEntity<BeaconSetting, number>>(
      `${this.baseUrl}/beacon-setting/send?buildingId=${buildingId}`,
      {
        nodeIds
      }
    );
  }

  public getBeaconSettingsForFloorAndNodeIds(floorId: number, nodeIds: number[]): Observable<BeaconSetting[]> {
    return this.httpClient.get<BeaconSetting[]>(`${this.baseUrl}/beacon-setting/floor/${floorId}?nodeIds=${nodeIds}`);
  }
}
