import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractUnmappedNodesDataSource, UnmappedNode } from '@app/shared/models/unmapped-nodes-datasource';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SensorNode } from '@app/shared/models/sensor-node';
import { UserFriendlyDiscriminator } from '@app/shared/models/selectable.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NodeFilter, NodesFilterComponent } from '@components/node-list-card/nodes-filter/nodes-filter.component';

@Component({
  selector: 'app-unmapped-nodes-list',
  templateUrl: './unmapped-nodes-list.component.html',
  styleUrls: ['./unmapped-nodes-list.component.scss'],
  imports: [
    CdkDrag,
    CdkDropList,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    NodesFilterComponent
  ]
})
export class UnmappedNodesListComponent implements OnInit {
  @Input() buildingId: number;
  @Input() filterForm: FormGroup;
  @Output() onBlink = new EventEmitter<UnmappedNode>();
  @Output() onScroll = new EventEmitter<number>();
  tableColumns: string[] = ['address', 'nodeType', 'gatewayName', 'blinkButton', 'draggableNode'];
  shortenedTableColumns: string[] = ['address', 'gatewayName', 'draggableNode'];
  filterFields = ['address', 'nodeType'];
  constructor(public dataSource: AbstractUnmappedNodesDataSource, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.dataSource.loadUnmappedNodes(this.buildingId);
  }

  blinkClicked(nodeInfo: UnmappedNode): void {
    this.onBlink.emit(nodeInfo);
  }

  getIconClass(nodeInfo: UnmappedNode): string {
    let classNames = this.isNodeDisabled(nodeInfo) ? 'dragging-disabled' : '';
    let nodeClass = ' or-icon-node';
    if (nodeInfo.nodeType === SensorNode.HIM84_NODE_TYPE) {
      nodeClass = ' or-icon-him84';
    } else if (nodeInfo.nodeType === SensorNode.HCD405_NODE_TYPE) {
      nodeClass = ' or-icon-hcd405';
    } else if (nodeInfo.nodeType === SensorNode.HCD038_NODE_TYPE) {
      nodeClass = ' or-icon-hcd038';
    } else if (nodeInfo.nodeType === SensorNode.FUJITSU_ENV_C02 || nodeInfo.nodeType === SensorNode.FUJITSU_ENV) {
      nodeClass = ' or-icon-environmental-node';
    }
    classNames += nodeClass;
    return classNames;
  }

  getContainerClass(nodeInfo: UnmappedNode): string {
    const className = this.isNodeDisabled(nodeInfo) ? 'shadow-disabled ' : '';
    switch (nodeInfo.nodeType) {
      case SensorNode.SN3_NODE_TYPE:
        return className + 'container-sn3';
      case SensorNode.PASSIVE_NODE_TYPE:
        return className + 'container-passive-node';
      case SensorNode.HIM84_NODE_TYPE:
        return className + 'container-highbay';
      case SensorNode.HCD405_NODE_TYPE:
        return className + 'container-microwave';
      case SensorNode.HCD038_NODE_TYPE:
        return className + 'container-microwave';
      case SensorNode.FUJITSU_ENV || SensorNode.FUJITSU_ENV_C02:
        return className + 'container-environmental-node';
    }
  }

  getNodeTypeText(nodeInfo: UnmappedNode): string {
    return UserFriendlyDiscriminator[nodeInfo.nodeType] || nodeInfo.nodeType;
  }

  getRowClass(nodeInfo: UnmappedNode): string {
    return this.isNodeDisabled(nodeInfo) ? 'row-disabled' : '';
  }

  isNodeDisabled(nodeInfo: UnmappedNode): boolean {
    return nodeInfo.gatewayId == null || nodeInfo.isConnected === false;
  }

  sortChanged($event: Sort): void {
    this.dataSource.sortUnderlyingData($event);
  }

  getTooltipText(nodeInfo: UnmappedNode): string {
    return nodeInfo.gatewayId == null ? 'Node is not associated to any gateway' : 'Node is not connected';
  }

  isBlinkDisabled(nodeInfo: UnmappedNode): boolean {
    return this.isNodeDisabled(nodeInfo) || nodeInfo.nodeType === 'PN';
  }

  handleScroll(event: WheelEvent): void {
    this.onScroll.emit(event.deltaY);
  }

  onFilterChanged(nodeFilter: NodeFilter): void {
    this.dataSource.loadUnmappedNodes(this.buildingId, nodeFilter.filterCriteria);
  }

  refreshNodeList(nodeFilter: NodeFilter): void {
    this.dataSource.loadUnmappedNodes(this.buildingId, nodeFilter.filterCriteria);
  }
}
