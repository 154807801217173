import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastService } from '@services/toast/toast.service';
import { combineLatest, Observable, Subject, tap } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ServiceLevel } from '@app/shared/models/service-level';
import { ManagingCompany } from '@app/shared/models/building.interface';
import {
  getServiceLevelTypes,
  serviceLevelFromValue,
  valueFromServiceLevel
} from '@app/shared/models/service-level-type';
import { ManagingCompanyService } from '@services/managing-company.service';
import { ServiceLevelService } from '@services/service-level.service';
import { UserService } from '@services/user/user.service';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import { MatButton } from '@angular/material/button';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-service-level',
  templateUrl: './service-level.component.html',
  styleUrl: './service-level.component.scss',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    MatOption,
    AuthorizationModule,
    MatButton,
    SharedComponentsModule,
    AsyncPipe
  ]
})
export class ServiceLevelComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private toaster: ToastService,
    private managingCompanyService: ManagingCompanyService,
    private serviceLevelService: ServiceLevelService,
    private userService: UserService
  ) {}
  buildingId: number;
  fg: UntypedFormGroup;
  serviceLevelTypes = getServiceLevelTypes();
  details$: Observable<ServiceLevel>;
  managingCompanies$: Observable<ManagingCompany[]>;

  public readonly error$ = new Subject<string>();
  public readonly submit$ = new Subject<void>();
  public readonly reset$ = new Subject<void>();
  public readonly onDestroy$ = new Subject<void>();

  protected readonly valueFromServiceLevel = valueFromServiceLevel;

  protected readonly _maxLength = 255;

  private readonly _intMax = 2147483647;

  ngOnInit(): void {
    this.fg = this.fb.group({
      managingCompanyId: [{ value: null, disabled: true }],
      serviceLevel: [null, Validators.required],
      startDate: [null, Validators.required],
      squareMetres: [1, [Validators.required, Validators.min(1), Validators.max(this._intMax)]],
      paymentStartDate: [null],
      mixedSite: [false],
      supplierAndLuminaires: [null],
      contractNumber: [null, Validators.maxLength(this._maxLength)],
      servicePeriod: [null, Validators.maxLength(this._maxLength)],
      invoiceAddress: [null],
      invoiceEmail: [null, Validators.maxLength(this._maxLength)],
      vatNumber: [null, Validators.maxLength(this._maxLength)],
      notes: [null],
      internalReferenceContact: [null],
      additionalInformation: [null]
    });

    this.route.params.subscribe((params: Params) => {
      this.buildingId = params.buildingId;

      // Tap is needed to reset the form when the details are first loaded
      this.details$ = combineLatest([
        this.userService.getBuilding(this.buildingId),
        this.serviceLevelService.get(this.buildingId)
      ]).pipe(
        map((data) => {
          const building = data[0];
          const serviceLevel = data[1];
          serviceLevel.managingCompanyId = building.managingCompanyId;
          return serviceLevel;
        }),
        tap(() => this.reset$.next())
      );
      this.managingCompanies$ = this.managingCompanyService.getManagingCompanies();

      this.initReset();
      this.initSubmit();
      this.initError();
    });
  }

  public submit(): void {
    this.submit$.next();
  }

  public reset(): void {
    this.reset$.next();
  }

  private initSubmit(): void {
    this.submit$.pipe(withLatestFrom(this.details$), takeUntil(this.onDestroy$)).subscribe({
      next: ([, serviceLevel]) => {
        const updatedServiceLevel = {
          ...serviceLevel,
          ...this.fg.value
        };
        updatedServiceLevel.serviceLevel = serviceLevelFromValue(updatedServiceLevel.serviceLevel);
        this.processSubmit(updatedServiceLevel);
      },
      error: (err) => {
        console.log(err);
        this.error$.next(err);
      }
    });
  }

  processSubmit(updatedServiceLevel: ServiceLevel): void {
    this.serviceLevelService.save(updatedServiceLevel).subscribe({
      next: () => {
        this.toaster.success({ message: 'Service Information Saved!', dataCy: 'service-level-toast-success' });
        this.fg.markAsPristine();
      },
      error: () => {
        this.toaster.error({ message: 'Error when saving Service Information', dataCy: 'service-level-toast-error' });
      }
    });
  }

  private initReset(): void {
    combineLatest([this.details$, this.reset$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: ([serviceLevel]) => {
          this.fg.reset({ ...serviceLevel });
        },
        error: (err) => this.error$.next(err)
      });
  }

  private initError(): void {
    this.error$.subscribe((err) => {
      this.toaster.error({ message: err, dataCy: 'service-level-general-toast-error' });
    });
  }
}
