<div id="orGlobalAdministrationManagingCompanies">
  <div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner style="margin:0 auto;" mode="indeterminate"></mat-spinner>
  </div>

  <div class="add-container">
    <mat-form-field>
      <mat-label>Managing Company</mat-label>
      <input matInput [(ngModel)]='companyName'>
    </mat-form-field>
    <button mat-mini-fab (click)="addManagingCompany()" color="primary">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <mat-table class="users-table mat-elevation-z8" [dataSource]="dataSource"
             matSort matSortActive="id" matSortDirection="asc" matSortDisableClear>

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
      <mat-cell *matCellDef="let managingCompany">{{managingCompany.id}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <mat-header-cell *matHeaderCellDef>Company Name</mat-header-cell>
      <mat-cell class="name-cell" *matCellDef="let managingCompany">{{managingCompany.companyName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell class="actions-cell" *matCellDef="let managingCompany">
        <button mat-mini-fab (click)="editManagingCompany(managingCompany)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-mini-fab color="warn" (click)="deleteManagingCompany(managingCompany)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  </mat-table>

</div>