import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Floor, floorCompare } from '@app/shared/models/floor.interface';
import { MultiselectOption as SelectOptionInterface } from '@app/shared/components/multiselect/multiselect-option';
import { DisplayTags, Tag } from '@app/shared/models/tag.interface';
import { MultiselectOption } from '@app/shared/models/multiselect-option';
import { EmergencyLightingScheduleService } from '@app/shared/services/emergency-lighting/emergency-lighting-schedule.service';
import { IEmergencyLightingSchedule } from '@app/shared/models/emergency-lighting-schedule.interface';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ScheduleRecurrenceDialogComponent } from '@app/emergency-lighting/schedules/schedule-recurrence-dialog/schedule-recurrence-dialog.component';
import {
  CUSTOM,
  ScheduleRecurrence,
  ScheduleRecurrenceCustom
} from '@app/shared/models/schedule-recurrence/schedule-recurrence';
import { EmergencyLightingSchedulingStrategy } from '@app/shared/models/emergency-lighting-scheduling-strategy';
import { ITimeOption } from '@app/shared/models/time-option.interface';
import { IEmergencyLightingScheduleForm } from '@app/shared/models/emergency-lighting-schedule-form.interface';
import { EmergencyLightingTestType } from '@app/shared/models/emergency-lighting-test-type';
import { RepeatUnit } from '@app/shared/models/schedule-recurrence/repeat-unit';
import { IScheduleRecurrenceDialogInput } from '@app/shared/models/schedule-recurrence/schedule-recurrence-dialog-input.interface';
import { SensorNodeService } from '@app/shared/services/sensor-node.service';
import { EmergencyLightingSchedule } from '@app/shared/models/emergency-lighting-schedule';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from '@services/toast/toast.service';
import { TimeUtils } from '@app/shared/utils/time.utils';
import { Building } from '@app/shared/models/building.interface';
import { TenantService } from '@services/building/tenant.service';
import { ConfirmComponent, ConfirmDialogData } from '@components/dialogs/confirm/confirm.component';
import { IElmtScheduleDto } from '@app/shared/models/elmt-schedule-dto.interface';
import { scheduleValidator } from '@app/emergency-lighting/schedules/form-emergency-lighting-schedule/schedule.validator';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { StatusClass } from '@components/notification-block/notification-block.component';
import { MatError, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { NotificationBlockComponent } from '../../../shared/components/notification-block/notification-block.component';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { AsyncPipe, NgClass } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { SharedComponentsModule } from '../../../shared/shared-components.module';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { AuthorizationModule } from '../../../shared/directives/authorization.module';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-form-emergency-lighting-schedule',
  templateUrl: './form-emergency-lighting-schedule.component.html',
  styleUrls: ['./form-emergency-lighting-schedule.component.scss'],
  imports: [
    ReactiveFormsModule,
    MatError,
    NotificationBlockComponent,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    NgClass,
    MatOption,
    SharedComponentsModule,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    AuthorizationModule,
    MatButton,
    AsyncPipe
  ]
})
export class FormEmergencyLightingScheduleComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private scheduleService: EmergencyLightingScheduleService,
    private navigationService: NavigationService,
    private sensorNodeService: SensorNodeService,
    private dialog: MatDialog,
    private readonly toast: ToastService,
    private tenantService: TenantService,
    private dialogService: ConfirmationDialogService
  ) {
    this.buildingId = this.navigationService.getActiveSection().routeParams.buildingId;
    this.floors = this.navigationService.getActiveSection().routeParams.floors;
  }
  @Input() details: IEmergencyLightingSchedule;
  @Input() isNew: boolean;
  @Input() tags$: Observable<DisplayTags>;
  @Input() building$: Observable<Building>;

  @Output() closeNewSchedule = new EventEmitter<{}>();
  @Output() resetAndCloseSchedule = new EventEmitter<{}>();
  @Output() deleteSchedule = new EventEmitter<IEmergencyLightingSchedule>();
  @Output() reloadScheduleEmitter = new EventEmitter<{}>();
  @Output() newScheduleCreationEmitter = new EventEmitter<number>();

  readonly floors: Floor[] = [];
  tagOptions: BehaviorSubject<SelectOptionInterface<Tag>[]> = new BehaviorSubject([]);
  floorOptions: BehaviorSubject<SelectOptionInterface<Floor>[]> = new BehaviorSubject([]);
  timeOptions: ITimeOption[];
  repeatOptions: ScheduleRecurrence[];
  isValidTimeRange = true;
  testTypes = EmergencyLightingTestType.all();
  schedulingStrategies: EmergencyLightingSchedulingStrategy[];
  minSelectableDate: Date;
  currentDate: Date;
  scheduleForm: FormGroup<IEmergencyLightingScheduleForm>;
  tenantTagIds: number[] = [];

  private expectedEndDateTime: Date;
  private previousScheduleRecurrence: ScheduleRecurrence;
  private onDestroy$ = new Subject<void>();
  readonly buildingId: number;
  private readonly START_TIME_MINUTES_GAP = 15;

  protected readonly Object = Object;

  static openConfirmDialog(dialog: MatDialog, message: string): Observable<any> {
    const config = new MatDialogConfig();
    config.data = new ConfirmDialogData(message);
    config.autoFocus = true;
    config.width = '600px';
    const dialogRef = dialog.open(ConfirmComponent, config);
    return dialogRef.afterClosed();
  }

  ngOnInit(): void {
    this.tenantService.getUserTenants(this.details.buildingId).subscribe((tenants) => {
      tenants.forEach((tenant) => {
        tenant.tagIds.forEach((tagId) => {
          this.tenantTagIds.push(tagId);
        });
      });
      this.updateNodeCount();
    });
    this.currentDate = TimeUtils.convertTimezone(new Date(), this.details.timezone);
    this.minSelectableDate =
      this.isNew || this.details.startDate > this.currentDate ? this.currentDate : this.details.startDate;

    this.schedulingStrategies = EmergencyLightingSchedulingStrategy.all();
    if (this.isNew) {
      this.timeOptions = this.produceTimeOptions(this.currentDate);
      this.repeatOptions = this.produceBasicRepeatOptions(this.currentDate);
    } else {
      this.timeOptions = this.produceTimeOptions(new Date(this.details.startDateTime * 1000));
      this.repeatOptions = this.produceBasicRepeatOptions(new Date(this.details.startDateTime * 1000));
    }

    this.updateFloorOptions();
    this.initFormControls();
    this.computeEndDateTime();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  private produceTimeOptions(date: Date): ITimeOption[] {
    const timeOptions: ITimeOption[] = [];
    const isStartDateToday =
      date.getDate() === this.currentDate.getDate() &&
      date.getMonth() === this.currentDate.getMonth() &&
      date.getFullYear() === this.currentDate.getFullYear();
    const isStartDateUnchanged =
      date.getDate() === this.details.startDate.getDate() &&
      date.getMonth() === this.details.startDate.getMonth() &&
      date.getFullYear() === this.details.startDate.getFullYear();
    for (let hours = 0; hours < 24; hours += 1) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        const isStartTimeInFuture =
          this.minSelectableDate.getHours() * 60 + this.minSelectableDate.getMinutes() + this.START_TIME_MINUTES_GAP >
          hours * 60 + minutes;
        const isStartTimeAfterTheOption =
          this.details.startDate.getHours() * 60 + this.details.startDate.getMinutes() + this.START_TIME_MINUTES_GAP >
          hours * 60 + minutes;
        timeOptions.push({
          timeString: [('0' + hours).slice(-2), ('0' + minutes).slice(-2)].join(':'),
          hour: hours,
          minute: minutes,
          disabled:
            (isStartDateToday && isStartTimeInFuture) ||
            (!this.details.hasRan && isStartDateUnchanged && isStartTimeAfterTheOption)
        });
      }
    }
    return timeOptions;
  }

  public onChangeStartTime(): void {
    this.computeEndDateTime();
  }

  public onChangeSchedulingStrategy(): void {
    this.computeEndDateTime();
  }

  public onChangeTestType(): void {
    this.computeEndDateTime();
  }

  public onChangeStartDate(): void {
    if (this.details.hasRan) {
      this.minSelectableDate = this.currentDate;
      if (this.currentDate.getDate() <= this.scheduleForm.value.startDate.getDate()) {
        this.scheduleForm.get('startTime').enable();
        this.details.startTime = '';
      } else {
        this.scheduleForm.get('startTime').disable();
      }
      this.scheduleForm.get('scheduleRecurrence').enable();
      this.scheduleForm.get('schedulingStrategy').enable();
    }
    this.timeOptions = this.produceTimeOptions(this.scheduleForm.value.startDate);
    this.repeatOptions = this.produceBasicRepeatOptions(this.scheduleForm.value.startDate);
    this.scheduleForm.patchValue({
      startTime: this.timeOptions.find((element) => this.details.startTime === element.timeString),
      scheduleRecurrence: this.repeatOptions[0]
    });
  }

  private computeEndDateTime(): void {
    if (
      this.scheduleForm.value.testsType &&
      this.scheduleForm.value.schedulingStrategy &&
      this.scheduleForm.value.startDate &&
      this.scheduleForm.value.startTime
    ) {
      this.expectedEndDateTime = new Date(this.scheduleForm.value.startDate);
      const hoursToAdd =
        this.scheduleForm.value.testsType.expectedDurationHours *
        this.scheduleForm.value.schedulingStrategy.timeMultiplier;
      const minutesFraction = hoursToAdd % Math.trunc(hoursToAdd);
      if (minutesFraction > 0) {
        this.expectedEndDateTime.setHours(
          this.scheduleForm.value.startTime.hour + Math.trunc(hoursToAdd),
          this.scheduleForm.value.startTime.minute + Math.round(minutesFraction * 60)
        );
      } else {
        this.expectedEndDateTime.setHours(
          this.scheduleForm.value.startTime.hour + Math.trunc(hoursToAdd),
          this.scheduleForm.value.startTime.minute
        );
      }
      this.scheduleForm.controls.endTime.setValue(
        ('0' + this.expectedEndDateTime.getHours()).slice(-2) +
          ':' +
          ('0' + this.expectedEndDateTime.getMinutes()).slice(-2)
      );
    }
  }

  private initFormControls(): void {
    this.scheduleForm = this.fb.group({
      name: [this.details.name || '', [Validators.maxLength(64), Validators.required]],
      testsType: [this.details.testsType, [Validators.required]],
      floorIds: [this.details.floorIds],
      tagIds: [this.details.tagIds],
      startTime: [
        this.timeOptions.find((element) => this.details.startTime === element?.timeString),
        [Validators.required]
      ],
      startDate: new FormControl(new Date(this.details.startDate), {
        validators: [Validators.required],
        updateOn: 'blur'
      }),
      endTime: [this.details.endTime],
      scheduleRecurrence: [this.details.repeat, [Validators.required]],
      schedulingStrategy: [this.details.schedulingStrategy, [Validators.required]]
    });
    this.scheduleForm.addValidators(scheduleValidator(this.scheduleService, this.details));
    if (this.details.hasRan) {
      this.scheduleForm.get('testsType').disable();
      if (this.details.startDate.toDateString() != this.currentDate.toDateString()) {
        this.scheduleForm.get('startTime').disable();
      } else {
        const currentStartTime = this.details.startTime;
        this.onChangeStartDate();
        this.scheduleForm.patchValue({
          startTime: this.timeOptions.find((element) => currentStartTime === element.timeString)
        });
      }
    }
  }

  private produceBasicRepeatOptions(startDate: Date): ScheduleRecurrence[] {
    const basicRepeatOptions = [];
    basicRepeatOptions.push(new ScheduleRecurrence(startDate, 0, RepeatUnit.NONE));
    basicRepeatOptions.push(new ScheduleRecurrence(startDate, 1, RepeatUnit.WEEKLY, null, null, null));
    basicRepeatOptions.push(new ScheduleRecurrence(startDate, 1, RepeatUnit.MONTHLY_WEEK_DATE, null, null, null));
    basicRepeatOptions.push(new ScheduleRecurrence(startDate, 1, RepeatUnit.YEARLY, null, null, null));
    if (this.details.repeat != null) {
      basicRepeatOptions.push(this.details.repeat);
    }
    basicRepeatOptions.push(CUSTOM);
    return basicRepeatOptions;
  }

  private updateFloorOptions(): void {
    const sortedFloors = [...(this.floors || [])].sort(floorCompare);
    this.floorOptions.next(sortedFloors.map((floor) => new MultiselectOption<Floor>(floor.name, floor)));
  }

  public selectedTagsChanged(newTags: Tag[]): void {
    this.details.tagIds = newTags.map((tag) => tag.id);
    this.scheduleForm.get('tagIds').setValue(this.details.tagIds);
    this.updateNodeCount();
    this.scheduleForm.markAsDirty({ onlySelf: true });
  }

  public selectedFloorsChanged(newFloors: Floor[]): void {
    this.details.floorIds = newFloors.map((floor) => floor.id);
    this.scheduleForm.get('floorIds').setValue(this.details.floorIds);
    this.updateNodeCount();
    this.scheduleForm.markAsDirty({ onlySelf: true });
  }

  private updateNodeCount(): void {
    const nodeIdSelector = {
      id: 1,
      selector: {
        buildingId: this.buildingId,
        floorIds: this.details.floorIds,
        tagIds: this.details.tagIds,
        elmt: true,
        includeUnmapped: false,
        authorizedTags: this.tenantTagIds
      }
    };
    this.sensorNodeService
      .getNodeCountsForElmtSchedules([nodeIdSelector])
      .subscribe((scheduleIdsToNodeCountMap) => (this.details.nodeCount = scheduleIdsToNodeCountMap[1]));
  }

  public save(): void {
    const fullScheduleFormValue = this.scheduleForm.getRawValue();
    const startTimeComponents = fullScheduleFormValue.startTime?.timeString.split(':');
    const startDateTime = fullScheduleFormValue.startDate.setHours(
      Number(startTimeComponents[0]),
      Number(startTimeComponents[1])
    );

    const { name: scheduleName, schedulingStrategy, testsType, scheduleRecurrence } = fullScheduleFormValue;

    const payload = EmergencyLightingSchedule.toDto(this.details);
    payload.name = scheduleName;
    payload.testsType = testsType.value;
    payload.startDateTime = TimeUtils.toBrowserTimeZone(fullScheduleFormValue.startDate, this.details.timezone);
    payload.schedulingStrategy = schedulingStrategy.value;
    payload.repeatEvery = scheduleRecurrence.repeatOccurrence;
    payload.repeatUnit = scheduleRecurrence.repetitionUnit.value;
    payload.repeatOnWeekdays = scheduleRecurrence.repeatOnDays?.map((weekday) => weekday.value);
    payload.repeatEndsOn = scheduleRecurrence.endsOnDate;
    payload.repeatEndsAfterNumOccurrences = scheduleRecurrence.endsAfterOccurrences;

    if (this.details.nodeCount === 0) {
      FormEmergencyLightingScheduleComponent.openConfirmDialog(
        this.dialog,
        `This schedule contains zero emergency devices, do you still want to save your changes?`
      ).subscribe((result: boolean) => {
        if (result) {
          this.saveSchedule(payload);
        }
      });
    } else if (this.details.nodeCount > 1800) {
      this.toast.error({
        message: `This schedule contains more than 1800 emergency devices which will result in unexpected timeouts.
                  \n\nPlease revisit the current tags and floor selection`,
        autoClose: false,
        dismissible: true,
        dataCy: `update-error-toast`
      });
    } else if (this.details.nodeCount > 1500) {
      FormEmergencyLightingScheduleComponent.openConfirmDialog(
        this.dialog,
        `This schedule contains more than recommended 1500 devices which this may result in unexpected timeouts,
                    do you still want to save your changes?`
      ).subscribe((result: boolean) => {
        if (result) {
          this.saveSchedule(payload);
        }
      });
    } else {
      this.saveSchedule(payload);
    }
  }

  public saveSchedule(payload: IElmtScheduleDto): void {
    this.scheduleService.validateSchedule(this.buildingId, payload).subscribe({
      next: () => {
        const scheduleOperation$ =
          payload.id != null
            ? this.scheduleService.updateSchedule(payload)
            : this.scheduleService.saveSchedule(this.buildingId, payload);
        scheduleOperation$.subscribe({
          next: (schedule) => {
            if (!payload.id) {
              this.newScheduleCreationEmitter.emit(schedule.id);
            }
            this.reloadScheduleEmitter.emit();
            this.scheduleService.fetchSchedules(this.buildingId);
            this.close();
          },
          error: (errorResponse: HttpErrorResponse) => {
            const { message } = errorResponse.error;
            const action = payload.id ? 'update' : 'create';
            this.toast.error({
              message: `Unable to ${action} schedule: ${message}`,
              autoClose: false,
              dismissible: true,
              dataCy: `${action}-error-toast`
            });
          }
        });
      },
      error: (response) => {
        const errors = this.scheduleForm.errors || {};
        errors.uniqueTagAndFloor = response.error.message;
        this.scheduleForm.setErrors(errors);
      }
    });
  }

  public delete(): void {
    this.scheduleService.getImpactedEmailReportsByDeletion(this.buildingId, this.details.id).subscribe({
      next: (reportNames) => {
        let deleteScheduleMsg = ``;
        if (reportNames != null && reportNames.length > 0) {
          deleteScheduleMsg += ` The following Email Report(s) will be impacted by deleting this schedule: [${reportNames.join(
            ']---['
          )}].`;
        } else {
          deleteScheduleMsg = 'No Email Reports will be impacted by deleting this schedule.';
        }
        this.dialogService
          .open({
            title: `Delete schedule ( ${this.details.name} ) ?`,
            message: deleteScheduleMsg,
            showConfirm: true
          })
          .subscribe({
            next: (result) => {
              if (result) {
                this.deleteScheduleConfidently();
              }
            }
          });
      },
      error: (error) => {
        this.toast.error({
          message: 'Deleting the schedule encountered an error.',
          autoClose: false,
          dismissible: true,
          dataCy: `send-error-toast`
        });
      }
    });
  }

  private deleteScheduleConfidently(): void {
    this.scheduleService.deleteSchedule(this.buildingId, this.details.id).subscribe({
      next: (result) => {
        this.toast.success({
          message: 'The schedule was deleted successfully',
          dataCy: 'send-success-toast'
        });
        this.deleteSchedule.emit(this.details);
        this.scheduleService.fetchSchedules(this.buildingId);
      },
      error: (err) => {
        console.error(err);
        this.toast.error({
          message: 'Deleting the schedule encountered an error.',
          autoClose: false,
          dismissible: true,
          dataCy: `send-error-toast`
        });
        this.deleteSchedule.emit(this.details);
        this.scheduleService.fetchSchedules(this.buildingId);
      }
    });
  }

  public close(): void {
    this.isNew ? this.closeNewSchedule.emit() : this.resetAndCloseSchedule.emit();
  }

  public openRecurrenceDialog(dialog: MatDialog): Observable<ScheduleRecurrence> {
    const config = new MatDialogConfig<IScheduleRecurrenceDialogInput>();
    config.autoFocus = true;
    config.data = {
      startDate: this.scheduleForm.value.startDate
    };
    config.width = '600px';
    const dialogRef = dialog.open(ScheduleRecurrenceDialogComponent, config);
    return dialogRef.afterClosed();
  }

  public onOpenScheduleRecurrence(isToggled: boolean): void {
    if (isToggled) {
      this.previousScheduleRecurrence = this.scheduleForm.value.scheduleRecurrence;
    }
  }

  public onChangeScheduleRecurrence(scheduleRecurrence: ScheduleRecurrence): void {
    if (scheduleRecurrence instanceof ScheduleRecurrenceCustom) {
      this.openRecurrenceDialog(this.dialog).subscribe((selectedRecurrenceOption) => {
        if (selectedRecurrenceOption) {
          this.scheduleForm.controls.scheduleRecurrence.setValue(
            this.getSelectedRecurrenceOption(selectedRecurrenceOption)
          );
        } else {
          this.scheduleForm.controls.scheduleRecurrence.setValue(this.previousScheduleRecurrence);
        }
      });
    }
  }

  private getSelectedRecurrenceOption(recurrence: ScheduleRecurrence): ScheduleRecurrence {
    const existingRecurrenceOption = this.repeatOptions.filter(
      (option) => option.displayName === recurrence.displayName
    );
    if (existingRecurrenceOption.length > 0) {
      return existingRecurrenceOption[0];
    } else {
      this.repeatOptions.splice(this.repeatOptions.length - 1, 0, recurrence);
      return recurrence;
    }
  }

  protected readonly StatusClass = StatusClass;
}
