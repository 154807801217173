<div id="orAdministrationDdl" class="or-content">
  @if(ddlForm) {
    <form [formGroup]="ddlForm">
      <div class='or-content-header'></div>
      <div class="or-content-body">
        <div class="or-switch-container or-flex">
          <h2 class="or-form-title">DDL Enabled</h2>
          <label class="switch">
            <mat-slide-toggle color='primary' data-cy='beacon-enabled' formControlName="modeEnabled" (change)="updateDdlMode()"></mat-slide-toggle>
          </label>
        </div>
        <div class="flex flex-row align-bottom">
          <div class="or-input-container">
            <h1 class="or-label or-label-above">Outdoor Sensor Address</h1>
            <input matInput class="or-input" type="number" placeholder="Id" formControlName="address"/>
          </div>
          <div class="or-input-container">
            <h1 class="or-label or-label-above">DDL Luminaires</h1>
            <mat-select class="or-select" formControlName="ddlTagId" name="repeatSelect">
              @for (ddlTag of ddlTags ; track ddlTag.id) {
                <mat-option value="{{ddlTag.id}}">
                  {{ddlTag.name}}
                </mat-option>
              }
            </mat-select>
          </div>
          <div class="content-end or-inline-item">
            <button class='or-button' matTooltip="Save" color="primary" (click)='saveButtonPressed()' mat-raised-button
                    type="submit" data-cy="save-ddl" [disabled]='ddlForm.invalid || ddlForm.pristine || !ddlMode.enabled'>
              <span class='or-icon or-small or-inverse or-icon-checkmark'></span>
              <span class="or-button-label">Save</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  }
</div>
