<div class='w-full shadow-md'>
  <app-panel [title]="'OFF &#8660; ON'" [icon]="'power_settings_new'" [disabled]='!isReady'>
    <div panel-content>
      <div class="w-[30%] block">
        <mat-slide-toggle color='primary' data-cy='beacon-enabled' [(ngModel)]="status" [disabled]='!isReady'></mat-slide-toggle>
      </div>
    </div>
    <div footer-content>
      <app-panel-footer [dataCy]="'status'" [disableReset]="isBusy || !isReady" [disableSubmit]='!isReady' (resetHandler)="resetStatus()" (updateHandler)="setStatus()"></app-panel-footer>
    </div>
  </app-panel>
  <app-panel [title]="'Power Level'" [icon]="'bolt'" [disabled]='!isReady'>
    <div panel-content>
      <div>
        <input class="or-input or-power-dbm" type="text" readonly id="dbm" data-cy="beacon-power-read" [(ngModel)]="powerLevel" [disabled]='!isReady'/>
        <input class="or-input or-no-shadow" type="range" min="0" max="6" data-cy="set-beacon-power"
        [(ngModel)]="indexedPower" (change)="convert()" value="0" [disabled]='!isReady'/>
      </div>
    </div>
    <div footer-content>
      <app-panel-footer [disableReset]="isBusy || !isReady" [disableSubmit]='!isReady' [dataCy]="'power'" (resetHandler)="resetPower()" (updateHandler)="setPowerLevel()"></app-panel-footer>
    </div>
  </app-panel>
  <app-panel [title]="'Beacon Content'" [icon]="'content_paste_go'" [disabled]='!isReady'>
    <div panel-content>
      <mat-form-field class="w-full skinny-form-4">
        <mat-select data-cy="set-beacon-content" [(ngModel)]="content" [disabled]='!isReady'>
          @for (item of contents; track $index) {
            <mat-option  [value]="item"> {{item.name}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div footer-content>
      <app-panel-footer [disableReset]="isBusy || !isReady" [disableSubmit]='!isReady' [dataCy]="'content'" (resetHandler)="resetContent()" (updateHandler)="setContent()"></app-panel-footer>
    </div>
  </app-panel>
  <app-panel [title]="'Beaconing Repetition'" [icon]="'repeat'" [disabled]='!isReady'>
    <div panel-content>
      <div>
        <input class="or-input or-no-shadow" type="range" min="0" max="2" data-cy="set-beacon-interval"
               [(ngModel)]="indexedInterval" list="steplist" [disabled]='!isReady'/>
        <datalist id="steplist">
          @for(item of intervals; track $index) {
            <option value="{{$index}}" label="{{item.name}}"></option>
          }
        </datalist>
      </div>
    </div>
    <div footer-content>
      <app-panel-footer [disableReset]="isBusy || !isReady" [disableSubmit]='!isReady' [dataCy]="'interval'" (resetHandler)="resetInterval()" (updateHandler)="setInterval()"></app-panel-footer>
    </div>
  </app-panel>
  @if (showPanelBasedOnFeature) {
    <app-panel [title]="'UUID'" [icon]="'qr_code'" [disabled]='!isReady'>
      <div panel-content>
        <mat-form-field class="w-full skinny-form-4">
          <mat-select data-cy="set-beacon-uuid" [(ngModel)]="uuid" [disabled]='!isReady'>
            @for (item of uuids; track $index) {
              <mat-option  [value]="item"> {{item.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div footer-content>
        <app-panel-footer [disableReset]="isBusy || !isReady" [disableSubmit]='!isReady' [dataCy]="'uuid'" (resetHandler)="resetUuid()" (updateHandler)="setUuid()"></app-panel-footer>
    </div>
    </app-panel>
    <app-panel [title]="'Major'" [icon]="'engineering'" [disabled]='!isReady'>
      <div panel-content>
        <div  appIsAuthorized="MANAGE_BUILDINGS" isFeatureAvailable="beaconNimway">
            <input matInput class="or-input" type='number' data-cy="set-beacon-major" [(ngModel)]="major" [disabled]='!isReady'>
        </div>
      </div>
      <div footer-content>
        <app-panel-footer [disableReset]="isBusy || !isReady" [disableSubmit]='!isReady' [dataCy]="'major'" (resetHandler)="resetMajor()" (updateHandler)="setMajor()"></app-panel-footer>
    </div>

    </app-panel>
    <app-panel [title]="'Minor'" [icon]="'bookmark_remove'" [disabled]='!isReady'>
      <div panel-content>
        <div  appIsAuthorized="MANAGE_BUILDINGS" isFeatureAvailable="beaconNimway">
          <input matInput class="or-input" type='number' data-cy="set-beacon-minor" [(ngModel)]="minor" disabled>
        </div>
      </div>
      <div footer-content>
        <app-panel-footer [disableReset]="true" [disableSubmit]='!isReady' [dataCy]="'minor'" (resetHandler)="resetMinor()" (updateHandler)="setMinor()" [submitLabel]="'Generate'"></app-panel-footer>
    </div>
    </app-panel>
  }
  </div>
