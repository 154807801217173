import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { ServiceLevel } from '@app/shared/models/service-level';

@Injectable({
  providedIn: 'root'
})
export class ServiceLevelResource {
  private readonly baseUrl: string;
  constructor(private httpClient: HttpClient, @Inject(environmentToken) private environment: Environment) {
    this.baseUrl = `${environment.apiUrl + environment.apiPath}/service-level`;
  }

  get(buildingId: number): Observable<ServiceLevel> {
    return this.httpClient.get<ServiceLevel>(`${this.baseUrl}/building/${buildingId}`);
  }

  save(item: ServiceLevel): Observable<{}> {
    return this.httpClient.put<HttpResponse<ServiceLevel>>(`${this.baseUrl}/building/${item.buildingId}`, item);
  }
}
