import { Component } from '@angular/core';
import { NgStyle } from '@angular/common';
import { HeatmapJsGradient } from '@components/heatmap/renderer/gradient';

@Component({
  selector: 'app-heatmap-legend',
  imports: [NgStyle],
  templateUrl: './heatmap-legend.component.html',
  styleUrl: './heatmap-legend.component.scss'
})
export class HeatmapLegendComponent {
  private gradient = HeatmapJsGradient.DEFAULT_GRADIENT;
  public gradientStyle: any;

  constructor() {
    this.gradientStyle = this.produceGradientStyle();
  }

  public produceGradientStyle(): any {
    const style = {};
    const stops = [];
    let idx;
    // Start with first colour in Gradient
    let colour = this.gradient[Object.keys(this.gradient).sort()[0]];
    for (let i = 0; i < 100; i++) {
      idx = i / 100;
      colour = this.getColourForIndex(idx);
      if (i > 75) {
        stops.push(colour);
      } else {
        stops.push(
          colour +
            Math.min(Math.round(i * 1.8), 255)
              .toString(16)
              .padStart(2, '0')
        ); // Add opacity based on position in index
      }
    }
    style['background-image'] = 'linear-gradient(' + stops.join(', ') + ')';
    return style;
  }

  private getColourForIndex(index: number): string {
    let colour = null;
    if (this.gradient[index] != null) {
      colour = this.gradient[index];
    } else {
      let adjust;
      let nIdx;
      // Go plus or minus 20 to find the nearest colour in gradient
      for (let i = 1; index + i < 20; i++) {
        adjust = i / 100;
        nIdx = Math.round((index + adjust + Number.EPSILON) * 100) / 100;
        colour = this.gradient[nIdx];
        if (colour == null) {
          // If not found plus check minus
          nIdx = Math.round((index - adjust + Number.EPSILON) * 100) / 100;
          colour = this.gradient[nIdx];
        }
        // Stop loop early if found
        if (colour != null) {
          break;
        }
      }
      // If no colour within 20 steps get first colour
      if (colour == null) {
        colour = this.gradient[Object.keys(this.gradient).sort()[0]];
      }
    }
    return colour;
  }
}
