import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { AuthMethod } from '@app/shared/models/auth-method';

@Injectable({
  providedIn: 'root'
})
export class LoginMethodResource {
  private readonly baseUrl: string;

  constructor(private readonly http: HttpClient, @Inject(environmentToken) environment: Environment) {
    this.baseUrl = `${environment.apiUrl}/auth/logins`;
  }

  getSupportedLoginMethods(): Observable<AuthMethod[]> {
    return this.http.get<AuthMethod[]>(this.baseUrl);
  }
}
