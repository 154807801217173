import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { BuildingNotificationPreference } from '@app/shared/models/user-info.interface';
import { Observable } from 'rxjs';
import { Resource } from '@app/shared/resources/resource';
import { Building } from '@app/shared/models/building.interface';

@Injectable({
  providedIn: 'root'
})
export class BuildingNotificationResource extends Resource<Building, number> {
  constructor(protected httpClient: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(httpClient, `${environment.apiUrl + environment.apiPath}/buildingnotification`);
  }

  updateBuildingPreferences(preferences: BuildingNotificationPreference[]): Observable<void> {
    return this.httpClient.put<void>(`${this.baseUrl}`, { preferences });
  }
}
