import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { Rule } from '@app/shared/models/rule';
import { combineLatestWith, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TagService } from '@services/tag.service';
import { DisplayTags, Tag } from '@app/shared/models/tag.interface';
import { NavigationService } from '@services/navigation/navigation.service';
import { Floor } from '@app/shared/models/floor.interface';
import { RuleType } from '@app/shared/models/rule-type';
import { DimLevel } from '@app/shared/models/dim-level';
import { DwellTime } from '@app/shared/models/dwell-time';
import { Personality } from '@app/shared/models/personality';
import { Scene } from '@app/shared/models/scene';

@Component({
  selector: 'app-schedule-rule',
  standalone: true,
  imports: [MatLabel, MatSelect, MatOption, FormsModule, AsyncPipe, SharedComponentsModule],
  templateUrl: './schedule-rule.component.html',
  styleUrl: './schedule-rule.component.scss'
})
export class ScheduleRuleComponent implements OnInit {
  @Input() active!: boolean;
  @Input() buildingId: number;
  @Input() rule: Rule;
  @Output() onRemoveRule = new EventEmitter<void>();
  @Output() onChange = new EventEmitter<void>();

  tags$: Observable<DisplayTags>;
  floors: Floor[];
  ruleOptions = RuleType.all();
  options: DimLevel[] | DwellTime[] | Personality[] | Scene[];

  constructor(private readonly tagService: TagService, private navigationService: NavigationService) {}

  ngOnInit(): void {
    this.loadValueOptions();
    this.updateTagOptions();
    this.floors = this.navigationService.getActiveSection().routeParams.floors;
  }

  getTagIds(): number[] {
    return this.rule.tags.map((tag) => tag.id);
  }

  getFloorIds(): number[] {
    return this.rule.floors.map((floor) => floor.id);
  }

  removeRule(event): void {
    this.onRemoveRule.emit(event.target.value);
  }

  selectedTagsChanged(newTags: Tag[]): void {
    this.rule.tags = newTags.map((tag) => ({ ...tag, buildingId: this.buildingId }));
  }

  selectedFloorsChanged(newFloors: Floor[]): void {
    this.rule.floors = newFloors.map((floor) => ({ ...floor, buildingId: this.buildingId }));
  }

  onRuleChange(): void {
    this.loadValueOptions();
  }

  private loadValueOptions(): void {
    if (
      this.rule.command === RuleType.LOWLIGHT.toString() ||
      this.rule.command === RuleType.MAXLIGHT.toString() ||
      this.rule.command === RuleType.MINLIGHT.toString()
    ) {
      this.options = DimLevel.all();
    } else if (
      this.rule.command === RuleType.DWELL.toString() ||
      this.rule.command === RuleType.LOWLIGHT_TIME.toString()
    ) {
      this.options = DwellTime.all();
    } else if (this.rule.command === RuleType.PERSONALITY.toString()) {
      this.options = Personality.all();
    } else if (this.rule.command === RuleType.SCENE.toString()) {
      this.options = Scene.all();
    }
  }

  private updateTagOptions(): void {
    this.tags$ = this.tagService.getTagsForBuildingId(this.buildingId, true).pipe(
      combineLatestWith(this.tagService.getTagsForBuildingId(this.buildingId, false)),
      map((tags) => {
        return {
          displayTags: tags[1],
          tags: tags[0]
        };
      })
    );
  }
}
