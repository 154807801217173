import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BacnetResource } from '@app/shared/resources/bacnet.resource';
import { BacnetArea } from '@app/shared/models/bacnet-area.interface';

@Injectable({
  providedIn: 'root'
})
export class BacnetAreaService {
  constructor(private bacnetResource: BacnetResource) {}

  getAreas(buildingId: number): Observable<BacnetArea[]> {
    return this.bacnetResource.getAreas(buildingId);
  }

  deleteArea(areaId: number): Observable<void> {
    return this.bacnetResource.delete(areaId);
  }

  addArea(area: BacnetArea): Observable<BacnetArea> {
    return this.bacnetResource.addArea(area);
  }

  updateArea(area: BacnetArea): Observable<BacnetArea> {
    return this.bacnetResource.updateArea(area);
  }
}
