<form
  [formGroup]="buildingForm"
  name="buildingDetails.form"
  novalidate="novalidate"
  data-cy='building-form'
>
  @if (isLoading()) {
    <div class="loading-overlay">
      <mat-spinner />
    </div>
  }
  <div class='lg:container lg:mx-auto'>
    <div class='flex sm:flex-row flex-col'>
      <div class="basis-1 sm:basis-8/12">
        <mat-form-field formGroupName="building" class='w-full sm:w-10/12 sm:ml-2'>
          <mat-label data-cy='building-name-label'>Building name</mat-label>
          <input data-cy='building-name-input' matInput id='name1' data-test="form-building-details-name" formControlName="name" type="text"/>
          @if (buildingForm.get('building').get('name').invalid) {
            <mat-error data-cy='building-name-error'>{{buildingForm.get('building').get('name').errors.message}}</mat-error>
          }
        </mat-form-field>
        <mat-form-field formGroupName="address" class='w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
          <mat-label data-cy='zip-code-label'>ZIP/Postal code</mat-label>
          <input data-cy='zip-code-label' matInput id='postcode' data-test="form-building-details-postcode" formControlName="postcode" type="text" />
        </mat-form-field>
        <mat-form-field  formGroupName="address" class='w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
          <mat-label data-cy='country-label'>Country</mat-label>
          <mat-select data-cy='country-select' id='country' formControlName="country" data-test="form-building-details-country">
            <mat-option data-cy='country-option-disabled' selected disabled >--- Choose a country ---</mat-option>
            @for (country of countries$ | async; track country) {
              <mat-option [attr.data-cy]="'country-option-' + country" [value]="country">{{ country }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        @if(isAuthorizedIsNew$ | async) {
          <mat-form-field  formGroupName="building" class='w-full sm:w-10/12 sm:ml-2'>
            <mat-label data-cy='timezone-label'>Time zone</mat-label>
            <mat-select data-cy='timezone-select' id='timeZone' formControlName="timeZone" data-test="form-building-details-timezone">
              <mat-option data-cy='timezone-option-disabled' selected disabled >--- Choose a timezone ---</mat-option>
              @for (timeZone of timezones$ | async; track timeZone) {
                <mat-option [attr.data-cy]="'timezone-option-' + timeZone" [value]="timeZone">{{ timeZone }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
        <mat-form-field  formGroupName="address" class='w-full sm:w-10/12 sm:ml-2'>
          <mat-label data-cy='address-line1-label'>Address line 1</mat-label>
          <input data-cy='address-line1-input' matInput id='addressLine1' formControlName="addressLine1" type="text" data-test="form-building-details-address1" />
        </mat-form-field>
        <mat-form-field  formGroupName="address" class='w-full sm:w-10/12 sm:ml-2'>
          <mat-label data-cy='address-line2-label'>Address line 2</mat-label>
          <input data-cy='address-line2-input' matInput id='addressLine2' formControlName="addressLine2" type="text" data-test="form-building-details-address2" />
        </mat-form-field>
        <mat-form-field  formGroupName="address" class='w-full sm:w-10/12 sm:ml-2'>
          <mat-label data-cy='address-line3-label'>Address line 3</mat-label>
          <input data-cy='address-line3-input' matInput id='addressLine3' formControlName="addressLine3" type="text" data-test="form-building-details-address3" />
        </mat-form-field>
        <mat-form-field  formGroupName="building" class='w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
          <mat-label data-cy='latitude-label'>Latitude</mat-label>
          <input data-cy='latitude-input' matInput formControlName="latitude" type="number" data-test="form-building-details-latitude" />
        </mat-form-field>
        <mat-form-field formGroupName="building" class='w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
          <mat-label data-cy='longitude-label'>Longitude</mat-label>
          <input data-cy='longitude-input' matInput formControlName="longitude" type="number" data-test="form-building-details-longitude" />
        </mat-form-field>
        <mat-form-field  appIsAuthorized="MANAGE_LUMINAIRE_MANUFACTURERS" formGroupName="building" class='w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
          <mat-label data-cy='luminaire-manufacturers-label'>Luminaire Manufacturers</mat-label>
          <mat-select data-cy='luminaire-manufacturers-select' [id]='"luminaires"' multiple formControlName="luminaireManufacturers">
            <mat-option data-cy='luminaire-manufacturers-option-disabled' selected disabled>--- Choose luminaire manufacturer/s ---</mat-option>
            @for (luminaireManufacturer of luminaireManufacturers$ | async; track luminaireManufacturer.id) {
              <mat-option [attr.data-cy]="'luminaire-manufacturers-option-' + luminaireManufacturer.id" [value]="luminaireManufacturer.id">{{ luminaireManufacturer.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        @if (isNew) {
          <mat-form-field formGroupName="building" class='w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
            <mat-label data-cy='managing-company-label'>Managing Company</mat-label>
            <mat-select data-cy='managing-company-select' id='managingCompanyNew' formControlName="managingCompanyId" data-test="form-building-details-managing-company">
              <mat-option data-cy='managing-company-option-disabled' selected disabled >--- Choose a company ---</mat-option>
              @for (managingCompany of managingCompanies$ | async; track $index) {
                <mat-option [attr.data-cy]="'managing-company-option-' + managingCompany.id" [value]="managingCompany.id">{{ managingCompany.companyName }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        } @else {
          <mat-form-field formGroupName="building" appIsAuthorized="MANAGE_BUILDINGS" class='w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
            <mat-label data-cy='managing-company-label'>Managing Company</mat-label>
            <mat-select data-cy='managing-company-select' id='managingCompany' formControlName="managingCompanyId" data-test="form-building-details-managing-company">
              <mat-option data-cy='managing-company-option-disabled' selected disabled >--- Choose a company ---</mat-option>
              @for (managingCompany of managingCompanies$ | async; track managingCompany.id) {
                <mat-option [attr.data-cy]="'managing-company-option-' + managingCompany.id" [value]="managingCompany.id">{{ managingCompany.companyName }}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field  isFeatureAvailable="setStatusFrequency" formGroupName="building" class='w-full sm:w-10/12  md:w-5/12 sm:ml-2'>
            <mat-label data-cy='status-frequency-seconds-label'>Status Frequency (seconds)</mat-label>
            <input data-cy='status-frequency-seconds-input' matInput formControlName="statusFrequencySeconds"
                   type="number" data-test="form-building-details-status-frequency" />
          </mat-form-field>

          <mat-form-field matTooltip='Editable on Service Information page' formGroupName="building" appearance='fill' class='w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
            <mat-label data-cy='service-level-label'>Service Level</mat-label>
            <input readonly data-cy='service-level-input' matInput id='serviceLevel'
                   data-test="form-building-details-service-level" formControlName="serviceLevel" />
          </mat-form-field>

          <mat-form-field matTooltip='Editable on Service Information page' formGroupName="building" appearance='fill' class='w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
            <mat-label data-cy='building-floor-area-label'>Building Floor Area (sqm)</mat-label>
            <input readonly data-cy='building-floor-area-input' matInput id='buildingFloorAreaSqMeters'
                   data-test="form-building-details-floor-area" formControlName="buildingFloorAreaSqMeters" />
          </mat-form-field>

          <mat-form-field matTooltip='Editable on Service Information page' formGroupName="building" appearance='fill' class='to-date-field filter-form-field w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
            <mat-label>Service Level Start Date</mat-label>
            <input readonly formControlName='serviceLevelStartDate' data-cy='service-level-start-date' matInput>
          </mat-form-field>
        }
        <div class='w-full md:w-10/12 flex md:flex-row-reverse flex-col sm:ml-2'>
          <button
            data-cy='building-form-submit'
            mat-raised-button
            data-test="form-building-details-submit"
            class="w-full sm:w-10/12 md:w-1/2 md:ml-6 mb-4"
            color='primary'
            type="submit"
            (click)="submit()"
            [disabled]="buildingForm.invalid || buildingForm.pristine"
          >
            @if (isNew) {
              <span class="or-icon or-small or-inverse or-icon-plus"></span>
              <span class="or-button-label" data-cy='building-form-submit-label'>Add building</span>
            }
            @else {
              <span class="or-icon or-small or-inverse or-icon-checkmark"></span>
              <span class="or-button-label" data-cy='building-form-submit-label'>Save</span>
            }
          </button>
          <button
            data-cy='building-form-reset'
            class='w-full sm:w-10/12 md:w-1/2'
            (click)="reset()"
            mat-raised-button
            color='accent'
            data-test="form-building-details-reset"
            [disabled]="buildingForm.pristine"
          >
            <span class="or-icon or-small or-icon-reload"></span>
            <span>Reset changes</span>
          </button>
        </div>
      </div>
      <div class="sm:basis-4/12 basis-1 mt-6" #imageParent>
        <app-image-upload
          [imageUrl$]="imageUrl$"
          [error$]="error$"
          (onUpload)="onImageUpload($event)"
          [caption]="'Upload building photo'"
          [width]="imageWidth"
          [height]="imageWidth"
        >
        </app-image-upload>
        @if (!isNew && details$ | async; as details) {
          <mat-form-field class='w-full'>
            <mat-label data-cy='ble-scanning-label'>BLE Scanning Message</mat-label>
            <mat-select data-cy='ble-scanning-drop-down' #bleScanning data-test="form-building-details-ble-scanning-option">
              <mat-option data-cy='ble-scanning-choose-option' disabled>Choose a BLE Command</mat-option>
              <mat-option data-cy='ble-scanning-query' [value]='"QUERY"'>Query BLE Scanning</mat-option>
              <mat-option data-cy='ble-scanning-enable' [value]='"ENABLE"'>Enable BLE Scanning</mat-option>
              <mat-option data-cy='ble-scanning-disable' [value]='"DISABLE"'>Disable BLE Scanning</mat-option>
            </mat-select>
          </mat-form-field>
          <button (click)="sendBleScanning(bleScanning.value, details)" class='w-full'
                  mat-raised-button
                  color='primary'
                  data-test="form-building-enable-ble"
                  data-cy='ble-scanning-broadcast-button'>
            <div class='flex flex-row'>
              <span class="or-icon or-small or-icon-ble-on or-inverse"></span>
              <span class='ml-4 pt-1'>Broadcast BLE Command</span>
            </div>
          </button>
        }
      </div>
    </div>
  </div>
</form>
