import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/shared/services/user/user.service';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { ITenant } from '@app/shared/models/tenant.interface';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { concatMap, Observable, Subject } from 'rxjs';
import {
  TenantDialogComponent,
  TenantDialogData
} from '@app/administration/tenants/tenant-dialog/tenant-dialog.component';
import { TenantService } from '@app/shared/services/building/tenant.service';
import { TenantsDatasource } from '@app/administration/tenants/tenants.datasource';
import { TenantResource } from '@app/shared/resources/tenant.resource';
import { ConfirmComponent, ConfirmDialogData } from '@app/shared/components/dialogs/confirm/confirm.component';
import { TagService } from '@app/shared/services/tag.service';
import { TagTenantBridgeService } from '@app/shared/services/tag-tenant-bridge.service';
import { ActivatedRoute } from '@angular/router';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from '@angular/material/table';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatFabButton, MatMiniFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatCard } from '@angular/material/card';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatPaginator } from '@angular/material/paginator';
import { TenantUserComponent } from './tenant-user/tenant-user.component';
import { AuthorizationModule } from '../../shared/directives/authorization.module';

/**
 * This component is responsible for listing available tenants for the building
 * From this component, new tenants can be added, or existing tenants can be removed or edited
 * The component uses a locally defined `DialogAction` type which is used to show dialogs
 * based on "ADD" or "UPDATE" action
 */
@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.scss'],
  imports: [
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatSortHeader,
    MatMiniFabButton,
    MatTooltip,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    NgIf,
    MatCard,
    MatProgressSpinner,
    MatPaginator,
    MatFabButton,
    TenantUserComponent,
    AuthorizationModule,
    AsyncPipe
  ]
})
export class TenantsComponent implements OnInit {
  constructor(
    private buildingService: UserService,
    private navigationService: NavigationService,
    private dialog: MatDialog,
    private tenantService: TenantService,
    private tenantResource: TenantResource,
    private tagService: TagService,
    private bridgeService: TagTenantBridgeService,
    private route: ActivatedRoute
  ) {}

  buildingId: number;
  tenantDatasource: TenantsDatasource;
  tenantDisplayedColumns: string[] = ['id', 'name', 'actions'];
  DA: typeof DialogAction = DialogAction;
  readonly tenantsPageSize = 10;
  tagNames: string[] = [];
  reloadUsers: Subject<boolean> = new Subject<boolean>();

  ngOnInit(): void {
    this.route.params
      .pipe(
        concatMap((params) => {
          const { buildingId } = params;
          return this.buildingService.getBuilding(buildingId);
        })
      )
      .subscribe((building) => {
        this.navigationService.initNavigation(window.location.href, building);
        this.buildingId = building.id;
        this.setup();
      });
  }

  setup(): void {
    this.tenantDatasource = new TenantsDatasource(this.tenantResource, this.buildingId);
    this.refresh();
  }

  private updateTenant(updatedValue: ITenant): void {
    this.tenantService.updateTenant(updatedValue).subscribe({
      next: () => {
        this.bridgeService.shouldReloadTags.next(true);
        this.refresh();
      },
      error: (error) => {
        alert(error.error);
        this.refresh();
      }
    });
  }

  public deleteUsersFromTenant(tenantToDelete: ITenant): void {
    this.tenantService.deleteUsersFromTenant(tenantToDelete.id).subscribe(() => {
      this.reloadUsers.next(true);
    });
  }

  public addTenant(tenant: ITenant): void {
    if (!this.tagNames.includes(tenant.name)) {
      this.tenantService.addTenant(tenant).subscribe({
        next: () => {
          this.bridgeService.shouldReloadTags.next(true);
          this.refresh();
        },
        error: (error) => {
          alert(error.error);
          this.refresh();
        }
      });
    } else {
      alert('Invalid tenant name. A tag with the same name already exists in the building.');
    }
  }

  private refresh(): void {
    this.tenantDatasource.loadTenants('', 0, this.tenantsPageSize);
    this.tagService.getTagsForBuildingId(this.buildingId, false).subscribe((tags) => {
      this.tagNames = tags.map((tag) => tag.name);
    });
  }

  openTenantDialog(dialog: MatDialog, obj): Observable<any> {
    const config = new MatDialogConfig();
    config.autoFocus = true;
    config.data = obj;
    config.width = '600px';
    const dialogRef = dialog.open(TenantDialogComponent, config);
    return dialogRef.afterClosed();
  }

  openDeleteConfirmationDialog(dialog: MatDialog, data): Observable<any> {
    const config = new MatDialogConfig();
    config.autoFocus = true;
    config.data = data;
    config.width = '600px';
    config.panelClass = 'no-overflow-dialog';
    const dialogRef = dialog.open(ConfirmComponent, config);
    return dialogRef.afterClosed();
  }

  public doDeleteUsers(tenant: ITenant): void {
    const data = new ConfirmDialogData(`Are you sure you want to remove users from Tenant: "${tenant.name}"`);
    this.openDeleteConfirmationDialog(this.dialog, data).subscribe((confirm: boolean) => {
      if (confirm) {
        this.deleteUsersFromTenant(tenant);
      }
    });
  }

  public doAdd(): void {
    const dialogData: TenantDialogData = {
      action: DialogAction.ADD,
      buildingId: this.buildingId
    };
    this.openTenantDialog(this.dialog, dialogData).subscribe((result: { event: string; data: any }) => {
      if (result.data) {
        this.addTenant(result.data);
      }
    });
  }

  public doUpdate(tenant: ITenant): void {
    const dialogData: TenantDialogData = {
      action: DialogAction.UPDATE,
      tenant,
      buildingId: this.buildingId
    };
    this.openTenantDialog(this.dialog, dialogData).subscribe((result: { event: string; data: any }) => {
      if (result.data) {
        this.updateTenant(result.data);
      }
    });
  }
}

export enum DialogAction {
  ADD = 'Add',
  UPDATE = 'Update'
}
