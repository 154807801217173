<mat-card class="config-card mb-6 pb-4" [attr.disabled]="disabled || null" [matTooltip]='tooltip' [matTooltipPosition]="'left'">
  <mat-card-header class='text-lg text-grey-600 font-medium mb-4'>
    <div class='mb-2'>
      <mat-icon class='mx-2 align-middle'>{{icon}}</mat-icon><span>{{title}}</span>
    </div>
  </mat-card-header>
  <mat-card-content class='mx-4 min-h-[60px] content-center'>
    <ng-content select="[panel-content]"></ng-content>
  </mat-card-content>
  <mat-card-footer class='px-1'>
    <ng-content select="[footer-content]"></ng-content>
  </mat-card-footer>
</mat-card>
