import { AuthMethod } from '@app/shared/models/auth-method';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoginMethodResource } from '@app/shared/resources/login-method.resource';

@Injectable({
  providedIn: 'root'
})
export class LoginMethodsService {
  constructor(private readonly loginMethodResource: LoginMethodResource) {}

  getSupportedLoginMethods(): Observable<AuthMethod[]> {
    return this.loginMethodResource
      .getSupportedLoginMethods()
      .pipe(
        map((authMethods: AuthMethod[]) =>
          authMethods.map((authMethod) => AuthMethod.fromString(authMethod.toString()))
        )
      );
  }
}
