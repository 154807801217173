import { Building } from '@app/shared/models/building.interface';
import { ServiceLevelType } from '@app/shared/models/service-level-type';

export const building: Building = {
  address: {
    addressLine1: 'level 1',
    addressLine2: 'trennery cresent',
    addressLine3: 'Melbourne',
    postcode: '3067',
    country: 'Australia'
  },
  floors: [
    {
      id: 215613706,
      buildingId: 72595518,
      floorNumber: 1,
      floorplan: '0671ae5b-737f-4995-bf89-8c8aa5b61431-trenerrycre.png',
      name: 'Melbourne Office',
      note: 'Used in Cypress Automation - please do not use!'
    },
    {
      id: 215613751,
      buildingId: 72595518,
      floorNumber: 2,
      floorplan: '129_556f24d9-b26a-4d5a-bb6a-baf64e3c1ab8-floorplan_Link-ping.png',
      name: 'Linköping Office',
      note: ''
    },
    {
      id: 215613755,
      buildingId: 72595518,
      floorNumber: 3,
      floorplan: '3348_d3ee1c97-847f-45dd-8a86-6edd0c517c09-floorplan_big.jpg',
      name: 'Office Workshop',
      note: ''
    },
    {
      id: 220207587,
      buildingId: 72595518,
      floorNumber: 4,
      floorplan: '37_ce9fe00b-4749-49bb-85a2-2ee6655bd658-floorplan_bigger.jpg',
      name: 'Emergency Schedule Testing',
      note: 'Used in Cypress Automation - please do not use!'
    },
    {
      id: 220282999,
      buildingId: 72595518,
      floorNumber: 5,
      floorplan: '3348_b0c394ad-569d-4b8c-9f16-08ccd8cd09fa-trenerrycre.png',
      name: 'Map Nodes',
      note: 'Used in Cypress Automation - please do not use!'
    },
    {
      id: 220283004,
      buildingId: 72595518,
      floorNumber: 108,
      floorplan: '3348_75a50141-878a-4af6-9c0e-f132d8dd8296-floorplan.png',
      name: 'Portal Team - Manual Testing',
      note: 'developers '
    },
    {
      id: 220283532,
      buildingId: 72595518,
      floorNumber: 111,
      floorplan: 'e203fee9-362e-4ba7-a615-7ac1ced384b3-floorplan_big.jpg',
      name: 'Asha ',
      note: 'dgds'
    }
  ],
  id: 72595518,
  latitude: -90,
  longitude: 180,
  managingCompanyId: 8,
  name: 'Örgânìç Réspònsè Òffïçë',
  statusFrequencySeconds: 300,
  serviceLevel: ServiceLevelType.SMART_START,
  buildingFloorAreaSqMeters: 100,
  tags: [],
  tenants: [],
  thumbnailImage: '37_1af5ea81-2e2c-4172-908f-08573a1f58ff-OR2.png',
  timeZone: 'Australia/Melbourne'
};

export const nodeData = [
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435416203,
    address: 658188,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 215613706,
    groupId: 10,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'PN',
    properlyMapped: true,
    scene: null,
    subscriber: true,
    tags: [],
    updatedAt: '2024-06-02T22:09:41.618696Z',
    x: 309,
    y: 405,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435469751,
    address: 57005,
    bleKey: null,
    connected: false,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 215613706,
    groupId: null,
    installedAt: '2023-10-20T00:01:18.676325Z',
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: null,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-26T20:31:32.424525Z',
    x: 85,
    y: 434,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: 'ON',
    id: 435472925,
    address: 11609245,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220282999,
    groupId: 1122,
    installedAt: '2024-05-09T12:05:13.516704Z',
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 8,
    subscriber: false,
    tags: [],
    updatedAt: '2024-05-20T04:55:28.525659Z',
    x: 254,
    y: 497,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474637,
    address: 200001,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:30.053503Z',
    x: 60,
    y: 395,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474638,
    address: 200002,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:30.265900Z',
    x: 120,
    y: 395,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474639,
    address: 200003,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:30.094084Z',
    x: 180,
    y: 395,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474640,
    address: 200004,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:30.061303Z',
    x: 240,
    y: 395,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474641,
    address: 200005,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:30.241517Z',
    x: 300,
    y: 395,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474642,
    address: 200006,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:30.188036Z',
    x: 360,
    y: 395,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474643,
    address: 200007,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:30.108058Z',
    x: 420,
    y: 395,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474644,
    address: 200008,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:30.242841Z',
    x: 480,
    y: 395,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474645,
    address: 200009,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:29.848820Z',
    x: 540,
    y: 395,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474646,
    address: 200010,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:30.225489Z',
    x: 600,
    y: 395,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474647,
    address: 200011,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:29.892945Z',
    x: 660,
    y: 395,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474648,
    address: 200012,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:30.256896Z',
    x: 720,
    y: 395,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474649,
    address: 200013,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:29.990791Z',
    x: 780,
    y: 395,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474650,
    address: 200014,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:30.053990Z',
    x: 844,
    y: 399,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474651,
    address: 200015,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:30.186744Z',
    x: 900,
    y: 395,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474652,
    address: 200016,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:30.129764Z',
    x: 960,
    y: 395,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474653,
    address: 200017,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:30.158555Z',
    x: 1020,
    y: 395,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474654,
    address: 200018,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:30.256495Z',
    x: 1080,
    y: 395,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474655,
    address: 200019,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:30.212440Z',
    x: 1140,
    y: 395,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474656,
    address: 200020,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:29:30.216321Z',
    x: 1216,
    y: 397,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474671,
    address: 200101,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.260616Z',
    x: 20,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474672,
    address: 200102,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.146409Z',
    x: 80,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474673,
    address: 200103,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.212429Z',
    x: 140,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474674,
    address: 200104,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.146967Z',
    x: 200,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474675,
    address: 200105,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.154063Z',
    x: 260,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474676,
    address: 200106,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.274973Z',
    x: 320,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474677,
    address: 200107,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.292455Z',
    x: 380,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474678,
    address: 200108,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.273982Z',
    x: 440,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474679,
    address: 200109,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.155988Z',
    x: 500,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474680,
    address: 200110,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.160455Z',
    x: 560,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474681,
    address: 200111,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.219895Z',
    x: 620,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474682,
    address: 200112,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.219363Z',
    x: 680,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474683,
    address: 200113,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.264446Z',
    x: 740,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474684,
    address: 200114,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.235198Z',
    x: 800,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474685,
    address: 200115,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.210150Z',
    x: 860,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474686,
    address: 200116,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.312425Z',
    x: 920,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474687,
    address: 200117,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.250220Z',
    x: 980,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474688,
    address: 200118,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.283003Z',
    x: 1040,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474689,
    address: 200119,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.286666Z',
    x: 1100,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474690,
    address: 200120,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 220207587,
    groupId: null,
    installedAt: null,
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-14T05:34:51.210968Z',
    x: 1160,
    y: 200,
    isNotifying: false
  },
  {
    value: 0,
    radius: 0,
    hasMappingBeenAttempted: false,
    bleScanning: null,
    id: 435474691,
    address: 11184810,
    bleKey: null,
    connected: true,
    duplicateAddressMappings: [],
    duplicateGroupMappings: [],
    emDrivers: [],
    floorId: 215613706,
    groupId: null,
    installedAt: '2024-02-26T12:42:27.638Z',
    luminaireDrivers: [],
    nodeType: 'SN3',
    properlyMapped: true,
    scene: 0,
    subscriber: null,
    tags: [],
    updatedAt: '2024-05-31T00:44:29.941829Z',
    x: 214,
    y: 94,
    isNotifying: false
  }
];

export const mockLogData = [
  {
    testId: 1315447,
    timeStarted: 1717555689262.486,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137097,
    testsType: 'DURATION',
    state: 'SUCCEEDED',
    message: 'Success',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315446,
    timeStarted: 1717555689262.486,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137096,
    testsType: 'DURATION',
    state: 'SUCCEEDED',
    message: 'Success',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315445,
    timeStarted: 1717555635414.7898,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137097,
    testsType: 'DURATION',
    state: 'SUCCEEDED',
    message: 'Success',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315444,
    timeStarted: 1717555635414.7898,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137096,
    testsType: 'DURATION',
    state: 'SUCCEEDED',
    message: 'Success',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315443,
    timeStarted: 1717555579315.99,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137097,
    testsType: 'DURATION',
    state: 'SUCCEEDED',
    message: 'Success',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315442,
    timeStarted: 1717555579315.99,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137096,
    testsType: 'DURATION',
    state: 'SUCCEEDED',
    message: 'Success',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315441,
    timeStarted: 1717555521483.146,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137097,
    testsType: 'DURATION',
    state: 'FAILED',
    message: 'Battery Duration Failure, Duration test result: 100 minutes',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315440,
    timeStarted: 1717555521483.146,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137096,
    testsType: 'DURATION',
    state: 'FAILED',
    message: 'Battery Duration Failure, Duration test result: 100 minutes',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315438,
    timeStarted: 1717555465643.5918,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137096,
    testsType: 'DURATION',
    state: 'FAILED',
    message: 'Circuit Failure, Emergency Lamp Failure, Duration test result: 0 minutes',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315439,
    timeStarted: 1717555465643.5918,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137097,
    testsType: 'DURATION',
    state: 'FAILED',
    message: 'Circuit Failure, Emergency Lamp Failure, Duration test result: 0 minutes',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315436,
    timeStarted: 1717555410486.5989,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137096,
    testsType: 'FUNCTION',
    state: 'CANCELLED',
    message: 'Cancelled by user',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315437,
    timeStarted: 1717555410486.5989,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137097,
    testsType: 'FUNCTION',
    state: 'CANCELLED',
    message: 'Cancelled by user',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315434,
    timeStarted: 1717555354157.779,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137096,
    testsType: 'FUNCTION',
    state: 'SUCCEEDED',
    message: 'Success',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315435,
    timeStarted: 1717555354157.779,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137097,
    testsType: 'FUNCTION',
    state: 'SUCCEEDED',
    message: 'Success',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315432,
    timeStarted: 1717555299932.6938,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137096,
    testsType: 'FUNCTION',
    state: 'SUCCEEDED',
    message: 'Success',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315433,
    timeStarted: 1717555299932.6938,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137097,
    testsType: 'FUNCTION',
    state: 'SUCCEEDED',
    message: 'Success',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315430,
    timeStarted: 1717555243327.881,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137096,
    testsType: 'FUNCTION',
    state: 'FAILED',
    message: '',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315431,
    timeStarted: 1717555243327.881,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137097,
    testsType: 'FUNCTION',
    state: 'FAILED',
    message: '',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315429,
    timeStarted: 1717555185424.57,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137097,
    testsType: 'FUNCTION',
    state: 'FAILED',
    message: 'Emergency Lamp Failure',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  },
  {
    testId: 1315428,
    timeStarted: 1717555185424.57,
    sensorNodeId: 435474691,
    sensorNodeLink: '/buildings/72595518/emergency-lighting/215613706/manual-tests?n=435474691',
    driverId: 433137096,
    testsType: 'FUNCTION',
    state: 'FAILED',
    message: 'Emergency Lamp Failure',
    timeZone: 'Australia/Melbourne',
    scheduleName: 'Manually Executed'
  }
];
