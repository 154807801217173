@if (building) {
  <div class="grid grid-cols-12 gap-[10px] pt-[10px] px-[20px]">
    <app-panel-toggle class="col-span-4" label="Nodes" icon="node" (togglePanel)="toggleLeft($event)" [toggleState]="isLeftColHidden"></app-panel-toggle>
    <div class="col-span-6"></div>
    <div class="flex col-span-2" [ngClass]="isRightColHidden ? 'justify-end' : ''">
      <app-panel-toggle label="Tags" icon="tag" (togglePanel)="toggleRight($event)" [toggleState]="isRightColHidden"></app-panel-toggle>
    </div>
  </div>
  <div id="sensor-nodes-view" class="grid grid-rows-12 grid-cols-12 gap-[10px] py-[10px] px-[20px]">
    <div class="row-span-12" [ngClass]="nodeListPanelClass">
      <div class="h-full">
        <app-node-list-card [sortByFields]='sortByFields' [buildingId]="building.id" [floorId]="floorId"></app-node-list-card>
      </div>
    </div>
    <div class="row-span-12" [ngClass]="floorPanelClass">
      <div class="bg-white rounded-sm p-3 text-inverse shadow-dark h-full relative">
        <app-floorplan
          [buildingId]="building.id"
          (onNodeClick)="nodeClick($event)"
          (onSnMappingMode)="toggleBoth($event)"
          (onPnMappingMode)="toggleBoth($event)">
        </app-floorplan>
        <app-mapped-nodes-counter [nodes]="currentFloorNodes$ | async"></app-mapped-nodes-counter>
      </div>
    </div>
    <div class="row-span-12" [ngClass]="isRightColHidden ? 'hidden' : 'col-span-2'">
      <div class="h-full">
        <app-tags
          [buildingId]="building.id"
          [tags$]="tags$"
          [isEditable]="true"
          [isCreateEnabled]="true"
          [isNodeTaggingModeActive]="true"
          (tagSelectionChange)="toggleTag($event)"
          (tagCreate)="tagCreate($event)"
          (tagUpdate)="tagUpdate($event)"
          (tagDeleted)="deleteTag($event)">
        </app-tags>
        <app-lamp-type-form></app-lamp-type-form>
      </div>
    </div>
  </div>
}
