import { BehaviorSubject, Observable } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { NumericUtils } from '@app/shared/utils/numeric';
import { map } from 'rxjs/operators';

export enum ZoomOperation {
  ZOOM_IN,
  ZOOM_OUT
}

@Injectable({
  providedIn: 'root'
})
export class ImageZoomService {
  private currentScale: number;
  private attemptToRecenter: boolean;
  recenterEvent = new EventEmitter<void>();

  private readonly scale$: BehaviorSubject<number>;
  private readonly centered$: BehaviorSubject<boolean>;
  private initialScale = 1.0;
  private readonly minimumScale = 0.1;
  private readonly maximumScale = 2.0;
  private readonly scaleFactor = 1.2;

  constructor() {
    this.scale$ = new BehaviorSubject<number>(this.initialScale);
    this.centered$ = new BehaviorSubject<boolean>(true);
    this.scale$.subscribe((latestVal) => (this.currentScale = latestVal));
  }

  public reset(): void {
    this.scale$.next(this.initialScale);
  }

  public resetAndRecenter(): void {
    this.reset();
    this.recenterEvent.emit();
    this.attemptToRecenter = true;
  }

  public setCentered(): void {
    this.attemptToRecenter = false;
    this.centered$.next(true);
  }

  public setUnCentered(): void {
    this.centered$.next(false);
  }

  public shouldAttemptToRecenter(): boolean {
    return this.attemptToRecenter;
  }

  public isCentered(): Observable<boolean> {
    return this.centered$.asObservable();
  }

  public zoom(operation: ZoomOperation): void {
    if (this.canZoom(this.currentScale, operation)) {
      this.scale$.next(this.calculateNewScale(this.currentScale, operation));
    }
  }

  public isScaled(): Observable<boolean> {
    return this.scale$.pipe(map((currentScale: number) => currentScale !== this.initialScale));
  }

  public canZoom(currentScale: number, operation: ZoomOperation): boolean {
    const newScale = this.calculateNewScale(currentScale, operation);
    return (
      newScale !== currentScale &&
      (newScale >= this.minimumScale || operation === ZoomOperation.ZOOM_IN) &&
      (newScale <= this.maximumScale || operation === ZoomOperation.ZOOM_OUT)
    );
  }

  public canZoomIn(): Observable<boolean> {
    return this.scale$.pipe(
      map((currentScale: number) => {
        return this.canZoom(currentScale, ZoomOperation.ZOOM_IN);
      })
    );
  }

  public canZoomOut(): Observable<boolean> {
    return this.scale$.pipe(
      map((currentScale: number) => {
        return this.canZoom(currentScale, ZoomOperation.ZOOM_OUT);
      })
    );
  }

  private calculateNewScale(currentScale: number, operation: ZoomOperation): number {
    let val;
    if (operation === ZoomOperation.ZOOM_IN) {
      val = NumericUtils.roundFloatingPoint(currentScale * this.scaleFactor, 2);
      return val > this.maximumScale ? this.maximumScale : val;
    }
    if (operation === ZoomOperation.ZOOM_OUT) {
      val = NumericUtils.roundFloatingPoint(currentScale / this.scaleFactor, 2);
      return val < this.minimumScale ? this.minimumScale : val;
    }
  }

  public getScale(): Observable<number> {
    return this.scale$.asObservable();
  }

  public setScale(scale: number): void {
    this.scale$.next(scale);
  }

  public setDefaultScale(scale: number): void {
    this.initialScale = scale;
  }
}
