import { Component, Input, OnInit } from '@angular/core';
import { OtapUpgradeTaskDTO } from '@app/shared/models/otap-upgrade-task.interface';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-job-tasks-tile',
  templateUrl: './job-tasks-tile.component.html',
  styleUrls: ['./job-tasks-tile.component.scss'],
  imports: [NgIf]
})
export class JobTasksTileComponent implements OnInit {
  @Input() task: OtapUpgradeTaskDTO;
  dataSource: { startedDateTime: Date; endedDateTime: Date; count: number; expectedCount: number };

  constructor() {}

  ngOnInit(): void {
    this.dataSource = {
      startedDateTime: null,
      endedDateTime: null,
      count: null,
      expectedCount: this.task.expectedCount
    };

    if (this.task && this.task.statuses && this.task.statuses.length > 0) {
      this.dataSource.startedDateTime = this.task.statuses[0].startedDateTime;
      this.dataSource.endedDateTime = this.task.statuses[0].endedDateTime;
      this.dataSource.count = this.task.statuses[0].count;
    }
  }

  public dateInLocale(date: Date): string {
    return date ? new Date(date).toLocaleString() : new Date().toLocaleString();
  }
}
