import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Schedule, ScheduleType } from '@app/shared/models/schedule';
import { ScheduleService } from '@services/schedules.service';
import { WeekdaySelection } from '@app/shared/models/weekday-selection';
import { ScheduleTileComponent } from '@app/schedules/schedule-tile/schedule-tile.component';
import { FormWeekdayScheduleComponent } from '@app/schedules/form-weekday-schedule/form-weekday-schedule.component';
import { FormDatedScheduleComponent } from '@app/schedules/form-dated-schedule/form-dated-schedule.component';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { DayOfTheWeek } from '@app/shared/models/day-of-the-week';
import { StringUtils } from '@app/shared/utils/string.utils';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { Building } from '@app/shared/models/building.interface';
import { TimeUtils } from '@app/shared/utils/time.utils';
import { TimezoneUtils } from '@app/shared/utils/timezoneUtils';

@Component({
  selector: 'app-schedule-summary',
  templateUrl: './schedule-summary.component.html',
  styleUrl: './schedule-summary.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    ScheduleTileComponent,
    FormWeekdayScheduleComponent,
    FormDatedScheduleComponent,
    FormsModule,
    MatButton,
    MatTooltip,
    MatIcon,
    MatButtonToggleModule,
    SharedComponentsModule
  ]
})
export class ScheduleSummaryComponent implements OnInit {
  @Input() isNew: boolean;
  @Input() building: Building;
  @Input() schedule?: Schedule;
  @Output() onSaveNewSchedule = new EventEmitter<Schedule>();
  @Output() onDeleteSchedule = new EventEmitter<Schedule>();
  @Output() onClose = new EventEmitter<void>();
  @Output() onMoveUp = new EventEmitter<Schedule>();
  @Output() onMoveDown = new EventEmitter<Schedule>();

  startOfDay = '00:00';
  endOfDay = '23:59';

  isCollapsed = true;
  isControlActionsMenuActive: boolean;
  isBusy: boolean;
  status: string;
  promptToDisable = false;
  scheduleType: ScheduleType = 'weekday';
  private oldDetails: Schedule;

  constructor(private scheduleService: ScheduleService) {}

  ngOnInit(): void {
    if (!this.schedule || this.isNew) {
      this.schedule = new Schedule();
      this.schedule.rules = [];
      this.schedule.editable = true;
      this.isNew = true;
      this.schedule.startDate = TimeUtils.convertTimezone(
        new Date(),
        TimezoneUtils.mapUtcToKnownTimezone(this.building.timeZone)
      );
      this.schedule.endDate = new Date(this.schedule.startDate.getTime());
    } else if (!this.schedule.days) {
      this.scheduleType = 'dated';
    }
    this.oldDetails = Schedule.clone(this.schedule);
    this.updateStatus();
  }

  toggleSchedule(scheduleType: MatButtonToggleChange): void {
    this.scheduleType = scheduleType.value;
    if (this.scheduleType === 'dated') {
      this.schedule.days = null;
    } else if (this.oldDetails.days) {
      this.schedule.days = this.oldDetails.days;
    } else {
      this.schedule.days = new WeekdaySelection();
    }
  }

  get activeActionIndex(): number {
    return this.schedule.alwaysActive ? 2 : this.schedule.enabled ? 0 : 1;
  }

  get controlActionsClass(): string {
    return 'action-' + `${this.activeActionIndex}`;
  }

  getControlActionClass(index: number): string {
    return `${this.controlActionsClass}  ${
      this.isControlActionsMenuActive || this.activeActionIndex === index ? 'active' : ''
    }`;
  }

  toggleCollapse(): void {
    if (this.schedule.editable) {
      this.isCollapsed = this.schedule.active ? true : !this.isCollapsed;
      if (this.schedule.active && !this.isBusy) {
        this.promptToDisable = true;
      }
    }
  }

  toggleEnabledState(setEnabled: boolean): void {
    if (!setEnabled) {
      this.promptToDisable = false;
    }
    const schedule = Schedule.clone(this.schedule);
    schedule.enabled = setEnabled;
    schedule.alwaysActive = false;

    if (!schedule.enabled) {
      schedule.active = false;
    }
    this.updateSchedule(schedule);
    this.isControlActionsMenuActive = false;
  }

  toggleAlwaysActiveState(isActive: boolean): void {
    this.promptToDisable = false;
    this.schedule.alwaysActive = isActive;
    if (this.schedule.alwaysActive) {
      this.schedule.enabled = true;
      this.schedule.active = true;
    }
    if (!this.isNew) {
      this.updateSchedule(this.schedule);
    }
    this.isControlActionsMenuActive = false;
  }

  toggleControlActionsMenu(isActive: boolean): void {
    this.isControlActionsMenuActive = isActive;
  }

  get daysOfTheWeek(): DayOfTheWeek[] {
    return DayOfTheWeek.all();
  }

  getDayOfWeekLabel(day: DayOfTheWeek): string {
    const dayString = StringUtils.toSentenceCase(day.toString());
    this.checkDays();
    if (this.schedule.days?.isActive(day.ordinal)) {
      return `Enabled on ${dayString}`;
    } else {
      return `Disabled on ${dayString}`;
    }
  }

  getCircleClass(day: number): string {
    if (this.schedule.days.isActive(day)) {
      return 'or-filled';
    } else {
      return null;
    }
  }

  get summaryStateClass(): string {
    let style = '';
    if (this.schedule.active) {
      style = 'or-schedule-summary-header-running';
    }
    return style;
  }

  moveUp(): void {
    this.onMoveUp.emit(this.schedule);
  }

  moveDown(): void {
    this.onMoveDown.emit(this.schedule);
  }

  checkDays(): void {
    if (!this.schedule.days) {
      this.schedule.days = new WeekdaySelection();
    }
  }

  isSameDay(): boolean {
    const d1 = this.schedule.startDate;
    const d2 = this.schedule.endDate;
    return (
      (d1 == null && d2 == null) ||
      (d1 &&
        d2 &&
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate())
    );
  }

  close(): void {
    this.onClose.emit();
  }

  saveNewSchedule(): void {
    this.onSaveNewSchedule.emit(this.schedule);
  }

  deleteSchedule(): void {
    this.onDeleteSchedule.emit(this.schedule);
  }

  private updateSchedule(schedule: Schedule): void {
    this.isBusy = true;
    this.status = 'Pending';
    this.promptToDisable = false;
    if (this.scheduleType === 'dated') {
      this.schedule.days = null;
    } else {
      this.schedule.startDate = null;
      this.schedule.endDate = null;
    }
    this.scheduleService.updateSchedule(Schedule.toDto(schedule)).subscribe({
      next: (newSchedule) => {
        this.schedule = Schedule.clone(Schedule.fromDto(newSchedule));
        this.schedule.editable = true;
        this.isBusy = false;
        this.updateStatus();
      },
      error: () => {
        this.isBusy = false;
      }
    });
  }

  private updateStatus(): void {
    this.isCollapsed = true;
    if (this.isBusy) {
      this.status = 'Pending';
    } else {
      if (this.schedule.alwaysActive) {
        this.status = 'Always Active';
      } else if (this.schedule.active) {
        this.status = 'Active';
      } else if (this.schedule.enabled) {
        this.status = 'Inactive';
      } else {
        this.status = 'Paused';
      }
    }
  }

  toDateString(date: Date): string {
    if (!date) return '';
    return (
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      date.getDate().toString().padStart(2, '0')
    );
  }
}
