<div class='or-floorplan' #floorplan>
  <div
    data-cy="or-floorplan-container"
    class='or-floorplan-container is-active'
    cdkDropList
    [ngClass]='{"is-dragging": isDragging, "is-active": isActive, "is-mapping": isSNMappingMode}'
    (dblclick)="onFloorplanDoubleClick($event)"
    (mousedown)='onMouseDown($event)'
    (contextmenu)="onRightClick($event)"
    (mouseover)='onMouseOver($event)'
    (mouseleave)='onMouseLeave($event)'
    (mousemove)='onMouseMove($event)'
    (mouseup)='onMouseUp($event)'
    (click)='addDummyNode($event)'
    (touchend)='onTouchEnd($event)'
    (touchmove)='onTouchMove($event)'
    (touchstart)='onTouchStart($event)'
    (wheel)='onWheelScroll($event)'
    (cdkDropListDropped)="nodeDropped($event)">
    @if (nodes$ | async; as allNodes) {
      @if (isHeatmapEnabled && allNodes.length > 0) {
        <app-heatmap [buildingId]="buildingId" [nodes]='allNodes' [width]='floorPlanWidth' [height]='floorPlanHeight' [style]='style'></app-heatmap>
      }
    }
    @if (isActionsTrayEnabled && buildingId != null && floor != null) {
      <app-floorplan-actions class="or-floorplan-buttons"
                             (runTestsInBatch)="runTestsInBatch($event)"
                             (cancelTestsInBatch)="cancelTests()"
                             [buildingId]="buildingId"
                             (addNodeClicked)="handleAddNode($event)"
                             (undoAddClicked)="handleUndoAddNode()"
                             (saveAddedNodesClicked)="handleSaveAddedNodes()"
                             (discardAddClicked)="handleDiscardAddedNodes()"
                             (saveMoveClicked)="handleSaveMovedNodes()"
                             (undoMoveClicked)="handleUndoMovedNodes()"
                             (discardMoveClicked)="handleDiscardMovedNodes()"
                             (snMappingModeClicked)="handleSnMappingMode($event)"
                             (pnMappingModeClicked)="handlePnMappingMode($event)"
                             [floorId]="floor.id">
      </app-floorplan-actions>
    }
    <app-floorplan-zoom-control
      class='or-floorplan-buttons'
      (onZoomDecrease)="handleZoomDecrease()"
      (onZoomIncrease)="handleZoomIncrease()">
    </app-floorplan-zoom-control>
    @if (floorplanImageUrl != null) {
      <div class='or-floorplan-image-bg' [ngStyle]='style' #imageContainer>
        <img
          alt='Floorplan overview'
          class='or-floorplan-image reset-max-width'
          [ngClass]='{ "or-show-nodes": areNodesEnabled }'
          src='{{ floorplanImageUrl }}'
          (load)='onImageLoad()'
          #floorplanImage
        />
      </div>
    }
    @if (isSelecting) {
      <div class="or-floorplan-select-area-container" [ngStyle]="style">
        <div class="or-floorplan-select-area" [ngStyle]="selectionArea"></div>
      </div>
    }
    <div
      class="or-floorplan-nodes"
      [ngClass]='{ "or-floorplan-nodes-muted": false, "or-floorplan-nodes-zoom-transition": true }'
      [ngStyle]='style'
    >
      @if (areNodesEnabled) {
        <svg id="pub-sub-container"
             [attr.width]="pubSubContainer.width"
             [attr.height]="pubSubContainer.height"></svg>
        @if (showPendingNodes) {
          <app-sensor-node [details]="dummyNode" [x]="dummyNode.x" [y]="dummyNode.y"></app-sensor-node>
          @for (pendingNode of pendingNodes; track pendingNode.x) {
            <app-sensor-node [details]="pendingNode" [x]="pendingNode.x" [y]="pendingNode.y"></app-sensor-node>
          }
        }
        @if (nodes$ | async; as allNodes) {
          @for (node of allNodes; track node.id) {
            @if (arePubSubConnectionsEnabled) {
              <app-sensor-node
                [details]='node'
                [liveNodeData]='liveNodeData'
                [x]='node.x'
                (onNodeClick)="handleNodeClick($event)"
                [y]='node.y'
                pubSubConnections
                [node]='node'
                [nodes]='allNodes'>
              </app-sensor-node>
            } @else {
              <app-sensor-node
                [details]='node'
                [liveNodeData]='liveNodeData'
                [x]='node.x'
                (onNodeClick)="handleNodeClick($event)"
                [y]='node.y'>
              </app-sensor-node>
            }
          }
        }
        <!--project a component within the nodes-container, like a context menu-->
        <ng-content></ng-content>
      }
    </div>
  </div>
</div>
