import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { CommonModule, NgClass } from '@angular/common';
import { ScheduleService } from '@services/schedules.service';
import { Schedule } from '@app/shared/models/schedule';
import { Rule } from '@app/shared/models/rule';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ScheduleRuleComponent } from '@app/schedules/schedule-rule/schedule-rule.component';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { DayOfTheWeek } from '@app/shared/models/day-of-the-week';
import { TimeUtils } from '@app/shared/utils/time.utils';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { ConfirmDialogData } from '@components/dialogs/confirm/confirm.component';
import { NotificationBlockComponent, StatusClass } from '@components/notification-block/notification-block.component';
import { IScheduleWeekdayForm } from '@app/shared/models/schedule-form.interface';
import { Building } from '@app/shared/models/building.interface';

@Component({
  selector: 'app-form-weekday-schedule',
  standalone: true,
  imports: [
    FormsModule,
    MatButton,
    MatFormFieldModule,
    MatIcon,
    MatInput,
    MatSelectModule,
    MatTooltip,
    NgClass,
    NotificationBlockComponent,
    ReactiveFormsModule,
    ScheduleRuleComponent
  ],
  templateUrl: './form-weekday-schedule.component.html',
  styleUrl: './form-weekday-schedule.component.scss'
})
export class FormWeekdayScheduleComponent implements OnInit {
  @Input() schedule: Schedule = new Schedule();
  @Input() building: Building;
  @Output() onDeleteSchedule = new EventEmitter<number>();
  @Output() onSaveNewSchedule = new EventEmitter<number>();
  @Output() onClose = new EventEmitter<void>();
  @Output() onChange = new EventEmitter<Schedule>();
  isNew: boolean;
  saveLabel: string;
  form: FormGroup<IScheduleWeekdayForm>;
  readonly StatusClass = StatusClass;

  constructor(
    private scheduleService: ScheduleService,
    private fb: FormBuilder,
    private readonly dialog: ConfirmationDialogService
  ) {}

  ngOnInit(): void {
    if (!this.schedule?.id) {
      this.isNew = true;
    }
    this.saveLabel = 'Save';
    this.setForm();
  }

  setForm(): void {
    this.form = this.fb.group({
      name: [this.schedule.name || '', [Validators.required]],
      startTime: [
        { value: this.schedule?.startTime || '00:00', disabled: this.schedule?.alwaysActive },
        [Validators.required]
      ],
      endTime: [
        { value: this.schedule?.endTime || '00:00', disabled: this.schedule?.alwaysActive },
        [Validators.required]
      ],
      days: new FormArray([new FormControl(false)])
    });

    this.createDayFormArray();
  }

  createDayFormArray(): void {
    this.days.clear();
    this.daysOfTheWeek.forEach((dayOfTheWeek) => {
      const day = new FormControl(this.schedule?.days?.isActive(dayOfTheWeek.ordinal));
      this.days.push(day);
    });
  }

  setDays(index: number): void {
    const value = this.daysOfTheWeekControl[index].value;
    this.daysOfTheWeekControl[index].setValue(!value);
    this.schedule.days.toggleActive(index + 1);
  }

  get endTimeFormControl(): string {
    return this.form.get('endTime').getRawValue();
  }

  get startTimeFormControl(): string {
    return this.form.get('startTime').getRawValue();
  }

  get daysOfTheWeekControl(): FormControl[] {
    return this.days.controls as FormControl[];
  }

  get days(): FormArray {
    return this.form.get('days') as FormArray;
  }

  get daysOfTheWeek(): DayOfTheWeek[] {
    return DayOfTheWeek.all();
  }

  get times(): string[] {
    return TimeUtils.getTimesInDay(10);
  }

  close(): void {
    this.onClose.emit();
  }

  updateStartTime(): void {
    this.schedule.startTime = this.startTimeFormControl;
    this.onChange.emit(this.schedule);
  }

  updateEndTime(): void {
    this.schedule.endTime = this.endTimeFormControl;
    this.onChange.emit(this.schedule);
  }

  addRule(): void {
    this.schedule.rules?.push(new Rule());
  }

  removeRule(index: number): void {
    this.schedule.rules.splice(index, 1);
  }

  save(): void {
    if (this.form.invalid) {
      return;
    }

    this.saveLabel = 'Saving...';

    this.schedule.name = this.form.get('name').value;
    if (this.schedule.id != null) {
      this.scheduleService.updateSchedule(Schedule.toDto(this.schedule)).subscribe({
        next: (schedule) => {
          this.schedule = Schedule.clone(Schedule.fromDto(schedule));
          this.close();
          this.saveLabel = 'Save';
        }
      });
    } else {
      this.scheduleService.saveSchedule(Schedule.toDto(this.schedule), this.building.id).subscribe({
        next: (schedule) => {
          this.schedule = Schedule.clone(schedule);
          this.onSaveNewSchedule.emit();
          this.saveLabel = 'Save';
        }
      });
    }
  }

  delete(): void {
    const data = new ConfirmDialogData(`Delete schedule ${this.schedule.name}?`, 'Delete Schedule');
    this.dialog.open(data).subscribe({
      next: (result) => {
        if (result && this.schedule.id != null) {
          this.scheduleService.deleteSchedule(this.schedule).subscribe({
            next: () => {
              this.onDeleteSchedule.emit(this.schedule.id);
            }
          });
        } else {
          return;
        }
      }
    });
  }

  validateRules(): boolean {
    if (!this.schedule?.rules?.length) {
      return false;
    }

    return this.schedule.rules.every((rule) => {
      return rule.command != null && rule.value != null;
    });
  }

  validateActiveWeekDays(): boolean {
    return this.daysOfTheWeekControl.some((day) => day.getRawValue() === true);
  }

  validateWeekDayTimeSchedule(): boolean {
    return TimeUtils.isAfter(this.endTimeFormControl, this.startTimeFormControl);
  }
}
