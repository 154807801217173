import { NavigationService } from '@services/navigation/navigation.service';
import { allRoutes, MAIN_ROUTE } from '@common/route-constants';
import { NavigationSectionInfo } from '@services/navigation/navigation-section-info';
import { NavigationSection } from '@services/navigation/navigation-section';

export function setupNavigationSections(navigationService: NavigationService): void {
  navigationService.addRootSection(
    new NavigationSection(
      new NavigationSectionInfo(MAIN_ROUTE.id, MAIN_ROUTE.name, MAIN_ROUTE.path, MAIN_ROUTE.regex, MAIN_ROUTE.icon),
      MAIN_ROUTE.urlGenerator
    )
  );
  const sections = allRoutes.map((r) => {
    const sectionInfo = new NavigationSectionInfo(r.id, r.name, r.path, r.regex, r.icon);
    return new NavigationSection(sectionInfo, r.urlGenerator);
  });
  navigationService.addSectionsToSiteMap(sections);
}
