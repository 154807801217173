import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { ManagingCompany } from '@app/shared/models/building.interface';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-managing-company-dialog',
  templateUrl: './managing-company-dialog.component.html',
  styleUrls: ['./managing-company-dialog.component.scss'],
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatDialogActions,
    MatButton
  ]
})
export class ManagingCompanyDialogComponent implements OnInit {
  form: FormGroup;
  managingCompany: ManagingCompany;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) managingCompany: ManagingCompany,
    private dialogRef: MatDialogRef<ManagingCompanyDialogComponent>
  ) {
    this.managingCompany = managingCompany;

    this.form = this.fb.group({
      companyName: [managingCompany.companyName]
    });
  }

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close(this.form.value);
  }
}
