<div class="or-multiselect" [ngClass]="{ 'edit-mode': isEditMode }" name="multiselect.form">
  <ul
    class="or-inline-items or-muliselect-items or-multiselect-active-items"
    *ngIf="(selectedCount$ | async) + (unselectedCount$ | async) > 0"
  >
    <li
      class="or-inline-item or-multiselect-item or-multiselect-active-item"
      *ngFor="let option of selectedOptions$ | async"
    >
      <span class="or-multiselect-item-label">{{ label(option) }}</span>
      <button
        class="or-button or-iconic or-multiselect-remove-item"
        *ngIf="isEditMode"
        (click)="unselect(option)"
      >
        <span class="or-icon or-tiny or-icon-cross"></span>
      </button>
    </li>
    <li class="or-inline-item" [hidden]="!(isEditMode && (unselectedCount$ | async) > 0)">
      <form #innerForm="ngForm">
        <select [id]='id' name="state" ngModel (change)="change(innerForm.value?.state)">
          <option [selected]='true' disabled [value]='undefined'>--- Add another ---</option>
          <option *ngFor="let option of unselectedOptions$ | async" [ngValue]="option">
            {{ label(option) }}
          </option>
        </select>
      </form>
    </li>
  </ul>
</div>
