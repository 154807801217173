import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavigationService } from '@services/navigation/navigation.service';
import { setupNavigationSections } from '@app/navigation-setup';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet],
  templateUrl: './app.component.html'
})
export class AppComponent {
  private navService = inject(NavigationService);

  constructor() {
    setupNavigationSections(this.navService);
  }
}
