import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyNoticeComponent } from '@components/footer/privacy-notice/privacy-notice.component';
import { UserService } from '@services/user/user.service';
import { User } from '@app/shared/models/user.interface';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  imports: [RouterLink],
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear = new Date().getFullYear();

  constructor(private userService: UserService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.userService.getCurrentUser().subscribe((user: User) => {
      if (user.joinedAt == null) {
        this.showPrivacyPolicy(true);
      }
    });
  }

  showPrivacyPolicy(showActions: boolean): void {
    this.dialog.open(PrivacyNoticeComponent, { data: showActions, disableClose: true });
  }
}
