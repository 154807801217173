import { Component, OnInit } from '@angular/core';
import { UnmappedNodesListComponent } from '@components/unmapped-nodes-list/unmapped-nodes-list.component';
import { CdkDropListGroup } from '@angular/cdk/drag-drop';
import { HeaderService } from '@services/header.service';
import { UserService } from '@services/user/user.service';
import { ActivatedRoute } from '@angular/router';
import { delay, Subject, switchMap } from 'rxjs';
import { NavigationService } from '@services/navigation/navigation.service';
import { Building } from '@app/shared/models/building.interface';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { AbstractUnmappedNodesDataSource, NodePoint, UnmappedNode } from '@app/shared/models/unmapped-nodes-datasource';
import { SensorNodeService } from '@services/sensor-node.service';
import { MappingService } from '@services/mapping.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EnhancedSelectable, SelectableNode } from '@app/shared/models/sensor-node';
import { HotToastService } from '@ngneat/hot-toast';
import { CancelOnlyDialogData, ConfirmDialogData } from '@components/dialogs/confirm/confirm.component';
import { DISCRIMINATOR } from '@app/shared/models/selectable.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FloorplanComponent } from '@components/floorplan/floorplan.component';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-mapping-nodes',
  templateUrl: './map-nodes.component.html',
  styleUrls: ['./map-nodes.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      state('out', style({ transform: 'translateY(100%)', opacity: 0 })),
      transition('in => out', [animate('400ms ease-in-out')]),
      transition('out => in', [animate('400ms ease-in-out')])
    ])
  ],
  imports: [
    CdkDropListGroup,
    CommonModule,
    FloorplanComponent,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    UnmappedNodesListComponent
  ]
})
export class MapNodesComponent implements OnInit {
  filterForm: FormGroup;
  private floorId: number;
  private nodeClickedSource = new Subject<SelectableNode>();
  private readonly MIN_REQUEST_DELAY = 750;
  nodeClicked$ = this.nodeClickedSource.asObservable();
  building: Building;
  showNodeListRefreshBtn = 'in';
  constructor(
    private readonly dataSource: AbstractUnmappedNodesDataSource,
    private readonly dialogService: ConfirmationDialogService,
    private readonly header: HeaderService,
    private readonly mappingService: MappingService,
    private readonly navService: NavigationService,
    private readonly route: ActivatedRoute,
    private readonly snService: SensorNodeService,
    private readonly toastService: HotToastService,
    private readonly userService: UserService,
    private readonly fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.header.showBuildingsMenu();
    this.header.showSiteMenu();
    this.header.showUserMenu();
    this.header.showSessionMenu();
    this.header.showFloorsMenu();
    this.setBuildingId();
    this.filterForm = this.fb.group({
      id: '',
      address: '',
      nodeType: ''
    });
  }

  onScroll(deltaY: number): void {
    this.showNodeListRefreshBtn = deltaY > 0 ? 'out' : 'in';
  }

  private setBuildingId(): void {
    this.route.params
      .pipe(
        switchMap((params) => {
          this.floorId = params.floorId;
          return this.userService.getBuilding(params.buildingId);
        }),
        delay(this.MIN_REQUEST_DELAY)
      )
      .subscribe((building) => {
        this.navService.initNavigation(window.location.href, building);
        this.building = building;
      });
  }

  nodeDropped(target: NodePoint): void {
    const msgBoxData = new ConfirmDialogData(
      'Are you sure you want to add this node to the floor plan?',
      'Add node to floorplan'
    );
    this.dialogService.open(msgBoxData).subscribe((confirm) => {
      if (confirm) {
        this.mappingService
          .mapGenericNode(target, this.floorId)
          .pipe(
            delay(this.MIN_REQUEST_DELAY),
            this.toastService.observe({
              loading: {
                content: 'Mapping node on the floor, please wait',
                attributes: { 'data-cy': 'map-pending-toast' }
              },
              success: {
                content: `Node with the address ${target.address.toString(16)}, was successfully mapped`,
                attributes: { 'data-cy': 'map-success-toast' }
              },
              error: { content: 'Mapping failed! Please try again', attributes: { 'data-cy': 'map-error-toast' } }
            })
          )
          .subscribe({
            next: (_) => {
              this.snService.fetchNodes(this.floorId, this.building.id);
              this.filterForm.reset();
              this.dataSource.loadUnmappedNodes(this.building.id);
            },
            error: (err) => console.error(err)
          });
      }
    });
  }

  issueBlinkCommand(nodeToBlink: Pick<UnmappedNode, 'nodeId' | 'buildingId' | 'address'>): void {
    this.snService
      .blinkNode(nodeToBlink.nodeId, nodeToBlink.buildingId)
      .pipe(
        delay(this.MIN_REQUEST_DELAY),
        this.toastService.observe({
          loading: { content: 'Sending blink command to node', attributes: { 'data-cy': 'blink-pending-toast' } },
          success: {
            content: `Blink command was issued for node with the address ${nodeToBlink.address.toString(16)}`,
            attributes: { 'data-cy': 'blink-success-toast' }
          },
          error: { content: 'Blink command not sent! Please try again', attributes: { 'data-cy': 'blink-error-toast' } }
        })
      )
      .subscribe({
        error: (err) => console.error(err)
      });
  }

  refreshNodesOnFloorplan(): void {
    this.snService.fetchNodes(this.floorId, this.building.id);
  }

  handleNodeClick(node: SelectableNode): void {
    this.nodeClickedSource.next(node);
  }

  unmapNode(context: SelectableNode): void {
    const isGroupedPNHasAPublisherOnTheFloor = this.snService.currentFloorNodes.some(
      (node: EnhancedSelectable) =>
        context.isPassiveNode &&
        context.groupId != null &&
        node.discriminator === DISCRIMINATOR.SN3 &&
        node.groupId === context.groupId
    );
    isGroupedPNHasAPublisherOnTheFloor
      ? this.preventUnmappingGroupedPNPublisherOnTheFloor(context)
      : this.unmapGenericNode(context);
  }

  private preventUnmappingGroupedPNPublisherOnTheFloor(context: SelectableNode): void {
    const msgBoxData = new CancelOnlyDialogData(
      'You cannot unmap a grouped passive node when publisher is on the floor',
      'Not Allowed'
    );
    this.dialogService.open(msgBoxData).subscribe(() => {
      this.nodeClickedSource.next(null);
    });
  }

  private unmapGenericNode(context: SelectableNode): void {
    const msgBoxData = new ConfirmDialogData('Are you sure you want to unmap this node?', 'Unmap this node');
    this.dialogService.open(msgBoxData).subscribe((confirm) => {
      if (confirm) {
        this.mappingService
          .unmapGenericNode(context)
          .pipe(
            delay(this.MIN_REQUEST_DELAY),
            this.toastService.observe({
              loading: { content: 'Unmapping the node, please wait', attributes: { 'data-cy': 'unmap-pending-toast' } },
              success: {
                content: `Node with the address ${context.address.toString(16)} has been unmapped successfully`,
                attributes: { 'data-cy': 'unmap-success-toast' }
              },
              error: { content: 'Unmapping failed! Please try again', attributes: { 'data-cy': 'unmap-error-toast' } }
            })
          )
          .subscribe({
            next: (_) => {
              this.snService.fetchNodes(this.floorId, this.building.id);
              this.filterForm.reset();
              this.dataSource.loadUnmappedNodes(this.building.id);
            },
            error: (err) => console.error(err)
          });
      }
      this.nodeClickedSource.next(null);
    });
  }

  blinkNode(context: SelectableNode): void {
    const payload = {
      nodeId: context.id,
      buildingId: this.building.id,
      address: context.address
    };
    this.issueBlinkCommand(payload);
    this.nodeClickedSource.next(null);
  }

  handleFloorClick(_: {}): void {
    this.nodeClickedSource.next(null);
  }

  isConnected(clickedNode: SelectableNode): boolean {
    return clickedNode && clickedNode.connected;
  }
}
