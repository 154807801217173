import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ManagingCompanyResource } from '../resources/managing-company.resource';
import { ManagingCompany } from '@app/shared/models/building.interface';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManagingCompanyService {
  constructor(private managingCompanyResource: ManagingCompanyResource) {}

  public getManagingCompanies(): Observable<ManagingCompany[]> {
    return this.managingCompanyResource.getAll().pipe(shareReplay());
  }

  public saveManagingCompany(managingCompany: ManagingCompany): Observable<ManagingCompany> {
    return this.managingCompanyResource.saveManagingCompany(managingCompany);
  }

  public deleteManagingCompany(id: number): Observable<{}> {
    return this.managingCompanyResource.delete(id);
  }
}
