import { IMultiUserInvitation, IUserInvitation } from '@app/shared/models/user-invitation.interface';

export class MultiUserInvitation implements IMultiUserInvitation {
  buildingId: number;
  invitations: IUserInvitation[];

  constructor(currentBuildingId: number, invitations: IUserInvitation[]) {
    this.buildingId = currentBuildingId;
    this.invitations = invitations;
  }
}
