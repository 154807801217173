import { Injectable } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { SensorNode } from '@app/shared/models/sensor-node';
import { NavigationService } from '@services/navigation/navigation.service';
import { BEACONS_ROUTE, LIGHTING_CONFIGURATION_ROUTE, SENSORNODES_ROUTE } from '@common/route-constants';
import { LightingConfigurationService } from '@services/lighting-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class CopyToClipboardService {
  constructor(
    private clipboard: Clipboard,
    private navService: NavigationService,
    private lightingConfigurationService: LightingConfigurationService
  ) {}

  copy(nodes: Partial<SensorNode>[]): void {
    this.clipboard.copy(this.getNodesAsCsv(nodes));
  }

  copyString(toCopy: string): void {
    this.clipboard.copy(toCopy);
  }

  private getNodesAsCsv(nodes: Partial<SensorNode>[]): string {
    const activePageId = this.navService.getActiveSection().info.Id;
    const headerData = this.getHeaderDataBasedOnPageId(activePageId);
    const nodesData = this.getNodesDataBasedOnPageId(activePageId, nodes);
    return headerData + nodesData;
  }

  private getHeaderDataBasedOnPageId(activePageId: string): string {
    switch (activePageId) {
      case BEACONS_ROUTE.id:
        return 'Id,Address(Portal),Enabled,Power Level,Beacon Content,Beaconing Repetition,UUID,Major,Minor,Update Status\n';
      case SENSORNODES_ROUTE.id:
        return 'Id,Address(Portal),Node Type,Connection Status,Luminaire Drivers,EM Drivers,Driver Faults,Tags\n';
      case LIGHTING_CONFIGURATION_ROUTE.id:
        return 'Id,Address(Portal),Node Type, Zone\n';
    }
  }

  private getNodesDataBasedOnPageId(activePageId: string, nodes: Partial<SensorNode>[]): string {
    console.log('trying something', nodes[0]['beaconInterval.value']);
    switch (activePageId) {
      case BEACONS_ROUTE.id:
        return nodes
          .map((node) => {
            return (
              node.id +
              ',' +
              node.address16 +
              ',' +
              (node.beaconEnabled ? node.beaconEnabled : 'false') +
              ',' +
              node.beaconPowerLevel +
              ',' +
              node.beaconContent.value +
              ',' +
              node.beaconInterval.value +
              ',' +
              node.beaconUUID.value +
              ',' +
              node.beaconMajor +
              ',' +
              node.beaconMinor +
              ',' +
              node.beaconUpdateStatus
            );
          })
          .join('\n');
      case SENSORNODES_ROUTE.id:
        return nodes
          .map((node) => {
            const nodeType = node.nodeType != null ? node.nodeType : 'SN3';
            const tags = node.tags ? node.tags.map((tag) => tag.name).join(';') : '';
            const lumeDrivers = node.luminaireDrivers ? node.luminaireDrivers.map((l) => l.address16).join(';') : '';
            const emDrivers = node.emDrivers ? node.emDrivers.map((e) => e.address16).join(';') : '';
            const nodeAlerts = node.alerts ? node.alerts.map((a) => a.value).join(';') : '';
            return (
              node.id +
              ',' +
              node.address16 +
              ',' +
              nodeType +
              ',' +
              node.connected +
              ',' +
              lumeDrivers +
              ',' +
              emDrivers +
              ',' +
              nodeAlerts +
              ',' +
              tags
            );
          })
          .join('\n');
      case LIGHTING_CONFIGURATION_ROUTE.id:
        return nodes
          .map((node) => {
            return `${node.id},${node.address16},${
              node.nodeType
            },${this.lightingConfigurationService.convertZoneIdToName(node?.lightingConfiguration?.zone)}`;
          })
          .join('\n');
    }
  }
}
