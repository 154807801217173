import { Component, Input } from '@angular/core';
import { RefreshTimerComponent } from '../refresh-timer/refresh-timer.component';
import { CommonModule } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { RefreshPageService } from '@app/shared/services/refresh-page/refresh-page.service';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-refresh-page',
  imports: [CommonModule, MatTooltip, MatButtonModule, RefreshTimerComponent],
  templateUrl: './refresh-page.component.html',
  styleUrl: './refresh-page.component.scss'
})
export class RefreshPageComponent {
  @Input() showAutoRefresh: boolean;

  autoRefresh = false;
  refresh = false;
  constructor(private refreshPageService: RefreshPageService) {}

  refreshPage(): void {
    this.refresh = true;
    this.refreshPageService.onRefresh();
    setTimeout(() => (this.refresh = false), 200);
  }

  setAutoRefresh(): void {
    if (!this.autoRefresh) {
      this.autoRefresh = true;
    } else {
      this.autoRefresh = false;
      this.refreshPageService.onAutoRefreshComplete();
    }
  }
}
