import { Tab } from '@components/tabs/tab.interface';
import { SecurityUtils } from '@app/shared/utils/security.utils';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';

export interface BacnetArea {
  id?: number;
  name: string;
  description: string;
  gatewayId: number;
  localId?: number;
}

export interface GatewayOption {
  id: number;
  label: string;
}

export const IntegrationsTabs: Tab[] = [
  {
    name: 'Areas',
    path: '/buildings/{buildingId}/{floorId}/integrations/areas',
    dataCy: 'integrations-area',
    isAuthorized: SecurityUtils.hasBuildingAuthority(BuildingAuthorityType.MANAGE_BACNET_AREAS)
  }
];
