import { Component, Input } from '@angular/core';
import { OtapUpgradeTaskDTO } from '@app/shared/models/otap-upgrade-task.interface';
import { OtapUpgradeDTO } from '@app/shared/models/otap.upgrade.interface';
import { NgFor } from '@angular/common';
import { JobTasksSummaryComponent } from '../job-tasks-summary/job-tasks-summary.component';

@Component({
  selector: 'app-job-tasks-list',
  templateUrl: './job-tasks-list.component.html',
  styleUrls: ['./job-tasks-list.component.scss'],
  imports: [NgFor, JobTasksSummaryComponent]
})
export class JobTasksListComponent {
  @Input() tasks: OtapUpgradeTaskDTO[];
  @Input() job: OtapUpgradeDTO;
  @Input() jobStartEnabled: boolean;
  constructor() {}
}
