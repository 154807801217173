import { Component, Input, OnInit } from '@angular/core';
import { User } from '@app/shared/models/user.interface';
import { Observable } from 'rxjs';
import { Building } from '@app/shared/models/building.interface';
import { UserService } from '@app/shared/services/user/user.service';
import { AsyncPipe } from '@angular/common';
import { FloorsMenuComponent } from '../floors-menu/floors-menu.component';
import { BuildingsMenuComponent } from '../buildings-menu/buildings-menu.component';
import { SessionMenuComponent } from '../session-menu/session-menu.component';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  imports: [FloorsMenuComponent, BuildingsMenuComponent, SessionMenuComponent, AsyncPipe]
})
export class UserMenuComponent implements OnInit {
  @Input() buildingsMenuActive$: Observable<boolean>;
  @Input() floorsMenuActive$: Observable<boolean>;
  @Input() sessionMenuActive$: Observable<boolean>;
  @Input() user$: Observable<User>;

  public buildings$: Observable<Building[]>;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.buildings$ = this.userService.getBuildings();
  }
}
