<div class='w-full'>
  @if (isBusy) {
    <mat-spinner class='absolute top-1/2 left-1/2 z-[1000]'></mat-spinner>
  }
  <app-panel [title]="'Zone'" [icon]="'layers'" [disabled]='isZoneDisabled || !isReady' [tooltip]="tooltip('zone')">
    <div panel-content>
      <mat-form-field class='w-full skinny-form-4'>
        <mat-select data-cy="" [(ngModel)]="zone" [disabled]='isZoneDisabled || !isReady'>
          <mat-option [value]='-1' [disabled]='true'>Please select a zone</mat-option>
          @for (item of lightingConfigService.zoneValueArray; track $index) {
            <mat-option  [value]="item.id"> {{item.value}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div footer-content>
      <app-panel-footer [disableReset]="isBusy || isZoneDisabled || !isReady" [disableSubmit]='isZoneDisabled || !isReady' (resetHandler)="resetZone()" (updateHandler)="setZone()" dataCy='zone'></app-panel-footer>
    </div>
  </app-panel>
  <app-panel [title]="'Max Light'" [icon]="'light_mode'" [disabled]='!isReady' [tooltip]='tooltip()'>
    <div panel-content>
      <div class='mr-1'>
        <div class='flex flex-row justify-between'>
          <mat-chip data-cy="lighting-config-max-light-chip" [disabled]='!isReady'>{{maxLight}}</mat-chip>
          @if (indexedMaxLight === 0) {
            <mat-chip class='mx-1' data-cy="lighting-config-max-light-factory-default-chip">Factory Default</mat-chip>
          }
        </div>
        <div class='slider-div'>
          <mat-slider class='config-slider' min='1' max='31' step='1' [disabled]='!isReady'>
            <input matSliderThumb [(ngModel)]='indexedMaxLight'>
          </mat-slider>
        </div>
      </div>
    </div>
    <div footer-content>
      <app-panel-footer [disableReset]="isBusy || !isReady" [disableSubmit]='!isReady' (resetHandler)="resetMaxLight()" (updateHandler)="setMaxLight()" dataCy='max-light-level'></app-panel-footer>
    </div>
  </app-panel>
  <app-panel [title]="'Low Light'" [icon]="'wb_twilight'" [disabled]='!isReady' [tooltip]='tooltip()'>
    <div panel-content>
      <div>
        <div class='flex flex-row justify-between'>
          <mat-chip data-cy="lighting-config-low-light-chip">{{lowLight}}</mat-chip>
          @if (indexedLowLight === 0) {
            <mat-chip class='mx-1' data-cy="lighting-config-low-light-factory-default-chip">Factory Default</mat-chip>
          }
        </div>
        <div class='slider-div'>
          <mat-slider class='config-slider' min='1' max='31' step='1' [disabled]='!isReady'>
            <input matSliderThumb [(ngModel)]='indexedLowLight'>
          </mat-slider>
        </div>
      </div>
    </div>
    <div footer-content>
      <app-panel-footer [disableReset]="isBusy || !isReady" [disableSubmit]='!isReady' (resetHandler)="resetLowLight()" (updateHandler)="setLowLight()" dataCy='low-light-level'></app-panel-footer>
    </div>
  </app-panel>
  <app-panel [title]="'Min Light'" [icon]="'emoji_objects'" [disabled]='!isReady' [tooltip]='tooltip()'>
    <div panel-content>
      <div>
        <div class='flex flex-row justify-between'>
          <mat-chip data-cy="lighting-config-min-light-chip">{{minLight}}</mat-chip>
          @if (indexedMinLight === 0) {
            <mat-chip class='mx-1' data-cy="lighting-config-min-light-factory-default-chip">Factory Default</mat-chip>
          }
        </div>
        <div class='slider-div'>
          <mat-slider class='config-slider' [min]='1' max='31' step='1' [disabled]='!isReady'>
            <input matSliderThumb [(ngModel)]='indexedMinLight'>
          </mat-slider>
        </div>
      </div>
    </div>
    <div footer-content>
      <app-panel-footer [disableReset]="isBusy || !isReady" [disableSubmit]='!isReady' (resetHandler)="resetMinLight()" (updateHandler)="setMinLight()" dataCy='low-light-level'></app-panel-footer>
    </div>
  </app-panel>
  <app-panel [title]="'Dwell Time'" [icon]="'history_toggle_off'" [disabled]='!isReady' [tooltip]='tooltip()'>
    <div panel-content>
      <mat-form-field class='w-full skinny-form-4'>
        <mat-select data-cy="set-beacon-content" [(ngModel)]="dwellTime" [disabled]='!isReady'>
          <mat-option [value]='-1' [disabled]='true'>Please select a dwell time</mat-option>
          @for (item of lightingConfigService.dwellTimeValueArray; track $index) {
            <mat-option  [value]="item.id"> {{item.value}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div footer-content>
      <app-panel-footer [disableReset]="isBusy || !isReady" [disableSubmit]='!isReady' (resetHandler)="resetDwellTime()" (updateHandler)="setDwellTime()" dataCy='dwell-time'></app-panel-footer>
    </div>
  </app-panel>
  <app-panel [title]="'Low Light Time'" [icon]="'alarm'" [disabled]='!isReady' [tooltip]='tooltip()'>
    <div panel-content>
      <mat-form-field class='w-full skinny-form-4'>
        <mat-select data-cy="set-beacon-content" [(ngModel)]="lowLightTime" [disabled]='!isReady'>
          <mat-option [value]='-1' [disabled]='true'>Please select a low light time</mat-option>
          @for (item of lightingConfigService.dwellTimeValueArray; track item.id) {
            <mat-option  [value]="item.id"> {{item.value}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div footer-content>
      <app-panel-footer [disableReset]="isBusy || !isReady" [disableSubmit]='!isReady' (resetHandler)="resetLowLightTime()" (updateHandler)="setLowLightTime()" dataCy='low-light-time'></app-panel-footer>
    </div>
  </app-panel>
  <app-panel [title]="'Scene'" [icon]="'splitscreen_landscape'" [disabled]='!isReady' [tooltip]='tooltip()'>
    <div panel-content>
      <mat-form-field class='w-full skinny-form-4'>
        <mat-select data-cy="set-beacon-content" [(ngModel)]="scene" [disabled]='!isReady'>
          <mat-option [value]='-1' [disabled]='true'>Please select a scene</mat-option>
          @for (item of lightingConfigService.sceneValueArray; track item.id) {
            <mat-option  [value]="item.id"> {{item.value}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div footer-content>
      <app-panel-footer [disableReset]="isBusy || !isReady" [disableSubmit]='!isReady' (resetHandler)="resetScene()" (updateHandler)="setScene()" dataCy='scene'></app-panel-footer>
    </div>
  </app-panel>
</div>
