import { Injectable } from '@angular/core';
import { LightingConfigurationResource } from '@app/shared/resources/lighting-configuration.resource';
import { Observable } from 'rxjs';
import { SensorNode } from '@app/shared/models/sensor-node';
import { LightingConfiguration } from '@app/shared/models/lighting-configuration';
import { map } from 'rxjs/operators';
import { Selectable } from '@app/shared/models/selectable.interface';

@Injectable({
  providedIn: 'root'
})
export class LightingConfigurationService {
  public dwellTimes: Record<number, string>;
  public lightLevels: Record<number, string>;
  public scenes: Record<number, string>;
  public zones: Record<number, string>;

  public readonly DEFAULT_MIN_LIGHT = 1;
  public readonly DEFAULT_LOW_LIGHT = 11;
  public readonly DEFAULT_MAX_LIGHT = 31;

  constructor(private readonly lightingConfigurationResource: LightingConfigurationResource) {
    this.dwellTimes = {};
    this.dwellTimes[4] = '10 Seconds';
    this.dwellTimes[7] = '30 Seconds';
    this.dwellTimes[9] = '1 Minute';
    this.dwellTimes[11] = '2 Minutes';
    this.dwellTimes[12] = '3 Minutes';
    this.dwellTimes[13] = '5 Minutes';
    this.dwellTimes[14] = '7 Minutes';
    this.dwellTimes[15] = '10 Minutes';
    this.dwellTimes[16] = '15 Minutes';
    this.dwellTimes[17] = '20 Minutes';
    this.dwellTimes[18] = '25 Minutes';
    this.dwellTimes[19] = '30 Minutes';
    this.dwellTimes[20] = '45 Minutes';
    this.dwellTimes[21] = '1 Hour';
    this.dwellTimes[22] = '1.5 Hours';
    this.dwellTimes[23] = '2 Hours';
    this.dwellTimes[24] = '3 Hours';
    this.dwellTimes[25] = '4 Hours';
    this.dwellTimes[26] = '6 Hours';
    this.dwellTimes[27] = '8 Hours';
    this.dwellTimes[28] = '12 Hours';
    this.dwellTimes[29] = '1 Day';
    this.dwellTimes[30] = '1 Week';
    this.dwellTimes[31] = '1 Year';

    this.lightLevels = {};
    this.lightLevels[1] = '0%';
    this.lightLevels[2] = '1%';
    this.lightLevels[3] = '2%';
    this.lightLevels[4] = '3%';
    this.lightLevels[5] = '4%';
    this.lightLevels[6] = '5%';
    this.lightLevels[7] = '6%';
    this.lightLevels[8] = '7%';
    this.lightLevels[9] = '8%';
    this.lightLevels[10] = '9%';
    this.lightLevels[11] = '10%';
    this.lightLevels[12] = '11%';
    this.lightLevels[13] = '12%';
    this.lightLevels[14] = '14%';
    this.lightLevels[15] = '16%';
    this.lightLevels[16] = '18%';
    this.lightLevels[17] = '20%';
    this.lightLevels[18] = '22%';
    this.lightLevels[19] = '25%';
    this.lightLevels[20] = '28%';
    this.lightLevels[21] = '32%';
    this.lightLevels[22] = '36%';
    this.lightLevels[23] = '40%';
    this.lightLevels[24] = '45%';
    this.lightLevels[25] = '50%';
    this.lightLevels[26] = '55%';
    this.lightLevels[27] = '60%';
    this.lightLevels[28] = '70%';
    this.lightLevels[29] = '80%';
    this.lightLevels[30] = '90%';
    this.lightLevels[31] = '100%';

    this.scenes = {};
    this.scenes[0] = '0';
    this.scenes[1] = '1';
    this.scenes[2] = '2';
    this.scenes[3] = '3';
    this.scenes[4] = '4';
    this.scenes[5] = '5';
    this.scenes[6] = '6';
    this.scenes[7] = 'Off';
    this.scenes[8] = 'No scene (On/Auto)';

    this.zones = {};
    this.zones[0] = '1';
    this.zones[1] = '2';
    this.zones[2] = '3';
    this.zones[3] = '4';
    this.zones[4] = '5';
    this.zones[5] = '6';
    this.zones[6] = '7';
    this.zones[7] = '8';
    this.zones[8] = '9';
    this.zones[9] = '10';
    this.zones[10] = '11';
    this.zones[11] = '12';
    this.zones[15] = 'X';
  }

  convertTimeIdToName(id: number): string {
    return id || id === 0 ? this.dwellTimes[id] : 'N/A';
  }

  convertLightIdToName(id: number): string {
    return id || id === 0 ? this.lightLevels[id] : 'N/A';
  }

  convertSceneIdToName(id: number): string {
    return id || id === 0 ? this.scenes[id] : 'N/A';
  }

  convertZoneIdToName(id: number): string {
    return id || id === 0 ? this.zones[id] : 'N/A';
  }

  convertMaxLightIdToName(id: number): string {
    return id !== 0 ? this.convertLightIdToName(id) : this.convertLightIdToName(this.DEFAULT_MAX_LIGHT);
  }

  public convertLowLightIdToName(id: number): string {
    return id !== 0 ? this.convertLightIdToName(id) : this.convertLightIdToName(this.DEFAULT_LOW_LIGHT);
  }

  public convertMinLightIdToName(id: number): string {
    return id !== 0 ? this.convertLightIdToName(id) : this.convertLightIdToName(this.DEFAULT_MIN_LIGHT);
  }

  get zoneValueArray(): { id: number; value: string }[] {
    return Object.keys(this.zones).map((id: string) => {
      return { id: Number(id), value: this.zones[id] };
    });
  }

  get dwellTimeValueArray(): { id: number; value: string }[] {
    return Object.keys(this.dwellTimes).map((id: string) => {
      return { id: Number(id), value: this.dwellTimes[id] };
    });
  }

  get sceneValueArray(): { id: number; value: string }[] {
    return Object.keys(this.scenes).map((id: string) => {
      return { id: Number(id), value: this.scenes[id] };
    });
  }

  getNode(sensorNode: SensorNode | Selectable, buildingId: number): Observable<LightingConfiguration> {
    return this.lightingConfigurationResource.getNode(sensorNode.id, buildingId);
  }

  queryNode(sensorNode: SensorNode | Selectable, buildingId: number): Observable<void> {
    return this.lightingConfigurationResource.queryNode(sensorNode.id, buildingId);
  }

  updateZone(sensorNode: SensorNode, zone: number, buildingId: number): Observable<void> {
    return this.lightingConfigurationResource.updateConfig('zone', { nodeId: sensorNode.id, value: zone, buildingId });
  }

  updateMaxLight(sensorNode: SensorNode, maxLight: number, buildingId: number): Observable<void> {
    return this.lightingConfigurationResource.updateConfig('max_light', {
      nodeId: sensorNode.id,
      value: maxLight,
      buildingId
    });
  }

  updateLowLight(sensorNode: SensorNode, lowLight: number, buildingId: number): Observable<void> {
    return this.lightingConfigurationResource.updateConfig('low_light', {
      nodeId: sensorNode.id,
      value: lowLight,
      buildingId
    });
  }

  updateMinLight(sensorNode: SensorNode, minLight: number, buildingId: number): Observable<void> {
    return this.lightingConfigurationResource.updateConfig('min_light', {
      nodeId: sensorNode.id,
      value: minLight,
      buildingId
    });
  }

  updateDwellTime(sensorNode: SensorNode, dwellTime: number, buildingId: number): Observable<void> {
    return this.lightingConfigurationResource.updateConfig('dwell_time', {
      nodeId: sensorNode.id,
      value: dwellTime,
      buildingId
    });
  }

  updateLowLightTime(sensorNode: SensorNode, lowLightTime: number, buildingId: number): Observable<void> {
    return this.lightingConfigurationResource.updateConfig('low_light_time', {
      nodeId: sensorNode.id,
      value: lowLightTime,
      buildingId
    });
  }

  updateScene(sensorNode: SensorNode, scene: number, buildingId: number): Observable<void> {
    return this.lightingConfigurationResource.updateConfig('scene', {
      nodeId: sensorNode.id,
      value: scene,
      buildingId
    });
  }

  getLightingConfigsForFloor(floorId: number): Observable<LightingConfiguration[]> {
    return this.lightingConfigurationResource
      .getLightingConfigsForFloor(floorId)
      .pipe(map((configs) => configs.map(LightingConfiguration.fromResponse)));
  }
}
