export class NumericUtils {
  public static roundFloatingPoint(value: number, decimalPoints: number): number {
    return parseFloat(value.toFixed(decimalPoints));
  }

  public static random(min = 0, max = 1, generateFraction: () => number = () => Math.random()): number {
    return min + generateFraction() * (max - min);
  }

  static fixDigitsInValue(value: string | number): string {
    return String(value)
      .split(' ')
      .map((item) => {
        const matched = item.match(/\d+\.\d+/g);
        if (matched) {
          const num = Number(matched[0]);
          if (num >= 1 && num < 10) {
            return num.toFixed(1) === '10.0' ? '10' : num.toFixed(1);
          } else if (num >= 10) {
            return Math.round(num).toString();
          } else {
            return num.toFixed(2) === '1.00' ? '1.0' : num.toFixed(2);
          }
        }
        return item;
      })
      .join(' ');
  }
}
