import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AbstractNodeDataSource } from '@app/shared/models/node-data-source';
import { SensorNodeService } from '@services/sensor-node.service';
import { NodeFilter, NodesFilterComponent } from '@components/node-list-card/nodes-filter/nodes-filter.component';
import { ColorUtils } from '@app/shared/utils/color.utils';
import { SensorNode } from '@app/shared/models/sensor-node';
import { CommonModule } from '@angular/common';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { MatSortModule } from '@angular/material/sort';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NavigationService } from '@services/navigation/navigation.service';

interface SelectableNode extends SensorNode {
  selected: boolean;
  expanded: boolean;
}

@Component({
  selector: 'app-node-list-card',
  imports: [CommonModule, SharedComponentsModule, MatSortModule, NodesFilterComponent, AuthorizationModule],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
  templateUrl: './node-list-card.component.html',
  styleUrl: './node-list-card.component.scss'
})
export class NodeListCardComponent implements OnInit, OnDestroy {
  @Input() buildingId: number;
  @Input() floorId: number;
  @Input() isDriverDetailsActive = true;
  @Input() sortByFields: Record<string, string> = {};

  subTableColumns: string[] = ['type', 'id', 'address', 'burnHours', 'energyConsumption'];
  expandedElement: SelectableNode | Partial<SensorNode>;
  showHidden = true;
  nodes: any[] = [];
  filterForm: FormGroup;
  mouseOverColumn: string;
  filter: Record<string, string> = {};

  @ViewChildren('matCardRows', { read: ElementRef }) matCardRows!: QueryList<ElementRef<HTMLElement>>;

  constructor(
    protected fb: FormBuilder,
    protected dataSource: AbstractNodeDataSource,
    protected nodeService: SensorNodeService,
    protected navService: NavigationService
  ) {}

  ngOnInit(): void {
    this.filterForm = this.fb.group({
      id: '',
      address: '',
      sortBy: Object.keys(this.sortByFields)[0],
      sortAsc: true,
      nodeType: ''
    });
    this.dataSource.loadSensorNodes();
    this.enableScrollTo();
  }

  ngOnDestroy(): void {
    this.dataSource.collapseAll();
  }

  getPropValForLuminaire(nodeInfo: SelectableNode | Partial<SensorNode>, property: string): string {
    if (Array.isArray(nodeInfo.luminaireDrivers) && nodeInfo.luminaireDrivers.length > 0) {
      if (nodeInfo.luminaireDrivers[0][property] == null) {
        return 'N/A';
      }
      return nodeInfo.luminaireDrivers[0][property].toFixed(0).toString();
    }
    return 'N/A';
  }

  copyToClipboard(): void {
    this.dataSource.copyNodes();
  }

  toggleRow(row: SelectableNode | Partial<SensorNode>): void {
    this.expandedElement = this.expandedElement === row ? null : row;
    this.dataSource.toggleExpand(row);
    this.nodeService.toggleEntitySelection(row as SelectableNode);
  }

  getNodeStatusIconClass(node: SelectableNode | Partial<SensorNode>): string {
    const styleClass = 'or-icon or-small';
    switch (node.nodeType) {
      case SensorNode.SN3_NODE_TYPE:
        return styleClass + ' or-icon-node';
      case SensorNode.HIM84_NODE_TYPE:
        return styleClass + ' or-icon-him84';
      case SensorNode.HCD405_NODE_TYPE:
        return styleClass + ' or-icon-hcd405';
      case SensorNode.HCD038_NODE_TYPE:
        return styleClass + ' or-icon-hcd038';
      case SensorNode.PASSIVE_NODE_TYPE:
        return styleClass + ' or-icon-passive-node';
      case SensorNode.FUJITSU_ENV_C02:
      case SensorNode.FUJITSU_ENV:
        return styleClass + ' or-icon-environmental-node';
      default:
        return styleClass + ' or-icon-node';
    }
  }

  getNodeStatusAndTypeClass(node: SelectableNode | Partial<SensorNode>): string {
    const styleClass = 'or-node-tile-status-body or-node-tile-status-body-' + (node.status || 'unknown');
    switch (node.nodeType) {
      case SensorNode.SN3_NODE_TYPE:
        return styleClass;
      case SensorNode.HIM84_NODE_TYPE:
        return styleClass + ' him84 p-2';
      case SensorNode.HCD405_NODE_TYPE:
        return styleClass + ' hcd405 p-2';
      case SensorNode.HCD038_NODE_TYPE:
        return styleClass + ' hcd038 p-2';
      case SensorNode.PASSIVE_NODE_TYPE:
        return styleClass + ' passive-node';
      case SensorNode.FUJITSU_ENV_C02:
      case SensorNode.FUJITSU_ENV:
        return styleClass + ' environmental-node';
      default:
        return styleClass;
    }
  }

  onFilterChanged(nodeFilter: NodeFilter): void {
    this.dataSource.loadSensorNodes(nodeFilter.filterCriteria, nodeFilter.sortCriteria);
  }

  refreshNodeList(): void {
    this.dataSource.collapseAll();
    this.dataSource.fetchSensorNodes(this.floorId, this.buildingId);
  }

  public getForegroundColor(bgColor: string): any {
    return {
      'background-color': '#' + bgColor,
      color: ColorUtils.getConstrastingGrey(bgColor)
    };
  }

  getProperValue(column: string, input: any): any {
    return input == null ? 'N/A' : column === 'energyConsumption' ? input.toFixed(0).toString() + 'KWh' : input;
  }

  getDataCy(column: string, input: any): string {
    if (column === 'id') {
      return 'subnode-id-' + input;
    } else if (column === 'address') {
      return 'subnode-address-' + input;
    }
  }

  enableScrollTo(): void {
    this.nodeService.getCurrentFloorSelectedEntities$().subscribe((sNodes) => {
      if (sNodes && sNodes.length > 0) {
        const selectedElement = this.matCardRows.find((r) => r.nativeElement.id === sNodes[0].id.toString());
        if (selectedElement) {
          selectedElement.nativeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }
    });
  }

  bleScanningClass(node: SelectableNode | Partial<SensorNode>): string {
    const styleClass = 'or-node-tile-value or-tiny ';
    if (node.bleScanning === 'ON' || node.nodeType === 'PN') {
      return styleClass + 'or-icon-ble-status-on';
    } else if (node.bleScanning == null) {
      return styleClass + 'or-icon-ble-status-unknown';
    } else {
      return styleClass + 'or-icon-ble-status-off';
    }
  }

  isBeaconsPage(): boolean {
    return this.navService.getActiveSection().info.Id === 'beacons';
  }

  isSensorNodePage(): boolean {
    return this.navService.getActiveSection().info.Id === 'sensor-nodes';
  }

  isLightingConfigPage(): boolean {
    return this.navService.getActiveSection().info.Id === 'lighting-configuration';
  }
}
