import { HeatmapDataPoint, HeatmapRenderer } from '@components/heatmap/renderer/heatmap-renderer';

export class ShapesRenderer implements HeatmapRenderer {
  private pointTemplates = {};

  constructor(private shape: Shape) {}

  render(
    points: HeatmapDataPoint[],
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    max: number,
    width: number,
    height: number
  ): void {
    for (const point of points) {
      const boundedValue = Math.max(0, Math.min(max, point.value));
      if (boundedValue) {
        const alpha = boundedValue / max;
        const radius = point.radius;

        if (!this.pointTemplates[radius]) {
          this.pointTemplates[radius] = this.shape.draw(radius);
        }

        ctx.globalAlpha = alpha;
        ctx.drawImage(this.pointTemplates[radius], point.x - radius / 2, point.y - radius / 2);
      }
    }
  }
}

export interface Shape {
  draw(radius: number): HTMLCanvasElement;
}

export class Circle implements Shape {
  draw(radius: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const x = radius;
    const y = radius;
    canvas.width = canvas.height = radius * 2;

    context.beginPath();
    context.arc(x, y, radius, 0, 2 * Math.PI, false);
    context.fillStyle = 'rgba(0,0,0,1)';
    context.fill();

    return canvas;
  }
}

export class Hexagon implements Shape {
  draw(radius: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.fillStyle = 'rgba(0,0,0,1)';

    context.beginPath();
    context.moveTo(radius, 0);
    const halfEdgeLength = radius / 2;
    context.lineTo(radius * 2, radius - halfEdgeLength);
    context.lineTo(radius * 2, radius + halfEdgeLength);
    context.lineTo(radius, radius * 2);
    context.lineTo(0, radius + halfEdgeLength);
    context.lineTo(0, radius - halfEdgeLength);
    context.lineTo(radius, 0);
    context.closePath();
    context.fill();

    return canvas;
  }
}

export class Rectangle implements Shape {
  draw(radius: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.fillStyle = 'rgba(0,0,0,1)';

    context.beginPath();
    context.moveTo(0, 0);
    context.lineTo(radius * 2, 0);
    context.lineTo(radius * 2, radius * 2);
    context.lineTo(radius, radius * 2);
    context.lineTo(0, radius * 2);
    context.lineTo(0, 0);
    context.closePath();
    context.fill();

    return canvas;
  }
}
