import { RepeatUnitString } from '@app/shared/models/schedule-recurrence/repeat-unit-string';

export class RepeatUnit {
  constructor(public value: RepeatUnit_) {}

  public static NONE = new RepeatUnit('NONE');
  public static DAILY = new RepeatUnit('DAILY');
  public static WEEKLY = new RepeatUnit('WEEKLY');
  public static MONTHLY_CALENDAR_DATE = new RepeatUnit('MONTHLY_CALENDAR_DATE');
  public static MONTHLY_WEEK_DATE = new RepeatUnit('MONTHLY_WEEK_DATE');
  public static YEARLY = new RepeatUnit('YEARLY');

  public static getAll(): RepeatUnit[] {
    return [
      RepeatUnit.NONE,
      RepeatUnit.DAILY,
      RepeatUnit.WEEKLY,
      RepeatUnit.MONTHLY_WEEK_DATE,
      RepeatUnit.MONTHLY_CALENDAR_DATE,
      RepeatUnit.YEARLY
    ];
  }

  public static fromRepeatUnitString(repeatUnitString: RepeatUnitString, monthlyWeekDate: boolean = false): RepeatUnit {
    switch (repeatUnitString.value) {
      case 'NONE':
        return RepeatUnit.NONE;
      case 'DAY':
        return RepeatUnit.DAILY;
      case 'WEEK':
        return RepeatUnit.WEEKLY;
      case 'MONTH':
        return monthlyWeekDate ? RepeatUnit.MONTHLY_WEEK_DATE : RepeatUnit.MONTHLY_CALENDAR_DATE;
      case 'YEAR':
        return RepeatUnit.YEARLY;
    }
  }

  public static fromValue(value: string): RepeatUnit {
    return this.getAll().find((value1, index) => {
      if (value1.value === value) {
        return value1;
      }
    });
  }

  public getUnitString(): RepeatUnitString {
    switch (this.value) {
      case 'NONE':
        return RepeatUnitString.NONE;
      case 'DAILY':
        return RepeatUnitString.DAY;
      case 'WEEKLY':
        return RepeatUnitString.WEEK;
      case 'MONTHLY_WEEK_DATE':
        return RepeatUnitString.MONTH;
      case 'MONTHLY_CALENDAR_DATE':
        return RepeatUnitString.MONTH;
      case 'YEARLY':
        return RepeatUnitString.YEAR;
    }
  }
}

type RepeatUnit_ = 'NONE' | 'DAILY' | 'WEEKLY' | 'MONTHLY_CALENDAR_DATE' | 'MONTHLY_WEEK_DATE' | 'YEARLY';
