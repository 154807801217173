import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from '@app/shared/services/header.service';
import { UserService } from '@app/shared/services/user/user.service';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { concatMap, Observable, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Building } from '@app/shared/models/building.interface';
import { TagChangeEvent, TagsComponent } from '@components/tags/tags.component';
import { SensorNodeService } from '@services/sensor-node.service';
import { Tag } from '@app/shared/models/tag.interface';
import { map, takeUntil } from 'rxjs/operators';
import { PanelToggleComponent } from '@components/panel-toggle/panel-toggle.component';
import { MetricSelectorComponent } from '@app/analytics/metric-selector/metric-selector.component';
import { HeatmapLegendComponent } from '@components/heatmap-legend/heatmap-legend.component';
import { NgClass } from '@angular/common';
import { AnalyticsMetricsService } from '@services/analytics-metrics.service';
import { FloorplanService } from '@services/floorplan.service';
import { DataType } from '@app/shared/models/sensor-node-data-type';
import { SelectableNode } from '@app/shared/models/sensor-node';
import { FloorplanComponent } from '@components/floorplan/floorplan.component';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  imports: [
    PanelToggleComponent,
    MetricSelectorComponent,
    HeatmapLegendComponent,
    TagsComponent,
    NgClass,
    FloorplanComponent
  ],
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit, OnDestroy, AfterViewInit {
  buildingId: number;
  floorId: number;
  building: Building;
  tags$: Observable<Tag[]>;

  isLeftColHidden = false;
  isRightColHidden = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly header: HeaderService,
    private readonly userService: UserService,
    private readonly navigationService: NavigationService,
    private readonly nodeService: SensorNodeService,
    private readonly route: ActivatedRoute,
    private readonly metricsService: AnalyticsMetricsService,
    private readonly floorplanService: FloorplanService
  ) {}

  ngOnInit(): void {
    this.header.showSiteMenu();
    this.header.showBuildingsMenu();
    this.header.showFloorsMenu();
    this.header.showUserMenu();
    this.header.showSessionMenu();
    this.route.params
      .pipe(
        takeUntil(this.destroy$),
        concatMap((params) => {
          const { buildingId, floorId } = params;
          this.floorId = floorId;
          this.buildingId = buildingId;
          this.metricsService.updateContextBuilding(buildingId, floorId);
          return this.userService.getBuilding(buildingId);
        })
      )
      .subscribe((building) => {
        this.building = building;
        this.navigationService.initNavigation(window.location.href, building);
      });

    this.addNodeChangeSubscription();
    this.subscribeToNodeSelection();
  }

  ngAfterViewInit(): void {
    this.floorplanService.enableAllNodesMode = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.floorplanService.heatmapMode = false;
    this.metricsService.updateContextDataType(DataType.PRESENCE);
    this.metricsService.tagContext = [];
  }

  toggleLeft(val: boolean): void {
    this.isLeftColHidden = val;
  }

  toggleRight(val: boolean): void {
    this.isRightColHidden = val;
  }

  get floorPanelClass(): string {
    if (this.isLeftColHidden && this.isRightColHidden) {
      return 'col-span-12';
    } else if (this.isLeftColHidden) {
      return 'col-span-10';
    } else if (this.isRightColHidden) {
      return 'col-span-10';
    } else {
      return 'col-span-8';
    }
  }

  addNodeChangeSubscription(): void {
    this.tags$ = this.nodeService.getCurrentFloorNodes$().pipe(
      map((nodes) => {
        const tagMap = new Map<number, Tag>();
        nodes?.forEach((node) => {
          if (node.tags) {
            node.tags.forEach((nodeTag) => {
              tagMap[nodeTag.id] = nodeTag;
            });
          }
        });
        return Object.keys(tagMap).map((key) => {
          const tag = tagMap[key];
          tag.isActive = this.metricsService.currentContext.tagIds.includes(tag.id);
          return tag;
        });
      })
    );
  }

  tagToggle(event: TagChangeEvent): void {
    const tag = event.tag;
    tag.isActive = event.isActive;
    const currentTagContext = JSON.parse(JSON.stringify(this.metricsService.currentContext.tagIds));
    if (event.isActive) {
      if (!currentTagContext.includes(tag.id)) {
        this.metricsService.tagContext = [...currentTagContext, tag.id];
      }
    } else {
      currentTagContext.splice(currentTagContext.indexOf(tag.id), 1);
      this.metricsService.tagContext = currentTagContext;
    }
  }

  handleNodeClick(node: SelectableNode): void {
    if (this.floorplanService.isCumulativeModeActive) {
      this.nodeService.toggleEntitySelection(node, this.floorplanService.isCumulativeModeActive);
    } else {
      this.nodeService.selectEntitiesInArea([node], this.floorplanService.isCumulativeModeActive);
    }
    this.nodeService.updateQueryParams();
  }

  subscribeToNodeSelection(): void {
    this.nodeService
      .getCurrentFloorSelectedEntities$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedNodes) => {
        this.metricsService.updateNodeIs(selectedNodes.map((node) => node.id));
      });
  }
}
