<div class="or-schedule-rule-tile">
    @if(rule.command && rule.value !== null) {
      <span class="align-middle inline-block">
        <span class="or-schedule-rule-setting-tile-name" data-cy="rule-setting-tile-name">{{ getRuleName() }}</span>
        @if(hasValue) {
          to <span class="or-schedule-rule-setting-tile-value" data-cy="rule-setting-tile-value">{{ getValue() }}</span>
        }
      </span>
    }
    @if(rule.tags.length) {
      <span class="or-label ml-1">for:</span>
      <app-inline-tags [isEditable]="false" [displayTags]="rule.tags" [selectedTagIds]="getTagIds()"></app-inline-tags>
    }
    @if(rule.floors.length) {
      <label class="or-label ml-1">on:</label>
      <app-inline-floors [isEditable]="false" [floors]="rule.floors" [selectedFloorIds]="getFloorIds()"></app-inline-floors>
    }
</div>
