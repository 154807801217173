<div id='orAdministrationFloors'>
  <div class='or-content'>
    @if (isBusy) {
      <mat-spinner />
    } @else {
      <div class='or-content-body'>
        @if (floors.length === 0) {
          <app-notification-block
            [header]="'No floors found'"
            [statusClass]="statusClass.WARNING">
            @if(!hasManageFloorsPermission()) {
              Please contact the operations team to setup floors for this building.
            } @else {
              Go ahead and add one.
            }
          </app-notification-block>
        }
        @if(hasManageFloorsPermission()) {
          <div class='or-floors-list'>
            @for (floor of floors; track floor.id) {
              <div class='or-floors-list-item'>
                <app-form-floor-details
                  [floor]='floor'
                  [isBusy]='isBusy'
                  [building]='building'
                  (valueChanged)='loadFloorsAndUser()' />
              </div>
            }
          </div>
          <div class='or-floors-list'>
            <div class='or-floors-list-item'>
              <app-form-floor-details
                [isNew]='true'
                [building]='building'
                [isBusy]='isBusy'
                [newFloorNum]='newFloorNum'
                (valueChanged)='loadFloorsAndUser()' />
            </div>
          </div>
        }
      </div>
    }
  </div>
</div>
