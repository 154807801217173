import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ],
  imports: [NgClass, NgIf]
})
export class CheckboxComponent implements ControlValueAccessor {
  public label: string;
  public propagateChange: Function;
  public value: boolean;
  public disabled: boolean;

  @Output() valueChange = new EventEmitter();

  @Input() id: string;
  @Input() dataCy: any;

  public writeValue(value: boolean): void {
    this.value = value;
  }

  public registerOnChange(fn: Function): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: Function): void {}

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public toggle() {
    this.value = !this.value;
    this.valueChange.emit();
    this.propagateChange(this.value);
  }
}
