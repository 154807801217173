<div class="or-user-menu">
  @if (floorsMenuActive$ | async) {
    <div class="or-inline-item"><app-floors-menu /></div>
  }
  @if (buildingsMenuActive$ | async) {
    <div class="or-inline-item"><app-buildings-menu [user$]="user$" [buildings$]='buildings$' /></div>
  }
  @if (sessionMenuActive$ | async) {
    <div class="or-inline-item"><app-session-menu [user$]="user$" /></div>
  }
  <div class="or-inline-item">
    <a data-cy='portal-help-link' class="or-button or-site-menu-link" href="https://organicresponse.atlassian.net/servicedesk/customer/portal/1">
      <span data-cy='portal-help-icon' class="or-icon or-small or-icon-info or-inverse"></span>
    </a>
  </div>
</div>
