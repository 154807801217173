import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectableNode, SensorNode, SensorNodeAlert } from '@app/shared/models/sensor-node';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FloorplanService } from '@services/floorplan.service';
import { DISCRIMINATOR, UserFriendlyDiscriminator } from '@app/shared/models/selectable.interface';
import { LightingConfigurationService } from '@services/lighting-configuration.service';
import { AnalyticsMetricsService } from '@services/analytics-metrics.service';
import { DataType } from '@app/shared/models/sensor-node-data-type';
import { LiveDataQueryResults } from '@app/analytics/metric-widget/query/outline/LiveDataQueryResults';
import { NgClass, NgStyle } from '@angular/common';
import { TagOutlineDirective } from '@components/floorplan/sensor-node/tag-outline.directive';
import { NavigationService } from '@services/navigation/navigation.service';
import { EmDriverComponent } from '@components/floorplan/sensor-node/em-driver/em-driver.component';
import { LuminaireDriverComponent } from '@components/floorplan/sensor-node/luminaire-driver/luminaire-driver.component';

@Component({
  selector: 'app-sensor-node',
  templateUrl: './sensor-node.component.html',
  styleUrls: ['./sensor-node.component.scss'],
  imports: [NgClass, NgStyle, TagOutlineDirective, EmDriverComponent, LuminaireDriverComponent]
})
export class SensorNodeComponent implements OnInit {
  @Input() liveNodeData: LiveDataQueryResults;
  @Input({ required: true }) details: SelectableNode;
  @Input({ required: true }) x: number;
  @Input({ required: true }) y: number;
  @Input() zoomLevel$: Observable<number> = of(1);
  @Output() onNodeClick = new EventEmitter<SelectableNode>();
  private propertiesStyle: Observable<Record<string, string>>;

  constructor(
    private readonly floorplanService: FloorplanService,
    private metricsService: AnalyticsMetricsService,
    private readonly navService: NavigationService,
    protected lightingConfigurationService: LightingConfigurationService
  ) {}

  ngOnInit(): void {
    this.propertiesStyle = this.zoomLevel$.pipe(map((zoomLevel) => this.producePropertiesStyle(zoomLevel)));
  }

  get enableDrivers(): boolean {
    return this.floorplanService.isShowDriversModeActive;
  }

  get enableGatewayMode(): boolean {
    return this.floorplanService.isGatewayModeActive;
  }

  get alerts(): SensorNodeAlert[] {
    return SensorNode.from(this.details).alerts;
  }

  produceClassForSensorNode(): Record<string, boolean> {
    return {
      'sensor-node-regular-shadow': true,
      'sensor-node-alert-shadow': !this.floorplanService.isAnalyticsPage && this.alerts?.length > 0,
      selected: this.details.selected,
      unmapped: !this.details.properlyMapped,
      mapped: this.details.properlyMapped,
      hidden: this.shouldHiddenClassBeActive(),
      'duplicated-mapping': this.details.duplicateAddressMappings?.length > 0,
      disconnected: this.details.properlyMapped && !this.details.connected,
      'has-emergency-gear': this.details.emDrivers?.length > 0,
      'is-changed': this.details.isChanged,
      'passive-node': this.details.isPassiveNode,
      'environmental-node': this.details.isEnvironmental,
      muted: this.shouldMutedClassBeActive(),
      test: this.floorplanService.showMappedNodesMode,
      notifying: this.details.isNotifying
    };
  }

  private shouldHiddenClassBeActive(): boolean {
    // node should not be visible when:
    // 1. floorplanService.isEnableAllNodesSelected is false
    // 2. floorplanService.showMappedNodesMode is false and node is mapped (this.details.properlyMapped is true)
    // 3. floorplanService.isUnmappedModeActive is false and node is not mapped (this.details.properlyMapped is false)
    // 4. floorplanService.isShowFaultyNodesModeActive is false and node is faulty (isFaulty is true), but only for SN page
    let shouldNodeBeVisible: boolean;
    const isSNPage = this.floorplanService.isSensorNodesPage;
    shouldNodeBeVisible = this.floorplanService.isEnableAllNodesSelected;
    if (!this.floorplanService.showMappedNodesMode && this.details.properlyMapped) {
      shouldNodeBeVisible = false;
    }
    if (!this.floorplanService.isUnmappedModeActive && !this.details.properlyMapped) {
      shouldNodeBeVisible = false;
    }
    if (isSNPage && !this.floorplanService.isShowFaultyNodesModeActive && SensorNode.from(this.details).isFaulty) {
      shouldNodeBeVisible = false;
    }
    return !shouldNodeBeVisible;
  }

  private shouldMutedClassBeActive(): boolean {
    if (this.floorplanService.isElmtPage || this.floorplanService.isHighlightEmergencyLightsModeActive) {
      return this.details.emDrivers == null || this.details.emDrivers?.length === 0;
    }
    if (this.floorplanService.isLightingConfigPage || this.floorplanService.isBeaconsPage) {
      // show PNs as disabled on lighting configuration and beacons page
      return !this.details.isSN3 && !this.details.isHIM84 && !this.details.isHCD405 && !this.details.isHCD038;
    }
    return false;
  }

  onClick(): void {
    this.onNodeClick.emit(this.details);
  }

  get address16(): string {
    return this.details.properlyMapped ? this.details.address.toString(16).toUpperCase() : 'Unmapped';
  }

  public producePropertiesStyle(zoomLevel: number): Record<string, string> {
    const style: Record<string, string> = {};
    const transform = 'translate(-50%, -8px) scale(' + 1 / zoomLevel + ')';
    style.transform = transform;
    style['-webkit-transform'] = transform;
    return style;
  }

  public produceNodeStyle(): Record<string, string> {
    const style: Record<string, string> = {};
    style.left = this.x + 'px';
    style.top = this.y + 'px';
    return style;
  }

  produceClassForSensorNodeIcon(): Record<string, string> {
    const styleClass = {};
    styleClass['or-icon-node'] = this.details.isPassiveNode || this.details.isSN3 || this.details.nodeType == null;
    styleClass['or-icon-him84'] = this.details.isHIM84;
    styleClass['or-icon-hcd405'] = this.details.isHCD405;
    styleClass['or-icon-hcd038'] = this.details.isHCD038;
    styleClass['or-icon-environmental-node'] = this.details.isEnvironmental;
    return styleClass;
  }

  getNodeIdText(): string {
    let text = UserFriendlyDiscriminator[this.details.nodeType] || this.details.nodeType;
    if ([SensorNode.HCD038_NODE_TYPE, SensorNode.HCD405_NODE_TYPE].includes(this.details.nodeType)) {
      text += ' (' + DISCRIMINATOR[this.details.nodeType].toString() + ')';
    }
    text += ' #' + this.details.id;
    return text;
  }

  get lampTypeName(): string {
    if (this.details.luminaireDrivers && this.details.luminaireDrivers[0]?.lampType?.name) {
      return (
        this.details.luminaireDrivers[0]?.lampType?.name +
        ' (' +
        this.details.luminaireDrivers[0]?.lampType?.powerConsumptionMax +
        'W)'
      );
    } else {
      return null;
    }
  }

  formatLightLevel(): number {
    const node = this.details as SensorNode;
    const values = this.liveNodeData?.lightLevel.values.find((data) => data.key === node.address);
    node.lightLevel = values ? values.value : 0;
    return node.lightLevel && node.lightLevel > 0 ? Math.round(node.lightLevel) : 0;
  }

  formatPresenceLevel(): number {
    const node = this.details as SensorNode;
    const values = this.liveNodeData?.presenceLevel.values.find((data) => data.key === node.address);
    node.presence = values ? values.value : 0;
    return node.presence && node.presence > 0 ? Math.round(node.presence * 100) / 100 : 0;
  }

  get scene(): string {
    return this.lightingConfigurationService.convertSceneIdToName(this.details.scene);
  }

  get dataType(): DataType {
    return this.metricsService.getCurrentContextDataType();
  }

  get dataTypeString(): string {
    return this.dataType?.toString().toLowerCase().replace('_', '-');
  }

  get metricFormattedValue(): string {
    return this.dataType?.format(this.details.value, this.details.valueSuffix) || 'No data available';
  }

  get isLightingConfigPage(): boolean {
    return this.navService.getActiveSection().info.Id === 'lighting-configuration';
  }

  get isHeatMapPage(): boolean {
    return this.navService.getActiveSection().info.Id === 'heatmap';
  }
}
