export interface Action {
  (): void;
}

export interface Producer<O> {
  (): O;
}

export interface Consumer<I> {
  (I): void;
}

export interface DoubleConsumer<A, B> {
  (A, B?): void;
}

export interface Func<I, O> {
  (I): O;
}

export interface Predicate<I> {
  (I): boolean;
}

export interface Comparator<A, B> {
  (A, B): boolean;
}

export const EmptyAction = () => {};
export const EmptyConsumer = (i) => {};
export const AlwaysTrue = (i) => true;
export const AlwaysFalse = (i) => false;
