import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@app/shared/services/user/user.service';
import { SessionService } from '@services/session.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-privacy-notice',
  imports: [MatButtonModule, MatIconModule],
  templateUrl: './privacy-notice.component.html',
  styleUrl: './privacy-notice.component.scss'
})
export class PrivacyNoticeComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public showActions: boolean,
    private dialogRef: MatDialogRef<PrivacyNoticeComponent>,
    private userService: UserService,
    private sessionService: SessionService
  ) {}

  public accept(): void {
    this.userService.join().subscribe({
      next: (_) => {
        this.userService.initializeUser();
        this.dialogRef.close();
      },
      error: () => {
        console.error('Failed to join the user');
        this.sessionService.logout();
      }
    });
  }

  public decline(): void {
    this.userService.getCurrentUser().subscribe({
      next: (user) => {
        this.userService.delete(user.id).subscribe();
        this.sessionService.logout();
      },
      error: () => {
        console.error('Failed to delete the user');
        this.sessionService.logout();
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
