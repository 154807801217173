import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-ble-dialog',
  templateUrl: './ble-dialog.component.html',
  styleUrls: ['./ble-dialog.component.scss'],
  imports: [MatDialogTitle, MatDialogContent, NgIf, MatDialogActions, MatButton]
})
export class BleDialogComponent {
  constructor(private dialogRef: MatDialogRef<BleDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {}

  get queryType(): string {
    return this.data;
  }

  cancel(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  send(): void {
    this.dialogRef.close({ event: 'Send' });
  }
}
