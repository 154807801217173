<div class="or-inline-items or-inline-floors or-editable">
  @if (isEditable) {
    <div class="or-inline-item or-inline-floor-add w-[100px] pt-1 pl-2">
      <mat-select placeholder="Pick a floor" #selectFloor (selectionChange)="addActiveFloor(selectFloor.value)" data-cy="pick-a-floor-list" [disabled]="isSelectionDisabled">
        @for(floor of availableOptions; track $index) {
          <mat-option  [value]="floor.value | json">{{produceFloorLabel(floor.value)}}</mat-option>
        }
        @if(availableOptions.length === 0) {
          <mat-option  disabled="true">No floors available</mat-option>
        }
      </mat-select>
    </div>
  }
  <div class="or-inline-item or-inline-floor" *ngFor="let floor of currentSelection; index as i">
    <span class="or-inline-floor-number" [textContent]="floor.floorNumber" [attr.data-cy]="'selected-schedule-floor-number-'+i"></span>
    <span class="or-inline-floor-name" [textContent]="produceFloorLabel(floor)" [attr.data-cy]="'selected-schedule-floor-name-'+i"></span>
    <button class="or-button or-inline-floor-remove" (click)="removeFloor(i)" *ngIf="isEditable" [attr.data-cy]="'delete-schedule-floor-'+i">
      <span class="or-icon or-tiny or-icon-cross"></span>
    </button>
  </div>
</div>
