import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { Building } from '@app/shared/models/building.interface';
import { concatMap } from 'rxjs/operators';
import { UserService } from '@services/user/user.service';
import { ActivatedRoute } from '@angular/router';
import { MobileTokenService } from '@services/mobile-token/mobile-token.service';
import { BleKeysService } from '@services/ble-keys/ble-keys.service';
import { MobileToken } from '@app/shared/models/mobile-token.interface';
import { BleKey } from '@app/shared/models/ble-key.interface';
import { ToastService } from '@services/toast/toast.service';
import { NgFor, NgIf } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { FormMobileTokenDetailsComponent } from './form-mobile-token-details/form-mobile-token-details.component';
import { CheckboxComponent } from '../../shared/components/checkbox/checkbox.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBleKeyDetailsComponent } from './form-ble-key-details/form-ble-key-details.component';

@Component({
  selector: 'app-mobile-token',
  templateUrl: './mobile-token.component.html',
  styleUrls: ['./mobile-token.component.scss'],
  imports: [
    NgIf,
    MatProgressSpinner,
    NgFor,
    FormMobileTokenDetailsComponent,
    CheckboxComponent,
    ReactiveFormsModule,
    FormsModule,
    FormBleKeyDetailsComponent
  ]
})
export class MobileTokenComponent implements OnInit {
  isBusy = true;
  mobileTokens: MobileToken[];
  deactivatedMobileTokens: MobileToken[];
  currentBleKey: BleKey;
  previousBleKeys: BleKey[];
  building: Building;
  showDeactivatedTokens = false;
  showPreviousKeys = false;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private mobileTokenService: MobileTokenService,
    private bleKeysService: BleKeysService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.loadDetails();
  }

  loadDetails(): void {
    this.isBusy = true;
    this.getSelectedBuilding().subscribe((selectedBuilding: Building) => {
      this.building = selectedBuilding;
      forkJoin([this.loadMobileTokens(), this.loadBleKeys()]).subscribe({
        next: (res: [MobileToken[], BleKey[]]) => this.handleResponse(res),
        error: () => this.handleError('Failed to load tokens. Please try again')
      });
    });
  }

  handleResponse(res: [MobileToken[], BleKey[]]): void {
    this.mobileTokens = res[0].filter((val) => val.tokenStatus === 'READY_FOR_USE' || val.tokenStatus === 'ACTIVATED');
    this.deactivatedMobileTokens = res[0].filter((val) => val.tokenStatus === 'DEACTIVATED');
    const keys = res[1].sort((a, b) => b.sequence - a.sequence);
    this.currentBleKey = keys[0];
    this.previousBleKeys = keys.slice(1, keys.length);
    this.isBusy = false;
  }

  loadMobileTokens(): Observable<MobileToken[]> {
    return this.mobileTokenService.getAllTokensForBuilding(this.building.id);
  }

  loadBleKeys(): Observable<BleKey[]> {
    return this.bleKeysService.getAllBleKeysForBuilding(this.building.id);
  }

  getSelectedBuilding(): Observable<Building> {
    return this.route.params.pipe(
      concatMap((params: any) => {
        return this.userService.getBuilding(params.buildingId);
      })
    );
  }

  toggleShowDeactivatedTokens(): void {
    this.showDeactivatedTokens = !this.showDeactivatedTokens;
  }

  toggleShowPreviousKeys(): void {
    this.showPreviousKeys = !this.showPreviousKeys;
  }

  handleError(message: string): void {
    this.toast.error({ message, dataCy: 'load-error-toast' });
  }
}
