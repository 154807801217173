<ul class="or-floorplan-actions" data-cy="floorplan-actions-container">
  <li id="show-tray">
    <app-floorplan-actions-tray
      [trayButtons]="showTray"
      [trayButtonToolTip]="'Nodes Visibility'"
      [trayButtonIconClass]="'or-icon-eye'"
      data-cy='tray-button-toggle-nodes'
    ></app-floorplan-actions-tray>
  </li>
  @if (hasHeatMap) {
    <li id="heat-map-actions">
      <button
        class="or-button"
        (click)="toggleNormalizeScale()"
        (touchend)="toggleNormalizeScale()"
        [ngClass]="{'or-active': (isScaleNormalized)}"
        [matTooltip]="'Scale Values'"
        [matTooltipPosition]="'left'"
        data-cy='scale-values-action-btn'
      >
        <span class="or-icon or-small or-icon-scale"></span>
      </button>
    </li>
    <li appIsAuthorizedForBuilding="HIGH_RESOLUTION_ANALYTICS" [appBuildingIdToAuthorize]='buildingId'>
      <button
        class="or-button"
        (click)="toggleLiveMode()"
        (touchend)="toggleLiveMode()"
        [ngClass]="{'or-active': (isLiveModeEnabled)}"
        [matTooltip]="'Live Mode'"
        [matTooltipPosition]="'left'"
        data-cy='live-mode-action-btn'
      >
        <span class="or-icon or-small or-icon-pulse"></span>
      </button>
    </li>
  }
  @if (hasAddTray) {
    <li id="add-tray">
      <app-floorplan-actions-tray
        (click)="toggleAddMode($event)"
        [trayButtonIconClass]="'or-icon-edit'"
        [trayButtonToolTip]="'Add Nodes'"
        [trayButtons]="addTray"
        [disableTrayButtons]="shouldDisableAddTrayBtns"
        [disable]="shouldDisableAddButton"
      ></app-floorplan-actions-tray>
    </li>
  }
  @if (hasMoveTray) {
    <li id="move-tray">
      <app-floorplan-actions-tray
        (click)="toggleMoveMode()"
        [trayButtonIconClass]="'or-icon-move'"
        [trayButtonToolTip]="'Move Nodes'"
        [trayButtons]="moveTray"
        [disableTrayButtons]="shouldDisableMoveTrayBtns"
        [disable]="shouldDisableMoveButton"
      ></app-floorplan-actions-tray>
    </li>
  }
  @if (hasSelectionTray) {
    <li id="select-tray">
      <app-floorplan-actions-tray
        [trayButtonIconClass]="'or-icon-selection'"
        [trayButtonToolTip]="'Nodes Selection'"
        [trayButtons]="selectionTray"
        [disable]="shouldDisableSelectionButton"
        data-cy='tray-button-toggle-select-nodes'
      ></app-floorplan-actions-tray>
    </li>
  }
  @if (hasMappingTray) {
    <li id="map-nodes-tray">
      <app-floorplan-actions-tray
        [trayButtonIconClass]="'or-icon-node'"
        [trayButtonToolTip]="'Map Nodes'"
        [trayButtons]="mapNodesTray"
        [disable]="disableMapping"
        data-cy='tray-button-map-nodes'>
      </app-floorplan-actions-tray>
    </li>
    @if (isPassiveNodeMappingModeEnabled) {
      <ul>
        <select class="or-select" [(ngModel)]="selectedPnId" data-cy="select-mapping-unmapping-dropdown">
          <option [ngValue]="null">--Unmap Passive Node--</option>
          @for (pn of availableUnmappedPNList; track pn.id) {
            <option [ngValue]="pn.id">{{optionLabelForPassiveNode(pn)}}</option>
          }
        </select>
      </ul>
      <li>
        <button
          (click)="mapOrUnmapSelectedPassiveNode()"
          [ngClass]="{'or-active': (isPassiveNodeMappingModeEnabled), 'or-button': true}"
          [matTooltip]="'Click to Map/Unmap selected PN'"
          [matTooltipPosition]="'right'"
          data-cy="unmap-pn-button">
          <span class="or-icon or-small or-icon-pin-drop"></span>
        </button>
      </li>
    }
  }
  @if (isGatewayModeAllowed) {
    <li appIsAuthorized="VIEW_HIDDEN_COMPONENTS">
      <button
        class="or-button"
        (click)="toggleGatewayMode()"
        (touchend)="toggleGatewayMode()"
        data-cy="gateway-mode"
        [matTooltip]="'Gateway Mode'"
        [matTooltipPosition]="'left'"
        [ngClass]="{'or-active': isGatewayModeEnabled()}"
      >
        <span class="or-icon or-small or-icon-plug"></span>
      </button>
    </li>
  }

  @if (hasNodeOptionsTray) {
    <li id="node-options-tray">
      <app-floorplan-actions-tray
        [trayButtons]="nodeOptionsTray"
        [trayButtonIconClass]="'or-icon-node-options'"
        [trayButtonToolTip]="'Node Operations'"
        data-cy="tray-button-node-options">
      </app-floorplan-actions-tray>
    </li>
  }
  @if (isEmergencyLightingPage) {
    <li appIsAuthorizedForBuilding="MANAGE_EMERGENCY_LIGHTING"
        [appBuildingIdToAuthorize]="buildingId">
      <button
        class="or-button"
        (click)="onInitiateTest('FUNCTION')"
        (touchend)="onInitiateTest('FUNCTION')"
        data-cy="initiate-functional-test"
        [matTooltip]="'Initiate Functional Test'"
        [matTooltipPosition]="'left'"
      >
        <span class="or-icon or-small or-icon-crash-test"></span>
      </button>
    </li>
    <li appIsAuthorizedForBuilding="MANAGE_EMERGENCY_LIGHTING"
        [appBuildingIdToAuthorize]="buildingId">
      <button
        class="or-button"
        (click)="onInitiateTest('DURATION')"
        (touchend)="onInitiateTest('DURATION')"
        data-cy="initiate-duration-test"
        [matTooltip]="'Initiate Duration Test'"
        [matTooltipPosition]="'left'"
      >
        <span class="or-icon or-small or-icon-stopwatch"></span>
      </button>
    </li>
    <li appIsAuthorizedForBuilding="MANAGE_EMERGENCY_LIGHTING"
        [appBuildingIdToAuthorize]="buildingId">
      <button
        class="or-button"
        (click)="cancelTestsManually()"
        (touchend)="cancelTestsManually()"
        data-cy="cancel-tests-manually"
        [matTooltip]="'Cancel Test'"
        [matTooltipPosition]="'left'"
      >
        <span class="or-icon or-small or-icon-cross"></span>
      </button>
    </li>
  }
</ul>
