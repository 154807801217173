@if (showHidden) {
  <div class='or-nodes-list'>
    <div class='or-nodes-list-control'>
      <app-nodes-filter
        [filterForm]="filterForm"
        [sortByFields]="sortByFields"
        (filterChange)="onFilterChanged($event)"
        (copyNodesToClipboard)="copyToClipboard()"
        (refreshNodeListEmitter)="refreshNodeList()"></app-nodes-filter>
    </div>
    @if(dataSource.isLoading$ | async) {
      <div class="mat-spinner spinner-container">
        <mat-spinner diameter="80" strokeWidth="8"></mat-spinner>
      </div>
    }
    <div class='nodes-table-parent'>
      @for(node of dataSource.connect(null)| async; track node.id) {
        <mat-card [id]="node.id" #matCardRows class='mt-2 mb-2 p-5' (click)='toggleRow(node)' [ngClass]="{'row-selected': node.selected}">
          <mat-card-content>
            <div class='flex flex-row flex-wrap justify-between'>
              <div [attr.data-cy]="'node-status-' + (node.address || '').toString(16)" class='p-1'>
                  <div [ngClass]="getNodeStatusAndTypeClass(node)">
                    <span [ngClass]="getNodeStatusIconClass(node)"></span>
                  </div>
                  <div class='or-node-tile-label'>Node</div>
                <br>
              </div>
              <div [attr.data-cy]="'node-id-' + (node.address || '').toString(16)" class='p-1'>
                  <div>
                    {{node.id}}
                  </div>
                <div class='or-node-tile-label'>Node Id</div>
                <br>
              </div>
              <div [attr.data-cy]="'group-id-' + (node.address || '').toString(16)" class='p-1'>
                <div>
                  {{ node.groupId || 'NA' }}
                </div>
                <div class='or-node-tile-label'>Group Id</div>
                <br>
              </div>
              <div [attr.data-cy]="'node-address-' + (node.address || '').toString(16)" class='p-1'>
                <div>
                  {{ (node.address || '').toString(16).toLocaleUpperCase() }}
                </div>
                <div class='or-node-tile-label'>Address</div>
                <br>
              </div>

              <!-- sensor node page specific columns-->
              @if (isSensorNodePage()) {
                <div [attr.data-cy]="'node-status-' + (node.address || '').toString(16)" class='p-1'>
                  <div>
                    {{ getPropValForLuminaire(node, 'burnInHours') }}
                  </div>
                  <div class='or-node-tile-label'>Burn Hours</div>
                  <br>
                </div>
                <div [attr.data-cy]="'node-status-' + (node.address || '').toString(16)" class='p-1'>
                  <div>
                    {{ getPropValForLuminaire(node, 'energyConsumption') === 'N/A' ? 'N/A' : getPropValForLuminaire(node, 'energyConsumption') + 'kWh' }}
                  </div>
                  <div class='or-node-tile-label'>Energy Consumption</div>
                  <br>
                </div>
                <div [attr.data-cy]="'node-status-' + (node.address || '').toString(16)" class='p-1'>
                  <div>
                    <img [ngClass]='bleScanningClass(node)'/>
                  </div>
                  <div class='or-node-tile-label'>Ble Scanning</div>
                  <br>
                </div>
                <div [attr.data-cy]="'node-status-' + (node.address || '').toString(16)" class='p-1'>
                  <div>
                    <ul>
                      <li>
                        <span class="or-icon or-tiny or-icon-node"></span>
                        <span class="or-node-tile-value or-node-tile-inline-text">{{node.luminaireDrivers?.length}}</span>
                      </li>
                      <li>
                        <span class="or-icon or-tiny or-icon-emergency"></span>
                        <span class="or-node-tile-value or-node-tile-inline-text">{{node.emDrivers?.length}}</span>
                      </li>
                    </ul>
                  </div>
                  <div class='or-node-tile-label'>Devices</div>
                  <br>
                </div>
              }

              <!-- beacons page specific columns-->
              @if (isBeaconsPage()) {
                <div [attr.data-cy]="'node-beacon-enabled-' + (node.address || '').toString(16)" class='mx-1 px-2 py-1'>
                  <div>
                    <span class="or-icon or-small" [ngClass]="{'or-icon-enabled': node.beaconEnabled, 'or-icon-disabled': !node.beaconEnabled}"></span>
                  </div>
                  <div class='or-node-tile-label'>Enabled</div>
                  <br>
                </div>
                <div [attr.data-cy]="'node-power-level-' + (node.address || '').toString(16)">
                  <div class='mb-1'>
                    {{ (node.beaconPowerLevel || '') }}
                  </div>
                  <div class='or-node-tile-label'>Power Level</div>
                </div>
                <div [attr.data-cy]="'node-beacon-content-' + (node.address || '').toString(16)"
                     (mouseenter)="mouseOverColumn='beaconContent'+node.address"
                     [ngClass]="{'or-mouseover': mouseOverColumn == 'beaconContent' + node.address}">
                  <div class='mb-1'>
                    {{ mouseOverColumn == 'beaconContent' + node.address ? node.beaconContent.value : node.beaconContent.name }}
                  </div>
                  <div class='or-node-tile-label' [ngClass]="{'or-mouseover-title': mouseOverColumn == 'beaconContent' + node.address}">Beaconing Content</div>
                </div>
                <div [attr.data-cy]="'node-beacon-repetition-' + (node.address || '').toString(16)"
                     (mouseover)="mouseOverColumn='beaconRepetition'+ node.address"
                     [ngClass]="{'or-mouseover': mouseOverColumn == 'beaconRepetition' + node.address}">
                  <div class='mb-1'>
                    {{  mouseOverColumn == 'beaconRepetition' + node.address ? node.beaconInterval.name : node.beaconInterval.value }}
                  </div>
                  <div class='or-node-tile-label' [ngClass]="{'or-mouseover-title': mouseOverColumn == 'beaconRepetition' + node.address}">Beaconing Repetition</div>
                </div>
                <div [attr.data-cy]="'node-beacon-uuid-'  + (node.address || '').toString(16)"
                     (mouseover)="mouseOverColumn='uuid'+node.address"
                     [ngClass]="{'or-mouseover': mouseOverColumn == 'uuid' + node.address}">
                  <div class='mb-1'>
                    {{ mouseOverColumn == 'uuid' + node.address ? node.beaconUUID?.value : node.beaconUUID?.name }}
                  </div>
                  <div class='or-node-tile-label'  [ngClass]="{'or-mouseover-title': mouseOverColumn == 'uuid' + node.address}">UUID</div>
                </div>
                <div [attr.data-cy]="'node-beacon-major-' + (node.address || '').toString(16)">
                  <div class='mb-1'>
                    {{ (node.beaconSetting?.major || 'N/A') }}
                  </div>
                  <div class='or-node-tile-label'>Major</div>
                </div>
                <div [attr.data-cy]="'node-beacon-minor-' + (node.address || '').toString(16)">
                  <div class='mb-1'>
                    {{ (node.beaconSetting?.minor || 'N/A') }}
                  </div>
                  <div class='or-node-tile-label'>Minor</div>
                </div>
                <div [attr.data-cy]="'node-beacon-update-' + (node.address || '').toString(16)">
                  <div class='mb-1'>
                    @if (node.beaconUpdateStatus === 'UPDATING') {
                      <span class="loader or-icon or-small or-inverse2 or-icon-gear"></span>
                    }
                    @if (node.beaconUpdateStatus === 'CONFIRMED') {
                      <span class="or-opacity or-icon or-small or-inverse or-icon-confirmed"></span>
                    }
                    @if (!(node.beaconUpdateStatus === 'UPDATING' || node.beaconUpdateStatus === 'CONFIRMED')) {
                      <span class="or-opacity or-icon or-small or-inverse or-icon-question-mark"></span>
                    }
                  </div>
                  <div class='or-node-tile-label'>Update</div>
                </div>
              }

              @if (isLightingConfigPage()) {
                <div [attr.data-cy]="'node-zone-' + (node.address || '').toString(16)" class='p-1'>
                  <div>
                    {{ node.lightingConfigurationZone || 'NA' }}
                  </div>
                  <div class='or-node-tile-label'>Zone</div>
                  <br>
                </div>
              }
            </div>
          </mat-card-content>
          <mat-card-footer>
            @if (!node.expanded) {
              <div [isFeatureAvailable]="'tagsEverywhere'">
                @for(tag of node.tags; track tag.id) {
                  <div class="or-node-tile-tag">
                    <span class="or-node-tile-tag-thumbnail" title="{{ tag.name }}" [ngStyle]='{ "background-color": "#" + tag.color }'></span>
                  </div>
                }
              </div>
            } @else {
              <div>
                @for (tag of node.tags; track tag.id) {
                  <div class="or-node-tile-details-tags-item mt-1 mb-1">
                    <span class="or-node-tile-details-tag" [ngStyle]="getForegroundColor(tag.color)">
                      <span class="or-node-tile-details-tag-name">{{tag.name}}</span>
                    </span>
                  </div>
                }
                @if (node?.alerts?.length !== 0 && isDriverDetailsActive) {
                  @for (alert of node.alerts; track $index) {
                    <div class="node-alerts py-2 px-6 m-1">{{alert.value}}</div>
                  }
                }
              </div>
              <br>
              <div>
                @if (isSensorNodePage()) {
                  @for(lumDriver of node.luminaireDrivers; track lumDriver.id) {
                    <div class='flex flex-row flex-wrap justify-between w-[70%] pt-2 pb-2 mx-auto'>
                      @for (innerColumn of subTableColumns; track $index) {
                        <div [attr.data-cy]="getDataCy(innerColumn, lumDriver[innerColumn])">
                          @if (innerColumn === 'type') {
                            <span class="{{'or-luminaire-driver-tile-status-body or-luminaire-driver-tile-status-body-' + lumDriver.status}}">
                            <span class="or-icon or-tiny or-icon-node"></span>
                          </span>
                          } @else {
                            {{getProperValue(innerColumn, lumDriver[innerColumn])}}
                          }
                          <br>
                          <span class="or-node-tile-label">{{innerColumn}}</span>
                        </div>
                      }
                    </div>
                  }
                  @for (emDriver of node.emDrivers; track emDriver.id) {
                    <div class='flex flex-row flex-wrap justify-between w-[70%] pt-2 pb-2 mx-auto'>
                      @for (innerColumn of subTableColumns; track $index) {
                        <div [attr.data-cy]="getDataCy(innerColumn, emDriver[innerColumn])">
                          @if (innerColumn === 'type') {
                            <span class="{{'or-em-driver-tile-status-body or-em-driver-tile-status-body-' + emDriver.status}}">
                            <span class="or-icon or-tiny or-icon-emergency"></span>
                          </span>
                          } @else {
                            {{getProperValue(innerColumn, emDriver[innerColumn])}}
                          }
                          <br>
                          <span class="or-node-tile-label">{{innerColumn}}</span>
                        </div>
                      }
                    </div>
                  }
                }
              </div>
            }
          </mat-card-footer>
        </mat-card>
      }
    </div>
  </div>
}

