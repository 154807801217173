<div id="orSchedulesList" class="pt-2.5">
  @if (isDataReady$ | async) {
    @if (!showNewForm && !schedules?.length) {
      <app-notification-block
        [header]="'No Schedules available!'"
        [statusClass]="StatusClass.WARNING">
        The building has nothing scheduled. You may add some schedules though!
      </app-notification-block>
    }
    @if (showNewForm || schedules?.length) {
      <div class="or-content-body or-schedules-timescale-container">
        <div class="or-schedules-timescale">
          <div class="or-schedules-list">
            @if(showNewForm) {
              <div class="or-schedules-list-item">
                <app-schedule-summary
                  [building]="building"
                  [isNew]="true"
                  (onClose)="reloadSchedules()"
                  (onSaveNewSchedule)="reloadSchedules()">
                </app-schedule-summary>
              </div>
            }
            @for (schedule of schedules; track schedule) {
              <div class="or-schedules-list-item">
                <app-schedule-summary
                  [building]="building"
                  [schedule]="schedule"
                  (onClose)="reloadSchedules()"
                  (onDeleteSchedule)="onDeleteSchedule()"
                  (onMoveDown)="onMoveDown($event)"
                  (onMoveUp)="onMoveUp($event)">
                </app-schedule-summary>
              </div>
            }
          </div>
        </div>
      </div>
    }
    <button
      matTooltip="Create new schedule"
      matTooltipClass="mat-tooltip font-15"
      matTooltipPosition="above"
      aria-label="Button to add new schedule"
      mat-fab
      (click)="createNew()"
      class="add-schedule-button"
      color="primary"
      data-cy="add-new-schedule-button">
      <mat-icon>add</mat-icon>
    </button>
  }
</div>

