<div class='or-floorplan-scale-gradient' [ngStyle]='gradientStyle'>
  <div class='or-floorplan-scale'>
    <div class='text-center my-2'>
      Low
    </div>
    <div class='text-center my-2 text-white'>
      High
    </div>
  </div>
</div>
