<div id="user">
  <div class="or-content">
    <div class="or-content-header">
        <label class="or-inline-item"><h1>User Info</h1></label>
    </div>
    <div class="or-content-body">
      <app-user-info [user]="user"></app-user-info>
    </div>
  </div>
</div>
