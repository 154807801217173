import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { IEmergencyLogGroup } from '@app/shared/resources/emergency-log.resource';
import { SensorNode } from '@app/shared/models/sensor-node';
import { MatMenuModule } from '@angular/material/menu';
import { Building } from '@app/shared/models/building.interface';
import { MatIconModule } from '@angular/material/icon';

export interface DialogData {
  logs: IEmergencyLogGroup[];
  building: Building;
  nodeIds: number[];
  driverIds: number[];
  nodesMap: Map<number, SensorNode>;
  floorplanLinksMap: Map<number, string>;
  initiateTest: (testType: string, driverIds: number[]) => void;
  openFloorplan: (link: string) => void;
}
@Component({
  selector: 'app-elmt-reports-options-dialog',
  templateUrl: './elmt-reports-options-dialog.component.html',
  imports: [
    MatDialogTitle,
    MatDialogContent,
    CommonModule,
    MatDialogActions,
    MatButtonModule,
    MatMenuModule,
    MatIconModule
  ],
  styleUrl: './elmt-reports-options-dialog.component.scss'
})
export class ElmtReportsOptionsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ElmtReportsOptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  openFloorplan(link: string): void {
    this.data?.openFloorplan(link);
    this.dialogRef.close();
  }

  getFloorName(floorID: number) {
    return this.data.building.floors.find((f) => f.id === floorID).name;
  }

  initateFunctionalTest() {
    this.data?.initiateTest('FUNCTION', this.data?.driverIds);
  }

  initiateDurationTest() {
    this.data?.initiateTest('DURATION', this.data?.driverIds);
  }
}
