import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { LightingConfiguration } from '@app/shared/models/lighting-configuration';

@Injectable({
  providedIn: 'root'
})
export class LightingConfigurationResource {
  private readonly baseUrl: string;

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(environmentToken) private readonly environment: Environment
  ) {
    this.baseUrl = `${this.environment.apiUrl}${this.environment.apiPath}/lighting-config`;
  }

  getNode(nodeId: number, buildingId: number): Observable<LightingConfiguration> {
    return this.httpClient.get<LightingConfiguration>(`${this.baseUrl}?nodeId=${nodeId}&buildingId=${buildingId}`);
  }

  queryNode(nodeId: number, buildingId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}?nodeId=${nodeId}&buildingId=${buildingId}`, null);
  }

  updateConfig(configUrl: string, updateObj: { nodeId: number; value: number; buildingId: number }): Observable<void> {
    return this.httpClient.put<void>(`${this.baseUrl}/${configUrl}`, updateObj);
  }

  getLightingConfigsForFloor(floorId: number): Observable<LightingConfiguration[]> {
    return this.httpClient.get<LightingConfiguration[]>(`${this.baseUrl}/floor/${floorId}`);
  }
}
