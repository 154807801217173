import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
  HttpXsrfTokenExtractor
} from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '@environment';
import { Router } from '@angular/router';
import { catchError, filter, map } from 'rxjs/operators';
import { CookieService } from '@services/cookie.service';

export const httpInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const router = inject(Router);
  const requestWithCredentials = req.clone({ withCredentials: true });
  const getCurrentPath = (): string => {
    const urlTree = router.parseUrl(router.url);
    urlTree.queryParams = {};
    return urlTree.toString();
  };

  const handleUnauthenticatedError = (): void => {
    const path = getCurrentPath();
    const errorMsgWhenNavigatingFails = 'Error while navigating to login page: ';

    router
      .navigate(['/login'], { queryParams: { redirectUrl: path } })
      .catch((e) => console.error(errorMsgWhenNavigatingFails + e));
  };

  return next(requestWithCredentials).pipe(
    catchError((e) => {
      const isHandlingRequired = e.status === 401 || e.status === 404;
      if (e instanceof HttpErrorResponse && isHandlingRequired) {
        switch (e.status) {
          case 401:
            handleUnauthenticatedError();
            break;
          case 404:
            window.location.href = environment.apiUrl + '/error/404.html?base=' + environment.frontendUrl;
            break;
        }
      }
      throw e;
    })
  );
};

export const httpsCsrfInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const tokenExtractor = inject(HttpXsrfTokenExtractor);
  const cookieService = inject(CookieService);
  let requestToForward = req;
  const token = tokenExtractor.getToken();
  if (token !== null && req.url.startsWith(environment.apiUrl)) {
    requestToForward = req.clone({ setHeaders: { 'X-XSRF-TOKEN': token } });
  }
  return next(requestToForward).pipe(
    filter((event) => event instanceof HttpResponse),
    map((event: HttpResponse<any>) => {
      const newEvent = event.clone();
      if (req.url.startsWith(environment.apiUrl) && event.headers.get('X-AUTH-TOKEN') !== null) {
        cookieService.setCookie('XSRF-TOKEN', event.headers.get('X-AUTH-TOKEN'), 1, '/');
        newEvent.headers.set('XSRF-TOKEN', event.headers.get('X-AUTH-TOKEN'));
      }
      return newEvent;
    })
  );
};
