import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Rule } from '@app/shared/models/rule';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { DimLevel } from '@app/shared/models/dim-level';
import { Scene } from '@app/shared/models/scene';
import { Personality } from '@app/shared/models/personality';
import { DwellTime } from '@app/shared/models/dwell-time';
import { RuleType } from '@app/shared/models/rule-type';

@Component({
  selector: 'app-schedule-tile',
  templateUrl: './schedule-tile.component.html',
  styleUrl: './schedule-tile.component.scss',
  standalone: true,
  imports: [CommonModule, FormsModule, SharedComponentsModule]
})
export class ScheduleTileComponent {
  @Input() rule: Rule;
  public hasValue = true;

  getRuleName(): string {
    return RuleType.fromValue(this.rule.command).name;
  }

  getValue(): string {
    if (
      this.rule.command === RuleType.LOWLIGHT.toString() ||
      this.rule.command === RuleType.MAXLIGHT.toString() ||
      this.rule.command === RuleType.MINLIGHT.toString()
    ) {
      return DimLevel.fromValue(this.rule.value).label;
    } else if (
      this.rule.command === RuleType.DWELL.toString() ||
      this.rule.command === RuleType.LOWLIGHT_TIME.toString()
    ) {
      return DwellTime.fromValue(this.rule.value).label;
    } else if (this.rule.command === RuleType.PERSONALITY.toString()) {
      return Personality.fromValue(this.rule.value).label;
    } else if (this.rule.command === RuleType.SCENE.toString()) {
      return Scene.fromValue(this.rule.value).label;
    } else {
      return this.rule.value.toString();
    }
  }

  getFloorIds(): number[] {
    return this.rule.floors.map((floor) => floor.id);
  }

  getTagIds(): number[] {
    return this.rule.tags.map((tag) => tag.id);
  }
}
