<div class="inline-flex w-[16%]">
  <mat-label class="or-label or-form-rule-details-label">Set</mat-label>
  <mat-select placeholder="Select type" class="pt-1 pl-2" [(ngModel)]="rule.command"  data-cy="rule-type" (selectionChange)="onRuleChange()">
    @for(ruleType of  ruleOptions; track ruleType ) {
     <mat-option [value]="ruleType.toString()">{{ruleType.name}}</mat-option>
   }
  </mat-select>
</div>
<div  class="inline-flex w-[15%]">
  <mat-label class="or-label or-form-rule-details-label pl-4">to</mat-label>
  <mat-select placeholder="Select value" class="pt-1" [(ngModel)]="rule.value" data-cy="rule-value">
    @for(option of  options; track option ) {
      <mat-option [value]="option.value">{{option.label}}</mat-option>
    }
  </mat-select>
</div>
<div class="inline-flex align-middle ml-4">
  <button (click)="removeRule($event)" data-cy="remove-rule">
    <mat-icon color="warn" class="text-xl-center" matTooltip="Remove rule"   fontIcon="delete" />
  </button>

</div>
<div class='w-full'>
  <label class='or-label or-form-rule-details-label pt-1'>For</label>
  @if(tags$ | async; as tags) {
    <app-inline-tags
      [tags]="tags.tags"
      [isEditable]='!active'
      [displayTags]='rule.tags'
      [selectedTagIds]="getTagIds()"
      (updatedSelection)="selectedTagsChanged($event)"
    ></app-inline-tags>
  }
  </div>
<div class='w-full'>
  <label class='or-label or-form-rule-details-label pt-1'>On:</label>
  <app-inline-floors
      [isEditable]='!active' [floors]="floors" [selectedFloorIds]="getFloorIds()" (updatedSelection)="selectedFloorsChanged($event)">
  </app-inline-floors>
</div>

