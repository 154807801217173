export class StringUtils {
  public static truncate(text: string, maxLength = 20): string {
    return text.length > maxLength ? text.substr(0, maxLength) + '...' : text;
  }

  public static humanizeConstant(text: string): string {
    if (text != null) {
      let newText = text.split('_').join(' ').toLowerCase();
      newText = newText.charAt(0).toUpperCase() + newText.slice(1);
      return newText;
    }
  }

  public static toSentenceCase(text: string): string {
    return text.replace(new RegExp('[A-Z|a-z]', 'g'), (letter, offset) => {
      return offset === 0 ? letter.toUpperCase() : letter.toLowerCase();
    });
  }
}
