import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NodeListCardComponent } from '@components/node-list-card/node-list-card.component';
import { PanelToggleComponent } from '@components/panel-toggle/panel-toggle.component';
import { Building } from '@app/shared/models/building.interface';
import { NgClass } from '@angular/common';
import { HeaderService } from '@services/header.service';
import { Subject, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@services/user/user.service';
import { NavigationService } from '@services/navigation/navigation.service';
import { SelectableNode, SensorNode } from '@app/shared/models/sensor-node';
import { SensorNodeService } from '@services/sensor-node.service';
import { MatTooltip } from '@angular/material/tooltip';
import { LightingConfigPanelComponent } from '@app/lighting-configuration/lighting-config-panel/lighting-config-panel.component';
import { LightingConfigurationService } from '@services/lighting-configuration.service';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { CancelOnlyDialogData } from '@components/dialogs/confirm/confirm.component';
import { ToastService } from '@services/toast/toast.service';
import { takeUntil } from 'rxjs/operators';
import { LightingConfiguration } from '@app/shared/models/lighting-configuration';
import { FloorplanComponent } from '@components/floorplan/floorplan.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

const RETRIES = 7;
const DELAY_BETWEEN_RETRIES = 5000;

@Component({
  selector: 'app-lighting-configuration',
  standalone: true,
  imports: [
    MatFabButton,
    MatIcon,
    NodeListCardComponent,
    PanelToggleComponent,
    NgClass,
    MatTooltip,
    LightingConfigPanelComponent,
    FloorplanComponent,
    MatProgressSpinner
  ],
  templateUrl: './lighting-configuration.component.html',
  styleUrl: './lighting-configuration.component.scss'
})
export class LightingConfigurationComponent implements OnInit, OnDestroy {
  building: Building;
  floorId: number;
  isLeftColHidden = false;
  isRightColHidden = false;
  private pendingNodes: SensorNode[] = [];
  private timedOutNodes: SensorNode[] = [];
  selectedNode: SensorNode;
  private readonly destroyed$ = new Subject<void>();

  readonly sortByFields = {
    id: 'Id',
    address: 'Address',
    'lightingConfiguration.zone': 'Zone'
  };

  constructor(
    private readonly header: HeaderService,
    private readonly route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly toastService: ToastService,
    private readonly snService: SensorNodeService,
    private readonly navService: NavigationService,
    private readonly dialogService: ConfirmationDialogService,
    private readonly lightingConfigService: LightingConfigurationService
  ) {}

  ngOnInit(): void {
    this.header.showBuildingsMenu();
    this.header.showSiteMenu();
    this.header.showUserMenu();
    this.header.showSessionMenu();
    this.header.showFloorsMenu();
    this.setBuildingIdAndFloorId();
    this.snService
      .getCurrentFloorSelectedEntities$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((sensorNodes: SensorNode[]) => {
        if (sensorNodes.length === 1) {
          this.selectedNode = sensorNodes[0];
        } else if (sensorNodes.length === 0) {
          this.selectedNode = null;
        } else if (sensorNodes.length > 0) {
          this.dialogService.error(
            new CancelOnlyDialogData('Please select only one sensor node', 'Invalid Node Selection')
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private setBuildingIdAndFloorId(): void {
    this.route.params
      .pipe(
        switchMap((params) => {
          this.floorId = params.floorId;
          return this.userService.getBuilding(params.buildingId);
        })
      )
      .subscribe((building) => {
        this.navService.initNavigation(window.location.href, building);
        this.building = building;
      });
  }

  nodeClick(node: SelectableNode): void {
    this.snService.toggleEntitySelection(node, false);
    this.snService.updateQueryParams();
  }

  querySelectedNode(): void {
    if (!this.selectedNode) {
      const errorMessage = new CancelOnlyDialogData(
        'At least one node must be selected to be able to operate!',
        'Invalid Node Selection'
      );
      this.dialogService.open(errorMessage).subscribe((_) => {});
      return;
    }
    this.addToPendingMultipleNodes(this.selectedNode);
    this.addToTimedOutNodes(this.selectedNode);
    const lastUpdatedTime = this.selectedNode.lightingConfiguration?.findLastUpdatedTime();

    this.lightingConfigService.queryNode(this.selectedNode, this.building.id).subscribe({
      next: () => {
        this.getNodeWithRetries(this.selectedNode, lastUpdatedTime, RETRIES);
      },
      error: (e) => {
        console.error(e);
        this.toastService.error({
          message: `An error happened for the sensor node (${this.selectedNode.address16}) query command!`,
          dataCy: 'config-query-error-toast'
        });
        this.removeFromPendingNodes(this.selectedNode);
        this.removeFromTimedOutNodes(this.selectedNode);
      }
    });
  }

  private addToPendingMultipleNodes(node: SensorNode): void {
    if (!this.isNodePending(node)) {
      this.pendingNodes.push(node);
    }
  }

  private addToTimedOutNodes(node: SensorNode): void {
    if (this.isNodePending(node)) {
      this.timedOutNodes.push(node);
    }
  }

  private removeFromPendingNodes(node: SensorNode): void {
    this.pendingNodes = this.pendingNodes.filter((n) => n.id !== node.id);
  }

  private removeFromTimedOutNodes(node: SensorNode): void {
    this.timedOutNodes = this.timedOutNodes.filter((n) => n.id !== node.id);
  }

  public isNodePending(node: SensorNode): boolean {
    return node && this.pendingNodes.filter((n) => n.id === node.id).length > 0;
  }

  private getNodeWithRetries(node: SensorNode, lastUpdatedTime: Date, retries: number): void {
    let retry = false;
    if (retries <= 0) {
      if (this.selectedNode.lightingConfiguration?.isPartiallyUpdated(lastUpdatedTime)) {
        this.toastService.info({
          message: `The sensor node (${SensorNode.from(node).address16}) was partially updated.`,
          dataCy: 'partially-update-toast'
        });
      } else {
        this.toastService.info({
          message: `The sensor node (${SensorNode.from(node).address16}) query time expired with no result.`,
          dataCy: 'time-expired'
        });
      }
      this.removeFromPendingNodes(node);
      this.removeFromTimedOutNodes(node);
      return;
    }
    this.lightingConfigService.getNode(node, this.building.id).subscribe({
      next: (result) => {
        result = LightingConfiguration.fromResponse(result);
        if (result && this.isQueryResultComplete(result, node.nodeType, lastUpdatedTime)) {
          this.removeFromPendingNodes(node);
          this.removeFromTimedOutNodes(node);
          this.updateNodeAndNotify(node, result);
          this.toastService.info({
            message: `The data was updated for sensor node (${SensorNode.from(node).address16})`,
            dataCy: 'node-updated-toast'
          });
        } else {
          retry = true;
          if (result) {
            this.updateNodeAndNotify(node, result);
          }
        }
        if (retry) {
          setTimeout(() => {
            this.getNodeWithRetries(node, lastUpdatedTime, retries - 1);
          }, DELAY_BETWEEN_RETRIES);
        }
      },
      error: (err) => {
        console.error(err);
        this.toastService.error({
          message: `Error in communication with the portal for query command: Sensor node (${
            SensorNode.from(node).address16
          })!`,
          dataCy: 'config-query-error-toast'
        });
        this.removeFromPendingNodes(node);
        this.removeFromTimedOutNodes(node);
      }
    });
  }

  private updateNodeAndNotify(node: SensorNode, result: LightingConfiguration): void {
    node = SensorNode.from(JSON.parse(JSON.stringify(node)));
    node.lightingConfiguration = LightingConfiguration.fromResponse(result);
    this.snService.updateEntitySelection(node);
    this.snService.updateCurrentFloorNodes(node);
  }

  get isSelectedNodeReady(): boolean {
    return (
      this.selectedNode &&
      !this.isNodePending(this.selectedNode) &&
      this.selectedNode.properlyMapped &&
      this.selectedNode.connected &&
      !this.selectedNode.isPassiveNode
    );
  }

  private isQueryResultComplete(config: LightingConfiguration, nodeType: string, lastTime: Date): boolean {
    // HIM84, HCD405, HCD038 nodes do not support zone configuration at present
    const isZoneUpdated = config.zoneUpdatedAt != null && config.zoneUpdatedAt > lastTime;

    return (
      (LightingConfiguration.isZoneSupportedNode(nodeType) ? isZoneUpdated : true) &&
      config.maxLightLevelUpdatedAt != null &&
      config.maxLightLevelUpdatedAt > lastTime &&
      config.minLightLevelUpdatedAt != null &&
      config.minLightLevelUpdatedAt > lastTime &&
      config.lowLightLevelUpdatedAt != null &&
      config.lowLightLevelUpdatedAt > lastTime &&
      config.dwellTimeUpdatedAt != null &&
      config.dwellTimeUpdatedAt > lastTime &&
      config.lowLightTimeUpdatedAt != null &&
      config.lowLightTimeUpdatedAt > lastTime &&
      config.scene != null &&
      config.scene.updatedAt != null
    );
    // as the scene timestamp is updated differently it shouldn't be engaged;
  }

  get nodeListPanelClass(): string {
    if (this.isLeftColHidden) {
      return 'hidden';
    } else if (this.isRightColHidden) {
      return 'col-span-6';
    } else {
      return 'col-span-4';
    }
  }

  get floorPanelClass(): string {
    if (this.isLeftColHidden && this.isRightColHidden) {
      return 'col-span-12';
    } else if (this.isLeftColHidden) {
      return 'col-span-10';
    } else if (this.isRightColHidden) {
      return 'col-span-6';
    } else {
      return 'col-span-6 ';
    }
  }

  get configPanelClass(): string {
    return `${this.isRightColHidden ? 'hidden' : 'col-span-2'}`;
  }

  toggleLeft(val: boolean): void {
    this.isLeftColHidden = val;
  }

  toggleRight(val: boolean): void {
    this.isRightColHidden = val;
  }
}
