export enum ServiceLevelType {
  EXISTING = 'EXISTING',
  SMART_LIGHT = 'SMART_LIGHT',
  SMART_INSIGHT = 'SMART_INSIGHT',
  SMART_PLUS = 'SMART_PLUS',
  SMART_START = 'SMART_START'
}

export function serviceLevelFromValue(value: string): ServiceLevelType {
  return value.split(' ').join('_').toUpperCase() as ServiceLevelType;
}

export function valueFromServiceLevel(serviceLevel: ServiceLevelType): string {
  return serviceLevel
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export function getServiceLevelTypes(): ServiceLevelType[] {
  return Object.values(ServiceLevelType);
}
