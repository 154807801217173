import { TimeScale } from '@app/shared/time/time-scale';
import { Resolution } from '@app/analytics/metric-widget/data-objects/resolution';
import {
  format,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  parse,
  setDate,
  setHours,
  setMinutes,
  setMonth,
  setYear
} from 'date-fns';

export function getOrdinalSuffix(day: number): string {
  if (day > 3 && day < 21) return `${day}th`;
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
}

export function createAllScales(): TimeScale[] {
  return [
    new TimeScale(
      Resolution.YEARLY,
      (value) => format(parse(value.toString(), 'M', new Date()), 'MMM'),
      'years',
      'yyyy',
      'yyyy',
      (date, value) => setYear(date, value),
      (date) => getYear(date),
      (dates) => dates.years,
      (dates, values) => (dates.years = values),
      true
    ),
    new TimeScale(
      Resolution.MONTHLY,
      (value) => {
        return getOrdinalSuffix(value);
      },
      'months',
      'MMM',
      'MMMM yyyy',
      (date, value) => setMonth(date, value - 1),
      (date) => getMonth(date) + 1,
      (dates) => dates.months,
      (dates, values) => (dates.months = values),
      true
    ),
    new TimeScale(
      Resolution.DAILY,
      (value) => format(parse(value.toString(), 'H', new Date()), 'ha'),
      'days',
      'do',
      'do MMM yyyy',
      (date, value) => setDate(date, value),
      (date) => getDate(date),
      (dates) => dates.days,
      (dates, values) => (dates.days = values),
      true
    ),
    new TimeScale(
      Resolution.HOURLY,
      (value) => format(parse(value.toString(), 'mm', new Date()), 'mm'),
      'hours',
      'ha',
      'do MMM ha',
      (date, value) => setHours(date, value),
      (date) => getHours(date),
      (dates) => dates.hours,
      (dates, values) => (dates.hours = values)
    ),
    new TimeScale(
      Resolution.FIVE_MINUTES,
      () => '',
      'minutes',
      'ha:mm',
      'do MMM ha:mm',
      (date, value) => setMinutes(date, value),
      (date) => getMinutes(date),
      (dates) => dates.minutes,
      (dates, values) => (dates.minutes = values)
    )
  ];
}
