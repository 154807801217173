<nav mat-tab-nav-bar class="tellus-mat-tab" [tabPanel]="tabPanel">
  @for(tab of tabs$ | async; track tab.name) {
    <a mat-tab-link [routerLink]="tab.path" routerLinkActive #rla="routerLinkActive"
       [isFeatureAvailable]="'tab-' + tab.name"
       [active]="rla.isActive" [attr.data-cy]='tab.dataCy'>
      {{ tab.name }}
    </a>
  }
</nav>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
