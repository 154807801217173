import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { combineLatestWith, mergeMap, Observable, of, Subject } from 'rxjs';
import { Building } from '@app/shared/models/building.interface';
import { HeaderService } from '@app/shared/services/header.service';
import { UserService } from '@app/shared/services/user/user.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { TagService } from '@app/shared/services/tag.service';
import { DisplayTags, orderByTagName } from '@app/shared/models/tag.interface';
import { Floor, floorCompare } from '@app/shared/models/floor.interface';
import { EmergencyLightingScheduleService } from '@app/shared/services/emergency-lighting/emergency-lighting-schedule.service';
import { scheduleCompare } from '@app/shared/models/elmt-schedule-dto.interface';
import { HotToastService } from '@ngneat/hot-toast';
import { EmergencyLightingTestType } from '@app/shared/models/emergency-lighting-test-type';
import { EmergencyLightingTestState } from '@app/shared/models/emergency-lighting-test-state';
import { IEmergencyLightingSchedule } from '@app/shared/models/emergency-lighting-schedule.interface';
import { EmergencyLogService } from '@services/emergency-log.service';
import { SensorNodeService } from '@services/sensor-node.service';
import { EmergencyLogPageFilter } from '@app/shared/resources/emergency-log.resource';
import { TimeUtils } from '@app/shared/utils/time.utils';
import { TimezoneUtils } from '@app/shared/utils/timezoneUtils';
import { ElmtReportsTableComponent } from '@app/emergency-lighting/reports/elmt-reports-table/elmt-reports-table.component';
import { EmergencyLightingSchedule } from '@app/shared/models/emergency-lighting-schedule';
import { ElmtSelectedFiltersService } from '@app/emergency-lighting/elmt-tests-filter/elmt-selected-filters.service';
import { EmergencyReportsDataSource } from '@app/emergency-lighting/reports/emergency-reports-data-source';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { AsyncPipe, NgIf } from '@angular/common';
import { ElmtTestsFilterComponent } from '../elmt-tests-filter/elmt-tests-filter.component';
import { ElmtReportsTableComponent as ElmtReportsTableComponent_1 } from './elmt-reports-table/elmt-reports-table.component';

const REPORTS_TAB_INDEX = 0;

export type FilterCriteria = {
  scheduleIds: number[] | null;
  floorIds: number[] | null;
  tagIds: number[] | null;
  testTypes: EmergencyLightingTestType[] | null;
  testStates: EmergencyLightingTestState[] | null;
  startDateUTC: Date | null;
  endDateUTC: Date | null;
};

@Component({
  selector: 'app-emergency-lighting-reports',
  templateUrl: './emergency-lighting-reports.component.html',
  styleUrls: ['./emergency-lighting-reports.component.scss'],
  imports: [NgIf, ElmtTestsFilterComponent, MatTabGroup, MatTab, ElmtReportsTableComponent_1, AsyncPipe]
})
export class EmergencyLightingReportsComponent implements OnInit, OnDestroy {
  vm$: Observable<{
    building: Building;
    tags: DisplayTags;
    schedules: IEmergencyLightingSchedule[];
  }>;
  floors: Floor[];
  logsDataSource: EmergencyReportsDataSource;
  building: Building;

  @ViewChild('loadingTemplate', { static: true })
  loadingTemplate: TemplateRef<any>;

  @ViewChild('successTemplate', { static: true })
  successTemplate: TemplateRef<any>;

  @ViewChild('errorTemplate', { static: true })
  errorTemplate: TemplateRef<any>;

  @ViewChild('elmtLogsTable') elmtLogsTable: ElmtReportsTableComponent;

  @Output() filterApplied = new EventEmitter<void>();

  private destroy$ = new Subject<void>();
  private readonly DEFAULT_PAGE_NUMBER = 0;
  private readonly DEFAULT_PAGE_SIZE = 20;
  pageNumber = this.DEFAULT_PAGE_NUMBER;
  pageSize = this.DEFAULT_PAGE_SIZE;
  activeTab = REPORTS_TAB_INDEX;

  constructor(
    private readonly header: HeaderService,
    private readonly user: UserService,
    private readonly router: Router,
    private readonly tagService: TagService,
    private scheduleService: EmergencyLightingScheduleService,
    private toast: HotToastService,
    private readonly route: ActivatedRoute,
    private logsService: EmergencyLogService,
    private nodeService: SensorNodeService,
    private elmtSelectedFiltersService: ElmtSelectedFiltersService
  ) {
    this.router.events.pipe(filter((e) => e instanceof NavigationStart)).subscribe(() => {
      this.initComponent();
    });
  }

  ngOnInit(): void {
    this.header.hideFloorsMenu();
    this.initComponent();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initComponent(): void {
    const manualTest: any = {
      id: 0,
      name: 'Manual Test',
      testsType: '',
      tagIds: []
    };
    this.route.params.subscribe((params) => {
      const { buildingId } = params;
      this.vm$ = this.user
        .getBuilding(buildingId)
        .pipe(
          mergeMap((building) => {
            this.building = building;
            this.floors = [...building.floors].sort(floorCompare);
            const tags$ = this.tagService.getTagsForBuildingId(buildingId, true).pipe(
              combineLatestWith(this.tagService.getTagsForBuildingId(buildingId, false)),
              map((tags) => {
                return {
                  displayTags: tags[1].sort(orderByTagName),
                  tags: tags[0].sort(orderByTagName)
                };
              })
            );
            const schedules$ = this.scheduleService.buildingELMTSchedules$.pipe(
              mergeMap((schedules) => {
                if (schedules.length === 0) {
                  return this.scheduleService
                    .getSchedules(building.id)
                    .pipe(map((schedules) => schedules.map((schedule) => EmergencyLightingSchedule.fromDto(schedule))));
                } else {
                  return of(schedules);
                }
              }),
              map((schedules) => [...schedules].sort(scheduleCompare))
            );
            return tags$.pipe(takeUntil(this.destroy$), combineLatestWith(schedules$, of(building)));
          }),
          map(([tags, schedules, building]) => {
            schedules = schedules.filter((schedule) => {
              return schedule.hasRan;
            });
            schedules.push(manualTest);
            this.loadLogsTable();
            return {
              tags,
              schedules,
              building
            };
          })
        )
        .pipe(
          this.toast.observe({
            loading: this.loadingTemplate,
            success: this.successTemplate,
            error: this.errorTemplate
          })
        );
    });
  }

  onFilterClick(): void {
    this.filterApplied.emit();
    this.loadLogsTable();
    this.triggerChildToggleSelectAllLogs(false);
  }

  loadLogsTable(): void {
    this.logsDataSource =
      this.logsDataSource ||
      new EmergencyReportsDataSource(
        this.logsService,
        this.nodeService.getNodeDataForElmtLogs({
          buildingId: this.building?.id,
          elmt: true,
          includeUnmapped: false
        }),
        this.user
      );
    this.pageNumber = this.DEFAULT_PAGE_NUMBER;
    this.filterLogs(this.pageNumber, this.pageSize, this.activeTab === REPORTS_TAB_INDEX);
  }

  filterLogs(pageNumber: number, pageSize: number, isReport: boolean = true): void {
    const filterParams: EmergencyLogPageFilter = {
      pageNumber,
      pageSize,
      filter: this.elmtSelectedFiltersService.getMappedFiltersToReportQuery(this.building?.id)
    };
    const filter = filterParams.filter;
    if (filter.startDate != null) {
      filter.startDate = this.shiftToUserDate(filter.startDate);
    }

    if (filter.endDate != null) {
      filter.endDate = this.shiftToUserDate(filter.endDate);
    }
    if (isReport) {
      filterParams.filter.latestLogs = true;
    }
    this.logsDataSource.loadEmergencyLogs(filterParams);
  }

  private shiftToUserDate(date: Date): Date {
    const userDate = date.valueOf();
    const buildingDate = TimeUtils.convertTimezone(
      date,
      TimezoneUtils.mapUtcToKnownTimezone(this.building?.timeZone)
    ).valueOf();
    const shiftedUserDate = userDate + (userDate - buildingDate);
    return new Date(shiftedUserDate);
  }

  triggerChildToggleSelectAllLogs(mode: boolean): void {
    this.elmtLogsTable?.toggleSelectAllLogs(mode);
  }

  tabChange(event: MatTabChangeEvent): void {
    this.pageSize = this.DEFAULT_PAGE_SIZE;
    this.pageNumber = this.DEFAULT_PAGE_NUMBER;
    this.activeTab = event.index;
    if (event.index === REPORTS_TAB_INDEX) {
      this.filterLogs(this.pageNumber, this.pageSize, true);
    } else {
      this.filterLogs(this.pageNumber, this.pageSize, false);
    }
  }
}
