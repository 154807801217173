import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleService } from '@services/schedules.service';
import { NotificationBlockComponent, StatusClass } from '@components/notification-block/notification-block.component';
import { ScheduleSummaryComponent } from '@app/schedules/schedule-summary/schedule-summary.component';
import { Schedule } from '@app/shared/models/schedule';
import { MatFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { ToastService } from '@services/toast/toast.service';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { MatIcon } from '@angular/material/icon';
import { IScheduleDto } from '@app/shared/models/schedule-dto';
import { Building } from '@app/shared/models/building.interface';

@Component({
  selector: 'app-schedules-list',
  templateUrl: './schedules-list.component.html',
  styleUrl: './schedules-list.component.scss',
  standalone: true,
  imports: [CommonModule, NotificationBlockComponent, ScheduleSummaryComponent, MatFabButton, MatIcon, MatTooltip]
})
export class SchedulesListComponent implements OnInit, OnChanges {
  @Input() building: Building;
  StatusClass = StatusClass;
  schedules: Schedule[];
  showNewForm = false;
  isBusy = true;
  isLoaded$ = new BehaviorSubject(false);

  constructor(private readonly scheduleService: ScheduleService, private readonly toastService: ToastService) {}

  ngOnInit(): void {
    this.loadSchedules();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.building.previousValue && changes.building.previousValue.id !== this.building.id) {
      this.loadSchedules();
    }
  }

  get isDataReady$(): Observable<boolean> {
    return this.isLoaded$.asObservable();
  }

  private loadSchedules(): void {
    this.isBusy = true;
    this.isLoaded$.next(false);
    forkJoin([
      this.scheduleService.getSchedules(this.building.id),
      this.scheduleService.getEditableIds(this.building.id)
    ]).subscribe({
      next: (res: [[IScheduleDto], [number]]) => {
        const scheduleDtos = res[0];
        scheduleDtos.forEach((schedule) => {
          schedule.editable = res[1].includes(schedule.id);
        });
        const schedules = scheduleDtos.map((schedule) => Schedule.fromDto(schedule));
        this.init(schedules);
      },
      error: () => {
        this.schedules = [];
        this.toastService.error({ message: 'Error loading Schedules', dataCy: 'load-schedule-error-toast' });
      }
    });
  }

  private init(schedules: Schedule[]): void {
    this.schedules = schedules;
    this.isBusy = false;
    this.isLoaded$.next(true);
  }

  createNew(): void {
    this.showNewForm = true;
  }

  onMoveUp(schedule: Schedule): void {
    this.scheduleService.moveUp(schedule).subscribe({
      next: () => {
        this.loadSchedules();
      },
      error: () => {
        this.toastService.error({
          message: 'Error occurred while moving up a schedule',
          dataCy: 'move-schedule-error-toast'
        });
      }
    });
  }

  onMoveDown(schedule: Schedule): void {
    this.scheduleService.moveDown(schedule).subscribe({
      next: () => {
        this.loadSchedules();
      },
      error: () => {
        this.toastService.error({
          message: 'Error occurred while moving down a schedule',
          dataCy: 'move-schedule-error-toast'
        });
      }
    });
  }

  onDeleteSchedule(): void {
    this.loadSchedules();
  }

  reloadSchedules(): void {
    this.showNewForm = false;
    this.loadSchedules();
  }
}
