import { ApplicationConfig } from '@angular/core';
import { provideRouter, withRouterConfig } from '@angular/router';
import { routes } from './app.routes';
import { environment, environmentToken } from '@environment';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { enAU } from 'date-fns/locale';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { httpInterceptor, httpsCsrfInterceptor } from '@app/shared/http-interceptors';
import { AbstractUnmappedNodesDataSource, UnmappedNodesDataSource } from '@app/shared/models/unmapped-nodes-datasource';
import { MappingService } from '@services/mapping.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
    { provide: environmentToken, useValue: environment },
    {
      provide: MAT_DATE_LOCALE,
      useValue: enAU
    },
    provideDateFnsAdapter(),
    provideHotToastConfig(),
    provideHttpClient(withInterceptors([httpInterceptor, httpsCsrfInterceptor])),
    provideAnimations(),
    {
      provide: AbstractUnmappedNodesDataSource,
      useFactory: (mappingService: MappingService) => new UnmappedNodesDataSource(mappingService),
      deps: [MappingService]
    }
  ]
};
