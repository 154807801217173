import { ICircadianCurve } from '@app/shared/models/circadian-curve.interface';
import { Tag } from '@app/shared/models/tag.interface';
import { Floor } from '@app/shared/models/floor.interface';

export class CircadianCurve implements ICircadianCurve {
  public id?: number;
  public name?: string;
  public colourTempValues: number[];
  public lightLevelValues: number[];
  public active: boolean;
  public enabled: boolean;
  public editable: boolean;
  public alwaysActive?: boolean;
  public buildingId?: number;
  public tags: Tag[];
  public floors: Floor[];
  public timezone: string;

  constructor(params: ICircadianCurve = {} as ICircadianCurve) {
    this.name = params.name || 'Standard Curve';
    this.colourTempValues = params.colourTempValues;
    this.lightLevelValues = params.lightLevelValues;
    this.active = params.active;
    this.enabled = params.enabled;
    this.editable = params.editable;
    this.alwaysActive = params.alwaysActive || false;
    this.buildingId = params.buildingId;
    this.tags = params.tags;
    this.floors = params.floors;
    this.timezone = params.timezone;
  }

  public static clone(other: ICircadianCurve): ICircadianCurve {
    return new this({
      id: other.id,
      name: other.name,
      colourTempValues: other.colourTempValues,
      lightLevelValues: other.lightLevelValues,
      active: other.active,
      enabled: other.enabled,
      editable: other.editable,
      alwaysActive: other.alwaysActive,
      buildingId: other.buildingId,
      tags: other.tags,
      floors: other.floors,
      timezone: other.timezone
    });
  }

  public static generateStandardCircadianCurve(name?: string): ICircadianCurve {
    const standardCurve: ICircadianCurve = {
      name,
      // The following values have been chosen based on OTP-1161:
      // colourTempValues: [2700, 2700, 2700, 2700, 2700, 2700,
      // 4000, 6000, 5500, 5500, 5000, 4500,
      // 4000, 4000, 4000, 4000, 4000, 4000,
      // 3000, 2700, 2700, 2700, 2700, 2700],
      //
      // lightLevelValues: [50, 50, 50, 50, 50, 50,
      // 200, 650, 650, 650, 650, 650,
      // 400, 200, 200, 200, 200, 200,
      // 100, 50, 50, 50, 50, 50],

      // The following values are adjusted values of the above table based on
      // https://organicresponse.atlassian.net/wiki/spaces/RND/pages/1365671954/Circadian+Curve+Messaging:
      colourTempValues: [
        2690, 2690, 2690, 2690, 2690, 2690, 4030, 5950, 5560, 5560, 5000, 4460, 4030, 4030, 4030, 4030, 4030, 4030,
        3010, 2690, 2690, 2690, 2690, 2690
      ],

      // For the sake of simplicity the following values have been chosen for now:
      lightLevelValues: [
        20, 20, 20, 20, 20, 20, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 20, 20, 20, 20, 20, 20
      ],
      active: false,
      enabled: true,
      editable: true,
      tags: [] as Tag[],
      floors: [] as Floor[],
      timezone: 'Australia/Melbourne'
    };
    return new this(standardCurve);
  }
}
