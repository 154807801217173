import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FeatureService } from '@app/shared/services/feature.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({ selector: '[isFeatureAvailable]' })
export class IsFeatureAvailableDirective implements OnInit, OnDestroy {
  @Input('isFeatureAvailable') public key: string;

  private readonly onDestroy$ = new Subject<void>();

  constructor(
    private readonly featureService: FeatureService,
    private readonly renderer: Renderer2,
    private readonly el: ElementRef
  ) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngOnInit(): void {
    this.featureService
      .isAvailable(this.key)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isAvailable) => {
        if (!isAvailable) {
          this.removeFeature();
        }
      });
  }

  removeFeature(): void {
    const parent = this.renderer.parentNode(this.el.nativeElement);
    this.renderer.removeChild(parent, this.el.nativeElement);
  }
}
