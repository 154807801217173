export class RepeatUnitString {
  constructor(
    public value: RepeatUnitStrings_,
    public displayName: string,
    public displayNamePlural: string,
    public recurringName: string
  ) {}

  public static NONE = new RepeatUnitString('NONE', 'day', 'days', 'Daily');
  public static DAY = new RepeatUnitString('DAY', 'day', 'days', 'Daily');
  public static WEEK = new RepeatUnitString('WEEK', 'week', 'weeks', 'Weekly');
  public static MONTH = new RepeatUnitString('MONTH', 'month', 'months', 'Monthly');
  public static YEAR = new RepeatUnitString('YEAR', 'year', 'years', 'Yearly');

  public static getAll(): RepeatUnitString[] {
    return [RepeatUnitString.DAY, RepeatUnitString.WEEK, RepeatUnitString.MONTH, RepeatUnitString.YEAR];
  }

  public toString(): string {
    return this.value;
  }

  public equals(other: RepeatUnitString): boolean {
    return this.equalsString(other.value);
  }

  public equalsString(other: string): boolean {
    return other !== null && other === this.value;
  }
}

type RepeatUnitStrings_ = 'NONE' | 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';
