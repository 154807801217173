export class WeekdaySelection {
  constructor(
    private mon: boolean = false,
    private tue: boolean = false,
    private wed: boolean = false,
    private thu: boolean = false,
    private fri: boolean = false,
    private sat: boolean = false,
    private sun: boolean = false
  ) {}

  public static clone(other: WeekdaySelection): WeekdaySelection {
    if (other) {
      return new WeekdaySelection(other.mon, other.tue, other.wed, other.thu, other.fri, other.sat, other.sun);
    } else {
      return null;
    }
  }

  public toggleActive(day: number): void {
    switch (day) {
      case 1:
        this.mon = !this.mon;
        return;
      case 2:
        this.tue = !this.tue;
        return;
      case 3:
        this.wed = !this.wed;
        return;
      case 4:
        this.thu = !this.thu;
        return;
      case 5:
        this.fri = !this.fri;
        return;
      case 6:
        this.sat = !this.sat;
        return;
      case 7:
        this.sun = !this.sun;
        return;
    }
  }

  public isActive(day: number): boolean {
    switch (day) {
      case 1:
        return this.mon;
      case 2:
        return this.tue;
      case 3:
        return this.wed;
      case 4:
        return this.thu;
      case 5:
        return this.fri;
      case 6:
        return this.sat;
      case 7:
        return this.sun;
    }
  }

  public numberOfDaysActive(): number {
    let counter = 0;
    if (this.mon) {
      counter++;
    }
    if (this.tue) {
      counter++;
    }
    if (this.wed) {
      counter++;
    }
    if (this.thu) {
      counter++;
    }
    if (this.fri) {
      counter++;
    }
    if (this.sat) {
      counter++;
    }
    if (this.sun) {
      counter++;
    }
    return counter;
  }
}
