import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MobileToken } from '@app/shared/models/mobile-token.interface';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MobileTokenService } from '@services/mobile-token/mobile-token.service';
import { Building } from '@app/shared/models/building.interface';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { ToastService } from '@services/toast/toast.service';
import { ConfirmDialogData } from '@components/dialogs/confirm/confirm.component';
import { NgClass, NgIf } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-form-mobile-token-details',
  templateUrl: './form-mobile-token-details.component.html',
  styleUrls: ['./form-mobile-token-details.component.scss'],
  imports: [ReactiveFormsModule, NgClass, NgIf, MatButton]
})
export class FormMobileTokenDetailsComponent implements OnInit, OnChanges {
  @Input() token: MobileToken;
  @Input() index: number;
  @Input() isBusy = true;
  @Input() isNew = false;
  @Input() isDeactivated = false;
  @Input() building: Building;

  tokenForm: FormGroup;

  @Output() valueChanged = new EventEmitter<void>();

  constructor(
    private fb: FormBuilder,
    private tokenService: MobileTokenService,
    private dialogService: ConfirmationDialogService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.initForm(this.token);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isNew) {
      this.initForm(null);
    }
  }

  initForm(token: MobileToken): void {
    this.tokenForm = this.fb.group({
      id: [token ? token.id : ''],
      token: [{ value: token ? token.token : '', disabled: true }, Validators.required],
      description: [
        { value: token ? token.description : '', disabled: this.isBusy || this.isDeactivated },
        Validators.required
      ],
      tokenStatus: [token ? token.tokenStatus : '']
    });
  }

  createToken(): void {
    this.tokenService.createToken(this.building.id, this.tokenForm.value).subscribe({
      next: (res: MobileToken) => {
        this.toast.success({
          message: `Successfully created token: ${res.token}`,
          dataCy: `create-success-toast`
        });
        this.valueChanged.emit();
      },
      error: () => {}
    });
  }
  updateToken(): void {
    this.tokenService.updateToken(this.building.id, this.tokenForm.value).subscribe({
      next: () => {
        this.toast.success({
          message: `Successfully updated token: ${this.token.token}`,
          dataCy: `update-success-toast`
        });
        this.valueChanged.emit();
      },
      error: () => {}
    });
  }
  resetToken(): void {
    const data = new ConfirmDialogData(`Are you sure you want to reset the token?`);
    this.dialogService.open(data).subscribe((confirm) => {
      if (confirm) {
        this.tokenForm.reset(this.token);
      }
    });
  }
  deactivateToken(): void {
    const data = new ConfirmDialogData(`Are you sure you want to deactivate the token: "${this.token.token}"?`);

    this.dialogService.open(data).subscribe((confirm) => {
      if (confirm) {
        this.tokenService.deactivateToken(this.building.id, this.token.id).subscribe({
          next: () => {
            this.toast.success({
              message: `Successfully deactivated token: ${this.token.token}`,
              dataCy: `delete-success-toast`
            });
            this.valueChanged.emit();
          },
          error: () => this.handleError(`Failed deactivate token: ${this.token.token}. Please try again.`)
        });
      }
    });
  }

  handleError(message: string): void {
    this.toast.error({
      message,
      dataCy: `send-error-toast`
    });
  }
}
