div#widgets-grid {
  height: 100%;
  max-height: calc(100% - 40px); // 40px is the height of add button
  .grid-item {
    @apply rounded-xl shadow-lg;
    &.gridster-item-moving {
      @apply shadow-xl;
    }
  }
}

div#widget-container {
  height: 100%;
}

.dashboard-tabs-container {
  max-height: 100%
}
