import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { GlobalAuthorityRequestsDatasource } from '@app/global-administration/user-information/global-authority-requests/global-authroity-requests.datasource';
import { GlobalAuthorityRequestService } from '@services/user-global-authority-request/global-authroity-request.service';
import { MatPaginator } from '@angular/material/paginator';
import { merge } from 'rxjs';
import { GlobalAuthorityRequest, Status } from '@app/shared/models/user-global-authority-request-interface';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { ToastService } from '@services/toast/toast.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from '@angular/material/table';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-global-authority-requests',
  templateUrl: './global-authority-requests.component.html',
  styleUrl: './global-authority-requests.component.scss',
  imports: [
    NgIf,
    MatProgressSpinner,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatButton,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatPaginator,
    AsyncPipe
  ]
})
export class GlobalAuthorityRequestsComponent implements AfterViewInit {
  displayedColumns = [
    'id',
    'avatar',
    'name',
    'emailAddress',
    'requestGlobalAuthority',
    'projectName',
    'managingCompanyName',
    'requestDate',
    'actions'
  ];
  dataSource: GlobalAuthorityRequestsDatasource;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private globalAuthorityRequestService: GlobalAuthorityRequestService, private toast: ToastService) {}

  ngOnInit(): void {
    this.dataSource = new GlobalAuthorityRequestsDatasource(this.globalAuthorityRequestService);
    this.dataSource.loadGlobalAuthorityRequests(0, 10);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    merge(this.sort.sortChange, this.paginator.page)
      .pipe()
      .subscribe(() => this.loadRequestsPage());
  }

  loadRequestsPage(): void {
    this.dataSource.loadGlobalAuthorityRequests(this.paginator.pageIndex, this.paginator.pageSize);
  }

  acceptRequest(globalAuthorityRequest: GlobalAuthorityRequest): void {
    this.globalAuthorityRequestService
      .setGlobalAuthorityRequestStatus(globalAuthorityRequest.id, globalAuthorityRequest.status, Status.APPROVED)
      .subscribe({
        next: (status) => {
          this.loadRequestsPage();
          this.toast.success({
            message: `Successfully granted ${globalAuthorityRequest.globalAuthority.toString()} to ${
              globalAuthorityRequest.user.name
            }`,
            dataCy: `grant-global-authority-success-toast`
          });
        },
        error: (error) => {
          console.error('Error approving request', error);
          const message = error.message.includes('400')
            ? 'Request was not in the expected state, please refresh the page'
            : error.message;
          this.toast.warning({
            message: `Failed to grant ${globalAuthorityRequest.globalAuthority.toString()} to ${
              globalAuthorityRequest.user.name
            } with error: ${message}`,
            dataCy: `grant-global-authority-failed-toast`
          });
        }
      });
  }

  declineRequest(globalAuthorityRequest: GlobalAuthorityRequest): void {
    this.globalAuthorityRequestService
      .setGlobalAuthorityRequestStatus(globalAuthorityRequest.id, globalAuthorityRequest.status, Status.DENIED)
      .subscribe({
        next: (status) => {
          this.loadRequestsPage();
          this.toast.success({
            message: `Successfully declined ${globalAuthorityRequest.globalAuthority.toString()} to ${
              globalAuthorityRequest.user.name
            }`,
            dataCy: `decline-global-authority-success-toast`
          });
        },
        error: (error) => {
          const message = error.message.includes('400')
            ? 'Request was not in the expected state, please refresh the page'
            : error.message;
          this.toast.warning({
            message: `Failed to decline ${globalAuthorityRequest.globalAuthority.toString()} to ${
              globalAuthorityRequest.user.name
            } with error: ${message}`,
            dataCy: `decline-global-authority-failed-toast`
          });
        }
      });
  }
}
