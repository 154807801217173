import { ChangeDetectionStrategy, Component, Input, OnInit, signal } from '@angular/core';
import { Address, Building } from '@app/shared/models/building.interface';
import { User } from '@app/shared/models/user.interface';
import { BuildingMetadataService } from '@app/shared/services/building-metadata/building-metadata.service';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { UserService } from '@app/shared/services/user/user.service';
import { concatMap, Observable, of } from 'rxjs';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { IsAuthorizedForBuildingDirective } from '../../../directives/is-authorized-for-building.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-buildings-menu',
  templateUrl: './buildings-menu.component.html',
  styleUrls: ['../dropdown/dropdown.scss', './buildings-menu.component.scss'],
  imports: [
    NgClass,
    MatMenuTrigger,
    NgStyle,
    MatMenu,
    MatMenuItem,
    IsAuthorizedForBuildingDirective,
    AsyncPipe,
    RouterLink,
    MatIconModule,
    MatTooltipModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildingsMenuComponent implements OnInit {
  @Input() public user$: Observable<User>;
  @Input() public buildings$: Observable<Building[]>;

  isActive = signal(false);
  currentBuilding$: Observable<Building>;

  constructor(
    private userService: UserService,
    private building: BuildingMetadataService,
    private navigationService: NavigationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.currentBuilding$ = this.route.params.pipe(
      concatMap((params) => {
        const { buildingId } = params;
        return buildingId ? this.userService.getBuilding(buildingId) : of(null);
      })
    );
    this.user$ = this.userService.getCurrentUser();
  }

  navigateToBuilding(building: Building): Observable<string> {
    return this.building.produceBuildingUrl(building);
  }

  navigateToBuildingAdmin(building: Building): void {
    this.toggle();
    this.navigationService.buildingId = building.id;
    this.navigationService.floorId = building.floors[0] ? building.floors[0].id : null;
    this.navigationService.navigateToSection(this.navigationService.getSectionById('building'), building.id);
  }

  produceBuildingThumbnailImageStyle(building: Building): any {
    const url = building ? this.building.thumbnailUrl(building) : undefined;
    return { 'background-image': url ? 'url(' + url + ')' : 'none' };
  }

  public getStringFromAddress(address: Address): string {
    const fields = [];
    let addressText = '';
    if (address.addressLine1) {
      fields.push(address.addressLine1);
    }
    if (address.addressLine2) {
      fields.push(address.addressLine2);
    }
    if (address.addressLine3) {
      fields.push(address.addressLine3);
    }
    if (address.postcode) {
      fields.push(address.postcode);
    }
    if (address.country) {
      fields.push(address.country);
    }

    for (const i in fields) {
      if (fields.hasOwnProperty(i)) {
        addressText += fields[i];
        if ((i as any as number) < fields.length - 1) {
          addressText += ' ';
        }
      }
    }

    return addressText;
  }

  toggle(): void {
    this.isActive.update((state) => !state);
  }

  setActive(state: boolean = true): void {
    this.isActive.set(state);
  }
}
