import { Component, OnInit } from '@angular/core';
import { Building } from '@app/shared/models/building.interface';
import { UserService } from '@app/shared/services/user/user.service';
import { combineLatest, concatMap, Observable, Subject } from 'rxjs';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuildingDetailsComponent } from '@components/building/form-building-details/form-building-details.component';
import { ServiceLevelService } from '@services/service-level.service';
import { map } from 'rxjs/operators';
import { serviceLevelFromValue, valueFromServiceLevel } from '@app/shared/models/service-level-type';

@Component({
  selector: 'app-administration-building',
  templateUrl: './building.component.html',
  imports: [FormBuildingDetailsComponent]
})
export class AdministrationBuildingComponent implements OnInit {
  public buildingDetails$: Observable<Building>;
  public success$: Subject<void> = new Subject();

  constructor(
    private readonly userService: UserService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private serviceLevelService: ServiceLevelService
  ) {}

  ngOnInit(): void {
    this.buildingDetails$ = this.route.params.pipe(
      concatMap((params) => {
        const { buildingId } = params;
        return combineLatest([this.userService.getBuilding(buildingId), this.serviceLevelService.get(buildingId)]).pipe(
          map((data) => {
            const building = data[0];
            const serviceLevel = data[1];
            building.serviceLevel = valueFromServiceLevel(serviceLevelFromValue(serviceLevel.serviceLevel));
            serviceLevel.startDate = new Date(serviceLevel.startDate);
            building.serviceLevelStartDate = serviceLevel.startDate.toLocaleDateString();
            building.buildingFloorAreaSqMeters = serviceLevel.squareMetres;
            return building;
          })
        );
      })
    );

    this.buildingDetails$.subscribe((building) => {
      this.navigationService.initNavigation(window.location.href, building);
    });
  }
}
