<div class="or-dropdown or-buildings-menu" [ngClass]="{ 'or-active': isActive() }">
  @if (currentBuilding$ | async; as currentBuilding) {
    <button [matMenuTriggerFor]="menu"
            data-cy="building-menu-drop-down-button"
            class="or-button or-rounded or-dropdown-trigger or-dropdown-trigger-inverse"
            (click)="toggle()"
            (menuClosed)='setActive(false)'>
      <span class="or-button-thumbnail" [ngStyle]="produceBuildingThumbnailImageStyle(currentBuilding)"></span>
      <span class="or-button-label" data-cy="current-building-label" [textContent]="currentBuilding.name"></span>
    </button>
  }
</div>

<mat-menu #menu="matMenu" class='mt-2 max-h-[70dvh]'>
  @for (building of buildings$ | async; track building.id) {
    <a (click)="toggle()"
       [attr.data-cy]="'building-menu-item-' + building.id"
       mat-menu-item
       appIsAuthorizedForBuilding="VIEW"
       [appBuildingIdToAuthorize]="building.id"
       [routerLink]="navigateToBuilding(building) | async">
      <div class='flex flex-row py-2 h-[75px]'>
        <div [attr.data-cy]="'building-thumbnail-link-' + building.id" class='w-[65px] relative'>
          <div class="or-button-thumbnail or-buildings-menu-item-thumbnail" [ngStyle]="produceBuildingThumbnailImageStyle(building)"></div>
        </div>
        @if (building.floors?.length === 0) {
          <mat-icon [matTooltip]="'Floors not configured!'"  class="warning relative top-5 text-3xl">warning</mat-icon>
        }
        <div class='overflow-hidden'>
          <div [attr.data-cy]="'building-name-link-' + building.id" class="min-h-[48px]">
            <span class="building-name" [textContent]="building.name"></span>
            <span class="building-address" [textContent]="getStringFromAddress(building.address)"></span>
          </div>
        </div>
        <div class='px-6'>
          <div [attr.data-cy]="'building-manage-button-permission-' + building.id" appIsAuthorizedForBuilding="MANAGE_BUILDING" [appBuildingIdToAuthorize]="building.id">
            <a [attr.data-cy]="'building-manage-button-link-' + building.id" class="building-manage-link" (click)="navigateToBuildingAdmin(building)">
              <span class="or-icon or-small or-icon-gear px-6"></span>
            </a>
          </div>
        </div>
      </div>
    </a>
  }
</mat-menu>
