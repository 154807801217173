import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureResource {
  private readonly apiUrl: string;
  constructor(private http: HttpClient, @Inject(environmentToken) environment: Environment) {
    this.apiUrl = environment.apiUrl + environment.apiPath + '/feature';
  }

  getFeatureForBuilding(feature: string, buildingId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}/building/${buildingId}?feature=${feature}`);
  }

  getFeature(feature: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}?feature=${feature}`);
  }

  getAllFeaturesForBuilding(buildingId: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}/building/${buildingId}/all`);
  }
}
