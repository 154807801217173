<div id="emergency-logs-table" [ngClass]="{ 'overlay': (logsDataSource.isLoading$ | async)}">
  @if (logsDataSource.isLoading$ | async) {
    <div class="spinner">
      <mat-spinner></mat-spinner>
    </div>
  }
  @if (!logsDataSource?.hasData && !(logsDataSource.isLoading$ | async)) {
    <app-notification-block
      [header]="'No ELMT logs for filter combination'"
      [statusClass]="StatusClass.WARNING">
      It appears that there are no tests that belong to the applied filters. Kindly change/clear the filters applied or visit
      <a [routerLink]="building.id ? getElmtScheduleRoute() : null">schedules page</a>
      to check the correct filters that need to be applied.
    </app-notification-block>
  }
  @if (logsDataSource.hasData && !(logsDataSource.isLoading$ | async)) {
    <div class="log-options">
      <button appIsAuthorizedForBuilding="MANAGE_EMERGENCY_LIGHTING" data-cy='export-report-btn' class='action-btn'
              [appBuildingIdToAuthorize]="building.id" (click)='export()'
              mat-raised-button color='primary' matTooltip='Export filtered results'>
        <mat-icon aria-hidden='false' aria-label='Export'>list_alt</mat-icon>
        Export
      </button>
      @if (selectionEnabled) {
        <button mat-raised-button color="primary" [disabled]="!isAnyLogSelected()" (click)="openDialog()" data-cy="initiate-tests-button" class='action-btn'>
          <mat-icon aria-hidden='false' aria-label='Export'>play_arrow</mat-icon>
          Initiate Tests
        </button>
        <button class='reset-btn action-btn' color='warn' mat-button [disabled]="!isAnyLogSelected()" (click)="toggleSelectAllLogs(false)" matTooltip="Reset Selections" data-cy="reset-logs-selection-button">
          <mat-icon aria-hidden="false" aria-label="Reset">settings_backup_restore</mat-icon>
          Reset Selection
        </button>
      }
    </div>
  }
  <mat-table
    aria-label="Emergency test logs"
    [dataSource]="logsDataSource"
    class="logs-table mat-elevation-z8"
    [ngClass]="{'invisible': ((logsDataSource.isLoading$ | async) || !logsDataSource.hasData)}"
  >
    <!-- Mat Checkbox Selection Column-->
    @if(selectionEnabled) {
      <ng-container matColumnDef="checkbox">
        <mat-header-cell *matHeaderCellDef style="max-width: 90px">
          <mat-checkbox
            data-cy="select-all-checkbox"
            color="primary"
            class="select-all-checkbox"
            (change)="toggleSelectAllLogs()"
            [ngModel]="allLogsSelectedByPage.get(this.pageNumber)"
            [indeterminate]="indeterminateState.get(this.pageNumber)">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let emLog" style="max-width: 90px">
          <mat-checkbox
            color="primary"
            (change)="toggleLogSelection(emLog.testId)"
            [ngModel]="logsMap.get(emLog.testId)?.selected"
            [attr.data-cy]="'log-checkbox-' + emLog.testId"
          ></mat-checkbox>
        </mat-cell>
      </ng-container>
    }

    <!-- EM inverter column -->
    <ng-container matColumnDef="driverId">
      <mat-header-cell *matHeaderCellDef> Device ID </mat-header-cell>
      <mat-cell [attr.data-cy]="'log-device-id-' + emLog.testId" *matCellDef="let emLog">{{emLog.driverId}}</mat-cell>
    </ng-container>
    <!-- Node column -->
    <ng-container matColumnDef="sensorNodeId">
      <mat-header-cell *matHeaderCellDef> Node ID </mat-header-cell>
      <mat-cell *matCellDef="let emLog">
        <a [attr.data-cy]="'log-node-link-' + emLog.testId" [routerLink]='emLog.sensorNodeLink'>{{emLog.sensorNodeId}}</a>
      </mat-cell>
    </ng-container>
    <!-- Test Type column -->
    <ng-container matColumnDef="testsType">
      <mat-header-cell *matHeaderCellDef> Test type </mat-header-cell>
      <mat-cell [attr.data-cy]="'log-test-type-' + emLog.testId" *matCellDef="let emLog">{{emLog.testsType}}</mat-cell>
    </ng-container>
    <!-- State column -->
    <ng-container matColumnDef="state">
      <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
      <mat-cell [attr.data-cy]="'log-status-' + emLog.testId" *matCellDef="let emLog" [ngStyle]="{'color': getStatusColor(emLog.state), 'font-weight': '600'}">{{getStatusDisplay(emLog.state)}}</mat-cell>
    </ng-container>
    <!-- Message column -->
    <ng-container matColumnDef="message">
      <mat-header-cell *matHeaderCellDef> Message </mat-header-cell>
      <mat-cell *matCellDef="let emLog">{{emLog.message}}</mat-cell>
    </ng-container>
    <!-- Schedule name Column -->
    <ng-container matColumnDef="scheduleName">
      <mat-header-cell *matHeaderCellDef> Schedule </mat-header-cell>
      <mat-cell [attr.data-cy]="'log-schedule-' + emLog.testId" *matCellDef="let emLog">
        {{emLog.scheduleName}}
      </mat-cell>
    </ng-container>
    <!-- Start date Column -->
    <ng-container matColumnDef="timeStarted">
      <mat-header-cell *matHeaderCellDef> Start date </mat-header-cell>
      <mat-cell [attr.data-cy]="'log-start-date-' + emLog.testId" *matCellDef="let emLog" >{{formattedDate(emLog.timeStarted)}}</mat-cell>
    </ng-container>
    <!-- End date Column -->
    <ng-container matColumnDef="endDate">
      <mat-header-cell *matHeaderCellDef> End date </mat-header-cell>
      <mat-cell *matCellDef="let emLog">{{emLog.endDate}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="logTableColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: logTableColumns" data-cy="log-row"></mat-row>


  </mat-table>
  <mat-paginator [ngClass]="{'invisible': ((logsDataSource.isLoading$ | async) || !logsDataSource.hasData)}"
    data-cy="logs-table-paginator"
    *ngIf="logsDataSource"
    class="mat-elevation-z8"
    #logsPaginator
    [length]="logsDataSource.counter$ | async"
    [pageSizeOptions]="[10 , 20 , 50, 100]"
    [pageSize]="pageSize"
    (page)="loadLogsFromPaginator($event)"
    [pageIndex]='pageNumber'
    showFirstLastButtons
    aria-label="Select page for logs">
  </mat-paginator>
</div>
