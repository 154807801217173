import { Component, OnInit } from '@angular/core';
import {
  CalendarA11y,
  CalendarAngularDateFormatter,
  CalendarCommonModule,
  CalendarDateFormatter,
  CalendarDayModule,
  CalendarEvent,
  CalendarEventTitleFormatter,
  CalendarMonthModule,
  CalendarMonthViewDay,
  CalendarUtils,
  CalendarView,
  CalendarWeekModule,
  DateAdapter
} from 'angular-calendar';
import { combineLatestWith, Subject, switchMap } from 'rxjs';
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  isSameDay,
  isSameMonth,
  startOfDay,
  startOfMonth,
  startOfWeek
} from 'date-fns';
import { EmergencyCalendarService } from '@app/shared/services/emergency-calendar.service';
import {
  EmergencyCalendarEvent,
  IEmergencyCalendarEvent
} from '@app/shared/resources/emergency-calendar-event.resource';
import { map } from 'rxjs/operators';
import { UserService } from '@app/shared/services/user/user.service';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { Router } from '@angular/router';

@Component({
  selector: 'app-emergency-lighting-calendar',
  templateUrl: './emergency-lighting-calendar.component.html',
  styleUrls: ['./emergency-lighting-calendar.component.scss'],
  standalone: true,
  imports: [
    MatGridList,
    MatGridTile,
    MatButtonToggleGroup,
    MatButtonToggle,
    CalendarCommonModule,
    CalendarMonthModule,
    CalendarWeekModule,
    CalendarDayModule
  ],
  providers: [
    { provide: DateAdapter, useFactory: adapterFactory },
    { provide: CalendarDateFormatter, useClass: CalendarAngularDateFormatter },
    CalendarUtils,
    CalendarA11y,
    CalendarEventTitleFormatter
  ]
})
export class GlobalAdministrationEmergencyLightingCalendarComponent implements OnInit {
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  refresh = new Subject<void>();

  events: CalendarEvent<IEmergencyCalendarEvent>[] = [];

  activeDayIsOpen = false;
  isToday = true;

  constructor(
    private router: Router,
    private buildingService: UserService,
    private emergencyCalendarService: EmergencyCalendarService
  ) {}

  ngOnInit(): void {
    this.fetchScheduleRuns();
  }

  private fetchScheduleRuns(): void {
    let start;
    let end;
    if (this.view === CalendarView.Month) {
      start = startOfMonth(this.viewDate);
      end = endOfMonth(this.viewDate);
    } else if (this.view === CalendarView.Week) {
      start = startOfWeek(this.viewDate);
      end = endOfWeek(this.viewDate);
    } else if (this.view === CalendarView.Day) {
      start = startOfDay(this.viewDate);
      end = endOfDay(this.viewDate);
    }

    this.emergencyCalendarService
      .getDistinctBuildingIdsWithSchedules()
      .pipe(
        switchMap((buildingIds) => {
          return this.buildingService.getBuildingsByIds(buildingIds).pipe(
            map((buildings) =>
              buildings.reduce((acc, currentValue) => {
                acc[currentValue.id] = currentValue;
                return acc;
              }, {})
            ),
            combineLatestWith(
              this.emergencyCalendarService.getScheduleRunsForBuildingBetween(
                buildingIds,
                start.getTime(),
                end.getTime()
              )
            ),
            map(([buildingsMap, scheduleRuns]) => {
              return scheduleRuns
                .filter((sr) => buildingsMap[sr.buildingId] != null)
                .map((sr) => {
                  return EmergencyCalendarEvent.from({ ...sr, buildingName: buildingsMap[sr.buildingId].name });
                });
            })
          );
        })
      )
      .subscribe({
        next: (events) => (this.events = events),
        error: (err) => {
          console.error(err);
        }
      });
  }

  setView(view: CalendarView): void {
    this.view = view;
    this.fetchScheduleRuns();
  }

  viewDateChanged(event): void {
    this.isToday = new Date().toDateString() === event.toDateString();

    this.fetchScheduleRuns();
  }

  dayClicked({ events, date }: CalendarMonthViewDay): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
    }

    this.activeDayIsOpen = !((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0);

    this.isToday = new Date().toDateString() === date.toDateString();
  }

  handleEvent(calendarEvent: IEmergencyCalendarEvent): void {
    this.router
      .navigateByUrl(
        `/buildings/${calendarEvent.buildingId}/emergency-lighting/schedules#schedule-${calendarEvent.scheduleId}`
      )
      .catch((err) => console.error('Error navigating to schedule', err));
  }
}
