import { ISchedule } from '@app/shared/models/schedule.interface';
import { WeekdaySelection } from '@app/shared/models/weekday-selection';
import { Rule } from '@app/shared/models/rule';
import { IScheduleDto } from '@app/shared/models/schedule-dto';

export class Schedule implements ISchedule {
  public name?: string;
  public startTime: string;
  public endTime: string;
  public days?: WeekdaySelection;
  public rules: Rule[];
  public enabled: boolean;
  public rank: number;
  public id?: number;
  public active: boolean;
  public alwaysActive: boolean;
  public startDate?: Date;
  public endDate?: Date;
  public editable?: boolean;
  public timezone: string;

  constructor(params: ISchedule = {} as ISchedule) {
    this.name = params.name;
    this.startTime = params.startTime || '00:00';
    this.endTime = params.endTime || '00:00';
    this.days = params.days;
    this.rules = params.rules || [];
    this.enabled = params.enabled !== null && params.enabled !== undefined ? params.enabled : true;
    this.rank = params.rank;
    this.id = params.id;
    this.active = params.active || false;
    this.alwaysActive = params.alwaysActive || false;
    this.startDate = params.startDate;
    this.endDate = params.endDate;
    this.editable = params.editable;
    this.timezone = params.timezone;
  }

  static clone(other: Schedule): Schedule {
    return new this({
      name: other.name,
      startTime: other.startTime,
      endTime: other.endTime,
      days: WeekdaySelection.clone(other.days),
      rules: other.rules.map((rule) => Rule.clone(rule)),
      enabled: other.enabled,
      rank: other.rank,
      id: other.id,
      active: other.active,
      alwaysActive: other.alwaysActive,
      startDate: other.startDate,
      endDate: other.endDate,
      editable: other.editable,
      timezone: other.timezone
    });
  }

  static fromDto(scheduleDto: IScheduleDto): ISchedule {
    return {
      name: scheduleDto.name,
      startTime: scheduleDto.startTime,
      endTime: scheduleDto.endTime,
      days: WeekdaySelection.clone(scheduleDto.days),
      rules: scheduleDto.rules.map((rule) => Rule.clone(rule)),
      enabled: scheduleDto.enabled,
      rank: scheduleDto.rank,
      id: scheduleDto.id,
      active: scheduleDto.active,
      alwaysActive: scheduleDto.alwaysActive,
      startDate: scheduleDto.startDate ? this.toDateFromString(scheduleDto.startDate) : null,
      endDate: scheduleDto.endDate ? this.toDateFromString(scheduleDto.endDate) : null,
      editable: scheduleDto.editable,
      timezone: scheduleDto.timezone
    };
  }

  static toDto(schedule: ISchedule): IScheduleDto {
    return {
      name: schedule.name,
      startTime: schedule.startTime,
      endTime: schedule.endTime,
      days: WeekdaySelection.clone(schedule.days),
      rules: schedule.rules.map((rule) => Rule.clone(rule)),
      enabled: schedule.enabled,
      rank: schedule.rank,
      id: schedule.id,
      active: schedule.active,
      alwaysActive: schedule.alwaysActive,
      startDate: schedule.startDate ? this.toDateString(schedule.startDate) : null,
      endDate: schedule.endDate ? this.toDateString(schedule.endDate) : null,
      editable: schedule.editable,
      timezone: schedule.timezone
    };
  }

  static toDateFromString(dateString: string): Date {
    return dateString != null ? new Date(dateString) : null;
  }
  static toDateString(date: Date): string {
    return (
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      date.getDate().toString().padStart(2, '0')
    );
  }
}

export type ScheduleType = 'weekday' | 'dated';
