import { Component, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElmtEmailScheduleFormComponent } from '@components/elmt-email-schedule-form/elmt-email-schedule-form.component';
import { ElmtEmailScheduleTableComponent } from '@components/elmt-email-schedule-table/elmt-email-schedule-table.component';
import {
  AbstractElmtEmailTableDatasource,
  ElmtEmailTableData,
  ElmtEmailTableDatasource
} from '@app/shared/models/elmt-email-table-datasource';
import { UserService } from '@services/user/user.service';
import { EmergencyLightingScheduleService } from '@services/emergency-lighting/emergency-lighting-schedule.service';
import { delay, forkJoin, Observable, of, switchMap } from 'rxjs';
import { User } from '@app/shared/models/user.interface';
import { IElmtScheduleDto } from '@app/shared/models/elmt-schedule-dto.interface';
import { Building } from '@app/shared/models/building.interface';
import { ActivatedRoute } from '@angular/router';
import { catchError, finalize, map } from 'rxjs/operators';
import { ElmtEmailDataService } from '@services/emergency-lighting/elmt-email-data.service';
import { EmailSchedulesService } from '@app/emergency-lighting/email-schedules/email-schedules.service';
import { ToastService } from '@services/toast/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-email-schedules',
  imports: [CommonModule, ElmtEmailScheduleFormComponent, ElmtEmailScheduleTableComponent, MatProgressSpinner],
  providers: [
    {
      provide: AbstractElmtEmailTableDatasource,
      useFactory: (emailService: ElmtEmailDataService) => new ElmtEmailTableDatasource(emailService),
      deps: [ElmtEmailDataService]
    }
  ],
  templateUrl: './email-schedules.component.html',
  styleUrl: './email-schedules.component.scss'
})
export class EmailSchedulesComponent implements OnInit {
  constructor(
    private userService: UserService,
    private scheduleService: EmergencyLightingScheduleService,
    private route: ActivatedRoute,
    private emailService: EmailSchedulesService,
    private emailDataService: ElmtEmailDataService,
    private toastService: ToastService,
    private confirmationService: ConfirmationDialogService
  ) {}
  emailScheduleViewModel$: Observable<{
    users: User[];
    schedules: IElmtScheduleDto[];
    building: Building;
  }>;
  private buildingId: number;
  private readonly MIN_DELAY_FORM_SUBMIT = 700;
  isLoading = signal(true);

  ngOnInit(): void {
    this.emailScheduleViewModel$ = this.route.params.pipe(
      switchMap((params) => {
        this.buildingId = params.buildingId;
        return forkJoin({
          building: this.userService.getBuilding(params.buildingId),
          users: this.userService.getUsersInBuilding(params.buildingId),
          schedules: this.scheduleService
            .getSchedules(params.buildingId)
            .pipe(map((schedules) => schedules.filter((s) => s.enabled)))
        }).pipe(finalize(() => this.isLoading.set(false)));
      }),
      catchError((_) => of({ building: null, users: [], schedules: [] }))
    );
  }

  handleDeleteRow(data: ElmtEmailTableData): void {
    this.confirmationService
      .open({
        message: `This will delete the email schedule for report <span class="font-bold">${data.reportName}</span>`,
        showConfirm: true,
        title: 'Delete this email schedule?'
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          this.isLoading.set(true);
          this.emailDataService.deleteEmailSchedule(this.buildingId, data.id).subscribe({
            next: () => this.handleSuccess('Email schedule deleted successfully'),
            error: (error: HttpErrorResponse) => this.handleError(error)
          });
        }
      });
  }

  handleSubmitForm(data: { form: ElmtEmailTableData; edit: boolean }): void {
    this.isLoading.set(true);
    const emailSchedule$ = data.edit
      ? this.emailDataService.updateEmailSchedule(this.buildingId, data.form)
      : this.emailDataService.saveEmailSchedule(this.buildingId, data.form);

    const messageToDisplay = data.edit ? 'Email schedule updated successfully' : 'Email schedule saved successfully';
    emailSchedule$.pipe(delay(this.MIN_DELAY_FORM_SUBMIT)).subscribe({
      next: () => this.handleSuccess(messageToDisplay),
      error: (error: HttpErrorResponse) => this.handleError(error)
    });
  }

  handleSuccess(message: string): void {
    this.toastService.success({ message, dataCy: 'success-toast' });
    this.isLoading.set(false);
    this.emailService.submitClicked();
  }

  handleError({ error }: HttpErrorResponse): void {
    this.toastService.error({ message: error, dataCy: 'error-toast' });
    this.isLoading.set(false);
    this.emailService.submitClicked();
  }

  handleRowClicked(data: ElmtEmailTableData): void {
    this.emailService.rowClicked(data);
  }
}
