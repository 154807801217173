<div
  class='or-timerange-widget'
  [ngClass]='{ "or-invalid-range": !isValidRange }'
>
  <div class='or-timerange-widget-fill-container'>
    <div #fill [ngClass]="{'paused': isPaused}" class='or-timerange-widget-fill' ></div>
    <div
      #fillOverlap
      class='or-timerange-widget-fill or-timerange-widget-fill-overlap'
      [hidden]='!isOverlapping'
    ></div>
    <span
      #labelStart
      class='or-timerange-widget-fill-label or-timerange-widget-fill-label-start'
      [textContent]="!hideStartLabel ? startTime : ''"
      [attr.data-cy]="'schedule-expected-start-time'"
    ></span>
    <span
      #labelEnd
      class='or-timerange-widget-fill-label or-timerange-widget-fill-label-end'
      [textContent]="!hideEndLabel ? endTime : ''"
      [attr.data-cy]="'schedule-expected-end-time'"
    ></span>
  </div>
  <ul #scaleElement class='or-timerange-widget-scale'>
    <li *ngFor='let tick of scale'>
      <span class='or-timerange-widget-label'>{{tick}}</span>
    </li>
  </ul>
</div>
