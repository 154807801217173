<div id="orAdministrationServiceLevel">
  <div class="or-content-body">
    <form #form
          [formGroup]="fg"
          name="serviceLevelForm"
          data-cy='service-level-form'
    >
      <div class='lg:container lg:mx-auto'>
        <div class='flex sm:flex-row flex-col'>
          <div class="basis-1 sm:basis-8/12">
            <mat-form-field matTooltip='Editable on Building page' class='w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
               <mat-label data-cy='managing-company-label'>Managing Company</mat-label>
               <mat-select data-cy='service-level-managing-company-select' #managingCompanyId formControlName="managingCompanyId" data-test="form-service-level-managing-company">
                 @for (managingCompany of managingCompanies$ | async; track managingCompany.id) {
                   <mat-option [attr.data-cy]="'managing-company-option-' + managingCompany.id" [value]="managingCompany.id">{{ managingCompany.companyName }}</mat-option>
                 }
               </mat-select>
             </mat-form-field>
            <mat-form-field appearance='fill' class='w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
              <mat-label data-cy='service-level-type-label'>Service Level</mat-label>
              <mat-select data-cy='service-level-type-select' #serviceLevel formControlName="serviceLevel" data-test="form-service-level-type">
                @for (serviceLevel of serviceLevelTypes; track serviceLevel) {
                  <mat-option [attr.data-cy]="'service-level-type-option-' + serviceLevel" [value]="serviceLevel">{{ valueFromServiceLevel(serviceLevel) }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance='fill' class='to-date-field filter-form-field w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
              <mat-label>Start Date</mat-label>
              <input formControlName='startDate'
                     [matDatepicker]='startDate'
                     data-cy='start-date' data-test="form-service-level-start-date" matInput>
              <mat-datepicker-toggle [for]='startDate' matSuffix data-cy="start-date-datepicker-toggle"></mat-datepicker-toggle>
              <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance='fill' class='w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
              <mat-label data-cy='service-level-square-metres-label'>Building Floor Area (sqm)</mat-label>
              <input data-cy='service-level-square-metres' type='number' matInput #squareMetres
                     data-test="form-service-level-square-metres" formControlName="squareMetres" />
            </mat-form-field>
            <mat-form-field appearance='fill' class='to-date-field filter-form-field w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
              <mat-label>Service Payment Start Date</mat-label>
              <input formControlName='paymentStartDate'
                     [matDatepicker]='paymentStartDate'
                     data-cy='payment-start-date' data-test="form-service-level-payment-start-date" matInput>
              <mat-datepicker-toggle [for]='paymentStartDate' matSuffix data-cy="service-payment-start-datepicker-toggle"></mat-datepicker-toggle>
              <mat-datepicker #paymentStartDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance='fill' class='w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
              <mat-label data-cy='service-level-mixed-site-label'>Mixed Site</mat-label>
              <mat-select data-cy='managing-company-select' #mixedSite formControlName="mixedSite" data-test="form-service-level-mixed-site">
                <mat-option [value]=true>Yes</mat-option>
                <mat-option [value]=false>No</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance='fill' class='w-full sm:w-10/12 md:w-10/12 sm:ml-2'>
              <mat-label data-cy='service-level-supplier-and-luminaires-label'>All suppliers and amount of luminaires for each</mat-label>
              <textarea data-cy='service-level-supplier-and-luminaires' matInput #suppliersAndLuminaires
                     data-test="form-service-level-supplier-and-luminaires" formControlName="supplierAndLuminaires"></textarea>
            </mat-form-field>
            <mat-form-field appearance='fill' class='w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
              <mat-label data-cy='service-level-contract-number-label'>Contract Number</mat-label>
              <input data-cy='service-level-contract-number' matInput #contractNumber
                        data-test="form-service-level-contract-number" formControlName="contractNumber" />
              <mat-hint align="end">{{contractNumber.value.length}} /  {{ _maxLength }}</mat-hint>
            </mat-form-field>
            <mat-form-field appearance='fill' class='w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
              <mat-label data-cy='service-level-service-period-label'>Service Period</mat-label>
              <input data-cy='service-level-service-period' matInput #servicePeriod
                     data-test="form-service-level-service-period" formControlName="servicePeriod" />
              <mat-hint align="end">{{servicePeriod.value.length}} /  {{ _maxLength }}</mat-hint>
            </mat-form-field>
            <mat-form-field appearance='fill' class='w-full sm:w-10/12 md:w-10/12 sm:ml-2'>
              <mat-label data-cy='service-level-invoice-address-label'>Invoice Address</mat-label>
              <textarea data-cy='service-level-invoice-address' matInput #invoiceAddress
                        data-test="form-service-level-invoice-address" formControlName="invoiceAddress" ></textarea>
            </mat-form-field>
            <mat-form-field appearance='fill' class='w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
              <mat-label data-cy='service-level-invoice-email-label'>Invoice Email</mat-label>
              <input data-cy='service-level-invoice-email' matInput #invoiceEmail
                     data-test="form-service-level-invoice-email" formControlName="invoiceEmail" />
              <mat-hint align="end">{{invoiceEmail.value.length}} /  {{ _maxLength }}</mat-hint>
            </mat-form-field>
            <mat-form-field appearance='fill' class='w-full sm:w-10/12 md:w-5/12 sm:ml-2'>
              <mat-label data-cy='service-level-vat-number-label'>VAT Number</mat-label>
              <input data-cy='service-level-vat-number' matInput #vatNumber
                     data-test="form-service-level-vat-number" formControlName="vatNumber" />
              <mat-hint align="end">{{vatNumber.value.length}} / {{ _maxLength }}</mat-hint>
            </mat-form-field>
            <mat-form-field appearance='fill' class='w-full sm:w-10/12 md:w-10/12 sm:ml-2'>
              <mat-label data-cy='service-level-notes-label'>Notes</mat-label>
              <textarea data-cy='service-level-notes' matInput #notes
                        data-test="form-service-level-notes" formControlName="notes" ></textarea>
            </mat-form-field>
            <mat-form-field appearance='fill' class='w-full sm:w-10/12 sm:ml-2'>
              <mat-label data-cy='service-level-internal-reference-contact-label'>Internal Reference Contact</mat-label>
              <textarea data-cy='service-level-internal-reference-contact' matInput #internalReferenceContact
                        data-test="form-service-level-internal-reference-contact" formControlName="internalReferenceContact" ></textarea>
            </mat-form-field>
            <mat-form-field appearance='fill' class='w-full sm:w-10/12 sm:ml-2'>
              <mat-label data-cy='service-level-additional-information-label'>Additional Information</mat-label>
              <textarea class='font-13' data-cy='service-level-additional-information' matInput #additionalInformation
                        data-test="form-service-level-additional-information" formControlName="additionalInformation" ></textarea>
            </mat-form-field>
            <div class='w-full md:w-10/12 flex md:flex-row-reverse flex-col sm:ml-2'>
              <button
                data-cy='service-level-form-submit'
                mat-raised-button
                data-test="form-service-level-submit"
                class="w-full sm:w-10/12 md:w-1/2 md:ml-6 mb-4"
                color='primary'
                type="submit"
                (click)="submit()"
                [disabled]="fg.invalid || fg.pristine"
              >
                <span class="or-icon or-small or-inverse or-icon-checkmark"></span>
                <span class="or-button-label" data-cy='service-level-form-submit-label'>Save</span>
              </button>
              <button
                data-cy='service-level-form-reset'
                class='w-full sm:w-10/12 md:w-1/2'
                (click)="reset()"
                mat-raised-button
                color='accent'
                data-test="form-service-level-reset"
                [disabled]="fg.pristine"
              >
                <span class="or-icon or-small or-icon-reload"></span>
                <span>Reset changes</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
