import { HeatmapDataPoint, HeatmapRenderer } from '@components/heatmap/renderer/heatmap-renderer';
import { DataInterpolator } from '@components/heatmap/interpolator/data-interpolator';
import { GridProvider } from '@components/heatmap/Grid';

export class GridRenderer implements HeatmapRenderer {
  constructor(
    private underlying: HeatmapRenderer,
    private gridProvider: GridProvider,
    private interpolatorFactory: (radius: number) => DataInterpolator
  ) {}

  render(
    dataPoints: HeatmapDataPoint[],
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    max: number,
    width: number,
    height: number
  ): void {
    const grid = this.gridProvider.compute(width, height);
    const points = this.interpolatorFactory(grid.distance * 0.5).interpolate(grid.points, dataPoints);
    this.underlying.render(points, canvas, ctx, max, width, height);
  }
}
