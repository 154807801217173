import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn } from '@angular/forms';
import { MatChip, MatChipRemove, MatChipSet } from '@angular/material/chips';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatError,
  MatFormField,
  MatLabel,
  MatSuffix
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { NgClass } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { Floor } from '@app/shared/models/floor.interface';
import { Tag } from '@app/shared/models/tag.interface';
import { MatTooltip } from '@angular/material/tooltip';
import { TimeUtils } from '@app/shared/utils/time.utils';
import { DashboardStateService } from './dashboard-state.service';
import { MatInput } from '@angular/material/input';
import { SensorNodeService } from '@services/sensor-node.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, merge, mergeMap, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { subMonths } from 'date-fns';

export interface DashboardFilterForm {
  startDate: FormControl<Date>;
  endDate: FormControl<Date>;
  floors: FormControl<Floor[]>;
  tags: FormControl<Tag[]>;
}

@Component({
  selector: 'app-dashboard-filter',
  imports: [
    FormsModule,
    MatChip,
    MatChipRemove,
    MatDatepickerToggle,
    MatFormField,
    MatIcon,
    MatLabel,
    MatOption,
    MatSelect,
    MatSelectTrigger,
    MatSuffix,
    ReactiveFormsModule,
    MatButton,
    MatChipSet,
    NgClass,
    MatTooltip,
    MatDatepicker,
    MatDatepickerInput,
    MatInput,
    MatError
  ],
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }],
  templateUrl: './dashboard-filter.component.html',
  styleUrl: './dashboard-filter.component.scss'
})
export class DashboardFilterComponent implements OnInit, OnDestroy {
  allTags: Tag[];
  selectableTags: Tag[];
  allFloors: Floor[];
  filterForm: FormGroup<DashboardFilterForm>;
  floorToTags: Record<number, Tag[]> = {};
  showDateFields = true;
  private destroy$ = new Subject<void>();
  private floorTagMappingSubscription: Subscription;
  private initialStartDate: Date;
  private initialEndDate: Date;
  @Input({ required: true }) buildingId: number;
  @Input({ required: true }) timeZone: string;

  constructor(
    private fb: FormBuilder,
    private stateService: DashboardStateService,
    private nodeService: SensorNodeService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.allTags = this.stateService.allTags;
    this.allFloors = this.stateService.allFloors;
    const { startDate, endDate } = this.getInitialStartAndEndDate();
    this.initialStartDate = startDate;
    this.initialEndDate = endDate;

    this.filterForm = this.fb.group({
      startDate: new FormControl<Date>(this.initialStartDate),
      endDate: new FormControl<Date>(this.initialEndDate),
      floors: new FormControl<Floor[]>([]),
      tags: new FormControl<Tag[]>([])
    });
    this.filterForm.addValidators(this.validateFilterForm());
    this.initStateChangeListener();
    this.initFloorTagMapping();
    this.initRouteListener();
  }

  initRouteListener(): void {
    merge(this.router.events.pipe(filter((event) => event instanceof NavigationEnd)), this.route.url)
      .pipe(
        mergeMap(() => this.route.firstChild?.data ?? []),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        const childRoute = this.route.firstChild?.snapshot?.url[0]?.path;
        this.showDateFields = childRoute !== 'metrics';
      });
  }

  ngOnDestroy(): void {
    this.reset();
    this.destroy$.next();
    this.destroy$.complete();
  }

  initStateChangeListener(): void {
    this.stateService.dashboardFilterState$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      if (this.stateService.currentBuilding.id !== this.buildingId) {
        this.filterForm?.reset({
          startDate: this.initialStartDate,
          endDate: this.initialEndDate,
          floors: [],
          tags: []
        });
        this.initFloorTagMapping();
        this.allFloors = this.stateService.allFloors;
        this.allTags = this.stateService.allTags;
      }
    });
  }

  initFloorTagMapping(): void {
    if (this.floorTagMappingSubscription) {
      this.floorTagMappingSubscription.unsubscribe();
    }

    this.floorTagMappingSubscription = this.nodeService
      .fetchNodesWithTagsForBuilding(this.buildingId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((nodes) => {
        nodes.forEach((node) => {
          const floorId = node.floorId;
          if (!this.floorToTags.hasOwnProperty(floorId)) {
            this.floorToTags[floorId] = [];
          }
          node.tags?.forEach((tag) => {
            if (!this.floorToTags[floorId].some((t) => t.id === tag.id)) {
              this.floorToTags[floorId].push(tag);
            }
          });
        });
        this.updateSelectableTags();
      });
  }

  validateFilterForm(): ValidatorFn {
    return (form: FormGroup) => {
      const startDate = this.startDate.value;
      const endDate = this.endDate.value;
      this.endDate.setErrors(endDate ? null : { endDateRequired: true });
      this.startDate.setErrors(endDate < startDate ? { endDateBeforeStartDate: true } : null);
      return null;
    };
  }

  get startDate(): FormControl {
    return this.filterForm.get('startDate') as FormControl;
  }

  get endDate(): FormControl {
    return this.filterForm.get('endDate') as FormControl;
  }

  get floors(): FormControl {
    return this.filterForm.get('floors') as FormControl;
  }

  get tags(): FormControl {
    return this.filterForm.get('tags') as FormControl;
  }

  onFloorRemove(floor: Floor): void {
    const floors = this.floors.value;
    this.floors.setValue(this.removeElement(floors, floor)); // To trigger change detection
    this.updateSelectableTags();
  }

  onTagRemove(tag: Tag): void {
    const tags = this.tags.value;
    this.tags.setValue(this.removeElement(tags, tag)); // To trigger change detection
  }

  private removeElement(array: (Floor | Tag)[], toRemove: any): (Floor | Tag)[] {
    return array.filter((e) => e.id !== toRemove.id);
  }

  apply(): void {
    this.stateService.saveFilter(this.filterForm.value);
  }

  reset(): void {
    this.filterForm.reset();
    this.startDate.setValue(this.initialStartDate);
    this.endDate.setValue(this.initialEndDate);
    this.updateSelectableTags();
    this.stateService.saveFilter(this.filterForm.value);
  }

  get chipsForFloors(): Floor[] {
    if (this.floors.value && this.floors.value.length > 3) {
      return [...this.floors.value.slice(0, 3), { name: this.floors.value.length - 3 + '+More' }];
    } else {
      return this.floors.value;
    }
  }

  get chipsForTags(): Tag[] {
    if (this.tags.value && this.tags.value.length > 3) {
      return [...this.tags.value.slice(0, 3), { name: this.tags.value.length - 3 + '+More' }];
    } else {
      return this.tags.value;
    }
  }

  updateSelectableTags(): void {
    let selectableTags: Tag[] = [];
    this.tags.reset();
    if (this.floors.value && this.floors.value.length > 0) {
      this.floors.value.forEach((floor) => {
        this.floorToTags[floor.id]?.forEach((tag) => {
          if (!selectableTags.some((t) => t.id === tag.id)) {
            selectableTags.push(tag);
          }
        });
      });
    } else {
      selectableTags = this.allTags?.slice();
    }
    this.selectableTags = selectableTags;
    this.stateService.setTags(this.selectableTags);
  }

  private getInitialStartAndEndDate(): { startDate: Date; endDate: Date } {
    const now = new Date();
    const previousMonth = subMonths(now, 1);
    return {
      startDate: TimeUtils.adjustDateToTimezone(previousMonth, this.timeZone),
      endDate: TimeUtils.adjustDateToTimezone(now, this.timeZone)
    };
  }
}
