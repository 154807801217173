import { Coordinate } from '@app/shared/utils/coordinate';
import { HeatmapDataPoint } from '@components/heatmap/renderer/heatmap-renderer';

export interface InterpolationRangeProvider {
  range(data: HeatmapDataPoint[]): number;
}

export class AutomaticInterpolationRange implements InterpolationRangeProvider {
  constructor(private interpolationRangeFactor: number) {}

  public range(data: HeatmapDataPoint[]): number {
    if (data.length > 1) {
      const averageClosestPt =
        data.reduce((n, dataPt) => {
          const dataPtCoords = new Coordinate(dataPt.x, dataPt.y);
          return (
            n +
            data.reduce((m, otherPt) => {
              if (otherPt == dataPt) {
                return m;
              } else {
                return Math.min(m, dataPtCoords.distance(new Coordinate(otherPt.x, otherPt.y)));
              }
            }, Infinity)
          );
        }, 0) / data.length;
      return averageClosestPt * this.interpolationRangeFactor;
    } else {
      return 50;
    }
  }
}

export class ConstantInterpolationRange implements InterpolationRangeProvider {
  constructor(private constantRange: number) {}

  public range(data: HeatmapDataPoint[]): number {
    return this.constantRange;
  }
}
