import { Component, OnInit } from '@angular/core';
import { HeaderService } from '@services/header.service';
import { UserService } from '@services/user/user.service';
import { User } from '@app/shared/models/user.interface';
import { UserInfoComponent } from '@app/user/user-info/user-info.component';
import { Building } from '@app/shared/models/building.interface';
import { BuildingAuthorization } from '@app/shared/models/building-authorization.interface';
import { ToastService } from '@services/toast/toast.service';

@Component({
  selector: 'app-user',
  standalone: true,
  imports: [UserInfoComponent],
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss'
})
export class UserComponent implements OnInit {
  user: User;
  constructor(
    private readonly headerService: HeaderService,
    private readonly userService: UserService,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.headerService.showUserMenu();
    this.headerService.showSessionMenu();
    this.getUserDetails();
  }

  getUserDetails(): void {
    this.userService.getCurrentUser().subscribe({
      next: (user) => {
        const buildingAuthMap = new Map<string, BuildingAuthorization>();
        user.buildingAuthorizations.forEach((buildingAuth) => {
          buildingAuthMap[buildingAuth.buildingId] = buildingAuth;
        });
        this.userService.getBuildings().subscribe({
          next: (buildings: Building[]) => {
            const buildingAuthorizations: BuildingAuthorization[] = [];
            buildings.forEach((building) => {
              buildingAuthorizations.push({ ...buildingAuthMap[building.id], building });
            });
            buildingAuthorizations.sort((a, b) => a.building.name.localeCompare(b.building.name));
            this.user = { ...user, buildingAuthorizations };
          }
        });
      },
      error: () => {
        this.toastService.error({
          message: 'Error loading user details',
          dataCy: 'load-user-error-toast'
        });
      }
    });
  }
}
