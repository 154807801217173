import { Component } from '@angular/core';
import { SecurityService } from '@services/security.service';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { AsyncPipe, NgIf } from '@angular/common';
import { GlobalAdministrationUsersComponent } from './user/user.component';
import { MatCard } from '@angular/material/card';
import { GlobalAuthorityRequestsComponent } from './global-authority-requests/global-authority-requests.component';

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss'],
  imports: [
    MatTabGroup,
    NgIf,
    MatTab,
    GlobalAdministrationUsersComponent,
    MatCard,
    GlobalAuthorityRequestsComponent,
    AsyncPipe
  ]
})
export class UserInformationComponent {
  private permissionStored: { [authority: string]: Observable<boolean> } = {};

  constructor(private readonly securityService: SecurityService) {}

  public checkPermission(authority: string): Observable<boolean> {
    if (!this.permissionStored[authority]) {
      this.permissionStored[authority] = this.securityService.isAuthorized(authority).pipe(shareReplay(1));
    }
    return this.permissionStored[authority];
  }
}
