interface RouteConstant {
  id: string;
  name: string;
  path: string;
  icon?: string;
  regex: string;
  urlGenerator?: (buildingId?: number, floorId?: number) => string;
}
export const GLOBAL_ADMIN_ROUTE: RouteConstant = {
  id: 'global-administration',
  name: 'Global Administration',
  path: '/global-administration',
  icon: 'or-icon-admin',
  regex: '/global-administration',
  urlGenerator: () => '/global-administration'
};
export const GLOBAL_ADMIN_USER_ROUTE: RouteConstant = {
  id: 'global-users',
  name: 'Global Users',
  path: '/global-administration/users',
  regex: '/global-administration/users',
  urlGenerator: () => '/global-administration/users'
};
export const GLOBAL_USER_INFORMATION_ROUTE: RouteConstant = {
  id: 'user-information',
  name: 'Users Information',
  path: '/global-administration/user-information',
  regex: '/global-administration/user-information',
  urlGenerator: () => '/global-administration/user-information'
};
export const GLOBAL_ADMIN_MANAGING_COMPANY_ROUTE: RouteConstant = {
  id: 'global-managing-companies',
  name: 'Global Managing Companies',
  path: '/global-administration/managing-company',
  regex: '/global-administration/managing-company',
  urlGenerator: () => '/global-administration/managing-company'
};
export const GLOBAL_ADMIN_EMERGENCY_LIGHTING_CALENDAR_ROUTE: RouteConstant = {
  id: 'global-emergency-lighting-calendar',
  name: 'Global Emergency Lighting Calendar',
  path: '/global-administration/emergency-lighting-calendar',
  regex: '/global-administration/emergency-lighting-calendar',
  urlGenerator: () => '/global-administration/emergency-lighting-calendar'
};
export const GLOBAL_ADMIN_PACKET_STREAMER_ROUTE: RouteConstant = {
  id: 'packet-stream',
  name: 'Packet Stream',
  path: '/global-administration/packet-stream',
  regex: '/global-administration/packet-stream',
  urlGenerator: () => '/global-administration/packet-stream'
};
export const GLOBAL_ADMIN_OTAP_UPGRADES: RouteConstant = {
  id: 'otap-upgrades',
  name: 'OTAP Upgrades',
  path: '/global-administration/otap-upgrades',
  regex: '/global-administration/otap-upgrades',
  urlGenerator: () => '/global-administration/otap-upgrades'
};

export const GLOBAL_ADMIN_OTAP_UPGRADES_GATEWAY: RouteConstant = {
  id: 'otap-upgrades-gateway',
  name: 'OTAP Upgrades',
  path: '/global-administration/otap-upgrades/job-information',
  regex: '/global-administration/otap-upgrades/job-information',
  urlGenerator: () => '/global-administration/otap-upgrades/job-information'
};

export const ADMIN_ROUTE: RouteConstant = {
  id: 'administration',
  name: 'Administration',
  path: '/buildings/:buildingId/administration',
  icon: 'or-icon-admin',
  regex: '/buildings/\\d+/administration',
  urlGenerator: (buildingId: number) => `/buildings/${buildingId}/administration`
};
export const BUILDING_ADMIN_ROUTE: RouteConstant = {
  id: 'building',
  name: 'Building',
  path: '/buildings/:buildingId/administration/building',
  regex: '/buildings/\\d+/administration/building',
  urlGenerator: (buildingId: number) => `/buildings/${buildingId}/administration/building`
};
export const FLOORS_ADMIN_ROUTE: RouteConstant = {
  id: 'floors',
  name: 'Floors',
  path: '/buildings/:buildingId/administration/floors',
  regex: '/buildings/\\d+/administration/floors',
  urlGenerator: (buildingId: number) => `/buildings/${buildingId}/administration/floors`
};
export const GATEWAYS_ADMIN_ROUTE: RouteConstant = {
  id: 'gateways',
  name: 'Gateways',
  path: '/buildings/:buildingId/administration/gateways',
  regex: '/buildings/\\d+/administration/gateways',
  urlGenerator: (buildingId: number) => `/buildings/${buildingId}/administration/gateways`
};
export const MOBILE_TOKENS_ADMIN_ROUTE: RouteConstant = {
  id: 'mobile-tokens',
  name: 'Mobile Tokens',
  path: '/buildings/:buildingId/administration/mobile-tokens',
  regex: '/buildings/\\d+/administration/mobile-tokens',
  urlGenerator: (buildingId: number) => `/buildings/${buildingId}/administration/mobile-tokens`
};
export const TENANT_GROUPS_ADMIN_ROUTE = {
  id: 'tenants',
  name: 'Tenants',
  path: '/buildings/:buildingId/administration/tenants',
  regex: '/buildings/\\d+/administration/tenants',
  urlGenerator: (buildingId: number) => `/buildings/${buildingId}/administration/tenants`
};
export const ROLES_ADMIN_ROUTE: RouteConstant = {
  id: 'roles',
  name: 'Roles',
  path: '/buildings/:buildingId/administration/roles',
  regex: '/buildings/\\d+/administration/roles',
  urlGenerator: (buildingId: number) => `/buildings/${buildingId}/administration/roles`
};
export const TOKENS_ADMIN_ROUTE: RouteConstant = {
  id: 'tokens',
  name: 'Tokens',
  path: '/buildings/:buildingId/administration/tokens',
  regex: '/buildings/\\d+/administration/tokens',
  urlGenerator: (buildingId: number) => `/buildings/${buildingId}/administration/tokens`
};
export const USERS_ADMIN_ROUTE: RouteConstant = {
  id: 'users',
  name: 'Users',
  path: '/buildings/:buildingId/administration/users',
  regex: '/buildings/\\d+/administration/users',
  urlGenerator: (buildingId: number) => `/buildings/${buildingId}/administration/users`
};
export const CALENDAR_ADMIN_ROUTE: RouteConstant = {
  id: 'elmt-schedules-calendar',
  name: 'Calendar',
  path: '/buildings/:buildingId/administration/calendar',
  regex: '/buildings/\\d+/administration/calendar',
  urlGenerator: (buildingId: number) => `/buildings/${buildingId}/administration/calendar`
};
export const EMERGENCY_LIGHTING_ADMIN_ROUTE: RouteConstant = {
  id: 'emergency-lighting-admin',
  name: 'Emergency Lighting',
  path: '/buildings/:buildingId/administration/emergency-lighting',
  regex: '/buildings/\\d+/administration/emergency-lighting',
  urlGenerator: (buildingId: number) => `/buildings/${buildingId}/administration/emergency-lighting`
};

export const HEATMAP_ROUTE: RouteConstant = {
  id: 'heatmap',
  name: 'Heatmap',
  path: '/buildings/:buildingId/:floorId/heatmap',
  icon: 'or-icon-floor-heatmap',
  regex: '/buildings/\\d+/\\d+/heatmap',
  urlGenerator: (buildingId: number, floorId: number) => `/buildings/${buildingId}/${floorId}/heatmap`
};

export const BEACONS_ROUTE: RouteConstant = {
  id: 'beacons',
  name: 'Bluetooth Beacons',
  path: '/buildings/:buildingId/:floorId/beacons',
  icon: 'or-icon-beacon',
  regex: '/buildings/\\d+/\\d+/beacons',
  urlGenerator: (buildingId: number, floorId: number) => `/buildings/${buildingId}/${floorId}/beacons`
};

export const LIGHTING_CONFIGURATION_ROUTE: RouteConstant = {
  id: 'lighting-configuration',
  name: 'Lighting Configuration',
  path: '/buildings/:buildingId/:floorId/lighting-configuration',
  icon: 'or-icon-lighting-config',
  regex: '/buildings/\\d+/\\d+/lighting-configuration',
  urlGenerator: (buildingId: number, floorId: number) => `/buildings/${buildingId}/${floorId}/lighting-configuration`
};

export const BUILDINGS_ROUTE: RouteConstant = {
  id: 'buildings',
  name: 'Buildings',
  path: '/buildings',
  regex: '/buildings',
  urlGenerator: () => '/buildings'
};

export const CIRCADIAN_CURVES_ROUTE: RouteConstant = {
  id: 'circadian-curves',
  name: 'Circadian Curves',
  path: '/buildings/:buildingId/circadian-curves',
  icon: 'or-icon-daylight-change',
  regex: '/buildings/\\d+/circadian-curves',
  urlGenerator: (buildingId: number) => `/buildings/${buildingId}/circadian-curves`
};

export const MAP_NODES_ROUTE: RouteConstant = {
  id: 'map-nodes',
  name: 'Map Nodes',
  path: '/buildings/:buildingId/:floorId/map-nodes',
  regex: '/buildings/\\d+/\\d+/map-nodes',
  icon: 'or-icon-mapping',
  urlGenerator: (buildingId: number, floorId: number) => `/buildings/${buildingId}/${floorId}/map-nodes`
};

export const DASHBOARD_ROUTE: RouteConstant = {
  id: 'dashboard',
  name: 'Dashboard',
  path: '/buildings/:buildingId/dashboard',
  icon: 'or-icon-dashboard',
  regex: '/buildings/\\d+/dashboard',
  urlGenerator: (buildingId: number) => `/buildings/${buildingId}/dashboard`
};

export const SENSORNODES_ROUTE: RouteConstant = {
  id: 'sensor-nodes',
  name: 'Sensor Nodes',
  path: '/buildings/:buildingId/:floorId/sensor-nodes',
  regex: '/buildings/\\d+/\\d+/sensor-nodes',
  icon: 'or-icon-node',
  urlGenerator: (buildingId: number, floorId: number) => `/buildings/${buildingId}/${floorId}/sensor-nodes`
};

export const EMERGENCY_LIGHTING_ROUTE: RouteConstant = {
  id: 'emergency-lighting',
  name: 'Emergency Lighting',
  path: '/buildings/:buildingId/emergency-lighting',
  regex: '/buildings/\\d+/emergency-lighting',
  icon: 'or-icon-emergency',
  urlGenerator: (buildingId: number) => `/buildings/${buildingId}/emergency-lighting`
};

export const EMERGENCY_LIGHTING_SCHEDULES_ROUTE: RouteConstant = {
  id: 'emergency-lighting-schedules',
  name: 'Emergency Lighting Schedules',
  path: '/buildings/:buildingId/emergency-lighting/schedules',
  regex: '/buildings/\\d+/emergency-lighting/schedules',
  icon: 'or-icon-emergency',
  urlGenerator: (buildingId: number) => `/buildings/${buildingId}/emergency-lighting/schedules`
};

export const EMERGENCY_LIGHTING_MANUAL_TEST_ROUTE: RouteConstant = {
  id: 'emergency-lighting-manual-tests',
  name: 'Emergency Lighting Manual Tests',
  path: '/buildings/:buildingId/emergency-lighting/:floorId/manual-tests',
  regex: '/buildings/\\d+/emergency-lighting/\\d+/manual-tests',
  urlGenerator: (buildingId: number, floorId: number) =>
    `/buildings/${buildingId}/emergency-lighting/${floorId}/manual-tests`
};

export const LOGIN_ROUTE: RouteConstant = {
  id: 'login',
  name: 'Log in',
  path: '/login',
  regex: '/login',
  urlGenerator: () => '/login'
};

export const MAIN_ROUTE: RouteConstant = { id: 'main', name: 'Main', path: '', regex: '.*', urlGenerator: () => '/' };

export const NEW_BUILDING_ROUTE: RouteConstant = {
  id: 'new',
  name: 'New Building',
  path: '/buildings/new',
  regex: '/buildings/new',
  urlGenerator: () => '/buildings/new'
};

export const SCHEDULES_ROUTE: RouteConstant = {
  id: 'schedules',
  name: 'Schedules',
  path: '/buildings/:buildingId/schedules',
  icon: 'or-icon-watch',
  regex: '/buildings/\\d+/schedules',
  urlGenerator: (buildingId: number) => `/buildings/${buildingId}/schedules`
};

export const USER_ROUTE: RouteConstant = {
  id: 'user',
  name: 'User',
  path: '/user',
  regex: '/user',
  urlGenerator: () => '/user'
};

export const DDL_ROUTE: RouteConstant = {
  id: 'ddl',
  name: 'DDL',
  path: '/buildings/:buildingId/administration/ddl',
  regex: '/buildings/\\d+/administration/ddl',
  urlGenerator: (buildingId: number) => `/buildings/${buildingId}/administration/ddl`
};

export const SERVICE_LEVEL_ROUTE: RouteConstant = {
  id: 'service-information',
  name: 'Service Information',
  path: '/buildings/:buildingId/administration/service-information',
  regex: '/buildings/\\d+/administration/service-information',
  urlGenerator: (buildingId: number) => `/buildings/${buildingId}/administration/service-information`
};

export const allRoutes: RouteConstant[] = [
  LOGIN_ROUTE,
  USER_ROUTE,
  BUILDINGS_ROUTE,
  NEW_BUILDING_ROUTE,
  DASHBOARD_ROUTE,
  HEATMAP_ROUTE,
  SENSORNODES_ROUTE,
  SCHEDULES_ROUTE,
  EMERGENCY_LIGHTING_ROUTE,
  EMERGENCY_LIGHTING_MANUAL_TEST_ROUTE,
  BEACONS_ROUTE,
  LIGHTING_CONFIGURATION_ROUTE,
  CIRCADIAN_CURVES_ROUTE,
  MAP_NODES_ROUTE,
  ADMIN_ROUTE,
  BUILDING_ADMIN_ROUTE,
  GLOBAL_ADMIN_ROUTE,
  GATEWAYS_ADMIN_ROUTE,
  FLOORS_ADMIN_ROUTE,
  USERS_ADMIN_ROUTE,
  ROLES_ADMIN_ROUTE,
  TOKENS_ADMIN_ROUTE,
  MOBILE_TOKENS_ADMIN_ROUTE,
  TENANT_GROUPS_ADMIN_ROUTE,
  EMERGENCY_LIGHTING_ADMIN_ROUTE,
  DDL_ROUTE,
  CALENDAR_ADMIN_ROUTE
];
