import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { AuthMethod } from '../models/auth-method';
import { AuthenticationUrlsService } from './authentication-urls.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor(
    private readonly location: Location,
    private readonly authenticationUrls: AuthenticationUrlsService,
    private readonly http: HttpClient
  ) {}

  authenticate(loginMethod?: AuthMethod, redirectUrl?: string): void {
    console.log('Session Service is setting the window location.', redirectUrl);
    const method = loginMethod ?? AuthMethod.AUTH0;
    const baseUrl = redirectUrl?.startsWith('/auth/invite/')
      ? this.authenticationUrls.authenticateBaseUrl(method, true)
      : this.authenticationUrls.authenticateBaseUrl(method);
    const test = baseUrl + (redirectUrl != null ? encodeURIComponent(redirectUrl) : encodeURIComponent('/buildings'));
    window.location.assign(test);
  }

  logout(): void {
    this.http.post(this.authenticationUrls.logoutBaseUrl, null, { responseType: 'text' }).subscribe((response) => {
      window.location.href = response;
    });
  }

  handleFailedRequest(response): void {
    if (response.status === 401) {
      window.location.href = `${this.authenticationUrls.postLogoutRedirectBaseUrl}?redirectUrl=${this.location.path()}`;
    }
  }
}
