@if(user) {
  <form class="or-form" [formGroup]="form">
    <div class="or-form-body">
      <div>
        @if(user.pictureUrl){
          <div class="inline-block align-middle h-[80px] w-[80px] ml-4 rounded-full">
            <img alt="User profile picture" class="rounded-full" src="{{user.pictureUrl}}" />
          </div>
        }
        <div class="inline-block p-1.5 w-[23%] ml-3 align-middle">
          <label class="leading-6 block text-2xl">User ID</label>
          <label class="or-label or-form-user-info-label">{{user.id}}</label>
        </div>
        <div class="inline-block p-1.5 w-[23%] align-middle">
          <label class="leading-6 block text-2xl">Name</label>
          <label class="or-label or-form-user-info-label">{{user.name}}</label>
        </div>
        @if(user.emailAddress || isEditable) {
          <div class="inline-block p-1.5 w-[23%] align-middle">
            <label class="leading-6 block text-2xl">Email Address</label>
            <input class="leading-9" matInput type="email" maxlength="64" formControlName="emailAddress"
                   [disabled]="!isEditable" />
          </div>
        }
      </div>
      <h1 class="text-2xl pt-6 pb-4">Buildings</h1>
      @for(permission of user.buildingAuthorizations; track $index) {
        <div class="or-inline-items pb-2 ml-4">
            <span class="or-button-thumbnail" [ngStyle]="produceBuildingThumbnailImageStyle(permission.building)"></span>
            <span class="or-button-label or-bold">{{permission.building?.name}}</span>
            <div>
              @for(authority of humanizeAuthorities(permission.authorities) ; track  $index) {
                <mat-chip class="m-1"
                  [ngClass]="authority !== 'None' ? 'or-no-authority': ''">
                  <span class="chip-label" >{{authority}}</span>
                </mat-chip>
              }
            </div>
          <div appIsAuthorizedForBuilding="MANAGE_MAINTENANCE_UPDATES"
               [appBuildingIdToAuthorize]="permission.buildingId">
            <span class="or-label or-label-inline">Receive maintenance updates</span>
            <button class="or-button or-checkbox" (click)="toggleCheckbox(permission.buildingId)"
                    [ngClass]="{ 'or-active': checked(permission.buildingId) }">
              <span class="or-button or-checkbox-control">
                <span class="or-icon or-tiny or-icon-checkmark or-checkbox-checkmark"></span>
              </span>
              <input type="checkbox" [formControlName]="permission.buildingId" hidden>
            </button>
          </div>
          @if(showNotificationWarning(permission)) {
            <div>
              <span class="or-label or-label-warning">You will continue to receive emergency lighting notifications for
                {{permission.building?.name}}. To stop them please ask an admin to
                remove your Emergency Lighting role.</span>
            </div>
          }
        </div>
      }
    </div>
    @if(isEditable) {
      <div class="flex flex-row w-full space-x-4 pt-4">
        <button
          mat-raised-button
          matTooltip="Save"
          color="primary"
          (click)="submit()"
          [disabled]="form.invalid || !form.dirty || isBusy"
          data-cy="btn-save">
          <mat-icon class="text-xl-center" fontIcon="task_alt" />
          <span class="text-lg">{{ isBusy ? "Saving..." : "Save" }}</span>
        </button>
        <button
          mat-raised-button
          matTooltip="Reset"
          (click)="reset()"
          data-cy="btn-reset">
          <mat-icon class="text-xl-center" fontIcon="refresh" />
          <span class="text-lg">Reset</span>
        </button>
      </div>
    }
</form>
}
