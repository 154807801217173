<div id="orLogin">
  <div class="or-login-background"></div>
  <div class="or-content">
    <span class="or-portal-logo-vertical-inverse or-login or-logo-center"></span>
    <div class="or-login or-login-label or-bold">Sign in</div>
    @for (loginMethod of loginMethods; track $index) {
      @if (loginMethod.isAdvertised) {
        <button
          class="or-button or-primary or-trigger-login or-login"
          (click)="authenticate(loginMethod)">
            <span
              class="or-icon or-small or-inverse"
              [ngClass]="getLoginButtonClass(loginMethod)">
            </span>
          <span class="or-button-label or-bold">{{getLoginButtonLabel(loginMethod)}}</span>
        </button>
      }
    }
  </div>
</div>
