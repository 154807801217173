import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PassiveNodeResource } from '@app/shared/resources/passive-node.resource';
import { SensorNodeIdAndAddressDTO } from '@app/shared/models/sensor-node-id-and-address-dto.interface';
import { VirtualNotificationDTO } from '@app/shared/models/virtual-notification-dto.interface';

@Injectable({
  providedIn: 'root'
})
export class PassiveNodeService {
  constructor(private resource: PassiveNodeResource) {}

  public getPassiveNodesList(buildingId: number): Observable<SensorNodeIdAndAddressDTO[]> {
    return this.resource.getPassiveNodesList(buildingId);
  }

  public mapVirtual2Actual(virtualNotificationDTO: VirtualNotificationDTO): Observable<{}> {
    return this.resource.mapVirtual2Actual(virtualNotificationDTO);
  }
}
