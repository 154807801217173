@if (building) {
  <div class="main-content">
    <div class="grid grid-cols-12 gap-[10px] pt-[10px] px-[20px]">
      <app-panel-toggle class="col-span-3" icon="node" tooltip="Nodes"
                        (togglePanel)="toggleLeft($event)"
                        [toggleState]="isLeftColHidden" label='Nodes'>
      </app-panel-toggle>
      <div [ngClass]="isRightColHidden ? 'col-span-8' : 'col-span-7'"></div>
      <div class="flex" [ngClass]="isRightColHidden ? 'hidden' : ''">
        <app-panel-toggle icon="gear" label='Configuration' tooltip="Configuration"
                          (togglePanel)="toggleRight($event)"
                          [toggleState]="isRightColHidden">
        </app-panel-toggle>
      </div>
      <div class="flex justify-end">
        <app-panel-toggle [ngClass]="isRightColHidden ? '' : 'hidden'"
                          tooltip="Configuration" icon="gear"
                          (togglePanel)="toggleRight($event)"
                          [toggleState]="isRightColHidden">
        </app-panel-toggle>
      </div>
    </div>
    <div id="ligting-config" class="grid grid-rows-12 grid-cols-12 gap-[10px] py-[10px] px-[20px]">
      <div class="row-span-12 h-full bg-white shadow-dark" [ngClass]="nodeListPanelClass">
        <app-node-list-card
          [sortByFields]='sortByFields'
          [buildingId]="building?.id"
          [floorId]="floorId"
          [isDriverDetailsActive]='false'/>
      </div>
      <div class="row-span-12 bg-white rounded-sm text-inverse shadow-dark h-full relative" [ngClass]="floorPanelClass">
        <app-floorplan [buildingId]="building.id" (onNodeClick)="nodeClick($event)" />
        <button mat-fab
                extended="true"
                color="primary"
                [disabled]="!isSelectedNodeReady"
                [matTooltip]="isNodePending(selectedNode) ? 'Waiting for Response': 'Query Selected Node'"
                matTooltipClass="mat-tooltip font-15"
                matTooltipPosition="above"
                aria-label="Button to refresh nodes in the floorplan"
                (click)="querySelectedNode()"
                class="query-node-button"
                data-cy="btn-query-nodes">
          <mat-icon>youtube_searched_for</mat-icon>
          {{ isNodePending(selectedNode) ? 'Querying...' : 'Query' }}
        </button>
      </div>
      <div class="row-span-12 overflow-y-auto overflow-x-hidden" [ngClass]="configPanelClass">
        @if (isNodePending(selectedNode)) {
          <div class='absolute top-1/2 right-10 lg:right-16 xl:right-24 2xl:right-32 3xl:right-40 z-[1000] opacity-40'>
            <mat-spinner></mat-spinner>
          </div>
        }
        <app-lighting-config-panel [buildingId]="building.id" [floorId]='floorId' [isReady]="isSelectedNodeReady" [selectedNode]='selectedNode'/>
      </div>
    </div>
  </div>
}
