<div class='or-tags' id="tags-component">
  @if (isCreateEnabled) {
    <app-create-tag-form (createTag)='createTag($event)' [buildingId]='buildingId' />
  }
  <div #tagList [ngClass]="{'or-tag-list-container': isCreateEnabled}">
    @if (tags$ | async; as tags) {
      @if (tags?.length === 0) {
        <app-notification-block [statusClass]='getNoTagStyle()'
                                [header]="'No tags available on this floor'">
        </app-notification-block>
      }
    }
    @for (tag of tags$ | async; track tag.id) {
      <div
        class='or-tags-list-item'
        [ngStyle]='{ "background-color": "#" + tag.color }'
        [ngClass]='{ "or-active": tag.isActive }'>
        <div class='or-inline-item'>
          <button [attr.data-cy]="'tag-toggle-checkbox-' + tag.id" class='or-button or-tags-list-toggle' (click)='toggle(tag)'>
            @if (isOfTenantType(tag)) {
              <span
                class='or-icon or-small or-inverse or-icon-checkbox or-icon-shadow'
                [ngClass]='getCheckboxClass(tag)'
                appIsAuthorizedForBuilding="MANAGE_TENANT" [appBuildingIdToAuthorize]="buildingId">
              </span>
              <span [attr.data-cy]="'tenant-tag-icon-' + tag.id"
                    class="or-icon or-tiny or-icon-tenant or-inverse"
                    title='Tag is part of a Tenant'>
              </span>
            }
            @if (!isOfTenantType(tag)) {
              <span
                class='or-icon or-small or-inverse or-icon-checkbox or-icon-shadow'
                [ngClass]='getCheckboxClass(tag)'>
              </span>
            }
            <span [attr.data-cy]="'tag-name-label-' + tag.id"
                  class='or-button-label or-tag-name'
                  [ngStyle]='getTagNameMaxWidth()'
                  [title]='tag.name'>
            {{tag.name}}
          </span>
          </button>
          @if (isEditable) {
            <div class='or-inline-item or-tags-list-actions'>
              <div class='or-inline-item'>
                <button
                  [attr.data-cy]="'tag-edit-form-show-button-' + tag.id"
                  [disabled]='isOfTenantType(tag) && !(hasTenantPermission() | async)' class='or-button or-iconic or-tags-list-action'
                  (click)='tag.showEditForm = !tag.showEditForm'>
                  <span class='or-icon or-tiny or-inverse or-icon-edit or-icon-shadow'></span>
                </button>
              </div>
              <div class='or-inline-item'>
                <button [attr.data-cy]="'delete-tag-button-' + tag.id"
                        [disabled]='isOfTenantType(tag)' class='or-button or-iconic or-tags-list-action'
                        (click)='deleteTag(tag)'>
                  <span class='or-icon or-tiny or-inverse or-icon-cross or-icon-shadow'></span>
                </button>
              </div>
            </div>
          }
        </div>
        @if (tag.showEditForm) {
          <app-edit-tag-form [tag]='tag'
                             (tagChanged)='tagChanged(tag, $event)'
                             (tagReset)='reset($event)'
                             (tagUpdate)='updateTag($event)'
                             [buildingId]='buildingId'>
          </app-edit-tag-form>
        }
      </div>
    }
  </div>
</div>
