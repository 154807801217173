import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { concatMap, Observable, Subject, Subscription } from 'rxjs';
import { UserService } from '@app/shared/services/user/user.service';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { CalendarDatasource } from '@app/administration/calendar/calendar.datasource';
import { MatPaginator } from '@angular/material/paginator';
import { CalendarEventResource } from '@app/shared/resources/calendar-event.resource';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmComponent } from '@app/shared/components/dialogs/confirm/confirm.component';
import {
  CalendarEventDialogComponent,
  CalendarEventDialogData,
  DialogResult,
  FORM_TYPE
} from '@app/administration/calendar/calendar-event-dialog/calendar-event-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from '@angular/material/table';
import { MatFabButton, MatMiniFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-administration-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  imports: [
    NgIf,
    MatProgressSpinner,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatMiniFabButton,
    MatTooltip,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatPaginator,
    MatFabButton,
    AsyncPipe
  ]
})
export class AdministrationCalendarComponent implements OnInit, OnDestroy {
  public calendarDataSource: CalendarDatasource;
  public invitationDisplayedColumns = ['name', 'date', 'actions'];
  public isBusy = true;
  public buildingId: number;
  public filterKeyUp = new Subject<KeyboardEvent>();
  private filterSubscription: Subscription;
  public readonly calendarEventsPageSize = 20;
  @ViewChild('calendarEventPaginator') calendarEventPaginator: MatPaginator;
  constructor(
    private readonly userService: UserService,
    private navigationService: NavigationService,
    private calendarEventResource: CalendarEventResource,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  private static openConfirmDialog(dialog: MatDialog, message: string): Observable<any> {
    const config = new MatDialogConfig();
    config.autoFocus = true;
    config.data = { message };
    config.width = '600px';
    const dialogRef = dialog.open(ConfirmComponent, config);
    return dialogRef.afterClosed();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        concatMap((params) => {
          const { buildingId } = params;
          return this.userService.getBuilding(buildingId);
        })
      )
      .subscribe((building) => {
        this.navigationService.initNavigation(window.location.href, building);
        this.buildingId = building.id;
        this.setup();
      });
  }

  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
  }

  private setup(): void {
    this.isBusy = false;
    this.calendarDataSource = new CalendarDatasource(this.calendarEventResource);
    this.loadCalendarEvents();
  }

  private loadCalendarEvents(): void {
    this.calendarDataSource.loadCalendarEvents(this.buildingId, 0, this.calendarEventsPageSize);
  }

  public dateInLocale(date: string): string {
    return date ? new Date(date).toLocaleDateString() : new Date().toLocaleDateString();
  }

  addCalendarEvent(): void {
    this.openCalendarEventDialog(this.dialog).subscribe((result: DialogResult) => {
      if (result) {
        const payload = { ...result.formValue };
        this.calendarEventResource.createCalendarEvent(this.buildingId, payload).subscribe({
          next: (_) => {
            this.loadCalendarEvents();
          },
          error: (error) => {
            console.error('There was an error creating the calendar event!', error);
          }
        });
      }
    });
  }

  updateCalendarEvent(calendarEvent): void {}

  removeCalendarEvent(calendarEvent): void {
    const message = `Are you sure you want to delete the calendar event: ${calendarEvent.name}?`;
    AdministrationCalendarComponent.openConfirmDialog(this.dialog, message).subscribe((val) => {
      if (val) {
        this.calendarEventResource
          .deleteCalendarEvent(this.buildingId, calendarEvent.id)
          .subscribe(() => this.loadCalendarEvents());
      }
    });
  }

  private openCalendarEventDialog(dialog: MatDialog): Observable<any> {
    const config = new MatDialogConfig<CalendarEventDialogData>();
    config.autoFocus = true;
    config.data = {
      buildingId: this.buildingId,
      mode: FORM_TYPE.CREATE
    };
    config.width = '600px';
    const dialogRef = dialog.open(CalendarEventDialogComponent, config);
    return dialogRef.afterClosed();
  }
}
