import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ManagingCompanyService } from '@app/shared/services/managing-company.service';
import { ManagingCompanyDatasource } from '@app/global-administration/managing-company/managing-company.datasource';
import { ManagingCompany } from '@app/shared/models/building.interface';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ManagingCompanyDialogComponent } from '@app/global-administration/managing-company/managing-company-dialog/managing-company-dialog.component';
import { Observable } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatMiniFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { ToastService } from '@services/toast/toast.service';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { ConfirmDialogData } from '@components/dialogs/confirm/confirm.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-managing-company',
  templateUrl: './managing-company.component.html',
  styleUrls: ['./managing-company.component.scss'],
  imports: [
    NgIf,
    MatProgressSpinner,
    MatFormField,
    MatLabel,
    MatInput,
    MatMiniFabButton,
    MatIcon,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    AsyncPipe,
    FormsModule
  ]
})
export class GlobalAdministrationManagingCompanyComponent implements OnInit, AfterViewInit {
  dataSource: ManagingCompanyDatasource;

  displayedColumns = ['id', 'companyName', 'actions'];
  companyName: string;

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  constructor(
    private managingCompanyService: ManagingCompanyService,
    private dialog: MatDialog,
    private toastService: ToastService,
    private confirmationDialogService: ConfirmationDialogService
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.dataSource = new ManagingCompanyDatasource(this.managingCompanyService);
    this.dataSource.loadManagingCompanies();
  }

  addManagingCompany(): void {
    const companyName = this.companyName;
    this.managingCompanyService.saveManagingCompany({ id: null, companyName }).subscribe({
      next: (data) => {
        this.dataSource.loadManagingCompanies();
        this.companyName = null;
        this.toastService.success({
          message: 'Created ' + data.companyName,
          dataCy: 'managing-company-success'
        });
      },
      error: (error) => {
        this.toastService.error({
          message: "Error when adding new Managing Company, please check the name doesn't already exist",
          dataCy: 'managing-company-create-error'
        });
      }
    });
  }

  editManagingCompany(managingCompany: ManagingCompany): void {
    this.openEditManagingCompanyDialog(this.dialog, managingCompany).subscribe((val) => {
      managingCompany.companyName = val.companyName;
      this.managingCompanyService.saveManagingCompany(managingCompany).subscribe({
        next: (data) => {
          this.toastService.success({
            message: 'Updated ' + data.companyName,
            dataCy: 'managing-company-update-success'
          });
        },
        error: (error) => {
          this.toastService.error({
            message: "Error when updating Managing Company, please check the name doesn't already exist",
            dataCy: 'managing-company-update-error'
          });
        }
      });
    });
  }

  deleteManagingCompany(managingCompany: ManagingCompany): void {
    const data = new ConfirmDialogData(
      `Delete managing company named: '${managingCompany.companyName}'?`,
      'Delete Managing Company'
    );
    this.confirmationDialogService.open(data).subscribe((confirm) => {
      if (confirm) {
        this.managingCompanyService.deleteManagingCompany(managingCompany.id).subscribe({
          next: (data) => {
            console.log(data);
            this.toastService.success({
              message: 'Deleted ' + managingCompany.companyName,
              dataCy: 'managing-company-delete-success'
            });
            this.dataSource.loadManagingCompanies();
            this.companyName = null;
          },
          error: (error) => {
            this.toastService.error({
              message: 'Error when deleting Managing Company, please check it exist',
              dataCy: 'managing-company-delete-error'
            });
          }
        });
      }
    });
  }

  openEditManagingCompanyDialog(dialog: MatDialog, managingCompany: ManagingCompany): Observable<any> {
    const config = new MatDialogConfig();
    config.autoFocus = true;
    config.data = {
      ...managingCompany
    };
    config.width = '600px';
    const dialogRef = dialog.open(ManagingCompanyDialogComponent, config);
    return dialogRef.afterClosed();
  }
}
