import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OtapUpgradeService } from '@services/otap-upgrade/otap.upgrade.service';
import { OtapUpgradesDatasource } from '@app/global-administration/otap-upgrades/otap-upgrades.datasource';
import { tap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  AddJobDialogData,
  CreateJobsDialogComponent,
  DialogResult,
  FORM_TYPE
} from '@app/global-administration/otap-upgrades/create-jobs-dialog/create-jobs-dialog.component';
import { AddJobDTO, JobTaskTemplateMap } from '@app/shared/models/otap.upgrade.interface';
import { switchMap } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-otap-upgrades',
  templateUrl: './otap-upgrades.component.html',
  styleUrls: ['./otap-upgrades.component.scss'],
  imports: [
    NgIf,
    MatProgressSpinner,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatPaginator,
    MatFabButton,
    MatTooltip,
    MatIcon,
    AsyncPipe
  ]
})
export class GlobalAdministrationOtapUpgradesComponent implements OnInit, AfterViewInit {
  displayedColumns = ['id', 'name', 'jobTaskTemplateName', 'status', 'createdDate', 'createdBy'];

  dataSource: OtapUpgradesDatasource;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private route: ActivatedRoute,
    private otapUpgradeService: OtapUpgradeService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.dataSource = new OtapUpgradesDatasource(this.otapUpgradeService);
    this.dataSource.loadOtapUpgrades(0, 15);
  }

  ngAfterViewInit(): void {
    this.paginator.page.pipe(tap(() => this.loadOtapUpgradesPage())).subscribe();
  }

  loadOtapUpgradesPage() {
    this.dataSource.updatePaginatedData(this.paginator.pageIndex, this.paginator.pageSize);
  }

  addJobs() {
    this.openAddJobsDialog(this.dialog).subscribe((result: DialogResult) => {
      if (result) {
        const payload: AddJobDTO = {
          name: result.formValue.name,
          jobTaskTemplate: result.formValue.jobTaskTemplate
        };
        this.otapUpgradeService.addJob(payload).subscribe({
          next: (_) => {
            this.dataSource.loadOtapUpgrades(this.paginator.pageIndex, this.paginator.pageSize);
          },
          error: (error) => {
            console.error('There was an error adding the job', error);
          }
        });
      }
    });
  }

  private openAddJobsDialog(dialog: MatDialog) {
    const config = new MatDialogConfig<AddJobDialogData>();

    return this.otapUpgradeService.getJobTemplates().pipe(
      switchMap((jobTemplates: JobTaskTemplateMap) => {
        config.data = {
          mode: FORM_TYPE.ADD,
          jobTaskTemplate: jobTemplates
        };
        config.autoFocus = true;
        config.width = '600px';
        const dialogRef = dialog.open(CreateJobsDialogComponent, config);
        return dialogRef.afterClosed();
      })
    );
  }

  onJobClicked(row: any) {
    this.otapUpgradeService.updateSelectedJob(row);
    this.router.navigate(['job-information'], {
      relativeTo: this.route
    });
  }
}
