import { Component, OnInit } from '@angular/core';
import { concatMap, Observable, of } from 'rxjs';
import { Building } from '@app/shared/models/building.interface';
import { HeaderService } from '@services/header.service';
import { UserService } from '@services/user/user.service';
import { NavigationService } from '@services/navigation/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SchedulesListComponent } from '@app/schedules/schedules-list/schedules-list.component';

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrl: './schedules.component.scss',
  standalone: true,
  imports: [CommonModule, SchedulesListComponent]
})
export class SchedulesComponent implements OnInit {
  building$: Observable<Building>;

  constructor(
    private readonly header: HeaderService,
    private readonly userService: UserService,
    private readonly navigationService: NavigationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.header.showBuildingsMenu();
    this.header.showSiteMenu();
    this.header.showUserMenu();
    this.header.showSessionMenu();
    this.header.hideFloorsMenu();
    this.setBuildingId();
  }

  setBuildingId(): void {
    this.route.params
      .pipe(
        concatMap((params) => {
          const { buildingId } = params;
          return this.userService.getBuilding(buildingId);
        })
      )
      .subscribe((building) => {
        this.navigationService.initNavigation(window.location.href, building);
        this.building$ = of(building);
      });
  }
}
