import { Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';

@Component({
  selector: 'app-simple-weekday-list',
  templateUrl: './simple-weekday-list.component.html',
  styleUrls: ['./simple-weekday-list.component.scss'],
  imports: [FormsModule, MatButtonToggleGroup, ReactiveFormsModule, MatButtonToggle]
})
export class SimpleWeekdayListComponent {
  @Input() isEditable: boolean;
  @Input() shortLabels: boolean;
  @Input() formControl: FormControl<string[]>;

  public weekdays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

  private weekdayLabels = {
    sun: { long: 'Sunday', short: 'Sun' },
    mon: { long: 'Monday', short: 'Mon' },
    tue: { long: 'Tuesday', short: 'Tue' },
    wed: { long: 'Wednesday', short: 'Wed' },
    thu: { long: 'Thursday', short: 'Thu' },
    fri: { long: 'Friday', short: 'Fri' },
    sat: { long: 'Saturday', short: 'Sat' }
  };

  constructor() {}

  public produceLabel(weekday: string): string {
    const labelFormat = this.shortLabels ? 'short' : 'long';
    return this.weekdayLabels[weekday][labelFormat];
  }

  public toggleDay(weekday): void {
    if (!this.isEditable) {
      return;
    }
    // this.weekdays[weekday] = !this.weekdays[weekday];
  }
}
