import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, environmentToken } from '@environment';
import { IScheduleDto } from '@app/shared/models/schedule-dto';

@Injectable({
  providedIn: 'root'
})
export class ScheduleResource {
  private readonly baseUrl: string;

  constructor(private httpClient: HttpClient, @Inject(environmentToken) private environment: Environment) {
    this.baseUrl = `${this.environment.apiUrl + this.environment.apiPath}`;
  }

  getSchedules(buildingId: number): Observable<IScheduleDto[]> {
    return this.httpClient.get<IScheduleDto[]>(`${this.baseUrl}/schedule/${buildingId}`);
  }

  getEditableIds(buildingId: number): Observable<number[]> {
    return this.httpClient.get<number[]>(`${this.baseUrl}/schedule/${buildingId}/editable-ids`);
  }

  updateResource(id: number, item: IScheduleDto): Observable<IScheduleDto> {
    return this.httpClient.put<IScheduleDto>(`${this.baseUrl}/schedule/${id}`, item);
  }

  moveUp(id: number): Observable<null> {
    return this.httpClient.post<null>(`${this.baseUrl}/schedule/up/${id}`, null);
  }

  moveDown(id: number): Observable<null> {
    return this.httpClient.post<null>(`${this.baseUrl}/schedule/down/${id}`, null);
  }

  delete(id: number): Observable<null> {
    return this.httpClient.delete<null>(`${this.baseUrl}/schedule/${id}`);
  }

  saveSchedule(buildingId: number, schedule: IScheduleDto): Observable<any> {
    return this.httpClient.post<void>(`${this.baseUrl}/schedule/${buildingId}`, schedule);
  }
}
