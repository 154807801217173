import { Component, Input } from '@angular/core';
import { UserRole } from '@app/shared/models/user-role.interface';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgClass, NgIf, NgStyle } from '@angular/common';

@Component({
  selector: 'app-form-user-details',
  templateUrl: './form-user-details.component.html',
  styleUrls: ['./form-user-details.component.scss'],
  imports: [ReactiveFormsModule, FormsModule, NgIf, NgStyle, NgClass]
})
export class FormUserDetailsComponent {
  @Input() public isNew: boolean;
  @Input() public isBusy: boolean;
  @Input() public userRole: UserRole;

  public produceUserThumbnailStyle(userRole: UserRole): Record<string, string> {
    return userRole.user.pictureUrl ? { 'background-image': 'url(' + userRole.user.pictureUrl + ')' } : {};
  }

  public produceUserClass(userRole: UserRole): Record<string, boolean> {
    return !userRole.user.pictureUrl
      ? {
          'or-icon-userpic': true,
          'or-form-user-details-dafault-image': true
        }
      : {};
  }
}
