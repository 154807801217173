@if (building) {
  <div class="grid grid-cols-12 gap-[10px] pt-[10px] px-[20px]">
    <app-panel-toggle data-cy='data-set-panel-toggle-btn' class="col-span-4" label="Data Set" icon="chart" (togglePanel)="toggleLeft($event)" [toggleState]="isLeftColHidden"></app-panel-toggle>
    <div class="col-span-6"></div>
    <div class="flex col-span-2" [ngClass]="isRightColHidden ? 'justify-end' : ''">
      <app-panel-toggle data-cy='tag-panel-toggle-btn' label="Tags" icon="tag" (togglePanel)="toggleRight($event)" [toggleState]="isRightColHidden"></app-panel-toggle>
    </div>
  </div>
  <div id="metric-selector-view" class="grid grid-rows-12 grid-cols-12 gap-[10px] py-[10px] px-[20px]">
    <div class="row-span-12 overflow-y-auto" [ngClass]="isLeftColHidden ? 'hidden' : 'col-span-2'">
      <div class="h-full">
        <app-metric-selector [building]='building' [floorId]='floorId'></app-metric-selector>
      </div>
    </div>
    <div class="row-span-12" [ngClass]="floorPanelClass">
      <div class="bg-white rounded-sm p-3 text-inverse shadow-dark h-full relative flex flex-row">
        <app-floorplan class='grow heatmap-floorplan' [buildingId]="buildingId" (onNodeClick)='handleNodeClick($event)'></app-floorplan>
        <app-heatmap-legend></app-heatmap-legend>
      </div>
    </div>
    <div class="row-span-12" [ngClass]="isRightColHidden ? 'hidden' : 'col-span-2'">
      <div class="h-full">
        <app-tags [tags$]='tags$' [buildingId]='buildingId' [isEditable]='false' [isCreateEnabled]='false' (tagSelectionChange)='tagToggle($event)' [isNodeTaggingModeActive]='false'></app-tags>
      </div>
    </div>
  </div>
}
