import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Building } from '@app/shared/models/building.interface';
import { IEmergencyLightingSchedule } from '@app/shared/models/emergency-lighting-schedule.interface';
import { EmergencyLightingScheduleService } from '@app/shared/services/emergency-lighting/emergency-lighting-schedule.service';
import { DisplayTags } from '@app/shared/models/tag.interface';
import { Observable, of, Subject } from 'rxjs';
import { HotToastService } from '@ngneat/hot-toast';
import { AsyncPipe, NgIf, ViewportScroller } from '@angular/common';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmergencyLightingSchedulesSummaryComponent } from '../emergency-lighting-schedules-summary/emergency-lighting-schedules-summary.component';
import { MatDivider } from '@angular/material/divider';
import { MatFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-emergency-lighting-schedules-list',
  templateUrl: './emergency-lighting-schedules-list.component.html',
  styleUrls: ['./emergency-lighting-schedules-list.component.scss'],
  imports: [
    NgIf,
    MatSlideToggle,
    ReactiveFormsModule,
    FormsModule,
    EmergencyLightingSchedulesSummaryComponent,
    MatDivider,
    MatFabButton,
    MatTooltip,
    MatIcon,
    AsyncPipe
  ]
})
export class EmergencyLightingSchedulesListComponent implements OnInit {
  @Input() public building$: Observable<Building>;
  @Input() public tags$: Observable<DisplayTags>;
  schedules$: Observable<IEmergencyLightingSchedule[]> = of([]);
  newlyAddedScheduleIds: Set<number> = new Set();
  showNewForm = false;
  shouldShowDisabledSchedules = false;

  @ViewChild('loadingTemplate', { static: true })
  loadingTemplate: TemplateRef<any>;
  @ViewChild('successTemplate', { static: true })
  successTemplate: TemplateRef<any>;
  @ViewChild('errorTemplate', { static: true })
  errorTemplate: TemplateRef<any>;

  private destroy$ = new Subject<void>();

  constructor(
    private scheduleService: EmergencyLightingScheduleService,
    private toast: HotToastService,
    private viewportScroller: ViewportScroller,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadSchedules();
  }

  private loadSchedules(): void {
    this.schedules$ = this.scheduleService.buildingELMTSchedules$.pipe(
      takeUntil(this.destroy$),
      this.toast.observe({
        loading: this.loadingTemplate,
        success: this.successTemplate,
        error: this.errorTemplate
      })
    );
    this.activatedRoute.fragment.pipe(takeUntil(this.destroy$)).subscribe((fragment) => {
      this.scrollWithinView(fragment);
    });
  }

  private scrollWithinView(fragment: string): void {
    setTimeout(() => {
      this.viewportScroller.scrollToAnchor(fragment);
    }, 400);
  }

  public createNew(): void {
    this.showNewForm = true;
    this.router
      .navigate([], {
        relativeTo: this.activatedRoute,
        fragment: 'schedule-new'
      })
      .catch((err) => console.error(err));
  }

  public closeNew(): void {
    this.showNewForm = false;
    this.scrollWithinView('');
  }

  public refreshList(): void {
    this.showNewForm = false;
    this.loadSchedules();
  }

  onNewScheduleCreated(scheduleId: number): void {
    this.newlyAddedScheduleIds.add(scheduleId);
  }

  toggleShowDisabledSchedules(): void {
    this.shouldShowDisabledSchedules = !this.shouldShowDisabledSchedules;
  }
}
