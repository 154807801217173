import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { BacnetArea } from '@app/shared/models/bacnet-area.interface';

@Injectable({
  providedIn: 'root'
})
export class BacnetResource {
  private readonly baseUrl: string;

  constructor(private http: HttpClient, @Inject(environmentToken) private environment: Environment) {
    this.baseUrl = `${environment.apiUrl}${environment.apiPath}`;
  }

  getAreas(buildingId: number): Observable<BacnetArea[]> {
    return this.http.get<BacnetArea[]>(`${this.baseUrl}/building/${buildingId}/bacnet-area`);
  }

  delete(areaId: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/bacnet-area/${areaId}`);
  }

  addArea(area: BacnetArea): Observable<BacnetArea> {
    return this.http.post<BacnetArea>(`${this.baseUrl}/bacnet-area`, area);
  }

  updateArea(area: BacnetArea): Observable<BacnetArea> {
    return this.http.put<BacnetArea>(`${this.baseUrl}/bacnet-area/${area.id}`, area);
  }
}
