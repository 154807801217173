import { Resolution } from '@app/analytics/metric-widget/data-objects/resolution';
import { Dates } from '@app/analytics/metric-widget/query/outline/Dates';
import { add, format } from 'date-fns';
import { Duration } from 'date-fns/types';

export class TimeScale {
  private values: number[] = [];

  constructor(
    public resolution: Resolution,
    public getPointLabel: (value: number) => string,
    private name: keyof Duration,
    private formatString: string,
    public longFormat: string,
    private _updateDate: (date: Date, values: number) => Date,
    private _fromDate: (date: Date) => number,
    private _get: (dates: Dates) => number[],
    private _set: (dates: Dates, values: number[]) => {},
    public isUsedToInitialize: boolean = false
  ) {}

  public shiftDate(date: Date, amount: number): Date {
    return add(date, { [this.name]: amount });
  }

  public select(blocks: number[]): void {
    this.values = blocks;
    this.values.sort((a, b) => a - b);
  }

  public hasValues(): boolean {
    return this.values.length > 0;
  }

  public getValues(): number[] {
    return this.values;
  }

  public isSingleValue(): boolean {
    return this.values.length === 1;
  }

  public getSingleValue(): number {
    return this.values[0];
  }

  public updateDate(date: Date): Date {
    return this._updateDate(date, this.values[0]);
  }

  public toDates(dates: Dates): void {
    this._set(dates, this.values);
  }

  public fromDates(dates: Dates): void {
    const update = this._get(dates);

    if (update != null && update.length > 0) {
      this.values = update;
    }
  }

  public reset(): void {
    this.values = [];
  }

  public setSingleValue(date: Date): void {
    this.values = [this._fromDate(date)];
  }

  public isActive(value: number): boolean {
    return this.values.includes(value);
  }

  public getLabel(date: Date): string {
    return this.values
      .map((value) => {
        const updatedDate = this._updateDate(date, value);
        return format(updatedDate, this.formatString);
      })
      .join(', ');
  }
}
