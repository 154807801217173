import { Component, OnDestroy, OnInit } from '@angular/core';
import { first, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, concatMap, Observable, Subject, tap } from 'rxjs';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ImageService } from '@services/image.service';
import { EmergencyLightingTestService } from '@services/emergency-lighting/emergency-lighting-test.service';
import { UserService } from '@services/user/user.service';
import { NavigationService } from '@services/navigation/navigation.service';
import {
  EmergencyLightingBuildingDetails,
  EmergencyLightingBuildingForm
} from '@app/shared/models/emergency-lighting-building-details';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@services/toast/toast.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { SharedComponentsModule } from '../../shared/shared-components.module';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-administration-emergency-lighting',
  templateUrl: './emergency-lighting.component.html',
  styleUrls: ['./emergency-lighting.component.scss'],
  imports: [NgIf, ReactiveFormsModule, MatSlideToggle, SharedComponentsModule, MatButton, AsyncPipe]
})
export class AdministrationEmergencyLightingComponent implements OnInit, OnDestroy {
  public emergencyLightingForm: FormGroup<EmergencyLightingBuildingForm>;
  public readonly error$ = new Subject<void>();
  public readonly logoUrl$ = new BehaviorSubject<string>(null);
  private buildingId: number;
  public details$: Observable<EmergencyLightingBuildingDetails>;
  public readonly onDestroy$ = new Subject<void>();
  public readonly reset$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private imageService: ImageService,
    private emergencyLightingTestService: EmergencyLightingTestService,
    private buildingService: UserService,
    private navigationService: NavigationService,
    private toast: ToastService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.emergencyLightingForm = this.fb.group({
      bannerEnabled: [true],
      reportThumbnailImage: [''],
      id: []
    });
    this.route.params
      .pipe(
        concatMap((params) => {
          const { buildingId } = params;
          return this.buildingService.getBuilding(buildingId);
        })
      )
      .subscribe((building) => {
        this.buildingId = building.id;
        this.init();
        this.navigationService.initNavigation(window.location.href, building);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public init(): void {
    this.details$ = this.emergencyLightingTestService.getDetails(this.buildingId).pipe(
      tap((details) => {
        if (details.logoName != null) {
          const imageUrl = this.imageService.getUploadedImageUrl('elmt/' + details.logoName);
          this.logoUrl$.next(imageUrl);
        }
        this.emergencyLightingForm.patchValue({
          bannerEnabled: details.bannerEnabled,
          reportThumbnailImage: details.logoName,
          id: details.id
        });
      })
    );
    this.initReset();
  }

  public onLogoUpload = (imageFile: File) => {
    this.imageService
      .uploadImage(imageFile, this.buildingId)
      .pipe(first())
      .subscribe({
        next: (imageName) => {
          const imageUrl = this.imageService.getUploadedImageUrl('elmt/' + imageName);
          this.logoUrl$.next(imageUrl);
          this.emergencyLightingForm.get(['reportThumbnailImage']).setValue(imageName);
          this.emergencyLightingForm.markAsDirty();
        },
        error: (e) => this.error$.next(e)
      });
  };

  public submit(): void {
    const { reportThumbnailImage, bannerEnabled, id } = this.emergencyLightingForm.value;
    const updatedBuildingDetails = {
      buildingId: this.buildingId,
      logoName: reportThumbnailImage,
      bannerEnabled,
      id
    };
    updatedBuildingDetails.logoName =
      updatedBuildingDetails.logoName && updatedBuildingDetails.logoName !== ''
        ? updatedBuildingDetails.logoName
        : undefined;
    this.emergencyLightingTestService.updateDetails(this.buildingId, updatedBuildingDetails).subscribe((_) => {
      this.toast.success({ message: 'Details saved', dataCy: 'save-success-toast' });
    });
  }

  private initReset(): void {
    combineLatest([this.details$, this.reset$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([buildingDetails]) => {
        this.emergencyLightingForm.reset(buildingDetails);
        const imageUrl = this.imageService.getUploadedImageUrl('elmt/' + buildingDetails.logoName);
        this.logoUrl$.next(imageUrl);
      });
    this.reset();
  }

  public reset(): void {
    this.reset$.next();
  }

  public toggleBanner(): void {
    this.emergencyLightingForm.markAsDirty();
  }
}
