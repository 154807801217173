<div class="or-schedule-summary"
  [ngClass]='{ "or-schedule-summary-collapsed": isCollapsed, "or-schedule-summary-paused": !schedule.enabled,
  "or-schedule-summary-always-active": schedule.alwaysActive, "or-disabled": !schedule.editable }'>
  <div class="or-schedule-summary-header" [ngClass]="summaryStateClass">
    @if(schedule.editable) {
      <div class="or-inline-item or-schedule-summary-actions">
        @if(!isNew) {
          <div class="or-inline-item or-schedule-summary-action" (mouseenter)="toggleControlActionsMenu(true)" (mouseleave)="toggleControlActionsMenu(false)">
            <div class="or-stacked-item or-schedule-summary-control-action" [ngClass]="getControlActionClass(0)">
              <button mat-button class="or-button or-iconic or-small" [matTooltip]="activeActionIndex != 0 ? 'Resume schedule': ''" (click)="toggleEnabledState(true)"
                type="button" [disabled]="isNew">
                <span class="or-icon or-tiny or-icon-play-alternate"></span>
              </button>
            </div>
            <div class="or-stacked-item or-schedule-summary-control-action" [matTooltip]="activeActionIndex != 1 ? 'Pause schedule': ''"  [ngClass]="getControlActionClass(1)">
              <button mat-button class="or-button or-iconic or-small" (click)="toggleEnabledState(false)" [disabled]="isNew">
                <span class="or-icon or-tiny or-icon-pause-alternate"></span>
              </button>
            </div>
            <div class="or-stacked-item or-schedule-summary-control-action" [matTooltip]="activeActionIndex != 2 ? 'Always active': ''" [ngClass]="getControlActionClass(2)">
              <button mat-button class="or-button or-iconic or-small" (click)="toggleAlwaysActiveState(true)" [disabled]="isNew">
                <span class="or-icon or-tiny or-icon-playlock"></span>
              </button>
            </div>
          </div>
        }
        <div class="or-inline-item or-schedule-summary-action">
          <button class="or-button or-iconic or-small" (click)="moveUp()" title="Move Up"
                  [disabled]="isNew || schedule.active" data-cy="schedule-summary-move-up">
            <span class="or-icon or-tiny or-icon-arrow-up"></span>
          </button>
        </div>
        <div class="or-inline-item or-schedule-summary-action">
          <button class="or-button or-iconic or-small" (click)="moveDown()" title="Move Down"
                  [disabled]="isNew || schedule.active" data-cy="schedule-summary-move-down">
            <span class="or-icon or-tiny or-icon-arrow-down"></span>
          </button>
        </div>
      </div>
    }
    <div class="or-schedule-summary-header-description" (click)="toggleCollapse()">
      <div class="or-inline-items or-pull-left or-schedule-summary-title">
        <div class="or-inline-item">
          <span class="or-schedule-summary-name" >{{schedule.name || 'New Schedule'}}</span>
          <span class="or-schedule-summary-label" [ngClass]="this.isBusy ? 'or-pulsate':''">{{status}}</span>
          @if(promptToDisable) {
            <span class="or-schedule-summary-label or-pulsate">Disable First Before Editing</span>
          }
        </div>
      </div>
      @if(scheduleType === 'dated' && !schedule.alwaysActive){
        <ul class="or-inline-items or-pull-right or-schedule-summary-weekdays">
          <div data-cy="schedule-summary-start-end-date">
            <mat-icon class="pt-1 text-xl">calendar_month</mat-icon>
            <span class="align-super">{{toDateString(schedule.startDate)}}</span>
            <mat-icon class="pt-1 text-xl">arrow_forward</mat-icon>
            <mat-icon class="pt-1 text-xl">calendar_month</mat-icon>
            <span class="align-super">{{toDateString(schedule.endDate)}}</span>
          </div>
        </ul>
      }
      @if(scheduleType === 'weekday' && !schedule.alwaysActive){
        <div class="or-inline-items or-pull-right or-schedule-summary-weekdays">
         @for(day of daysOfTheWeek; track  $index) {
          <div class="or-inline-item">
            <span class="or-schedule-summary-weekday" [ngClass]="getCircleClass(day.ordinal)"
                  title="{{getDayOfWeekLabel(day)}}">
            </span>
          </div>
          }
        </div>
      }
    </div>
  </div>

  @if(!schedule.alwaysActive) {
    <div class="pl-2">
      @if(scheduleType === 'weekday' || (scheduleType === 'dated' && isSameDay()) ) {
        <app-timerange-widget
          [startTime]="schedule.startTime"
          [endTime]="schedule.endTime"
          [minHours]="1"
          [isValidRange]="true"
          [isPaused]="status === 'Paused'">
        </app-timerange-widget>
      }

      @if(scheduleType === 'dated' && !isSameDay()) {
        <app-timerange-widget
          [startTime]="schedule.startTime"
          [endTime]="endOfDay"
          [minHours]="1"
          [isValidRange]="true"
          [isPaused]="status === 'Paused'"
          [hideEndLabel]="true">
        </app-timerange-widget>
        <app-timerange-widget
          [startTime]="startOfDay"
          [endTime]="schedule.endTime"
          [minHours]="1"
          [isValidRange]="true"
          [isPaused]="status === 'Paused'"
          [hideStartLabel]="true">
        </app-timerange-widget>
      }
    </div>
  }
  @if (schedule.active || !isNew && isCollapsed) {
    <div class="or-schedule-summary-content or-schedule-summary-content-overview" (click)="toggleCollapse()">
      <div class="or-stacked-items or-schedules-summary-rules">
        @for(rule of schedule.rules; track $index){
          <app-schedule-tile [rule]="rule"></app-schedule-tile>
        }
      </div>
    </div>
  }

  @if(!schedule.active && (isNew || !isCollapsed)) {
    <div class="or-schedule-summary-content">
      <mat-button-toggle-group [(ngModel)]="scheduleType" (change)="toggleSchedule($event)" [hideSingleSelectionIndicator]="true">
        <mat-button-toggle
          [attr.data-cy]="'create-dated-schedules-slider'"
          [value]="'weekday'"
          [disabled]="!isNew"
          class="w-40">
          Week Days
        </mat-button-toggle>
        <mat-button-toggle
          [attr.data-cy]="'create-dated-schedules-slider'"
          [value]="'dated'"
          [disabled]="!isNew"
          class="w-40">
           Date and Time
        </mat-button-toggle>
      </mat-button-toggle-group>
      <div class="or-schedule-summary-content-form">
        @if(scheduleType === 'dated'){
          <app-form-dated-schedule [schedule]="schedule"
                                   [building]="building"
                                   (onSaveNewSchedule)="saveNewSchedule()"
                                   (onClose)="close()"
                                   (onDeleteSchedule)="deleteSchedule()">
          </app-form-dated-schedule>
        } @else {
          <app-form-weekday-schedule [schedule]="schedule"
                                     [building]="building"
                                     (onSaveNewSchedule)="saveNewSchedule()"
                                     (onClose)="close()"
                                     (onDeleteSchedule)="deleteSchedule()">
          </app-form-weekday-schedule>
        }
      </div>
    </div>
  }
</div>
