<form #userForm="ngForm" class="or-form or-form-user-details" name="userForm" novalidate="novalidate">
  <div class="or-form-body" *ngIf="userRole">
    <ol class="or-form-items">
      <li class="or-form-item or-form-user-details-id">
        {{ userRole.userId ? "#" + userRole.userId : "" }}
      </li>
      <li
          class="or-form-item or-form-user-details-image"
          [ngStyle]="produceUserThumbnailStyle(userRole)"
          [ngClass]="produceUserClass(userRole)"
      ></li>
      <li class="or-form-item or-form-user-details-name">
        {{ userRole.user.name || "N/A" }}
      </li>
      <li class="or-form-item or-form-user-details-roles">
<!--        <app-multiselect-->
<!--            [isEditMode]="true"-->
<!--            [allOptions$]="getRoleOptions()"-->
<!--        ></app-multiselect>-->
      </li>
<!--      <li class="or-form-item" *ngIf="!isEditMode">-->
<!--        <button-->
<!--            class="or-button or-iconic or-primary or-edit"-->
<!--            type="button"-->
<!--            ng-click="startEditMode()"-->
<!--            ng-disabled=""-->
<!--            title="Edit"-->
<!--        >-->
<!--          <span class="or-icon or-tiny or-inverse or-icon-edit"></span>-->
<!--        </button>-->
<!--      </li>-->
<!--      <li class="or-form-item" *ngIf="isEditMode">-->
<!--        <button-->
<!--            class="or-button or-iconic or-primary or-save"-->
<!--            type="button"-->
<!--            ng-click="userDetails.save()"-->
<!--            ng-disabled=""-->
<!--            title="Save"-->
<!--        >-->
<!--          <span class="or-icon or-tiny or-inverse or-icon-checkmark"></span>-->
<!--        </button>-->
<!--      </li>-->
<!--      <li class="or-form-item" *ngIf="isEditMode">-->
<!--        <button-->
<!--            class="or-button or-iconic or-primary or-discard"-->
<!--            type="button"-->
<!--            ng-click="userDetails.discard()"-->
<!--            ng-disabled=""-->
<!--            title="Discard"-->
<!--        >-->
<!--          <span class="or-icon or-tiny or-inverse or-icon-cross"></span>-->
<!--        </button>-->
<!--      </li>-->
<!--      <li class="or-form-item" *ngIf="isEditMode">-->
<!--        <button-->
<!--            class="or-button or-iconic or-primary or-delete"-->
<!--            type="button"-->
<!--            ng-click="userDetails.delete()"-->
<!--            ng-disabled=""-->
<!--            title="Delete"-->
<!--        >-->
<!--          <span class="or-icon or-tiny or-inverse or-icon-trash"></span>-->
<!--        </button>-->
<!--      </li>-->
    </ol>
  </div>
</form>