import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { AbstractControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Building, ManagingCompany } from '@app/shared/models/building.interface';
import { LuminaireManufacturer } from '@app/shared/models/luminaire-manufacturer.interface';
import { BuildingMetadataService } from '@app/shared/services/building-metadata/building-metadata.service';
import { CountryService } from '@app/shared/services/country.service';
import { ImageService as ImageService } from '@app/shared/services/image.service';
import { LampTypeService } from '@app/shared/services/lamp-type.service';
import { TimezoneUtils } from '@app/shared/utils/timezoneUtils';
import { UserService } from '@app/shared/services/user/user.service';
import { ManagingCompanyService } from '@app/shared/services/managing-company.service';
import { BehaviorSubject, combineLatest, combineLatestWith, delay, Observable, of, Subject, switchMap } from 'rxjs';
import { first, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { AddressForm, BuildingForm } from './form.model';
import { SecurityService } from '@app/shared/services/security.service';
import { GlobalAuthority } from '@app/shared/models/global-authority';
import { SensorNodeService } from '@services/sensor-node.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BleDialogComponent } from '@app/administration/building/ble-dialog/ble-dialog.component';
import { ToastService } from '@services/toast/toast.service';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { ValidationCode } from '@app/shared/models/building-validation-response.interface';
import { CancelOnlyDialogData } from '@components/dialogs/confirm/confirm.component';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { AuthorizationModule } from '../../../directives/authorization.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButton } from '@angular/material/button';
import { SharedComponentsModule } from '../../../shared-components.module';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-form-building-details',
  templateUrl: './form-building-details.component.html',
  styleUrls: ['./form-building-details.component.scss'],
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    MatSelect,
    MatOption,
    AuthorizationModule,
    MatButton,
    SharedComponentsModule,
    MatDatepickerModule,
    AsyncPipe
  ]
})
export class FormBuildingDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private fb: UntypedFormBuilder,
    private imageService: ImageService,
    private countryService: CountryService,
    private managingCompanyService: ManagingCompanyService,
    private lampTypeService: LampTypeService,
    private buildingMetadata: BuildingMetadataService,
    private userService: UserService,
    private readonly securityService: SecurityService,
    private sensorNodeService: SensorNodeService,
    private dialog: MatDialog,
    private toastService: ToastService,
    private dialogService: ConfirmationDialogService
  ) {}

  @Input() public isNew: boolean;
  @Input() public details$: Observable<Building>;
  @Input() public success$?: Subject<number>;

  readonly imageUpdate$ = new Subject<void>();
  readonly error$ = new Subject<void>();
  readonly submit$ = new Subject<void>();
  readonly reset$ = new Subject<void>();
  readonly imageUrl$ = new BehaviorSubject<string>(null);
  readonly onDestroy$ = new Subject<void>();
  buildingForm: UntypedFormGroup;
  thumbnailImage: string;
  countries$: Observable<string[]> = of([]);
  timezones$: Observable<string[]> = of([]);
  luminaireManufacturers$: Observable<LuminaireManufacturer[]> = of([]);
  managingCompanies$: Observable<ManagingCompany[]> = of([]);
  isAuthorizedIsNew$: Observable<boolean>;
  imageWidth = this.getImageWidth();
  isLoading = signal(false);

  private userCanManageBuildings = false;
  private readonly ADD_BUILDING_WAIT_MS = 750;

  /*  This was added to fix the image resolution in the start of the page.
   **  otherwise when isNew undefined image resolution gets incorrect value
   */
  ngAfterViewInit(): void {
    this.imageWidth = this.getImageWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.imageWidth = this.getImageWidth();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngOnInit(): void {
    this.countries$ = this.countryService.getCountries();
    this.timezones$ = TimezoneUtils.getTimezones();
    this.securityService.isAuthorized(GlobalAuthority.MANAGE_LUMINAIRE_MANUFACTURERS.toString()).subscribe({
      next: (result) =>
        (this.luminaireManufacturers$ = result ? this.lampTypeService.getLuminaireManufacturers() : of([])),
      error: (err) => this.error$.next(err)
    });

    this.managingCompanies$ = this.managingCompanyService.getManagingCompanies();
    this.securityService.isAuthorized(GlobalAuthority.MANAGE_BUILDINGS.toString()).subscribe({
      next: (result) => {
        if (result) {
          this.isAuthorizedIsNew$ = this.isAuthorizedNew(GlobalAuthority.MANAGE_BUILDINGS.toString());
          this.userCanManageBuildings = true;
        }
      },
      error: (err) => this.error$.next(err)
    });

    const formControls = this.initFormControls();
    this.buildingForm = this.fb.group(formControls);

    this.initLatLong(formControls.building);
    this.initReset();
    this.initSubmit();

    if (!this.isNew) {
      this.reset();
    }
  }

  // Manage and validate Latitude/Longitude input changes
  private initLatLong(building: UntypedFormGroup): void {
    const latControl: AbstractControl = building.get('latitude');
    const longControl: AbstractControl = building.get('longitude');
    const lat$: Observable<number | null> = latControl.valueChanges.pipe(startWith(null));
    const long$: Observable<number | null> = longControl.valueChanges.pipe(startWith(null));

    combineLatest([lat$, long$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (latLong: [number, number]) => {
          const latValue: number | null = latLong[0];
          const longValue: number | null = latLong[1];

          // Valid values are -90 to 90 for latitude and -180 to 180 for longitude
          const isNotUsed: boolean = !latValue && !longValue;
          const isValidLat: boolean = latValue && latValue >= -90 && latValue <= 90;
          const isValidLong: boolean = longValue && longValue >= -180 && longValue <= 180;

          if (isNotUsed || (isValidLat && isValidLong)) {
            latControl.setErrors(null);
            longControl.setErrors(null);
          } else if (!isValidLat && isValidLong) {
            longControl.setErrors(null);
            latControl.setErrors({ invalid: true });
          } else if (isValidLat && !isValidLong) {
            latControl.setErrors(null);
            longControl.setErrors({ invalid: true });
          }
        },
        error: (err) => this.error$.next(err)
      });
  }

  public onImageUpload = (imageFile: File) => {
    this.details$
      .pipe(switchMap((building: Building) => this.imageService.uploadImage(imageFile, building?.id)))
      .subscribe({
        next: (imageName) => {
          const imageUrl = this.imageService.getUploadedImageUrl(imageName);
          this.imageUrl$.next(imageUrl);
          this.buildingForm.get(['building', 'thumbnailImage']).setValue(imageName);
          this.buildingForm.markAsDirty();
        },
        error: (error) => this.error$.next(error)
      });
  };

  public submit(): void {
    this.submit$.next();
  }

  public reset(): void {
    this.reset$.next();
  }

  private initFormControls(): { address: UntypedFormGroup; building: UntypedFormGroup } {
    const buildingForm: BuildingForm = {
      name: ['', [Validators.required, Validators.maxLength(64)]],
      luminaireManufacturers: [''],
      timeZone: ['', Validators.required],
      thumbnailImage: [''],
      longitude: ['', Validators.maxLength(13)],
      latitude: ['', Validators.maxLength(12)],
      managingCompanyId: ['', Validators.min(0)],
      statusFrequencySeconds: [''],
      serviceLevel: [{ value: null, disabled: true }],
      buildingFloorAreaSqMeters: [{ value: null, disabled: true }],
      serviceLevelStartDate: [{ value: null, disabled: true }]
    };
    const addressForm: AddressForm = {
      country: ['', Validators.required],
      postcode: ['', Validators.maxLength(8)],
      addressLine1: ['', Validators.maxLength(128)],
      addressLine2: ['', Validators.maxLength(128)],
      addressLine3: ['', Validators.maxLength(128)]
    };
    return {
      building: this.fb.group(buildingForm),
      address: this.fb.group(addressForm)
    };
  }

  private initReset(): void {
    combineLatest([this.details$, this.reset$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: ([building]) => {
          const luminaireManufacturers = building.luminaireManufacturers || [];
          this.buildingForm.reset({
            building: { ...building, luminaireManufacturers },
            address: { ...building.address }
          });
          const imageUrl = this.buildingMetadata.thumbnailUrl(building);
          this.imageUrl$.next(imageUrl);
        },
        error: (err) => this.error$.next(err)
      });
  }

  private initSubmit(): void {
    this.submit$.pipe(withLatestFrom(this.details$, this.managingCompanies$), takeUntil(this.onDestroy$)).subscribe({
      next: ([, building, companies]) => {
        const updatedBuilding = {
          ...building,
          ...this.buildingForm.value.building,
          address: { ...building.address, ...this.buildingForm.value.address }
        };
        updatedBuilding.managingCompany = this.resolveManagingCompany(updatedBuilding.managingCompany, companies);
        // Setting thumbnail to undefined instead of empty string
        updatedBuilding.thumbnailImage =
          updatedBuilding.thumbnailImage && updatedBuilding.thumbnailImage !== ''
            ? updatedBuilding.thumbnailImage
            : undefined;

        // For some reason the above code when shallow copying building it converts empty arrays into empty string
        updatedBuilding.luminaireManufacturers =
          Array.isArray(updatedBuilding.luminaireManufacturers) && updatedBuilding.luminaireManufacturers.length !== 0
            ? updatedBuilding.luminaireManufacturers
            : undefined;

        updatedBuilding.serviceLevel = null;
        updatedBuilding.buildingFloorAreaSqMeters = null;
        updatedBuilding.serviceLevelStartDate = null;

        this.processSubmit(updatedBuilding);
      },
      error: (err) => {
        console.log(err);
        this.error$.next(err);
      }
    });
  }

  processSubmit(updatedBuilding: Building): void {
    this.userService
      .validateBuilding(updatedBuilding)
      .pipe(combineLatestWith(this.details$))
      .subscribe({
        next: ([response, existingBuilding]) => {
          if (response && response.validationCode === ValidationCode.SUCCESS) {
            if (this.isNew) {
              this.addNewBuilding(updatedBuilding);
            } else {
              this.updateExistingBuilding(updatedBuilding);
            }
          } else {
            const msgBoxData = new CancelOnlyDialogData(
              `Are you sure you want to ${this.isNew ? 'update' : 'create'} this building? ${
                response.message
              } : ${response.buildingNames.join(' , ')}`,
              `${this.isNew ? 'Update' : 'Create'} the building`
            );
            this.dialogService.open(msgBoxData).subscribe(() => {
              this.buildingForm.get('building').get('name').setErrors({ message: response.message });
            });
          }
        },
        error: () => {}
      });
  }

  addNewBuilding(updatedBuilding: Building): void {
    this.isLoading.set(true);
    this.userService.addBuilding(updatedBuilding).subscribe({
      next: (building) => {
        of(building.id)
          .pipe(delay(this.ADD_BUILDING_WAIT_MS))
          .subscribe((id) => {
            this.buildingForm.markAsPristine();
            this.toastService.success({
              message: 'Building created successfully',
              dataCy: 'success-create-building'
            });
            this.success$?.next(id);
            this.isLoading.set(false);
          });
      },
      error: () => {
        this.toastService.error({ message: 'Failed to create building', dataCy: 'error-create-building' });
        this.isLoading.set(false);
      }
    });
  }

  updateExistingBuilding(updatedBuilding: Building): void {
    this.userService.updateBuilding(updatedBuilding).subscribe({
      next: () => {
        this.resetFormAfterUpdate(updatedBuilding);
        this.toastService.success({
          message: 'Building updated successfully',
          dataCy: 'success-update-building'
        });
      },
      error: () => this.toastService.error({ message: 'Failed to update building', dataCy: 'error-update-building' })
    });
  }

  resetFormAfterUpdate(updatedBuilding: Building): void {
    this.buildingForm.markAsPristine();
    this.onDestroy$.next();
    this.details$ = this.userService.getBuilding(updatedBuilding.id);
    this.initReset();
    this.initSubmit();
  }

  private resolveManagingCompany(managingCompanyId: number, companies: ManagingCompany[]): ManagingCompany {
    const managingCompanies = companies.filter((company: ManagingCompany) => (company.id = managingCompanyId));
    return managingCompanies.length > 0 ? managingCompanies[0] : null;
  }

  private isAuthorizedNew(permission: string): Observable<boolean> {
    if (this.isNew) {
      return of(true);
    } else {
      return this.securityService.isAuthorized(permission);
    }
  }

  sendBleScanning(type: string, building: Building): void {
    if (building.gateways.filter((gw) => gw.isActive).length > 0) {
      const buildingId = building.id;
      let scanningFunc;
      let error;
      switch (type) {
        case 'QUERY':
          console.log('Query BLE ', buildingId);
          scanningFunc = () => {
            this.sensorNodeService.queryBleScanningForBuilding(buildingId).pipe(first()).subscribe();
          };
          break;
        case 'ENABLE':
          scanningFunc = () => {
            this.sensorNodeService.enableBleScanningForBuilding(buildingId).pipe(first()).subscribe();
          };
          break;
        case 'DISABLE':
          scanningFunc = () => {
            this.sensorNodeService.disableBleScanningForBuilding(buildingId).pipe(first()).subscribe();
          };
          break;
        default:
          error = true;
      }
      if (error) {
        this.toastService.error({
          message: 'Please select a BLE Command option',
          dataCy: 'building-ble-no-command-selected'
        });
      } else {
        this.openBleDialog(type).subscribe((data) => {
          if (data != null && data.event === 'Send') {
            scanningFunc(buildingId);
          }
        });
      }
    } else {
      this.toastService.error({
        message: 'There are no gateways in this building',
        dataCy: 'building-ble-no-gateways'
      });
    }
  }

  openBleDialog(type: string): Observable<any> {
    const queryType = type.charAt(0) + type.substring(1).toLowerCase();
    const config = new MatDialogConfig();
    config.autoFocus = true;
    config.width = '600px';
    config.data = queryType;
    const dialogRef = this.dialog.open(BleDialogComponent, config);
    return dialogRef.afterClosed();
  }

  getImageWidth(): number {
    if (window.innerWidth > 639) {
      return window.innerWidth / 3 > 600 || window.innerWidth < 600 ? 600 : window.innerWidth / 3;
    } else {
      return window.innerWidth - (this.isNew ? 36 : 80);
    }
  }

  private get serviceLevel(): AbstractControl {
    return this.buildingForm.get('building').get('serviceLevel');
  }

  private get serviceLevelStartDate(): AbstractControl {
    return this.buildingForm.get('building').get('serviceLevelStartDate');
  }

  private get buildingFloorAreaSqMeters(): AbstractControl {
    return this.buildingForm.get('building').get('buildingFloorAreaSqMeters');
  }
}
