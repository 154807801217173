import { SensorNode } from '@app/shared/models/sensor-node';

export class LightingConfiguration {
  nodeId: number;
  zone: number;
  zoneUpdatedAt: Date;
  maxLightLevel: number;
  maxLightLevelUpdatedAt: Date;
  lowLightLevel: number;
  lowLightLevelUpdatedAt: Date;
  minLightLevel: number;
  minLightLevelUpdatedAt: Date;
  dwellTime: number;
  dwellTimeUpdatedAt: Date;
  lowLightTime: number;
  lowLightTimeUpdatedAt: Date;
  updateStatus: string;
  scene: {
    value: number;
    updatedAt: Date;
  };

  constructor(
    nodeId: number,
    zone?: number,
    scene?: {
      value: number;
      updatedAt: Date;
    },
    maxLightLevel?: number,
    lowLightLevel?: number,
    minLightLevel?: number,
    dwellTime?: number,
    lowLightTime?: number,
    updateStatus?: string
  ) {
    this.nodeId = nodeId;
    this.zone = zone;
    this.scene = scene;
    this.maxLightLevel = maxLightLevel;
    this.lowLightLevel = lowLightLevel;
    this.minLightLevel = minLightLevel;
    this.dwellTime = dwellTime;
    this.lowLightTime = lowLightTime;
    this.updateStatus = updateStatus;
  }

  public static isZoneSupportedNode(nodeType: string): boolean {
    return (
      nodeType !== SensorNode.HIM84_NODE_TYPE &&
      nodeType !== SensorNode.HCD405_NODE_TYPE &&
      nodeType !== SensorNode.HCD038_NODE_TYPE
    );
  }

  public static fromResponse(response: any): LightingConfiguration {
    if (response == null) {
      response = {};
    }
    const config = new LightingConfiguration(
      response.nodeId,
      response.zone,
      response.scene,
      response.maxLightLevel,
      response.lowLightLevel,
      response.minLightLevel,
      response.dwellTime,
      response.lowLightTime,
      response.updateStatus
    );
    config.convertTimestampsToDates(response);
    return config;
  }

  setScene(value: number, updatedAt?: Date): void {
    this.scene = {
      value,
      updatedAt
    };
  }

  convertTimestampsToDates(response: any): void {
    this.zoneUpdatedAt = new Date(response.zoneUpdatedAt);
    this.maxLightLevelUpdatedAt = new Date(response.maxLightLevelUpdatedAt);
    this.lowLightLevelUpdatedAt = new Date(response.lowLightLevelUpdatedAt);
    this.minLightLevelUpdatedAt = new Date(response.minLightLevelUpdatedAt);
    this.dwellTimeUpdatedAt = new Date(response.dwellTimeUpdatedAt);
    this.lowLightTimeUpdatedAt = new Date(response.lowLightTimeUpdatedAt);
    if (response.scene != null) {
      this.scene.updatedAt = new Date(response.scene.updatedAt);
    }
  }

  findLastUpdatedTime(): Date {
    let lastTime = new Date(0);
    if (this.zoneUpdatedAt > lastTime) {
      lastTime = this.zoneUpdatedAt;
    }
    if (this.maxLightLevelUpdatedAt > lastTime) {
      lastTime = this.maxLightLevelUpdatedAt;
    }

    if (this.minLightLevelUpdatedAt > lastTime) {
      lastTime = this.minLightLevelUpdatedAt;
    }

    if (this.lowLightLevelUpdatedAt > lastTime) {
      lastTime = this.lowLightLevelUpdatedAt;
    }

    if (this.dwellTimeUpdatedAt > lastTime) {
      lastTime = this.dwellTimeUpdatedAt;
    }
    if (this.lowLightTimeUpdatedAt > lastTime) {
      lastTime = this.lowLightTimeUpdatedAt;
    }
    if (this.scene?.updatedAt > lastTime) {
      lastTime = this.scene.updatedAt;
    }
    return lastTime;
  }

  isPartiallyUpdated(lastTime: Date): boolean {
    return (
      this.zoneUpdatedAt > lastTime ||
      this.maxLightLevelUpdatedAt > lastTime ||
      this.minLightLevelUpdatedAt > lastTime ||
      this.lowLightLevelUpdatedAt > lastTime ||
      this.dwellTimeUpdatedAt > lastTime ||
      this.lowLightLevelUpdatedAt > lastTime ||
      this.scene?.updatedAt > lastTime
    );
  }
}
