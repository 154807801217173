import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BacnetAreaTileComponent } from '@app/integrations/bacnet-area-tile/bacnet-area-tile.component';
import { ActivatedRoute } from '@angular/router';
import { GatewayService } from '@services/gateway.service';
import { BacnetAreaService } from '@services/bacnet-area.service';
import { BacnetArea, IntegrationsTabs } from '@app/shared/models/bacnet-area.interface';
import { Gateway } from '@app/api/building/gateway/Gateway';
import { HeaderService } from '@services/header.service';
import { NotificationBlockComponent, StatusClass } from '@components/notification-block/notification-block.component';
import { combineLatest, concatMap, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Tab } from '@components/tabs/tab.interface';
import { User } from '@app/shared/models/user.interface';
import { Building } from '@app/shared/models/building.interface';
import { UserService } from '@services/user/user.service';
import { NavigationService } from '@services/navigation/navigation.service';
import { TabsComponent } from '@components/tabs/tabs.component';

@Component({
  selector: 'app-integrations',
  imports: [CommonModule, BacnetAreaTileComponent, NotificationBlockComponent, TabsComponent],
  templateUrl: './integrations.component.html',
  styleUrl: './integrations.component.scss'
})
export class IntegrationsComponent implements OnInit {
  isBusy = true;
  isGatewaysLoaded = false;
  areas: BacnetArea[];
  newArea: BacnetArea;
  gateways: Gateway[];
  buildingId: number;
  floorId: number;
  StatusClass = StatusClass;
  tabs$: Observable<Tab[]>;

  constructor(
    private route: ActivatedRoute,
    private bacnetAreaService: BacnetAreaService,
    private gatewayService: GatewayService,
    private headerService: HeaderService,
    private userService: UserService,
    private navService: NavigationService
  ) {}

  ngOnInit(): void {
    this.headerService.showBuildingsMenu();
    this.headerService.showUserMenu();
    this.headerService.showSiteMenu();
    this.headerService.showSessionMenu();
    this.headerService.hideFloorsMenu();
    this.isBusy = true;
    this.initialize();
  }

  initialize(): void {
    this.tabs$ = combineLatest([
      this.userService.getCurrentUser(),
      this.route.params.pipe(
        concatMap((params) => {
          this.floorId = params.floorId;
          this.buildingId = params.buildingId;
          this.loadAreas();
          this.loadGateways();
          this.isBusy = false;
          return this.userService.getBuilding(params.buildingId);
        })
      )
    ]).pipe(
      map(([user, building]): Tab[] => {
        this.navService.initNavigation(window.location.href, building);
        return this.createIntegrationsMenu(user, building);
      })
    );
  }

  private createIntegrationsMenu(user: User, building: Building): Tab[] {
    return IntegrationsTabs.filter((tab) => tab.isAuthorized(user, building)).map((tab) => {
      const clonedTab = { ...tab };
      clonedTab.path = tab.path
        .replace(/\{buildingId}/i, String(building.id))
        .replace(/\{floorId}/i, String(this.floorId));
      return clonedTab;
    });
  }

  public loadAreas(): void {
    this.areas = null;
    this.bacnetAreaService.getAreas(this.buildingId).subscribe({
      next: (areas) => {
        this.areas = areas;
      }
    });
  }

  public loadGateways(): void {
    this.gatewayService.getGateways(this.buildingId).subscribe({
      next: (response) => {
        this.gateways = response.gateway;
        this.isGatewaysLoaded = true;
      }
    });
  }
}
