import { Component } from '@angular/core';
import { AuthMethod } from '@app/shared/models/auth-method';
import { NgClass } from '@angular/common';
import { SessionService } from '@services/session.service';
import { LoginMethodsService } from '@services/login-method.service';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-login',
  imports: [NgClass],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  private redirectUrl = '/buildings';
  private alreadyHandledAuthError = false;
  loginMethods: AuthMethod[] = [AuthMethod.AUTH0];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly sessionService: SessionService,
    private readonly loginMethodsService: LoginMethodsService
  ) {
    combineLatest([this.loginMethodsService.getSupportedLoginMethods(), this.route.queryParams]).subscribe(
      ([loginMethods, params]) => {
        this.redirectUrl = params.redirectUrl;
        if (loginMethods && loginMethods.length === 1 && !this.alreadyHandledAuthError) {
          this.authenticate(loginMethods[0]);
        }
      }
    );
  }

  public authenticate(loginMethod: AuthMethod): void {
    if (this.redirectUrl) {
      this.sessionService.authenticate(loginMethod, this.redirectUrl);
    } else {
      this.sessionService.authenticate(loginMethod);
    }
  }

  public getLoginButtonClass(loginMethod: AuthMethod): string {
    if (AuthMethod.LINKEDIN.toString() === loginMethod.toString()) {
      return 'or-icon-linkedin';
    } else {
      return 'or-icon-or';
    }
  }

  public getLoginButtonLabel(loginMethod: AuthMethod): string {
    if (AuthMethod.LINKEDIN.toString() === loginMethod.toString()) {
      return 'Sign in with ' + AuthMethod.LINKEDIN.displayName;
    } else {
      return 'Custom authentication';
    }
  }
}
