import { Injectable } from '@angular/core';
import { AuthMethod } from '../models/auth-method';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationUrlsService {
  public readonly postLogoutRedirectBaseUrl = 'http://organicresponse.com';

  private readonly apiUrl: string;
  private readonly baseUrl: string;

  constructor() {
    const { apiUrl } = environment;
    this.apiUrl = apiUrl;
    this.baseUrl = window.location.host;
  }

  get logoutBaseUrl(): string {
    return `${this.apiUrl}/auth/perform_logout`;
  }

  authenticateBaseUrl(authMethod: AuthMethod, apiUrlNeeded = false): string {
    const method = authMethod.toString().toLowerCase();
    if (apiUrlNeeded) {
      return `${this.apiUrl}/auth/${method}?target=${this.apiUrl}`;
    }
    return `${this.apiUrl}/auth/${method}?target=http://${this.baseUrl}`;
  }
}
