export class Scene {
  constructor(private value: number, public label: Scene_) {
    this.value = value;
  }

  public static SCENE_0 = new Scene(0, 'Scene 0');
  public static SCENE_1 = new Scene(1, 'Scene 1');
  public static SCENE_2 = new Scene(2, 'Scene 2');
  public static SCENE_3 = new Scene(3, 'Scene 3');
  public static SCENE_4 = new Scene(4, 'Scene 4');
  public static SCENE_5 = new Scene(5, 'Scene 5');
  public static SCENE_6 = new Scene(6, 'Scene 6');
  public static SCENE_7 = new Scene(7, 'Scene 7');

  public static all(): Scene[] {
    return [
      Scene.SCENE_0,
      Scene.SCENE_1,
      Scene.SCENE_2,
      Scene.SCENE_3,
      Scene.SCENE_4,
      Scene.SCENE_5,
      Scene.SCENE_6,
      Scene.SCENE_7
    ];
  }

  public static fromValue(value: number): Scene {
    for (const scene of Scene.all()) {
      if (value === scene.value) return scene;
    }
  }

  public toString(): string {
    return this.label;
  }
}

type Scene_ = 'Scene 0' | 'Scene 1' | 'Scene 2' | 'Scene 3' | 'Scene 4' | 'Scene 5' | 'Scene 6' | 'Scene 7';
