import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserService } from '@app/shared/services/user/user.service';
import { CircadianCurveResource } from '@app/shared/resources/circadian-curve.resource';
import { ICircadianCurve } from '@app/shared/models/circadian-curve.interface';
import { CircadianCurve } from '@app/shared/models/circadian-curve';
import { ICircadianCurveOutline } from '@app/shared/models/circadian-curve-outline.interface';

@Injectable({
  providedIn: 'root'
})
export class CircadianCurveService {
  constructor(private userService: UserService, private circadianResource: CircadianCurveResource) {}

  public saveCurve(buildingId: number, curve: ICircadianCurve): Observable<ICircadianCurve> {
    return this.circadianResource.addResource(buildingId, curve);
  }

  public getCurves(buildingId: number): Observable<ICircadianCurve[]> {
    return this.circadianResource.retrieveAllByParentId(buildingId);
  }

  public updateCurve(curve: ICircadianCurve): Observable<ICircadianCurve> {
    return this.circadianResource.updateResource(curve.id, curve);
  }

  public deleteCurve(curve: ICircadianCurve): Observable<{}> {
    return this.circadianResource.delete(curve.id);
  }

  public getStandardCurve(): Observable<ICircadianCurve> {
    return of(CircadianCurve.generateStandardCircadianCurve());
  }

  public updateRankOrder(buildingId: number, order: ICircadianCurveOutline): Observable<{}> {
    return this.circadianResource.reorderResource(buildingId, order);
  }

  public moveUp(scheduleId: number): Observable<void> {
    return this.circadianResource.moveUp(scheduleId);
  }

  public moveDown(scheduleId: number): Observable<void> {
    return this.circadianResource.moveDown(scheduleId);
  }
}
