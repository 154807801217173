import { Inject, Injectable } from '@angular/core';
import { Resource } from '@app/shared/resources/resource';
import { IResource } from '@app/shared/resources/resource.interface';
import { HttpClient } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import {
  ISensorNodeIdAndAddressDTO,
  SensorNodeIdAndAddressDTO
} from '@app/shared/models/sensor-node-id-and-address-dto.interface';
import { SensorNodeDTO } from '@app/shared/models/sensor-node';
import { VirtualNotificationDTO } from '@app/shared/models/virtual-notification-dto.interface';

@Injectable({
  providedIn: 'root'
})
export class PassiveNodeResource extends Resource<SensorNodeDTO, number> implements IResource<SensorNodeDTO, number> {
  constructor(protected httpClient: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(httpClient, `${environment.apiUrl + environment.apiPath}`);
  }
  public getPassiveNodesList(buildingId: number): Observable<ISensorNodeIdAndAddressDTO[]> {
    return this.httpClient.get<SensorNodeIdAndAddressDTO[]>(
      `${this.baseUrl}/node/unmapped/building/${buildingId}/node-type/PN`
    );
  }

  public mapVirtual2Actual(virtualNotificationDTO: VirtualNotificationDTO): Observable<{}> {
    return this.httpClient.post<{}>(`${this.baseUrl}/map/transform-virtual-to-actual`, virtualNotificationDTO);
  }
}
