<div class="or-inline-items or-inline-tags or-editable">
  @if(isEditable) {
    <div class="or-inline-item or-inline-tag-add w-[120px] pt-1 pl-2">
      <mat-select placeholder="Pick a tag" #selectTag (selectionChange)="addActiveTag(selectTag.value)" data-cy="pick-a-tag-list" [disabled]="isSelectionDisabled">
        @for(tag of availableOptions; track $index){
          <mat-option [value]="tag.value | json">{{tag.label}}</mat-option>
        }
        @if(availableOptions.length === 0) {
          <mat-option disabled="true">No tags available</mat-option>
        }
      </mat-select>
    </div>
  }
  <div class="or-inline-item or-inline-tag" *ngFor="let tag of currentSelection; index as i;">
    <span class="or-inline-tag-color" [style]="{ backgroundColor: '#' + tag.color }"></span>
    <span class="or-icon or-tiny or-icon-tenant"  title='Tag is part of a Tenant' *ngIf="isOfTenantType(tag)"></span>
    <span class="or-inline-tag-name" [textContent]="tag.name" [style]="{ color: '#' + tag.color }" [attr.data-cy]="'selected-schedule-tag-'+i"></span>
    <button class="or-button or-inline-tag-remove" type='button' (click)="removeTag(i)" *ngIf="canEdit(tag) | async" [attr.data-cy]="'delete-schedule-tag-'+i">
      <span class="or-icon or-tiny or-icon-cross"></span>
    </button>
  </div>
</div>
