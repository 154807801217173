import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Environment, environmentToken } from 'src/environments/environment.interface';
import { User } from '../models/user.interface';
import { Resource } from './resource';
import { IUserResource } from './user.interface';

@Injectable({
  providedIn: 'root'
})
export class UserResource extends Resource<User, number> implements IUserResource {
  constructor(httpClient: HttpClient, @Inject(environmentToken) environment: Environment) {
    super(httpClient, `${environment.apiUrl + environment.apiPath}/user`);
  }

  public join(): Observable<User> {
    return this.httpClient.get<User>(`${this.baseUrl}/join`);
  }

  public delete(id: number): Observable<{}> {
    return this.httpClient.delete<User>(`${this.baseUrl}/${id}`);
  }

  public find(filter, sortOrder, pageNumber, pageSize): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}/findPaginated`, {
      params: new HttpParams()
        .set('filter', filter)
        .set('sort', sortOrder)
        .set('page', pageNumber.toString())
        .set('size', pageSize.toString())
    });
  }

  public getUsersInBuilding(buildingId: number): Observable<User[]> {
    return this.httpClient.get<User[]>(`${this.baseUrl}/building/${buildingId}`);
  }

  updateCurrentUser(user: User): Observable<void> {
    return this.httpClient.put<void>(`${this.baseUrl}`, user);
  }
}
