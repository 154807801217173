import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Gateway } from '@app/api/building/gateway/Gateway';
import { SavedEntity } from '@app/api/SavedEntity';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GatewayService {
  public gateways: Gateway[];
  private endpoints = {
    gateway: '/gateway',
    building: '/building',
    health: '/gateway_health',
    generate: '/gateway/generatekeys'
  };
  private readonly apiUrl: string;

  constructor(private http: HttpClient, @Inject(environmentToken) environment: Environment) {
    this.apiUrl = environment.apiUrl + environment.apiPath;
  }

  public getGateways(buildingId: number): Observable<Gateways> {
    return this.http.get<Gateways>(`${this.apiUrl}${this.endpoints.building}/${buildingId}/gateway`);
  }

  public getGateway(gatewayId: number): Observable<Gateway> {
    return this.http.get<Gateway>(`${this.apiUrl}${this.endpoints.gateway}/${gatewayId}`);
  }

  public deleteGateway(gatewayId): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}${this.endpoints.gateway}/${gatewayId}`);
  }

  public addGateway(gatewayDetails: Gateway): Observable<SavedGateway> {
    return this.http.post<SavedGateway>(`${this.apiUrl}${this.endpoints.gateway}`, gatewayDetails);
  }

  public updateGateway(id: number, gatewayDetails: Gateway): Observable<boolean> {
    return this.http.put<boolean>(`${this.apiUrl}${this.endpoints.gateway}/${id}`, gatewayDetails);
  }

  public compareConfig(id: number): Observable<GatewayConfigComparison> {
    return this.http.get<GatewayConfigComparison>(`${this.apiUrl}${this.endpoints.gateway}/${id}/compare-config`);
  }

  public configureWithRigado(id: number, gatewayDetails: Gateway): Observable<boolean> {
    return this.http.put<boolean>(`${this.apiUrl}${this.endpoints.gateway}/${id}/configure`, gatewayDetails);
  }

  public produceCleanGateway(buildingId: number): Gateway {
    return new Gateway(null, null, null, buildingId);
  }

  public generateKeys(gatewayId: number): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}${this.endpoints.generate}/${gatewayId}`, {});
  }

  public getGatewayHealth(gatewayAddress: string): Observable<GatewayHealth> {
    return this.http
      .get<GatewayHealth>(`${this.apiUrl}${this.endpoints.health}/${gatewayAddress}?forFrontend=true`)
      .pipe(distinctUntilChanged(), shareReplay());
  }

  public requestGatewayRestart(gatewayAddress: string, gatewayHealth: GatewayHealth): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}${this.endpoints.health}/${gatewayAddress}`, gatewayHealth);
  }

  public produceCleanGatewayHealth(gatewayAddress: string): GatewayHealth {
    return {
      restartRequired: false,
      healthy: true,
      awsIotHealthy: true,
      meshHealthy: true,
      internetHealthy: true,
      lastRestarted: null,
      gatewayAddress
    };
  }

  public downloadExport(buildingId: number): Observable<Blob> {
    return this.http.post(
      `${this.apiUrl}/building/${buildingId}/export-gateways`,
      {},
      {
        responseType: 'blob'
      }
    );
  }
}

export class Gateways {
  constructor(public gateway: Gateway[]) {}
}

export interface Gateways {
  gateway: Gateway[];
}

export class GatewayHealth {
  restartRequired: boolean;
  healthy: boolean;
  awsIotHealthy: boolean;
  meshHealthy: boolean;
  internetHealthy: boolean;
  lastRestarted: Date;
  gatewayAddress: string;

  constructor(
    restartRequired: boolean,
    healthy: boolean,
    awsIotHealthy: boolean,
    meshHealthy: boolean,
    internetHealthy: boolean,
    lastRestarted: Date,
    gatewayAddress: string
  ) {
    this.restartRequired = restartRequired;
    this.healthy = healthy;
    this.awsIotHealthy = awsIotHealthy;
    this.meshHealthy = meshHealthy;
    this.internetHealthy = internetHealthy;
    this.lastRestarted = lastRestarted;
    this.gatewayAddress = gatewayAddress;
  }
}

export interface GatewayConfigComparison {
  portalConfig: string;
  rigadoConfig: string;
}

type SavedGateway = SavedEntity<Gateway, number>;
