import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import {
  FirmwareVersion,
  Gateway,
  GatewayType,
  GatewayVersion,
  LogLevel,
  NetworkType
} from '@app/api/building/gateway/Gateway';
import { GatewayHealth, GatewayService } from '@app/shared/services/gateway.service';
import { Observable } from 'rxjs';
import { NgFor, NgIf } from '@angular/common';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import { CheckboxComponent } from '@components/checkbox/checkbox.component';

@Component({
  selector: 'app-form-gateway-details',
  templateUrl: './form-gateway-details.component.html',
  styleUrls: ['./form-gateway-details.component.scss'],
  imports: [ReactiveFormsModule, FormsModule, NgIf, NgFor, AuthorizationModule, CheckboxComponent]
})
export class FormGatewayDetailsComponent implements OnInit {
  @Input() public isNew: boolean;
  @Input() public isBusy: boolean;
  @Input() public gateway: Gateway;
  public gatewayHealth?: GatewayHealth;
  @Input() public afterUpdate$: Observable<Gateway>;
  @Input() public error$: Observable<Gateway>;
  public wirepas = NetworkType.WIREPAS;
  public vesta = GatewayType.VESTA;
  @ViewChild('gatewayForm') form: NgForm;
  public isViewEdit = false;
  private visibleFields: string[];

  @Output() public deleteGateway = new EventEmitter<number>();
  @Output() public resetGateway = new EventEmitter<boolean>();
  @Output() public addGateway = new EventEmitter<Gateway>();
  @Output() public updateGateway = new EventEmitter<Gateway>();
  @Output() public compareWithRigado = new EventEmitter<Gateway>();
  @Output() public restartGateway = new EventEmitter<Gateway>();
  @Output() public generateKeys = new EventEmitter<Gateway>();
  @Output() public compareGatewayStates = new EventEmitter<Gateway>();

  public pattern = /^([a-fA-F\d]{8})-?([a-fA-F\d]{4})-?([a-fA-F\d]{4})-?([a-fA-F\d]{4})-?([a-fA-F\d]{12})$/;
  public namePattern = /^[\da-zA-Z-_'\s]{0,256}$/;
  public serialNumberPattern = /^[\da-zA-Z-_\s]{16,256}$/;
  public ipAddressPattern = /^(?:\d{1,3}[.]){3}\d{1,3}$/;
  public dnsServersPattern = /^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(\s*,\s*(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))?$/;

  constructor(private gatewayService: GatewayService) {
    // this is intentionally left blank
  }

  ngOnInit(): void {
    this.gateway.isDefaultDns = !this.gateway.dnsServers;
    this.gateway.original = Gateway.clone(this.gateway);

    this.afterUpdate$.subscribe((gateway) => {
      if (gateway.id === this.gateway.id) {
        this.form.reset(this.gateway);
      }
    });

    this.error$.subscribe((gateway) => {
      if (gateway.id === this.gateway.id) {
        this.isViewEdit = true;
      }
    });
    this.fetchHealth();
  }

  private fetchHealth(): void {
    if (this.gateway.address != null) {
      this.gatewayService.getGatewayHealth(this.gateway.address).subscribe((health) => {
        this.gatewayHealth = health;
      });
    }
  }

  public onAddressChange(): void {
    const groups = this.pattern.exec(this.gateway.address);
    if (groups != null && groups.length > 5) {
      this.gateway.address =
        groups[1].toLowerCase() +
        '-' +
        groups[2].toLowerCase() +
        '-' +
        groups[3].toLowerCase() +
        '-' +
        groups[4].toLowerCase() +
        '-' +
        groups[5].toLowerCase();
    }
  }

  public hasKeys(): boolean {
    return this.gateway.hasKeys;
  }

  public onAddGateway(): void {
    this.addGateway.emit(this.gateway);
  }

  public onUpdateGateway(): void {
    this.updateGateway.emit(this.gateway);
  }

  public onCompareWithRigado(): void {
    this.compareWithRigado.emit(this.gateway);
  }

  public onCompareGatewayStates(): void {
    this.compareGatewayStates.emit(this.gateway);
  }

  public onRestartGateway(): void {
    this.restartGateway.emit(this.gateway);
  }

  public onGenerateKeys(): void {
    this.generateKeys.emit(this.gateway);
  }

  public onResetGateway(): void {
    this.resetGateway.emit(true);
  }

  public onDelete(): void {
    this.deleteGateway.emit(this.gateway.id);
  }

  public getAllNetworkTypes(): NetworkType[] {
    return NetworkType.all();
  }

  public getAllFirmwareVersions(): FirmwareVersion[] {
    return FirmwareVersion.all();
  }

  public getAllGatewayTypes(): GatewayType[] {
    return GatewayType.all();
  }

  public getAllGatewayVersions(): GatewayVersion[] {
    return GatewayVersion.all();
  }

  public onOptionsSelected(event) {
    const value = event.target.value;
    if (value === GatewayVersion.VERSIONTWO.name) {
      if (
        confirm(
          'All gateways in the building need to be either Version 2 or Version 3 for Portal to work as expected, are you sure you want to select Version 2?'
        )
      ) {
        this.gateway.versionNumber = GatewayVersion.VERSIONTWO.value;
      } else {
        this.gateway.versionNumber = GatewayVersion.VERSIONTHREE.value;
      }
    }
  }

  public getAllLogLevels(): LogLevel[] {
    return LogLevel.all();
  }

  public resetHiddenFields(): void {
    const controls = Object.assign({}, this.form.controls);
    if (this.isViewEdit) {
      this.visibleFields = [];
      for (const attr in controls) {
        if (controls.hasOwnProperty(attr)) {
          this.visibleFields.push(attr);
        }
      }
    } else {
      const fieldsToReset = [];
      for (const attr in controls) {
        if (!this.visibleFields.includes(attr)) {
          fieldsToReset.push(attr);
        }
      }
      this.updateToDefault(fieldsToReset);
    }
  }

  public updateToDefault(attrs: string[]): void {
    for (const attr of attrs) {
      this.gateway[attr] = this.gateway.original[attr];
    }
  }

  public resetBacnetFields(): void {
    this.gateway.bacnetDeviceId = null;
    this.gateway.bacnetPort = null;
    this.gateway.bacnetLogLevel = null;
  }
}
