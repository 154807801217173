import { Inject, Injectable } from '@angular/core';
import { Building } from '@app/shared/models/building.interface';
import { Floor } from '@app/shared/models/floor.interface';
import { Environment, environmentToken } from '@environment';
import { IBuildingMetadataService } from './building-metadata.interface';
import { SecurityService } from '@services/security.service';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BuildingMetadataService implements IBuildingMetadataService {
  private readonly baseUrl: string;
  private readonly securityService: SecurityService;

  constructor(@Inject(environmentToken) environment: Environment, securityService: SecurityService) {
    const { apiUrl, apiPath } = environment;
    this.baseUrl = apiUrl + apiPath;
    this.securityService = securityService;
  }

  thumbnailUrl(building: Building): string {
    return building.thumbnailImage
      ? `${this.baseUrl}/building/${building.id}/thumbnail?id=${building.thumbnailImage}`
      : undefined;
  }

  analyticsUrl(building: Building, floor: Floor): string {
    let buildId = null;
    let floorId = null;
    if (building) {
      buildId = building.id;
    }
    if (floor) {
      floorId = floor.id;
    }
    return `/buildings/${buildId}/${floorId}/heatmap`;
  }

  adminUrl(id: number): string {
    return `/buildings/${id}/administration/building`;
  }

  dashboardUrl(id: number): string {
    return `/buildings/${id}/dashboard`;
  }

  floorUrl(id: number): string {
    return `/buildings/${id}/administration/floors`;
  }

  sensorNodeUrl(building: Building, floor: Floor): string {
    const buildId = building?.id ?? null;
    const floorId = floor?.id ?? null;
    return `/buildings/${buildId}/${floorId}/sensor-nodes`;
  }

  produceBuildingUrl(building: Building): Observable<string> {
    if (building.floors.length === 0) {
      return of(this.floorUrl(building.id));
    } else {
      return this.securityService
        .isRoleAuthorizedForBuilding(
          [BuildingAuthorityType.VIEW_DASHBOARD_INSIGHTS, BuildingAuthorityType.VIEW_DASHBOARD_ANALYTICS],
          building.id
        )
        .pipe(
          switchMap((isAuthorizedForDashboard) => {
            if (isAuthorizedForDashboard) {
              return of(this.dashboardUrl(building.id));
            } else {
              return this.securityService
                .isAuthorizedForBuilding(BuildingAuthorityType.ANALYTICS.value, building.id)
                .pipe(
                  switchMap((isAuthorizedForAnalytics) => {
                    if (isAuthorizedForAnalytics) {
                      return of(this.analyticsUrl(building, building.floors[0]));
                    } else {
                      return combineLatest([
                        this.securityService.isAuthorizedForBuilding(
                          BuildingAuthorityType.MANAGE_USERS.value,
                          building.id
                        ),
                        this.securityService.isAuthorizedForBuilding(
                          BuildingAuthorityType.MANAGE_BUILDING.value,
                          building.id
                        )
                      ]).pipe(
                        map(([hasManageUsers, hasManageBuilding]) => hasManageUsers || hasManageBuilding),
                        switchMap((hasAdmin) => {
                          if (hasAdmin) {
                            return of(this.adminUrl(building.id));
                          } else {
                            return of('');
                          }
                        })
                      );
                    }
                  })
                );
            }
          })
        );
    }
  }
}
