import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CheckboxComponent } from '@app/shared/components/checkbox/checkbox.component';
import { MultiselectComponent } from '@app/shared/components/multiselect/multiselect.component';
import { AuthorizationModule } from '@app/shared/directives/authorization.module';
import { BuildingMetadataService } from '@app/shared/services/building-metadata/building-metadata.service';
import { CountryService } from '@app/shared/services/country.service';
import { ImageCachingService } from '@app/shared/services/ImageCachingService';
import { LampTypeService } from '@app/shared/services/lamp-type.service';
import { AdministrationBuildingComponent } from './building/building.component';
import { FormBuildingDetailsComponent } from '@components/building/form-building-details/form-building-details.component';
import { FormGatewayDetailsComponent } from './gateway/form-gateway-details/form-gateway-details.component';
import { AdministrationGatewaysComponent } from './gateway/gateway.component';
import { AdministrationComponent } from './layout/administration.component';
import { CompareConfigDialogComponent } from './gateway/compare-config-dialog/compare-config-dialog.component';
import { JsonComparisonComponent } from '@app/administration/gateway/json-comparison/json-comparison.component';
import { AdministrationUsersComponent } from '@app/administration/user/user.component';
import { TenantsComponent } from '@app/administration/tenants/tenants.component';
import { FormUserDetailsComponent } from '@app/administration/user/form-user-details/form-user-details.component';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { UserInvitationDialogComponent } from '@app/administration/user/user-invitation-dialog/user-invitation-dialog.component';
import { TenantDialogComponent } from '@app/administration/tenants/tenant-dialog/tenant-dialog.component';
import { AdministrationCalendarComponent } from '@app/administration/calendar/calendar.component';
import { CalendarEventDialogComponent } from '@app/administration/calendar/calendar-event-dialog/calendar-event-dialog.component';
import { AdministrationEmergencyLightingComponent } from '@app/administration/emergency-lighting/emergency-lighting.component';
import { TenantUserComponent } from '@app/administration/tenants/tenant-user/tenant-user.component';
import { CreateJobsDialogComponent } from '@app/global-administration/otap-upgrades/create-jobs-dialog/create-jobs-dialog.component';
import { TokensComponent } from './tokens/tokens.component';
import { FormTokenDetailsComponent } from './tokens/form-token-details/form-token-details.component';
import { FloorsComponent } from './floors/floors.component';
import { FormFloorDetailsComponent } from '@app/administration/floors/form-floor-details/form-floor-details.component';
import { MobileTokenComponent } from './mobile-token/mobile-token.component';
import { FormMobileTokenDetailsComponent } from './mobile-token/form-mobile-token-details/form-mobile-token-details.component';
import { FormBleKeyDetailsComponent } from './mobile-token/form-ble-key-details/form-ble-key-details.component';
import { RolesComponent } from './roles/roles.component';
import { FormRoleDetailsComponent } from './roles/form-role-details/form-role-details.component';
import { BleDialogComponent } from './building/ble-dialog/ble-dialog.component';
import { ServiceLevelComponent } from '@app/administration/service-level/service-level.component';

@NgModule({
  exports: [FormBuildingDetailsComponent, FormGatewayDetailsComponent],
  imports: [
    AuthorizationModule,
    RouterModule,
    SharedComponentsModule,
    AdministrationBuildingComponent,
    AdministrationComponent,
    AdministrationCalendarComponent,
    AdministrationEmergencyLightingComponent,
    AdministrationGatewaysComponent,
    AdministrationUsersComponent,
    CalendarEventDialogComponent,
    CheckboxComponent,
    CompareConfigDialogComponent,
    FormBuildingDetailsComponent,
    FormGatewayDetailsComponent,
    FormUserDetailsComponent,
    JsonComparisonComponent,
    MultiselectComponent,
    TenantsComponent,
    FormUserDetailsComponent,
    UserInvitationDialogComponent,
    TenantDialogComponent,
    TenantUserComponent,
    CreateJobsDialogComponent,
    FloorsComponent,
    FormFloorDetailsComponent,
    TokensComponent,
    FormTokenDetailsComponent,
    MobileTokenComponent,
    FormMobileTokenDetailsComponent,
    FormBleKeyDetailsComponent,
    RolesComponent,
    FormRoleDetailsComponent,
    BleDialogComponent,
    ServiceLevelComponent
  ],
  providers: [BuildingMetadataService, CountryService, LampTypeService, ImageCachingService]
})
export class AdministrationModule {}
