import { Component, OnInit } from '@angular/core';
import { Floor } from '@app/shared/models/floor.interface';
import { UserService } from '@app/shared/services/user/user.service';
import { Building } from '@app/shared/models/building.interface';
import { combineLatest, concatMap, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FloorService } from '@services/floor.service';
import { ToastService } from '@services/toast/toast.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { FormFloorDetailsComponent } from './form-floor-details/form-floor-details.component';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { User } from '@app/shared/models/user.interface';
import { NotificationBlockComponent, StatusClass } from '@components/notification-block/notification-block.component';

@Component({
  selector: 'app-floors',
  templateUrl: './floors.component.html',
  styleUrls: ['./floors.component.scss'],
  imports: [MatProgressSpinner, FormFloorDetailsComponent, NotificationBlockComponent]
})
export class FloorsComponent implements OnInit {
  floors: Floor[] = [];
  building: Building;
  user: User;
  statusClass = StatusClass;
  isBusy = true;
  newFloorNum: number;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private floorService: FloorService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.loadFloorsAndUser();
  }

  loadFloorsAndUser(): void {
    this.isBusy = true;
    combineLatest([this.getSelectedBuilding(), this.userService.getCurrentUser()]).subscribe({
      next: ([selectedBuilding, user]) => {
        this.building = selectedBuilding;
        this.user = user;
        this.floorService.getAllFloorsForBuildingId(selectedBuilding.id).subscribe({
          next: (res: Floor[]) => this.handleFloorResponse(res),
          error: () =>
            this.handleError(`Failed to load floors for ${selectedBuilding.name} (${selectedBuilding.id}) building`)
        });
      },
      error: () => this.handleError('Failed to load building or user data')
    });
  }

  getSelectedBuilding(): Observable<Building> {
    return this.route.params.pipe(
      concatMap((params: any) => {
        return this.userService.getBuilding(params.buildingId);
      })
    );
  }

  handleFloorResponse(res: Floor[]): void {
    this.newFloorNum = 1;
    this.floors = res.map((fl: Floor) => {
      fl.buildingId = this.building.id;
      if (fl.floorNumber >= this.newFloorNum) {
        this.newFloorNum = fl.floorNumber + 1;
      }
      return fl;
    });
    this.isBusy = false;
  }

  handleError(message): void {
    this.toast.error({ message, dataCy: 'load-error-toast' });
  }

  hasManageFloorsPermission(): boolean {
    return this.user.buildingAuthorizations?.some(
      (ba) =>
        ba.buildingId === this.building.id &&
        ba.authorities.some((a) => {
          return [BuildingAuthorityType.MANAGE_FLOORS.toString()].includes(a.toString());
        })
    );
  }
}
