import { Inject, Injectable } from '@angular/core';
import { Resource } from '@app/shared/resources/resource';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Observable } from 'rxjs';
import { ICalendarEventResource } from '@app/shared/resources/calendar-event.interface';
import { ICalendarEvent } from '@app/shared/models/calendar-event.interface';

@Injectable({
  providedIn: 'root'
})
export class CalendarEventResource extends Resource<ICalendarEvent, number> implements ICalendarEventResource {
  constructor(private http: HttpClient, @Inject(environmentToken) private environment: Environment) {
    super(http, `${environment.apiUrl + environment.apiPath}/calendarEvents`);
  }

  getAllForBuilding(buildingId: number, pageNumber: number, pageSize: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/${buildingId}/findPaginated`, {
      params: new HttpParams().set('page', pageNumber.toString()).set('size', pageSize.toString())
    });
  }

  createCalendarEvent(buildingId: number, calendarEvent: ICalendarEvent): Observable<ICalendarEvent> {
    return this.http.post<ICalendarEvent>(`${this.baseUrl}/${buildingId}`, calendarEvent);
  }

  updateCalendarEvent(
    buildingId: number,
    calendarEventId: number,
    calendarEvent: ICalendarEvent
  ): Observable<ICalendarEvent> {
    return this.http.put<ICalendarEvent>(`${this.baseUrl}/${buildingId}/${calendarEventId}`, calendarEvent);
  }

  deleteCalendarEvent(buildingId: number, calendarEventId: number): Observable<{}> {
    return this.http.delete<ICalendarEvent>(`${this.baseUrl}/${buildingId}/${calendarEventId}`);
  }
}
