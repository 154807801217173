<div id='orIntegrationsAreas'>
  <div class='or-content'>
    <div class='or-content-header'>
      <app-tabs hidden [tabs$]="tabs$"></app-tabs>
    </div>
    @if(isGatewaysLoaded) {
      <div class='or-content-body'>
        @if(areas?.length === 0) {
          <app-notification-block
            [header]="'No areas found'"
            [statusClass]="StatusClass.WARNING">
            No areas for this building. Go ahead and set up one.
          </app-notification-block>
        }
        <div class='or-stacked-items or-integrations-areas-list'>
          @for(area of areas; track area.id; let i = $index) {
            <div  class='or-stacked-item or-integrations-areas-list-item' >
              <app-bacnet-area-tile [area]='area' [isNew]='false' [gateways]="gateways" [enableLabels]="i === 0"
                                      (valueChanged)='loadAreas()' data-cy="existing-bacnet-areas-form">
              </app-bacnet-area-tile>
            </div>
          }
        <div class="mt-8">
          <div>New Area</div>
          <app-bacnet-area-tile [area]='newArea' [isNew]='true' [gateways]="gateways" [enableLabels]="true"
                                (valueChanged)='loadAreas()' data-cy="new-bacnet-areas-form">
          </app-bacnet-area-tile>
        </div>
        </div>
      </div>
    }
  </div>
</div>
