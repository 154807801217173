import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Building } from '@app/shared/models/building.interface';
import { User } from '@app/shared/models/user.interface';
import { NavigationSection } from '@app/shared/services/navigation/navigation-section';
import { UserService } from '@app/shared/services/user/user.service';
import { Subject, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-site-menu',
  templateUrl: './site-menu.component.html',
  styleUrls: ['./site-menu.component.scss'],
  imports: [NgClass]
})
export class SiteMenuComponent implements OnInit, OnDestroy {
  @Input() menuItems: NavigationSection[];
  @Input() user: User;
  @Input() building: Building;
  private readonly navigate$ = new Subject<NavigationSection>();

  private navigationSubscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly navigationService: NavigationService
  ) {}

  navigateTo(section: NavigationSection): void {
    this.navigate$.next(section);
  }

  ngOnInit(): void {
    this.navigationSubscription = this.navigate$
      .pipe(withLatestFrom(this.route.params))
      .subscribe(([section, parameters]) => {
        const { buildingId, floorId } = parameters;
        if (!floorId && !this.navigationService.floorId) {
          this.userService.getFloorsForBuilding(buildingId).subscribe((floors) => {
            this.navigationService.navigateToSection(section, buildingId, floors[0]?.id);
          });
        } else {
          this.navigationService.navigateToSection(section, buildingId, floorId);
        }
      });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  isAuthorizedForMenuItemWhenBuildingHasFloors(section: NavigationSection): boolean {
    return section.info.IsAuthorized(this.user, this.building.id) && this.building.floors.length > 0;
  }
}
