<div class='my-5 mx-8' #container >
  <div class='flex flex-col-reverse sm:flex-row justify-between'>
    <h3 class='text-2xl mx-2' data-cy='buildings-heading'>Buildings</h3>
    <button appIsAuthorized='ADD_BUILDINGS' data-cy='add-new-building-button' (click)='openNewBuildingPage()'
            class='my-4 sm:my-0' mat-raised-button color='primary'><mat-icon>home</mat-icon>Add a building</button>
  </div>
  @if(searchForm) {
    <mat-form-field [formGroup]='searchForm' class='w-full my-4 mx-2'>
      <mat-label data-cy='search-label'>Search</mat-label>
      <input data-cy='search-input' matInput id='search' formControlName="search" type="text"/>
    </mat-form-field>
  }
  @if (loading$ | async) {
    <div class='flex w-full justify-center' data-cy='loading-spinner-div'>
      <mat-spinner></mat-spinner>
    </div>
  }
  <div class='flex flex-row flex-wrap'>
      @for (detail of filteredBuildings; track detail.id) {
        <div class='my-2 mx-2' [ngStyle]="{'width.px': elementWidth}">
          <app-building-tile [details]='detail'></app-building-tile>
        </div>
      }
  </div>
</div>
<ng-template #toastTemplate let-toastRef>
  <div class="absolute left-0 bottom-0 right-0 h-1 bg-blue-500 rounded-b shrink-line-animation"
       [style.--shrink-line-animation-duration]="toastRef.getToast().duration + 'ms'">
  </div>
  <div class="flex gap-x-2 w-full h-[56px] items-center relative px-4">
    <p>You don’t have sufficient permission to access building <b>"{{buildingNameWithNoFloors()}}"</b>. Please contact your luminaire manufacturer or building administrator to add floors.</p>
  </div>
</ng-template>
