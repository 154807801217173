import { Injectable } from '@angular/core';
import { ServiceLevelResource } from '@app/shared/resources/service-level.resource';
import { Observable } from 'rxjs';
import { ServiceLevel } from '@app/shared/models/service-level';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiceLevelService {
  constructor(private serviceLevelResource: ServiceLevelResource) {}

  public get(buildingId: number): Observable<ServiceLevel> {
    return this.serviceLevelResource.get(buildingId).pipe(shareReplay(2));
  }

  public save(serviceLevel: ServiceLevel): Observable<{}> {
    return this.serviceLevelResource.save(serviceLevel);
  }
}
