export interface InterpolationFunction {
  contributions(normalizedDistances: number[]): number[];
}

export class PolynomialInterpolationFunction implements InterpolationFunction {
  constructor(private exponent: number) {}

  contributions(normalizedDistances: number[]): number[] {
    const max = normalizedDistances.reduce((max, distance) => Math.max(max, distance), 0);
    return normalizedDistances.map((distance) => Math.pow(max / distance, this.exponent));
  }
}
