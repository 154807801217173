import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { fromEvent, merge, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { UserService } from '@services/user/user.service';
import { UserDatasource, UserOutline } from '@app/global-administration/user-information/user/user.datasource';
import { UserDialogComponent } from '@app/global-administration/user-information/user/user-dialog/user-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { User } from '@app/shared/models/user.interface';
import { ManagingCompanyService } from '@services/managing-company.service';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCard } from '@angular/material/card';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from '@angular/material/table';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { MatMiniFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-global-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  imports: [
    MatFormField,
    MatInput,
    MatCard,
    NgIf,
    MatProgressSpinner,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    NgFor,
    MatChipListbox,
    MatChipOption,
    MatMiniFabButton,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatPaginator,
    AsyncPipe
  ]
})
export class GlobalAdministrationUsersComponent implements OnInit, AfterViewInit {
  dataSource: UserDatasource;

  displayedColumns = [
    'id',
    'avatar',
    'name',
    'emailAddress',
    'authMethod',
    'managingCompany',
    'globalAuthorities',
    'actions'
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('input') input: ElementRef;

  protected defaultPageSize = 10;
  protected defaultPageOptions = [10, 25, 100];

  constructor(
    private usersService: UserService,
    private managingCompanyService: ManagingCompanyService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.dataSource = new UserDatasource(this.usersService, this.managingCompanyService);
    this.dataSource.loadUsers('', 'asc', 0, this.defaultPageSize);
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadUsersPage();
        })
      )
      .subscribe();

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadUsersPage()))
      .subscribe();
  }

  loadUsersPage(): void {
    this.dataSource.loadUsers(
      this.input.nativeElement.value,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
  }

  editUser(userOutline: UserOutline): void {
    const user = userOutline.toUser();
    this.openEditUserDialog(this.dialog, user).subscribe((val) => {
      user.managingCompanyId = val.managingCompany;
      user.globalAuthorities = val.globalAuthorities;
      this.usersService.update(user).subscribe({
        next: (_) => {
          this.loadUsersPage();
        },
        error: (error) => {
          console.error('There was an error updating the user', error);
        }
      });
    });
  }

  deleteUser(userOutline: UserOutline): void {
    if (!confirm(`User '${userOutline.name}' is going to be deleted. This cannot be undone! Continue?`)) {
      return;
    }
    this.usersService.delete(userOutline.id).subscribe({
      next: (_) => {
        this.loadUsersPage();
      },
      error: (error) => {
        console.error('There was an error deleting the user', error);
      }
    });
  }

  openEditUserDialog(dialog: MatDialog, user: User): Observable<any> {
    const config = new MatDialogConfig();
    config.autoFocus = true;
    config.data = {
      ...user
    };
    config.width = '600px';
    const dialogRef = dialog.open(UserDialogComponent, config);
    return dialogRef.afterClosed();
  }
}
