import { Inject, Injectable } from '@angular/core';
import { ManagingCompany } from '@app/shared/models/building.interface';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Environment, environmentToken } from '@environment';
import { Resource } from '@app/shared/resources/resource';
import { Observable } from 'rxjs';
import { IManagingCompanyResource } from '@app/shared/resources/managing-company.interface';
import { SavedEntity } from '@app/shared/models/saved-entity.interface';

@Injectable({
  providedIn: 'root'
})
export class ManagingCompanyResource extends Resource<ManagingCompany, number> implements IManagingCompanyResource {
  constructor(private http: HttpClient, @Inject(environmentToken) private environment: Environment) {
    super(http, `${environment.apiUrl + environment.apiPath}/managing-company`);
  }

  getAll(): Observable<ManagingCompany[]> {
    return this.http.get<ManagingCompany[]>(`${this.baseUrl}`);
  }

  saveManagingCompany(item: ManagingCompany): Observable<ManagingCompany> {
    return this.httpClient.post<ManagingCompany>(this.baseUrl + '/', item);
  }

  update(id: number, item: ManagingCompany): Observable<{}> {
    return this.httpClient.put<HttpResponse<ManagingCompany>>(this.baseUrl + '/', item);
  }
}
