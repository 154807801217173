<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
  <mat-tab label="Users" *ngIf="(checkPermission('MANAGE_BUILDINGS') | async) && (checkPermission('MANAGE_USERS') | async)">
    <app-global-user/>
  </mat-tab>

  <mat-tab label="Global Authority Requests" *ngIf="checkPermission('MANAGE_GLOBAL_AUTHORITY_REQUEST') | async">
    <mat-card class="mat-elevation-z8 job-information-card">
      <app-global-authority-requests/>
    </mat-card>
  </mat-tab>
</mat-tab-group>
