import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService } from '@app/shared/services/user/user.service';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { InvitationDatasource } from '@app/administration/user/invitation.datasource';
import { UserInvitationResource } from '@app/shared/resources/user-invitation.resource';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IUserInvitation } from '@app/shared/models/user-invitation.interface';
import {
  DialogResult,
  FORM_TYPE,
  InvitationDialogData,
  UserInvitationDialogComponent
} from '@app/administration/user/user-invitation-dialog/user-invitation-dialog.component';
import { UserDatasource } from '@app/administration/user/user.datasource';
import { MatPaginator } from '@angular/material/paginator';
import { UserRole } from '@app/shared/models/user-role.interface';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SecurityService } from '@app/shared/services/security.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { ConfirmComponent, ConfirmDialogData } from '@app/shared/components/dialogs/confirm/confirm.component';
import { RoleService } from '@app/shared/services/role.service';
import { Role } from '@app/shared/models/role.interface';
import { ActivatedRoute } from '@angular/router';
import { TenantService } from '@services/building/tenant.service';
import { ITenant } from '@app/shared/models/tenant.interface';
import { isBefore, subDays } from 'date-fns';
import { AsyncPipe } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from '@angular/material/table';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { MatFabButton, MatMiniFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  imports: [
    MatProgressSpinner,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatSortHeader,
    MatChipListbox,
    MatChipOption,
    MatMiniFabButton,
    MatTooltip,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatPaginator,
    ReactiveFormsModule,
    FormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatFabButton,
    AsyncPipe
  ]
})
export class AdministrationUsersComponent implements OnInit, OnDestroy {
  public userDataSource: UserDatasource;
  public invitationDataSource: InvitationDatasource;
  public userDisplayedColumns = ['id', 'avatar', 'name', 'userEmailAddress', 'roles', 'actions'];
  public invitationDisplayedColumns = ['invitationEmailAddress', 'creationTime', 'token', 'tenantName', 'actions'];
  public isBusy = true;
  public buildingId: number;
  public filterKeyUp = new Subject<KeyboardEvent>();
  private filterSubscription: Subscription;
  public readonly usersPageSize = 5;
  public readonly invitesPageSize = 10;
  private readonly invitationExpirationInDays = 7;
  private allRoles: Role[] = [];
  private tenants: ITenant[];
  private userTenant: number;
  @ViewChild('userPaginator') userPaginator: MatPaginator;
  @ViewChild('invitationPaginator') invitationPaginator: MatPaginator;

  constructor(
    private userService: UserService,
    private navigationService: NavigationService,
    private invitationResource: UserInvitationResource,
    private dialog: MatDialog,
    private securityService: SecurityService,
    private roleService: RoleService,
    private tenantService: TenantService,
    private route: ActivatedRoute
  ) {
    this.filterSubscription = this.filterKeyUp
      .pipe(
        map((event) => (event.target as HTMLInputElement).value),
        startWith(null),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((search) => {
        if (search !== null) {
          this.invitationDataSource.loadInvitations(this.buildingId, search, 0, this.invitesPageSize);
        }
      });
  }

  private static openConfirmDialog(dialog: MatDialog, message: string): Observable<any> {
    const config = new MatDialogConfig();
    config.autoFocus = true;
    config.data = new ConfirmDialogData(message);
    config.width = '600px';
    const dialogRef = dialog.open(ConfirmComponent, config);
    return dialogRef.afterClosed();
  }

  ngOnInit(): void {
    this.allRoles = [];
    this.route.params.subscribe((parameters) => {
      const { buildingId } = parameters;
      this.buildingId = buildingId;
      this.navigationService.applyContext(window.location.href, { buildingId });
      this.setup();
    });
    this.setup();
  }

  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
  }

  private setup(): void {
    this.isBusy = false;
    this.userDataSource = new UserDatasource(this.userService, this.buildingId, this.securityService);
    this.loadUsers();
    this.invitationDataSource = new InvitationDatasource(this.invitationResource);
    this.loadInvitation();
    this.setUserRoles();
    this.setTenants();
    this.userService.getCurrentUser().subscribe((user) => {
      if (user.tenantIds != null && user.tenantIds.length > 0) {
        this.userTenant = user.tenantIds[0];
      }
    });
  }

  private setUserRoles(): void {
    this.roleService.getAllForBuilding(this.buildingId).subscribe((roles) => {
      this.allRoles = roles;
    });
  }

  private setTenants(): void {
    this.tenantService.getAllTenants(this.buildingId).subscribe((tenants) => {
      this.tenants = tenants;
    });
  }

  public dateInLocale(date: string): string {
    return date ? new Date(date).toLocaleString() : new Date().toLocaleString();
  }

  showRefreshIcon(invitation: IUserInvitation): boolean {
    return isBefore(new Date(invitation.creationTime), subDays(new Date(), 7)); // moment(invitation.creationTime).isBefore(moment().subtract(this.invitationExpirationInDays, 'days'), 'days');
  }

  editUserRole(userRole: UserRole): void {
    this.openEditUserDialog(this.dialog, userRole).subscribe((result: DialogResult) => {
      if (result) {
        const newUserRole = {
          ...userRole,
          roles: result.roles,
          roleIds: result.formValue.roleIds,
          userId: userRole.user.id
        };
        this.userService.updateUserRoles(this.buildingId, newUserRole).subscribe(() => this.loadUsers());
      }
    });
  }

  removeUserRole(userRole: UserRole): void {
    const message = `Are you sure you want to delete user - ${userRole.user.name}?`;
    AdministrationUsersComponent.openConfirmDialog(this.dialog, message).subscribe((val) => {
      if (val) {
        this.userService.removeUser(this.buildingId, userRole.user.id).subscribe(() => this.loadUsers());
      }
    });
  }

  loadInvitation(): void {
    this.invitationDataSource.loadInvitations(this.buildingId, '', 0, this.invitesPageSize);
  }

  loadUsers(): void {
    this.userDataSource.loadUsers(0, this.usersPageSize);
  }

  refreshInvitation(invitation: IUserInvitation): void {
    this.invitationResource
      .refreshInviteForBuilding(this.buildingId, invitation.token)
      .subscribe(() => this.loadInvitation());
  }

  removeInvitation(invitation: IUserInvitation): void {
    const message = `Are you sure you want to delete the invitation for ${invitation.emailAddress}?`;
    AdministrationUsersComponent.openConfirmDialog(this.dialog, message).subscribe((val) => {
      if (val) {
        this.invitationResource
          .deleteInvitationForBuilding(this.buildingId, invitation.token)
          .subscribe(() => this.loadInvitation());
      }
    });
  }

  inviteUsers(): void {
    this.openInviteUsersDialog(this.dialog).subscribe((result: DialogResult) => {
      if (result) {
        const payload = [{ buildingId: this.buildingId, ...result.formValue }];
        this.userService.inviteUsers(this.buildingId, payload).subscribe({
          next: (_) => {
            this.loadInvitation();
          },
          error: (error) => {
            console.error('There was an error sending the invites', error);
          }
        });
      }
    });
  }

  private openInviteUsersDialog(dialog: MatDialog): Observable<any> {
    const config = new MatDialogConfig<InvitationDialogData>();
    config.autoFocus = true;
    config.data = {
      buildingId: this.buildingId,
      mode: FORM_TYPE.INVITE,
      allRoles: this.allRoles,
      tenants: this.tenants,
      autoSelectTenant: this.userTenant
    };
    config.width = '600px';
    const dialogRef = dialog.open(UserInvitationDialogComponent, config);
    return dialogRef.afterClosed();
  }

  private openEditUserDialog(dialog: MatDialog, role: UserRole): Observable<any> {
    const config = new MatDialogConfig<InvitationDialogData>();
    config.autoFocus = true;
    config.data = {
      buildingId: this.buildingId,
      mode: FORM_TYPE.EDIT,
      userRole: role,
      allRoles: this.allRoles,
      tenants: this.tenants
    };
    config.width = '600px';
    const dialogRef = dialog.open(UserInvitationDialogComponent, config);
    return dialogRef.afterClosed();
  }
}
