<div
  class="luminaire-driver luminaire-driver-regular-shadow driver"
  [attr.data-cy]="'luminaire-driver-' + driver.address"
  [ngClass]="produceClassForLuminaireDriver()"
  (click)="onClick($event)"
  [ngStyle]="produceDriverStyle(driver.x, driver.y)"
  (touchstart)="$event.stopPropagation()">
  <div>
    <svg
      tagOutline
      [isDriver]="true"
      [tags]="tags"
      class="test-in-progress-animate luminaire-driver-tags"></svg>
    <div>
      <span
        class="test-in-progress-animate luminaire-driver-icon or-icon or-small"
        [ngClass]="produceClassForLuminaireDriverIcon()">
      </span>
    </div>
  </div>
  <div class="luminaire-driver-properties" [ngClass]="{ 'muted': showMutedTooltip() }">
    <div class="luminaire-driver-properties-header"><h1 class="luminaire-driver-properties-id">{{getDriverIdText()}}</h1>
      <span class="luminaire-driver-properties-address">{{driver.address}}</span>
    </div>
    @if (tags.length) {
      <div class="em-driver-properties-tags">
        @for (tag of tags; track tag.id) {
          <div class="em-driver-properties-tag">
            <span class="em-driver-properties-tag-color" [ngStyle]="{ 'background-color': '#' + tag.color}"></span>
            <span class="em-driver-properties-tag-name">{{tag.name}}</span>
          </div>
        }
      </div>
    }
  </div>
</div>

