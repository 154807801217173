import { Component, Input } from '@angular/core';
import { OtapUpgradeTaskDTO } from '@app/shared/models/otap-upgrade-task.interface';
import { OtapUpgradeDTO } from '@app/shared/models/otap.upgrade.interface';
import { JobTasksListComponent } from './job-tasks-list/job-tasks-list.component';

@Component({
  selector: 'app-job-tasks',
  templateUrl: './job-tasks.component.html',
  styleUrls: ['./job-tasks.component.scss'],
  imports: [JobTasksListComponent]
})
export class JobTasksComponent {
  @Input() tasks: OtapUpgradeTaskDTO[];
  @Input() job: OtapUpgradeDTO;
  @Input() jobStartEnabled: boolean;

  constructor() {}
}
