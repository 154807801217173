<div id="orGlobalAdministrationEmergencyLightingCalendar">
  <mat-grid-list cols="3" rowHeight="5:1">
    <mat-grid-tile>
      <mat-button-toggle-group name="calendarView" aria-label="Calendar View">
        <mat-button-toggle mwlCalendarPreviousView
                           [view]="view"
                           [(viewDate)]="viewDate"
                           (viewDateChange)='viewDateChanged($event)'>
          Previous
        </mat-button-toggle>
        <mat-button-toggle mwlCalendarToday [(viewDate)]="viewDate" [class.mat-button-toggle-checked]="isToday" (viewDateChange)='viewDateChanged($event)'>Today</mat-button-toggle>
        <mat-button-toggle mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)='viewDateChanged($event)'>Next</mat-button-toggle>
      </mat-button-toggle-group>
    </mat-grid-tile>
    <mat-grid-tile>
      <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-button-toggle-group name="calendarView" aria-label="Calendar View">
        <mat-button-toggle (click)="setView(CalendarView.Month)" [class.mat-button-toggle-checked]="view === CalendarView.Month">Month</mat-button-toggle>
        <mat-button-toggle (click)="setView(CalendarView.Week)" [class.mat-button-toggle-checked]="view === CalendarView.Week">Week</mat-button-toggle>
        <mat-button-toggle (click)="setView(CalendarView.Day)" [class.mat-button-toggle-checked]="view === CalendarView.Day">Day</mat-button-toggle>
      </mat-button-toggle-group>
    </mat-grid-tile>
  </mat-grid-list>
  <br />
  @switch (view) {
    @case (CalendarView.Month) {
      <mwl-calendar-month-view
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent($event.event)" />
    }
    @case (CalendarView.Week) {
      <mwl-calendar-week-view
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent($event.event)" />
    }
    @case (CalendarView.Day) {
      <mwl-calendar-day-view
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent($event.event)" />
    }
  }
</div>
