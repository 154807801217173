export class DwellTime {
  constructor(private value: number, public label: DwellTime_) {
    this.value = value;
  }

  public static DWELL_1 = new DwellTime(1, '1 min');
  public static DWELL_2 = new DwellTime(2, '2 min');
  public static DWELL_5 = new DwellTime(5, '5 min');
  public static DWELL_10 = new DwellTime(10, '10 min');
  public static DWELL_15 = new DwellTime(15, '15 min');
  public static DWELL_20 = new DwellTime(20, '20 min');
  public static DWELL_30 = new DwellTime(30, '30 min');
  public static DWELL_60 = new DwellTime(60, '60 min');

  public static all(): DwellTime[] {
    return [
      DwellTime.DWELL_1,
      DwellTime.DWELL_2,
      DwellTime.DWELL_5,
      DwellTime.DWELL_10,
      DwellTime.DWELL_15,
      DwellTime.DWELL_20,
      DwellTime.DWELL_30,
      DwellTime.DWELL_60
    ];
  }

  public static fromValue(value: number): DwellTime {
    for (const dwellTime of DwellTime.all()) {
      if (value === dwellTime.value) return dwellTime;
    }
  }

  public toString(): string {
    return this.label;
  }
}

type DwellTime_ = '1 min' | '2 min' | '5 min' | '10 min' | '15 min' | '20 min' | '30 min' | '60 min';
