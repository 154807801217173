import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Tab } from './tab.interface';
import { MatTabLink, MatTabNav, MatTabNavPanel } from '@angular/material/tabs';
import { AsyncPipe } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { IsFeatureAvailableDirective } from '../../directives/is-feature-available.directive';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  imports: [MatTabNav, MatTabLink, RouterLinkActive, RouterLink, IsFeatureAvailableDirective, MatTabNavPanel, AsyncPipe]
})
export class TabsComponent {
  @Input() public tabs$: Observable<Tab[]>;
}
