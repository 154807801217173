<footer class="footer-container">
  <div><a class="or-logo" href="/"></a></div>
  <div><p>© Organic Response, 2016-{{this.currentYear}}</p></div>
  <div><a [routerLink]="" class="cursor-pointer">About</a></div>
  <div><a [routerLink]="" class="cursor-pointer" (click)="showPrivacyPolicy(false)">Privacy notice</a></div>
  <div><a [routerLink]="" class="cursor-pointer">Contact</a></div>
  <div>
    <a
      class="button-link or-button"
      href="https://www.linkedin.com/company/or-technologies"
      target="_blank"
      rel="noopener noreferrer">
      <span class="or-icon or-tiny or-icon-linkedin"></span>
    </a>
  </div>
  <div>
    <a
      class="button-link or-button"
      href="https://twitter.com/OrganicResponse"
      target="_blank"
      rel="noopener noreferrer">
      <span class="or-icon or-tiny or-icon-twitter"></span>
    </a>
  </div>
  <div>
    <a class="button-link or-button" [routerLink]="">
      <span class="or-icon or-tiny or-icon-facebook"></span>
    </a>
  </div>
</footer>
