import { Point } from '@app/shared/utils/coordinate';
import { InterpolationFunction } from '@components/heatmap/interpolator/interpolation-function';
import {
  ConstantInterpolationRange,
  InterpolationRangeProvider
} from '@components/heatmap/interpolator/interpolation-range';
import { HeatmapDataPoint } from '@components/heatmap/renderer/heatmap-renderer';
import { SearchableFloorplan, TreeSearchableFloorplan } from '@components/heatmap/interpolator/searchable-floorplan';

export class DataInterpolator {
  constructor(
    private radius: number,
    private interpolationFunction: InterpolationFunction,
    private interpolationRangeProvider: InterpolationRangeProvider,
    private searchableFloorplanFactory: (nodes: HeatmapDataPoint[]) => SearchableFloorplan<HeatmapDataPoint> = (
      nodes
    ) => new TreeSearchableFloorplan(nodes)
  ) {}

  public static naiveInterpolator(radius: number): DataInterpolator {
    return new DataInterpolator(
      radius,
      {
        contributions: (distances) => distances.map((distance) => 1.0 - distance)
      },
      new ConstantInterpolationRange(radius)
    );
  }

  public interpolate(targetPoints: Point[], data: HeatmapDataPoint[]): HeatmapDataPoint[] {
    const interpolationRange = this.interpolationRangeProvider.range(data);
    const searchableFloorplan = this.searchableFloorplanFactory(data);
    return targetPoints.map((point) => {
      const nearbyNodes = searchableFloorplan.search(point, interpolationRange);
      const value = this.interpolateFromNearbyNodes(nearbyNodes, interpolationRange);
      return {
        x: point.x,
        y: point.y,
        radius: this.radius,
        value
      };
    });
  }

  private interpolateFromNearbyNodes(nearbyNodes: [number, HeatmapDataPoint][], interpolationRange: number): number {
    if (nearbyNodes.length === 0) {
      return 0.0;
    }
    let value = 0.0;
    let totalContributions = 0.0;
    const normalizedDistances = nearbyNodes.map((nodeWithDistance) => nodeWithDistance[0] / interpolationRange);
    const individualContributions = this.interpolationFunction.contributions(normalizedDistances);

    for (let i = 0; i < nearbyNodes.length; i++) {
      const contributionRate = individualContributions[i];
      const node = nearbyNodes[i][1];
      value += contributionRate * node.value;
      totalContributions += contributionRate;
    }

    return value / totalContributions;
  }
}
