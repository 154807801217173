export class GlobalAuthority {
  constructor(public value: GlobalAuthority_) {}
  static ALL = new GlobalAuthority('ALL');
  static USER = new GlobalAuthority('USER');
  static ADD_BUILDINGS = new GlobalAuthority('ADD_BUILDINGS');
  static DELETE_BUILDINGS = new GlobalAuthority('DELETE_BUILDINGS');
  static GLOBAL_ADMIN = new GlobalAuthority('GLOBAL_ADMIN');
  static MANAGE_USERS = new GlobalAuthority('MANAGE_USERS');
  static MANAGE_MOBILE_TOKEN = new GlobalAuthority('MANAGE_MOBILE_TOKEN');
  static MANAGE_TOKEN_USERS = new GlobalAuthority('MANAGE_TOKEN_USERS');
  static MANAGE_CONFIG = new GlobalAuthority('MANAGE_CONFIG');
  static VIEW_HIDDEN_COMPONENTS = new GlobalAuthority('VIEW_HIDDEN_COMPONENTS');
  static MANAGE_LUMINAIRE_MANUFACTURERS = new GlobalAuthority('MANAGE_LUMINAIRE_MANUFACTURERS');
  static MANAGE_BUILDINGS = new GlobalAuthority('MANAGE_BUILDINGS');
  static MANAGE_AWS_CREDENTIALS = new GlobalAuthority('MANAGE_AWS_CREDENTIALS');
  static MANAGE_GLOBAL_AUTHORITY_REQUEST = new GlobalAuthority('MANAGE_GLOBAL_AUTHORITY_REQUEST');
  static APP_CONFIGURATOR = new GlobalAuthority('APP_CONFIGURATOR');
  static APP_WIZARD = new GlobalAuthority('APP_WIZARD');
  static ALL_BUILDINGS = new GlobalAuthority('ALL_BUILDINGS');

  static fromString(value: string): GlobalAuthority {
    switch (value) {
      case 'ALL':
        return GlobalAuthority.ALL;
      case 'USER':
        return GlobalAuthority.USER;
      case 'ADD_BUILDINGS':
        return GlobalAuthority.ADD_BUILDINGS;
      case 'DELETE_BUILDINGS':
        return GlobalAuthority.DELETE_BUILDINGS;
      case 'GLOBAL_ADMIN':
        return GlobalAuthority.GLOBAL_ADMIN;
      case 'MANAGE_USERS':
        return GlobalAuthority.MANAGE_USERS;
      case 'MANAGE_TOKEN_USERS':
        return GlobalAuthority.MANAGE_TOKEN_USERS;
      case 'MANAGE_MOBILE_TOKEN':
        return GlobalAuthority.MANAGE_MOBILE_TOKEN;
      case 'MANAGE_CONFIG':
        return GlobalAuthority.MANAGE_CONFIG;
      case 'VIEW_HIDDEN_COMPONENTS':
        return GlobalAuthority.VIEW_HIDDEN_COMPONENTS;
      case 'MANAGE_LUMINAIRE_MANUFACTURERS':
        return GlobalAuthority.MANAGE_LUMINAIRE_MANUFACTURERS;
      case 'MANAGE_BUILDINGS':
        return GlobalAuthority.MANAGE_BUILDINGS;
      case 'MANAGE_AWS_CREDENTIALS':
        return GlobalAuthority.MANAGE_AWS_CREDENTIALS;
      case 'MANAGE_GLOBAL_AUTHORITY_REQUEST':
        return GlobalAuthority.MANAGE_GLOBAL_AUTHORITY_REQUEST;
      case 'APP_CONFIGURATOR':
        return GlobalAuthority.APP_CONFIGURATOR;
      case 'APP_WIZARD':
        return GlobalAuthority.APP_WIZARD;
      case 'ALL_BUILDINGS':
        return GlobalAuthority.ALL_BUILDINGS;
    }
  }

  static all(): GlobalAuthority[] {
    const all = [];
    all.push(
      GlobalAuthority.ALL,
      GlobalAuthority.USER,
      GlobalAuthority.ADD_BUILDINGS,
      GlobalAuthority.DELETE_BUILDINGS,
      GlobalAuthority.GLOBAL_ADMIN,
      GlobalAuthority.MANAGE_USERS,
      GlobalAuthority.MANAGE_TOKEN_USERS,
      GlobalAuthority.MANAGE_MOBILE_TOKEN,
      GlobalAuthority.MANAGE_CONFIG,
      GlobalAuthority.VIEW_HIDDEN_COMPONENTS,
      GlobalAuthority.MANAGE_LUMINAIRE_MANUFACTURERS,
      GlobalAuthority.MANAGE_AWS_CREDENTIALS,
      GlobalAuthority.MANAGE_BUILDINGS,
      GlobalAuthority.MANAGE_GLOBAL_AUTHORITY_REQUEST,
      GlobalAuthority.APP_CONFIGURATOR,
      GlobalAuthority.APP_WIZARD,
      GlobalAuthority.ALL_BUILDINGS
    );
    return all;
  }

  toString(): string {
    return this.value;
  }
}

type GlobalAuthority_ =
  | 'ALL'
  | 'USER'
  | 'ADD_BUILDINGS'
  | 'DELETE_BUILDINGS'
  | 'GLOBAL_ADMIN'
  | 'MANAGE_USERS'
  | 'MANAGE_TOKEN_USERS'
  | 'MANAGE_MOBILE_TOKEN'
  | 'MANAGE_CONFIG'
  | 'VIEW_HIDDEN_COMPONENTS'
  | 'MANAGE_LUMINAIRE_MANUFACTURERS'
  | 'MANAGE_AWS_CREDENTIALS'
  | 'MANAGE_BUILDINGS'
  | 'MANAGE_GLOBAL_AUTHORITY_REQUEST'
  | 'APP_CONFIGURATOR'
  | 'APP_WIZARD'
  | 'ALL_BUILDINGS';
