div#widgets-grid {
  height: 100%;
  max-height: calc(100% - 40px);
}
div#widgets-grid .grid-item {
  @apply rounded-xl shadow-lg;
}
div#widgets-grid .grid-item.gridster-item-moving {
  @apply shadow-xl;
}

div#widget-container {
  height: 100%;
}

.dashboard-tabs-container {
  max-height: 100%;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL2hvbWUvc3JjL2FwcC9kYXNoYm9hcmQvZGFzaGJvYXJkLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTs7QUFDQTtFQUNFOztBQUNBO0VBQ0U7OztBQUtOO0VBQ0U7OztBQUdGO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyJkaXYjd2lkZ2V0cy1ncmlkIHtcbiAgaGVpZ2h0OiAxMDAlO1xuICBtYXgtaGVpZ2h0OiBjYWxjKDEwMCUgLSA0MHB4KTsgLy8gNDBweCBpcyB0aGUgaGVpZ2h0IG9mIGFkZCBidXR0b25cbiAgLmdyaWQtaXRlbSB7XG4gICAgQGFwcGx5IHJvdW5kZWQteGwgc2hhZG93LWxnO1xuICAgICYuZ3JpZHN0ZXItaXRlbS1tb3Zpbmcge1xuICAgICAgQGFwcGx5IHNoYWRvdy14bDtcbiAgICB9XG4gIH1cbn1cblxuZGl2I3dpZGdldC1jb250YWluZXIge1xuICBoZWlnaHQ6IDEwMCU7XG59XG5cbi5kYXNoYm9hcmQtdGFicy1jb250YWluZXIge1xuICBtYXgtaGVpZ2h0OiAxMDAlXG59XG4iXX0= */