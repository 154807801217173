import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  imports: [NgClass, NgIf, NgFor, ReactiveFormsModule, FormsModule, AsyncPipe]
})
export class MultiselectComponent<T> implements OnInit {
  @ViewChild('innerForm') dropdown: NgForm;

  @Input() public multiselectForm: UntypedFormGroup;
  @Input() public controlName: string;
  @Input() public isEditMode: boolean;
  @Input() public allOptions$: Observable<T[]>;
  @Input() public areEqual: (a: T, b: T) => boolean;
  @Input() public label: (a: T) => string;
  @Input() public id: string;

  public selectedOptions$ = new BehaviorSubject<T[]>([]);
  public unselectedOptions$: Observable<T[]>;
  public unselectedCount$: Observable<number>;
  public selectedCount$: Observable<number>;

  private get control() {
    return this.multiselectForm.get(this.controlName);
  }

  public ngOnInit(): void {
    this.unselectedOptions$ = combineLatest([this.allOptions$, this.selectedOptions$]).pipe(
      map(([all, selected]) => this.filterElementsIn(all, selected)),
      shareReplay(1)
    );
    this.control.valueChanges.subscribe(this.parentChange.bind(this));

    this.unselectedCount$ = this.unselectedOptions$.pipe(map((unselected) => unselected?.length));
    this.selectedCount$ = this.selectedOptions$.pipe(map((selected) => selected?.length));
    this.selectedOptions$.next(this.multiselectForm.getRawValue()[this.controlName]);
  }

  public parentChange = (newValue: T[]) => {
    this.resetSelection(newValue);
  };

  public change = (newValue: T) => {
    if (newValue != null) {
      this.toggleSelection(newValue);
    }
    this.dropdown.setValue({ state: null });
  };

  public toggleSelection(option: T) {
    if (this.isSelected(option)) {
      this.unselect(option);
    } else {
      this.select(option);
    }
  }

  public unselect(option: T) {
    const updatedSelection = this.selectedOptions$.value.filter((o) => !this.areEqual(o, option));
    this.pushChange(updatedSelection);
  }

  private select(option: T) {
    const updatedSelection = [...this.selectedOptions$.value, { ...option }];
    this.pushChange(updatedSelection);
  }

  private pushChange(updatedSelection: T[]) {
    this.control.setValue(updatedSelection);
    this.control.markAsDirty();
    this.selectedOptions$.next(updatedSelection);
  }

  private resetSelection(clearState: T[]) {
    this.selectedOptions$.next(clearState);
  }

  private isSelected(option: T): boolean {
    let valueAsArray = this.selectedOptions$.value;
    if (!Array.isArray(valueAsArray)) {
      valueAsArray = Array.from(this.selectedOptions$.value);
    }
    return valueAsArray.some((o) => this.areEqual(o, option));
  }

  private filterElementsIn(a: T[], b: T[]): T[] {
    return a.reduce((accumulative, current) => {
      if (!Array.isArray(b)) {
        b = Array.from(b);
      }
      if (!b.some((element) => this.areEqual(element, current))) {
        return [...accumulative, current];
      }
      return accumulative;
    }, []);
  }
}
