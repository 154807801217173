import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, delay, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ICalendarEvent } from '@app/shared/models/calendar-event.interface';
import { CalendarEventResource } from '@app/shared/resources/calendar-event.resource';
import { isAfter, isBefore } from 'date-fns';

export class CalendarDatasource implements DataSource<ICalendarEvent> {
  private calendarEventsSubject$ = new BehaviorSubject<ICalendarEvent[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  public counter$ = this.countSubject.asObservable();
  public loading$ = this.loadingSubject.asObservable();

  constructor(private calendarEventResource: CalendarEventResource) {}

  private static sortByDate(item1: ICalendarEvent, item2: ICalendarEvent): number {
    if (isBefore(new Date(item1.date), new Date(item2.date))) {
      return -1;
    } else if (isAfter(new Date(item1.date), new Date(item2.date))) {
      return 1;
    } else {
      return 0;
    }
  }

  loadCalendarEvents(buildingId: number, pageIndex = 0, pageSize = 10): void {
    this.loadingSubject.next(true);

    this.calendarEventResource
      .getAllForBuilding(buildingId, pageIndex, pageSize)
      .pipe(
        catchError(() => of([])),
        // introduce a slight delay in order to show spinning icon. Any error, or empty response from backend
        // will result in a quick and abrupt response to the frontend. Having a consistent delay provides a
        // consistent UX
        delay(500)
      )
      .subscribe({
        next: (calendarEvents) => {
          // sort the content by creation date
          calendarEvents.content.sort(CalendarDatasource.sortByDate);
          this.calendarEventsSubject$.next(calendarEvents.content);
          this.countSubject.next(calendarEvents.totalElements);
          this.loadingSubject.next(false);
        },
        error: (err) => {
          this.loadingSubject.next(false);
          console.error(err);
        }
      });
  }

  connect(_collectionViewer: CollectionViewer): Observable<ICalendarEvent[]> {
    return this.calendarEventsSubject$.asObservable();
  }

  disconnect(_collectionViewer: CollectionViewer): void {
    this.calendarEventsSubject$.complete();
    this.loadingSubject.complete();
  }
}
