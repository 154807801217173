import { IElmtScheduleDto } from '@app/shared/models/elmt-schedule-dto.interface';

export interface NodeSelectorType {
  buildingId: number;
  floorIds?: number[];
  tagIds?: number[];
  sensorNodeIds?: number[];
  elmt: boolean;
  nodeTypes?: string[];
  includeUnmapped: boolean;
}

export class NodeSelector implements NodeSelectorType {
  buildingId: number;
  floorIds?: number[];
  tagIds?: number[];
  sensorNodeIds?: number[];
  elmt = false;
  nodeTypes?: string[];
  includeUnmapped = false;

  constructor(nodeSelector: NodeSelectorType) {
    this.buildingId = nodeSelector.buildingId;
    this.floorIds = nodeSelector.floorIds || [];
    this.tagIds = nodeSelector.tagIds || [];
    this.sensorNodeIds = nodeSelector.sensorNodeIds || [];
    this.elmt = nodeSelector.elmt; // Can't use || as it uses truthy
    if (this.elmt == null) {
      this.elmt = false;
    }
    this.nodeTypes = nodeSelector.nodeTypes || [];
    this.includeUnmapped = nodeSelector.includeUnmapped; // Can't use || as it uses truthy
    if (this.includeUnmapped == null) {
      this.includeUnmapped = false;
    }
  }

  static fromElmtScheduleDto(elmtScheduleDto: IElmtScheduleDto): NodeSelectorType {
    return new NodeSelector({
      buildingId: elmtScheduleDto.buildingId,
      floorIds: elmtScheduleDto.floorIds,
      tagIds: elmtScheduleDto.tagIds,
      elmt: true,
      includeUnmapped: false
    });
  }
}
