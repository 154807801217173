import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { FloorService } from '@app/shared/services/floor.service';
import { combineLatest, Observable, Subject, Subscription, timer } from 'rxjs';
import { ImageZoomService, ZoomOperation } from '@app/shared/services/image-zoom.service';
import { Coordinate, Point } from '@app/shared/utils/coordinate';
import { Offset } from '@app/shared/components/floorplan/offset';
import { Area } from '@app/shared/components/floorplan/area';
import { Style } from '@app/shared/components/floorplan/style';
import { Transform } from '@app/shared/components/floorplan/transform';
import { SelectionBox, SensorNodeService } from '@app/shared/services/sensor-node.service';
import { SelectableNode, SensorNode } from '@app/shared/models/sensor-node';
import { EmergencyLightingTestType } from '@app/shared/models/emergency-lighting-test-type';
import { ActivatedRoute } from '@angular/router';
import { FloorplanService } from '@services/floorplan.service';
import { DISCRIMINATOR, Selectable } from '@app/shared/models/selectable.interface';
import { Tag } from '@app/shared/models/tag.interface';
import { SelectableEmDriver } from '@app/shared/models/em-driver';
import { ConfirmationDialogService } from '@services/confirmation-dialog/confirmation-dialog.service';
import { EmDriverService } from '@services/em-driver.service';
import { ToastService } from '@app/shared/services/toast/toast.service';
import { CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { NodePoint, UnmappedNode } from '@app/shared/models/unmapped-nodes-datasource';
import { ConfirmDialogData } from '@components/dialogs/confirm/confirm.component';
import { Floor } from '@app/shared/models/floor.interface';
import { SensorNodeChangeHistoryService } from '@services/sensor-node-change-history.service';
import * as d3 from 'd3';
import { NavigationService } from '@services/navigation/navigation.service';
import { SecurityService } from '@services/security.service';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { QueryExecutorService } from '@app/analytics/metric-widget/query/query-executor.service';
import { AnalyticsMetricsService } from '@services/analytics-metrics.service';
import { CommonModule } from '@angular/common';
import { SensorNodeComponent } from '@components/floorplan/sensor-node/sensor-node.component';
import { PubSubConnectionsDirective } from '@components/floorplan/sensor-node/pub-sub-connections.directive';
import { FloorplanZoomControlComponent } from '@components/floorplan/floorplan-zoom-control/floorplan-zoom-control.component';
import { FloorplanActionsComponent } from '@components/floorplan/floorplan-actions/floorplan-actions.component';
import { HeatmapComponent } from '@components/heatmap/heatmap.component';
import { LiveQueryOutline } from '@app/analytics/metric-widget/query/outline/LiveQueryOutline';

@Component({
  selector: 'app-floorplan',
  templateUrl: './floorplan.component.html',
  styleUrls: ['./floorplan.component.scss'],
  imports: [
    CommonModule,
    CdkDropList,
    HeatmapComponent,
    FloorplanActionsComponent,
    FloorplanZoomControlComponent,
    PubSubConnectionsDirective,
    SensorNodeComponent
  ]
})
export class FloorplanComponent implements OnInit, OnDestroy {
  private static FIT_TO_SCREEN_TRANSITION = 'transform 0.3s ease, top 0.3s ease, left 0.3s ease';
  public containerSize = new Area(0, 0);
  public pubSubContainer = new Area(0, 0);
  private isMouseDown: boolean;
  private position: Coordinate = null;
  private origin: Coordinate = new Coordinate(0, 0);
  private margins: Area;
  private initialSelection: SelectionBox;
  private currentSelectionArea: SelectionBox = {
    top: 0,
    left: 0,
    height: 0,
    width: 0
  };
  private initialOffset: Offset;
  private scaleModifier: number;
  private destroyed$ = new Subject<void>();
  private readonly DROPPED_NODE_SIZE = 16; // 32 - 16 (size of container div - size of span containing icon)
  private liveNodeSubscription: Subscription = null;
  floor: Floor;
  floorplanImageUrl: string;
  isDragging: boolean;
  isActive: boolean;
  zoomLevel = 1;
  style: Style = new Style(false, new Transform(this.zoomLevel));
  isSelecting: boolean;
  selectionArea = {
    top: '0px',
    left: '0px',
    width: '0px',
    height: '0px'
  };
  dummyNode: SensorNode | null;
  pendingNodes: SensorNode[] = [];
  showPendingNodes = false;
  liveNodeData: any;
  @ViewChild('floorplan') private floorplanRef: ElementRef;
  @ViewChild('floorplanImage') floorplanImageRef: ElementRef;
  @ViewChild('imageContainer') imageContainerRef: ElementRef; // used to interact with node-drop events
  @Output() onNodeDrop = new EventEmitter<NodePoint>();
  @Output() onNodeClick = new EventEmitter<SelectableNode>();
  @Output() onFloorplanRightClick = new EventEmitter<{}>();
  @Output() onFloorplanDblClick = new EventEmitter<{}>();
  @Output() onSnMappingMode = new EventEmitter<boolean>();
  @Output() onPnMappingMode = new EventEmitter<boolean>();
  @Input() isActionsTrayEnabled = true;
  @Input({ required: true }) buildingId: number;
  constructor(
    private floorService: FloorService,
    private imageZoomService: ImageZoomService,
    private sensorNodesService: SensorNodeService,
    private route: ActivatedRoute,
    private floorplanService: FloorplanService,
    private toast: ToastService,
    private confirmDialogService: ConfirmationDialogService,
    private emDriverService: EmDriverService,
    private nodeChangeHistoryService: SensorNodeChangeHistoryService,
    private navigationService: NavigationService,
    private securityService: SecurityService,
    private queryExecutor: QueryExecutorService,
    private metricsService: AnalyticsMetricsService
  ) {}

  ngOnInit(): void {
    this.isMouseDown = false;
    this.floorplanService.initializeActionTray();
    this.setupFloorSubscription();
    this.setupTagSelectionSubscription();
    if (!this.floorplanService.isUnmappingPage) {
      this.checkQueryParamsForSelected();
    }
    this.setupZoomSubscription();
    this.setupLiveNodeDataSubscription();
  }

  ngOnDestroy(): void {
    this.floorplanService.resetSelectedTags();
    this.sensorNodesService.clearSelection();
    this.imageZoomService.setDefaultScale(1.0);
    this.liveNodeSubscription?.unsubscribe();
    this.imageZoomService.reset();
    this.destroyed$.next();
    this.destroyed$.complete();
    d3.select('#pub-sub-container').remove();
  }

  private setupFloorSubscription(): void {
    this.floorService
      .getCurrentFloor()
      .pipe(
        filter((floor) => floor != null),
        takeUntil(this.destroyed$)
      )
      .subscribe((floor) => {
        this.floor = floor;
        this.sensorNodesService.fetchNodes(floor.id, floor.buildingId);
        this.floorplanImageUrl = this.floorService.getFloorImageUrl(floor);
        this.centerFloorplan();
      });
  }

  private setupTagSelectionSubscription(): void {
    combineLatest([this.floorplanService.selectedTagsList, this.nodes$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([tags, nodes]) => this.selectNodesBasedOnTags(tags, nodes));
  }

  private setupZoomSubscription(): void {
    this.imageZoomService
      .getScale()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.zoomLevel = value;
        this.updateZoomLevel();
      });
    this.imageZoomService.recenterEvent.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.centerFloorplan(true, false);
    });
  }

  get nodes$(): Observable<SensorNode[]> {
    return this.sensorNodesService.getCurrentFloorNodes$();
  }

  get arePubSubConnectionsEnabled(): boolean {
    return this.floorplanService.arePubSubConnectionsEnabled;
  }

  private checkQueryParamsForSelected(): void {
    combineLatest([this.route.queryParams, this.nodes$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([paramMap, nodes]) => {
        const { n, ld, em } = paramMap;
        const entitiesToSelect: Selectable[] = [];

        const findEntities = (param: string[] | string, extractor: (e: SensorNode) => Selectable[]) => {
          if (Array.isArray(param)) {
            return nodes.flatMap(extractor).filter((entity) => param.includes(entity.id.toString()));
          } else {
            const foundEntity = nodes.flatMap(extractor).find((entity) => entity.id === Number(param));
            if (foundEntity) {
              this.focusNode(foundEntity);
            }
            this.style.setVisibility(true);
            return foundEntity ? [foundEntity] : [];
          }
        };

        entitiesToSelect.push(...findEntities(n, (node) => [node]));
        entitiesToSelect.push(...findEntities(ld, (node) => node.luminaireDrivers || []));
        entitiesToSelect.push(...findEntities(em, (node) => node.emDrivers || []));

        // avoid triggering another event for same selected entities
        if (!this.isSelectableArraysEqual(entitiesToSelect, this.sensorNodesService.selectedEntities)) {
          this.sensorNodesService.selectEntitiesInArea(entitiesToSelect.filter((e) => e != null));
        }
      });
  }

  isSelectableArraysEqual(arr1: Partial<{ id: number }>[], arr2: Partial<{ id: number }>[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
    const ids1 = arr1.map((item) => item.id).sort();
    const ids2 = arr2.map((item) => item.id).sort();
    return ids1.toString() === ids2.toString();
  }

  /**
   * When tags are selected from the tags component, update the selected nodes
   * on the floorplan. On page load, the floorplan service will start with null
   * as "selectedTagsList". This helps us discriminate when "no tags are selected"
   * vs when the page is loaded/reloaded with nodes present in the query params
   * @param tags - Tags that are selected and shared by floorplan service
   * @param nodes - nodes on this floor
   * @private
   */
  private selectNodesBasedOnTags(tags: Tag[], nodes: SensorNode[]): void {
    if (Array.isArray(tags) && tags.length > 0) {
      const nodesWithSelectedTags = nodes.filter((n) => {
        // use the commented lines below to only select nodes with emDrivers
        return n.tags.some((nodeTag) => tags.map((t) => t.id).includes(nodeTag.id));
        // if (n.emDrivers && n.emDrivers?.length > 0) {
        //   return n.tags.some((nodeTag) => tags.map((t) => t.id).includes(nodeTag.id));
        // }
        // return false;
      });
      if (nodesWithSelectedTags.length > 0) {
        this.sensorNodesService.clearSelection();
        this.sensorNodesService.selectEntitiesInArea(nodesWithSelectedTags);
        this.sensorNodesService.updateQueryParams();
      }
    } else if (Array.isArray(tags) && tags.length === 0) {
      this.sensorNodesService.clearSelection();
      this.sensorNodesService.updateQueryParams();
    }
  }

  onImageLoad(): void {
    this.containerSize = new Area(
      this.floorplanRef.nativeElement.clientWidth,
      this.floorplanRef.nativeElement.clientHeight
    );

    this.pubSubContainer = new Area(
      this.floorplanImageRef.nativeElement.clientWidth,
      this.floorplanImageRef.nativeElement.clientHeight
    );

    this.margins = new Area(this.containerSize.width / 2, this.containerSize.height / 2);
    this.centerFloorplan();

    this.style.setWidth(this.floorplanImageRef.nativeElement.clientWidth);
    this.style.setHeight(this.floorplanImageRef.nativeElement.clientHeight);

    this.initializeZoom();
    this.imageZoomService.reset();
  }

  private focusNode(node: Selectable): void {
    this.imageZoomService.setScale(1);
    this.setInitialOffset(this.origin.x * this.zoomLevel, this.origin.y * this.zoomLevel);
    this.isDragging = true;
    this.moveFloor(
      (this.containerSize.width / 2 - node.x) * this.zoomLevel,
      (this.containerSize.height / 2 - node.y) * this.zoomLevel
    );
    this.isDragging = false;
  }

  onWheelScroll(event: WheelEvent): void {
    const operation = event.deltaY > 0 ? ZoomOperation.ZOOM_OUT : ZoomOperation.ZOOM_IN;
    this.imageZoomService.zoom(operation);
    this.updateCenteringForZoomService();
    event.preventDefault();
  }

  handleZoomIncrease(): void {
    this.imageZoomService.zoom(ZoomOperation.ZOOM_IN);
    this.updateCenteringForZoomService();
  }

  handleZoomDecrease(): void {
    this.imageZoomService.zoom(ZoomOperation.ZOOM_OUT);
    this.updateCenteringForZoomService();
  }

  private updateZoomLevel(): void {
    this.scaleModifier = 1 / this.zoomLevel;
    this.style.setTransform(new Transform(this.zoomLevel));
  }

  private get shouldFocus(): boolean {
    const params = this.route.snapshot?.queryParams.n;
    return params != null && !Array.isArray(params);
  }

  private centerFloorplan(resetOrigin = true, animate?: boolean): void {
    this.style.setTransition(animate ? FloorplanComponent.FIT_TO_SCREEN_TRANSITION : null);
    this.margins = new Area(this.containerSize.width / 2, this.containerSize.height / 2);

    if (resetOrigin || this.position == null) {
      this.origin = this.getCenteredLocationForFloorplan();
    } else {
      this.origin = new Coordinate(
        this.containerSize.width / 2 - (this.position.x || 0),
        this.containerSize.height / 2 - (this.position.y || 0)
      );
    }

    this.style.setVisibility(!this.shouldFocus || animate != null);
    this.imageZoomService.setCentered();

    if (resetOrigin) {
      this.position = null;
      this.style.setTransformOrigin(this.position);
    }

    this.translate(this.origin.x, this.origin.y);
  }

  runTestsInBatch(testType: string): void {
    const nodesAndDrivers = this.sensorNodesService.selectedEntities.reduce(
      (result: [Selectable[], Selectable[]], element) => {
        if (element.discriminator === DISCRIMINATOR.EM_DRIVER) {
          result[1].push(element);
        } else if (element.discriminator === DISCRIMINATOR.PN || element.discriminator === DISCRIMINATOR.SN3) {
          result[0].push(element);
        }
        return result;
      },
      [[], []] // [[SNs + PNs], [EMs]]
    );
    const unique = (value, index, self) => {
      return self.indexOf(value) === index;
    };
    const selectedDrivers = nodesAndDrivers[0]
      .flatMap((node: SelectableNode) => node.emDrivers as SelectableEmDriver[])
      .concat(nodesAndDrivers[1] as SelectableEmDriver[])
      .filter(unique);
    const selectedDriversCount = selectedDrivers.length;
    const testEnum = EmergencyLightingTestType.fromValue(testType);
    if (selectedDriversCount > 0) {
      const dialogData = new ConfirmDialogData(
        `Start ${testType.toLowerCase()} ${selectedDriversCount > 1 ? 'tests' : 'test'} on ${selectedDriversCount} ${
          selectedDriversCount > 1 ? ' devices' : ' device'
        }?`,
        'Confirm Test Start'
      );
      this.confirmDialogService.open(dialogData).subscribe((confirmed) => {
        if (confirmed) {
          const driverIds = selectedDrivers.map((driver) => driver.id);
          this.emDriverService.startEmergencyLightingTestBatch(this.buildingId, driverIds, testEnum).subscribe({
            next: () => {
              this.toast.info({
                message: 'Request for running tests sent successfully',
                dataCy: 'send-success-toast'
              });
            },
            error: (err) => {
              console.error(err);
              this.toast.error({
                message: `There was some problem in running ${testType.toLowerCase()} tests for selected nodes`,
                autoClose: false,
                dismissible: true,
                dataCy: `send-error-toast`
              });
            },
            complete: () => {
              this.floorplanService.areaMode = false;
            }
          });
        }
      });
    }
  }

  cancelTests(): void {
    const selectedEmDriverIds = this.sensorNodesService.selectedEntities
      .filter((driver) => driver.discriminator === DISCRIMINATOR.EM_DRIVER)
      .map((driver) => driver.id);
    if (selectedEmDriverIds && selectedEmDriverIds.length > 0) {
      this.emDriverService.cancelEmergencyLightingTestBatch(this.buildingId, selectedEmDriverIds).subscribe({
        next: (message: any) => {
          message = message || 'Successfully cancelled all the tests.';
          this.toast.success({ message, dataCy: 'send-success-toast' });
        },
        error: (err) => {
          // this.toastService.error(JSON.parse(err?.error)?.message); // TODO remove this when issue fixed -> BE error message not coming to FE
          console.log(err);
          if (err.status === 400) {
            this.toast.error({
              message: 'There are no in-progress tests to be cancelled',
              dataCy: 'send-error-toast'
            });
          }
        }
      });
    }
  }

  private getCenteredLocationForFloorplan(): Coordinate {
    if (this.floorplanImageRef) {
      return new Coordinate(
        (this.containerSize.width - this.floorplanImageRef.nativeElement.clientWidth) / 2,
        (this.containerSize.height - this.floorplanImageRef.nativeElement.clientHeight) / 2
      );
    } else {
      console.warn('Could not get floor plan image reference, setting coordinate to (0, 0)');
      return new Coordinate(0, 0);
    }
  }

  private initializeZoom(): void {
    const widthRatio = this.floorplanImageRef.nativeElement.clientWidth / this.containerSize.width;
    const heightRatio = this.floorplanImageRef.nativeElement.clientHeight / this.containerSize.height;
    const ratio = widthRatio > heightRatio ? widthRatio : heightRatio;
    this.imageZoomService.setDefaultScale(1 / ratio);
  }

  private translate(x: number, y: number): void {
    this.style.setLeft(x);
    this.style.setTop(y);
  }

  onRightClick($event: MouseEvent): void {
    this.sensorNodesService.clearSelection();
    this.sensorNodesService.updateQueryParams();
    this.floorplanService.resetSelectedTags();
    $event.preventDefault();
    this.onFloorplanRightClick.emit({});
  }

  onMouseDown($event: MouseEvent | Touch): void {
    this.isMouseDown = true;
    this.toggleDragMode($event, true);
    if (this.floorplanService.isAreaModeActive) {
      this.startSelecting($event);
    } else if (this.floorplanService.isMoveModeActive) {
      this.startRecSensorNodeChangeHistory($event);
    }
  }

  private startRecSensorNodeChangeHistory($event: MouseEvent | Touch): void {
    const nodesCloseToSelected = this.sensorNodesService.selectedEntities;
    this.nodeChangeHistoryService.startRecSensorNodeChangeHistory(nodesCloseToSelected);
  }

  onMouseMove($event: MouseEvent | Touch): void {
    if (!this.floorplanService.isAreaModeActive) {
      if (this.floorplanService.isMoveModeActive && this.isMouseDown) {
        const clickedCoordinate = new Coordinate($event.clientX, $event.clientY);
        if (clickedCoordinate.within(this.floorplanImageRef.nativeElement.getBoundingClientRect())) {
          this.moveSelectedNode($event);
        }
      } else {
        this.moveFloor($event.clientX, $event.clientY);
      }
    } else if (this.isSelecting) {
      this.moveSelection($event);
    }
    if (this.floorplanService.isAddModeEnabled) {
      this.updateDummyNodePosition($event.clientX, $event.clientY);
    }
  }

  private moveSelectedNode($event: MouseEvent | Touch): void {
    const lastChange = this.nodeChangeHistoryService.getLast();
    if (!lastChange || lastChange.size === 0) {
      return;
    }
    const draggedNode = Array.from(lastChange.values())[0].node;
    const newPosition = this.validateRelativePosition(this.getRelativePosition($event.clientX, $event.clientY));
    const newScaledPosition = new Coordinate(newPosition.x * this.scaleModifier, newPosition.y * this.scaleModifier);
    const diff = newScaledPosition.minus(new Coordinate(draggedNode.x, draggedNode.y));
    draggedNode.update(newScaledPosition.x, newScaledPosition.y);
    if (this.floorplanService.isMoveAllModeActive) {
      this.sensorNodesService.moveAllNodesRelativeToDraggedNode(draggedNode, diff, lastChange);
    }
  }

  private getValidCoordinateForDummyNode(x: number, y: number): Coordinate {
    const position: Coordinate = this.getRelativePosition(x, y);
    const { x: validX, y: validY } = this.validateRelativePosition(position);
    return new Coordinate(validX, validY);
  }

  private updateDummyNodePosition(x: number, y: number): void {
    const validCoordinates = this.getValidCoordinateForDummyNode(x, y);
    this.dummyNode.x = validCoordinates.x * this.scaleModifier;
    this.dummyNode.y = validCoordinates.y * this.scaleModifier;
  }

  private validateRelativePosition(position: Coordinate): { x: number; y: number } {
    const { x, y } = position;
    const clientRect = this.floorplanImageRef.nativeElement.getBoundingClientRect();
    let validX = x;
    if (x < 0) {
      validX = 0;
    } else if (x > clientRect.width) {
      validX = clientRect.width;
    }
    let validY = y;
    if (y < 0) {
      validY = 0;
    } else if (y > clientRect.height) {
      validY = clientRect.height;
    }
    return { x: validX, y: validY };
  }

  onMouseUp($event: MouseEvent | Touch): void {
    this.isMouseDown = false;
    this.toggleDragMode($event, false);
    if (this.floorplanService.isAreaModeActive) {
      this.endSelecting();
    } else if (this.floorplanService.isMoveModeActive) {
      this.nodeChangeHistoryService.stopRecSensorNodeChangeHistory();
    }
  }

  addDummyNode($event: MouseEvent): void {
    if (this.floorplanService.isAddModeEnabled) {
      const clickedCoordinate = new Coordinate($event.clientX, $event.clientY);
      if (clickedCoordinate.within(this.floorplanImageRef.nativeElement.getBoundingClientRect())) {
        this.placeDummyNode($event.clientX, $event.clientY);
      }
    }
  }

  private placeDummyNode(x: number, y: number): void {
    const validCoordinate = this.getValidCoordinateForDummyNode(x, y);
    validCoordinate.x = validCoordinate.x * this.scaleModifier;
    validCoordinate.y = validCoordinate.y * this.scaleModifier;
    this.pendingNodes.push(SensorNode.fromPosition(validCoordinate));
  }

  onMouseLeave($event: MouseEvent): void {
    this.isActive = false;
    this.isMouseDown = false;
    if (this.isDragging) {
      this.toggleDragMode($event, false);
    }
  }

  onTouchEnd($event: TouchEvent): void {
    this.onMouseUp($event.changedTouches[0]);
  }

  onTouchMove($event: TouchEvent): void {
    this.onMouseMove($event.touches[0]);
  }

  onTouchStart($event: TouchEvent): void {
    this.onMouseDown($event.touches[0]);
    this.isActive = true;
  }

  onMouseOver($event: MouseEvent): void {
    this.isActive = true;
  }

  private validateOffset(offset: number, offsetLimit: number, margin: number): number {
    if (offset < offsetLimit - margin) {
      offset = offsetLimit - margin;
    }
    if (offset > margin) {
      offset = margin;
    }
    return offset;
  }

  private calculateOffsetX(x: number, initialOffset: Offset): number {
    const offset: number = initialOffset.left + (x - initialOffset.x);
    const offsetLimit: number = this.containerSize.width - this.floorplanImageRef.nativeElement.clientWidth;
    return this.validateOffset(offset, offsetLimit, this.margins.width);
  }

  private calculateOffsetY(y: number, initialOffset: Offset): number {
    const offset: number = this.initialOffset.top + (y - initialOffset.y);
    const offsetLimit: number = this.containerSize.height - this.floorplanImageRef.nativeElement.clientHeight;
    return this.validateOffset(offset, offsetLimit, this.margins.height);
  }

  private moveFloor(x: number, y: number): boolean {
    if (this.floorplanImageRef && this.isDragging) {
      this.origin = new Coordinate(
        this.calculateOffsetX(x, this.initialOffset),
        this.calculateOffsetY(y, this.initialOffset)
      );
      this.position = new Coordinate(
        this.containerSize.width / 2 - this.origin.x,
        this.containerSize.height / 2 - this.origin.y
      );
      this.translate(this.origin.x, this.origin.y);
      this.setInitialOffset(x, y);
      this.updateCenteringForZoomService();
      return false;
    }
  }

  private isCentered(): boolean {
    const centeredCoordinates = this.getCenteredLocationForFloorplan();
    return centeredCoordinates.x === this.origin.x && centeredCoordinates.y === this.origin.y;
  }

  private updateCenteringForZoomService(): void {
    this.isCentered() ? this.imageZoomService.setCentered() : this.imageZoomService.setUnCentered();
  }

  private toggleDragMode($event, isActive: boolean): void {
    this.isDragging = isActive != null ? !!isActive : !this.isDragging;
    if (this.isDragging) {
      this.setInitialOffset($event.clientX, $event.clientY);
    }
  }

  private setInitialOffset(x: number, y: number): void {
    this.initialOffset = new Offset(this.origin.x, this.origin.y, x, y);
  }

  private startSelecting($event: MouseEvent | Touch): void {
    const position: Coordinate = this.getRelativePosition($event.clientX, $event.clientY);
    this.isSelecting = true;
    this.initialSelection = {
      left: position.x * this.scaleModifier,
      top: position.y * this.scaleModifier,
      width: 0,
      height: 0
    };
  }

  private moveSelection($event: MouseEvent | Touch): void {
    if (this.isSelecting) {
      const position = this.getRelativePosition($event.clientX, $event.clientY);
      this.currentSelectionArea.left = this.initialSelection.left;
      this.currentSelectionArea.top = this.initialSelection.top;
      this.currentSelectionArea.width = position.x * this.scaleModifier - this.initialSelection.left;
      this.currentSelectionArea.height = position.y * this.scaleModifier - this.initialSelection.top;
      if (this.currentSelectionArea.width < 0) {
        this.currentSelectionArea.width = -this.currentSelectionArea.width;
        this.currentSelectionArea.left = this.currentSelectionArea.left - this.currentSelectionArea.width;
      }
      if (this.currentSelectionArea.height < 0) {
        this.currentSelectionArea.height = -this.currentSelectionArea.height;
        this.currentSelectionArea.top = this.currentSelectionArea.top - this.currentSelectionArea.height;
      }
      this.selectionArea = {
        left: this.currentSelectionArea.left + 'px',
        top: this.currentSelectionArea.top + 'px',
        width: this.currentSelectionArea.width + 'px',
        height: this.currentSelectionArea.height + 'px'
      };
    }
  }

  private getRelativePosition(x: number, y: number, snap?: boolean): Coordinate {
    const clientRect = this.floorplanImageRef.nativeElement.getBoundingClientRect();
    const relativeX = x - clientRect.left;
    const relativeY = y - clientRect.top;
    return new Coordinate(relativeX, relativeY);
  }

  private endSelecting(): void {
    const nodesInSelectedArea = this.sensorNodesService.getNodesInSelectedArea(
      this.currentSelectionArea,
      this.floorplanService.isElmtPage
    );
    const selectedNodesInArea = nodesInSelectedArea.filter((n) => n.selected);
    if (selectedNodesInArea.length === nodesInSelectedArea.length) {
      this.sensorNodesService.clearSelection();
      this.sensorNodesService.selectEntitiesInArea(selectedNodesInArea);
    } else {
      // otherwise select those nodes
      this.sensorNodesService.selectEntitiesInArea(nodesInSelectedArea, this.floorplanService.isCumulativeModeActive);
    }
    this.sensorNodesService.updateQueryParams();
    // TODO: Add the ability to select tag(s) when any node with those tags are selected on the floor plan
    this.isSelecting = false;
    this.floorplanService.areaMode = false;
    this.selectionArea = {
      top: '0px',
      left: '0px',
      width: '0px',
      height: '0px'
    };
    this.currentSelectionArea = {
      top: 0,
      left: 0,
      width: 0,
      height: 0
    };
  }

  private getDroppedNodeRelativePosition(point: {
    left: number;
    right: number;
    top: number;
    bottom: number;
  }): Point | null {
    const topLeftPoint = new Coordinate(point.left, point.top);
    const bottomRightPoint = new Coordinate(point.right, point.bottom);
    const containerRect = this.imageContainerRef.nativeElement.getBoundingClientRect();
    if (topLeftPoint.within(containerRect) && bottomRightPoint.within(containerRect)) {
      return {
        x: Math.floor((point.left + this.DROPPED_NODE_SIZE - containerRect.x) * this.scaleModifier),
        y: Math.floor((point.top + this.DROPPED_NODE_SIZE - containerRect.y) * this.scaleModifier)
      };
    }
  }

  private resetPendingNodesAndActionTray(fetchNodes = true): void {
    if (fetchNodes) {
      this.sensorNodesService.fetchNodes(this.floor.id, this.buildingId);
    }
    this.showPendingNodes = false;
    this.pendingNodes = [];
    this.dummyNode = null;
    Object.assign(this.floorplanService, {
      addMode: false,
      moveMode: false
    });
  }

  nodeDropped($event: CdkDragDrop<UnmappedNode[]>): void {
    const node = $event.item.data;
    const droppedNodeDims = {
      left: $event.dropPoint.x - this.DROPPED_NODE_SIZE,
      right: $event.dropPoint.x + this.DROPPED_NODE_SIZE,
      top: $event.dropPoint.y - this.DROPPED_NODE_SIZE,
      bottom: $event.dropPoint.y + this.DROPPED_NODE_SIZE
    };
    const dropPoint = this.getDroppedNodeRelativePosition(droppedNodeDims);
    if (dropPoint) {
      const droppedNode = { ...node, dropPoint };
      this.onNodeDrop.emit(droppedNode);
    }
  }

  handleAddNode(isAddModeEnabled: boolean): void {
    // show dummy node on floorplan and move it with mouse pointer
    this.showPendingNodes = isAddModeEnabled;
    this.dummyNode = isAddModeEnabled ? SensorNode.fromPosition(new Coordinate(0, 0)) : null;
  }

  handleDiscardAddedNodes(): void {
    const resetNodesAndSelection = (): void => {
      this.showPendingNodes = false;
      this.pendingNodes = [];
      this.dummyNode = null;
      this.sensorNodesService.clearSelection();
    };

    if (this.pendingNodes.length > 0) {
      this.confirmDialogService
        .open({
          title: 'Discard Added Nodes',
          message: 'Are you sure you want to discard the added nodes?',
          showConfirm: true
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            resetNodesAndSelection();
          }
        });
    } else {
      resetNodesAndSelection();
    }
    this.floorplanService.addMode = false;
  }

  handleNodeClick(node: SelectableNode): void {
    this.onNodeClick.emit(node);
  }

  onFloorplanDoubleClick($event: MouseEvent): void {
    $event.preventDefault();
    this.onFloorplanDblClick.emit({});
  }

  get isSNMappingMode(): boolean {
    return this.floorplanService.isSensorNodeMappingModeActive;
  }

  get isHeatmapEnabled(): boolean {
    return this.floorplanService.isHeatmapModeActive;
  }

  get areNodesEnabled(): boolean {
    return this.floorplanService.isEnableAllNodesSelected;
  }

  handleSaveAddedNodes(): void {
    if (this.pendingNodes.length === 0) {
      return;
    }
    this.sensorNodesService.saveNodes(this.floor.id, this.pendingNodes).subscribe({
      next: () => {
        this.resetPendingNodesAndActionTray();
        // enable unmapped mode to show the newly added nodes
        this.floorplanService.unmappedMode = true;
      },
      error: (err) => {
        console.error(err);
        this.toast.error({
          message: 'There was some problem in saving the nodes',
          autoClose: false,
          dismissible: true,
          dataCy: 'send-error-toast'
        });
        this.resetPendingNodesAndActionTray();
      }
    });
  }

  handleUndoAddNode(): void {
    if (Array.isArray(this.pendingNodes)) {
      this.pendingNodes.pop();
    }
  }

  handleSaveMovedNodes(): void {
    this.sensorNodesService.saveChangedSensorNodes().subscribe((_) => {
      this.nodeChangeHistoryService.clearSensorNodeChangeHistory();
      this.resetPendingNodesAndActionTray();
    });
  }

  handleUndoMovedNodes(): void {
    if (this.floorplanService.isMoveModeActive) {
      // Because clicking the undo button while Move mode is active pushes another state to the history we need to undo twice
      this.nodeChangeHistoryService.undo();
      this.nodeChangeHistoryService.undo();
    }
  }

  handleDiscardMovedNodes(): void {
    if (this.nodeChangeHistoryService.total() > 0) {
      this.confirmDialogService
        .open(new ConfirmDialogData('Are you sure you want to discard changes?'))
        .subscribe((value) => {
          if (value) {
            this.nodeChangeHistoryService.undoAll();
            this.nodeChangeHistoryService.clearSensorNodeChangeHistory();
          }
        });
    }
    this.resetPendingNodesAndActionTray(false);
  }

  handleSnMappingMode(val: boolean): void {
    this.onSnMappingMode.emit(val);
  }

  handlePnMappingMode(val: boolean): void {
    this.onPnMappingMode.emit(val);
  }

  get floorPlanWidth(): number {
    return this.floorplanImageRef ? this.floorplanImageRef.nativeElement.clientWidth : 10;
  }

  get floorPlanHeight(): number {
    return this.floorplanImageRef ? this.floorplanImageRef.nativeElement.clientHeight : 10;
  }

  isHeatmapPage(): boolean {
    return this.navigationService.getActiveSection().info.Id === 'heatmap';
  }

  setupLiveNodeDataSubscription(): void {
    if (this.isHeatmapPage()) {
      this.securityService
        .isAuthorizedForBuilding(BuildingAuthorityType.HIGH_RESOLUTION_ANALYTICS.value, this.buildingId)
        .subscribe((value) => {
          this.doLiveNodeDataQuery();
        });
    }
  }

  // FIXME: The fetching of live data should be moved to the service or at least to analytics.component
  private doLiveNodeDataQuery(): void {
    this.liveNodeSubscription = timer(0, 60000)
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((_) => {
          const outline: LiveQueryOutline = this.metricsService.getLiveOutline();
          outline.sensorNodeIds = [];
          outline.tagIds = [];
          outline.zone = undefined;
          outline.timezoneOffset = undefined;
          return this.queryExecutor.doLiveDataQuery(outline);
        })
      )
      .subscribe((value) => {
        this.liveNodeData = value;
      });
  }
}
