import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import { IRole, Role } from '@app/shared/models/role.interface';
import { UserRole } from '@app/shared/models/user-role.interface';
import { ITenant } from '@app/shared/models/tenant.interface';
import { NgFor, NgIf } from '@angular/common';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatButton } from '@angular/material/button';

export type InvitationDialogData = {
  buildingId: number;
  mode: FORM_TYPE;
  userRole?: UserRole;
  allRoles: Role[];
  tenants: ITenant[];
  autoSelectTenant?: number;
};

export type DialogResult = {
  formValue: { emailAddress: string; roleIds: number[] };
  roles: IRole[];
};

@Component({
  selector: 'app-user-invitation-dialog',
  templateUrl: './user-invitation-dialog.component.html',
  styleUrls: ['./user-invitation-dialog.component.scss'],
  imports: [
    MatDialogTitle,
    MatDialogContent,
    ReactiveFormsModule,
    NgIf,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    MatSelect,
    MatOption,
    NgFor,
    MatDialogActions,
    MatButton
  ]
})
export class UserInvitationDialogComponent implements OnInit {
  inviteUserForm: FormGroup;
  readonly mode: FORM_TYPE;
  private readonly buildingId: number;
  private readonly roleIds: number[];
  private readonly userName: string;
  private readonly tenantId: number;
  readonly allRoles: Role[];
  readonly tenants: ITenant[];
  readonly autoSelectTenant?: number;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: InvitationDialogData,
    private dialogRef: MatDialogRef<UserInvitationDialogComponent>
  ) {
    this.buildingId = data.buildingId;
    this.mode = data.mode;
    this.roleIds = data.userRole ? data.userRole.roles.map((r) => r.id) : [];
    this.userName = data.userRole ? data.userRole.user.name : '';
    this.allRoles = data.allRoles || [];
    this.tenants = data.tenants || [];
    this.autoSelectTenant = data.autoSelectTenant;

    this.inviteUserForm = this.fb.group({
      emailAddress:
        this.mode === FORM_TYPE.INVITE
          ? ['', [Validators.required, Validators.email]]
          : // in user edit form, email address will not be present, so "required" validation can be removed
            ['', [Validators.email]],
      roleIds: [this.roleIds, [Validators.required, this.emptyArrayValidator()]],
      tenantId: [null, []],
      autoSelectTenant: [null, []]
    });
  }

  private emptyArrayValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (Array.isArray(control.value) && control.value.length === 0) {
        return { emptyArray: { value: control.value } };
      }
      return null;
    };
  }

  get title(): string {
    return this.mode === FORM_TYPE.INVITE ? 'Invite Users' : `Edit user - ${this.userName}`;
  }

  ngOnInit(): void {
    // intentionally left blank
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (this.inviteUserForm.invalid) {
      return;
    }
    const roles = this.inviteUserForm.value.roleIds.map((roleId) => this.allRoles.find((role) => role.id === roleId));
    if (this.autoSelectTenant != null) {
      this.inviteUserForm.value.tenantId = this.autoSelectTenant;
    }
    this.dialogRef.close({ formValue: this.inviteUserForm.value, roles });
  }
}

export enum FORM_TYPE {
  EDIT,
  INVITE
}
