import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ManagingCompany } from '@app/shared/models/building.interface';
import { ManagingCompanyService } from '@app/shared/services/managing-company.service';

export class ManagingCompanyDatasource implements DataSource<ManagingCompany> {
  private managingCompaniesSubject = new BehaviorSubject<ManagingCompany[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private managingCompanyService: ManagingCompanyService) {}

  loadManagingCompanies(): void {
    this.loadingSubject.next(true);

    this.managingCompanyService
      .getManagingCompanies()
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((res) => {
        this.managingCompaniesSubject.next(res);
      });
  }

  connect(collectionViewer: CollectionViewer): Observable<ManagingCompany[]> {
    return this.managingCompaniesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.managingCompaniesSubject.complete();
    this.loadingSubject.complete();
  }
}
