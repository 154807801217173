import { ScheduleResource } from '@app/shared/resources/schedule.resource';
import { Observable } from 'rxjs';
import { Schedule } from '@app/shared/models/schedule';
import { Injectable } from '@angular/core';
import { IScheduleDto } from '@app/shared/models/schedule-dto';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  constructor(private scheduleResource: ScheduleResource) {}

  public getSchedules(buildingId: number): Observable<IScheduleDto[]> {
    return this.scheduleResource.getSchedules(buildingId);
  }

  public getEditableIds(buildingId: number): Observable<number[]> {
    return this.scheduleResource.getEditableIds(buildingId);
  }

  public saveSchedule(schedule: IScheduleDto, buildingId: number): Observable<Schedule> {
    return this.scheduleResource.saveSchedule(buildingId, schedule);
  }

  public updateSchedule(schedule: IScheduleDto): Observable<IScheduleDto> {
    return this.scheduleResource.updateResource(schedule.id, schedule);
  }

  public deleteSchedule(schedule: Schedule): Observable<{}> {
    return this.scheduleResource.delete(schedule.id);
  }

  public moveUp(schedule: Schedule): Observable<{}> {
    return this.scheduleResource.moveUp(schedule.id);
  }

  public moveDown(schedule: Schedule): Observable<{}> {
    return this.scheduleResource.moveDown(schedule.id);
  }
}
