import { ActivatedRouteSnapshot, createUrlTreeFromSnapshot, Routes, UrlTree } from '@angular/router';
import { MainLayoutComponent } from '@app/main-layout/main-layout.component';
import { LoginComponent } from '@app/login/login.component';
import { GlobalAdministrationComponent } from '@app/global-administration/layout/global-administration.component';
import { UserInformationComponent } from '@app/global-administration/user-information/user-information.component';
import { GlobalAdministrationUsersComponent } from '@app/global-administration/user-information/user/user.component';
import { GlobalAuthorityRequestsComponent } from '@app/global-administration/user-information/global-authority-requests/global-authority-requests.component';
import { GlobalAdministrationManagingCompanyComponent } from '@app/global-administration/managing-company/managing-company.component';
import { GlobalAdministrationEmergencyLightingCalendarComponent } from '@app/global-administration/emergency-lighting-calendar/emergency-lighting-calendar.component';
import { GlobalAdministrationPacketStreamerComponent } from '@app/global-administration/packet-streamer/packet-streamer.component';
import { GlobalAdministrationOtapUpgradesComponent } from '@app/global-administration/otap-upgrades/otap-upgrades.component';
import { JobInformationComponent } from '@app/global-administration/otap-upgrades/job-information/job-information.component';
import { BuildingsComponent } from '@app/buildings/buildings.component';
import { AppNewBuildingComponent } from '@app/buildings/new/new-building.component';
import { inject } from '@angular/core';
import { UserService } from '@services/user/user.service';
import { map } from 'rxjs/operators';
import { GlobalAuthority } from '@app/shared/models/global-authority';
import { AdministrationComponent } from '@app/administration/layout/administration.component';
import { BuildingAuthorityType } from '@app/shared/models/building-authority-type';
import { AdministrationBuildingComponent } from '@app/administration/building/building.component';
import { AdministrationGatewaysComponent } from '@app/administration/gateway/gateway.component';
import { FloorsComponent } from '@app/administration/floors/floors.component';
import { AdministrationUsersComponent } from '@app/administration/user/user.component';
import { TokensComponent } from '@app/administration/tokens/tokens.component';
import { MobileTokenComponent } from '@app/administration/mobile-token/mobile-token.component';
import { RolesComponent } from '@app/administration/roles/roles.component';
import { TenantsComponent } from '@app/administration/tenants/tenants.component';
import { AdministrationCalendarComponent } from '@app/administration/calendar/calendar.component';
import { DdlComponent } from '@app/administration/ddl/ddl.component';
import { AdministrationEmergencyLightingComponent } from '@app/administration/emergency-lighting/emergency-lighting.component';
import { EmergencyLightingComponent } from '@app/emergency-lighting/layout/emergency-lighting.component';
import { EmergencyLightingSchedulesComponent } from '@app/emergency-lighting/schedules/emergency-lighting-schedules.component';
import { EmergencyLightingReportsComponent } from '@app/emergency-lighting/reports/emergency-lighting-reports.component';
import { EmailSchedulesComponent } from '@app/emergency-lighting/email-schedules/email-schedules.component';
import { EmergencyLightingManualTestsComponent } from '@app/emergency-lighting/manual-tests/emergency-lighting-manual-tests.component';
import { CircadianCurveComponent } from '@app/circadian-curve/circadian-curve.component';
import { SchedulesComponent } from '@app/schedules/schedules.component';
import { DashboardComponent } from '@app/dashboard/dashboard.component';
import { SecurityService } from '@services/security.service';
import { GeneralComponent } from '@app/dashboard/general/general.component';
import { EnergyComponent } from '@app/dashboard/energy/energy.component';
import { OccupancyComponent } from '@app/dashboard/occupancy/occupancy.component';
import { MaintenanceComponent } from '@app/dashboard/maintenance/maintenance.component';
import { MetricsComponent } from '@app/dashboard/metrics/metrics.component';
import { SensorNodesComponent } from '@app/sensor-nodes/sensor-nodes.component';
import { BeaconsComponent } from '@app/beacons/beacons.component';
import { LightingConfigurationComponent } from '@app/lighting-configuration/lighting-configuration.component';
import { AnalyticsComponent } from '@app/analytics/analytics.component';
import { MapNodesComponent } from '@app/mapping-nodes/map-nodes.component';
import { IntegrationsComponent } from '@app/integrations/integrations.component';
import { combineLatest } from 'rxjs';
import { UserComponent } from '@app/user/user.component';
import { ServiceLevelComponent } from '@app/administration/service-level/service-level.component';
import { hashRedirectGuard, metricActivationGuard } from './route-guards';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [hashRedirectGuard()]
  },
  {
    path: '',
    component: MainLayoutComponent,
    runGuardsAndResolvers: 'always',
    canActivateChild: [hashRedirectGuard()],
    children: [
      {
        path: '',
        redirectTo: '/buildings',
        pathMatch: 'full'
      },
      {
        path: 'user',
        component: UserComponent
      },
      {
        path: 'global-administration',
        component: GlobalAdministrationComponent,
        children: [
          {
            path: '',
            redirectTo: 'user-information',
            pathMatch: 'full'
          },
          {
            path: 'user-information',
            component: UserInformationComponent,
            children: [
              { path: 'users', component: GlobalAdministrationUsersComponent },
              { path: 'authority-requests', component: GlobalAuthorityRequestsComponent }
            ]
          },
          {
            path: 'managing-company',
            component: GlobalAdministrationManagingCompanyComponent
          },
          {
            path: 'emergency-lighting-calendar',
            component: GlobalAdministrationEmergencyLightingCalendarComponent
          },
          {
            path: 'packet-stream',
            component: GlobalAdministrationPacketStreamerComponent
          },
          {
            path: 'otap-upgrades',
            component: GlobalAdministrationOtapUpgradesComponent
          },
          {
            path: 'otap-upgrades/job-information',
            component: JobInformationComponent
          }
        ]
      }
    ]
  },
  {
    path: 'buildings',
    component: MainLayoutComponent,
    canActivateChild: [hashRedirectGuard()],
    children: [
      {
        path: '',
        component: BuildingsComponent,
        pathMatch: 'full'
      },
      {
        path: 'new',
        component: AppNewBuildingComponent,
        canActivate: [
          () => {
            const userService = inject(UserService);
            return userService
              .getCurrentUser()
              .pipe(
                map((user): boolean =>
                  user.globalAuthorities.some(
                    (authority) => authority?.toString() === GlobalAuthority.ADD_BUILDINGS.toString()
                  )
                )
              );
          }
        ]
      }
    ]
  },
  {
    path: 'buildings/:buildingId',
    component: MainLayoutComponent,
    canActivateChild: [hashRedirectGuard()],
    children: [
      {
        path: '',
        redirectTo: 'administration',
        pathMatch: 'full'
      },
      {
        path: 'administration',
        component: AdministrationComponent,
        canActivateChild: [
          (route: ActivatedRouteSnapshot) => {
            const buildingId = parseInt(route.params.buildingId, 10);
            const userService = inject(UserService);
            return combineLatest([userService.getCurrentUser(), userService.getBuilding(buildingId)]).pipe(
              map(([currentUser, building]): boolean | UrlTree => {
                const buildingAuthorizations = currentUser.buildingAuthorizations.filter(
                  (ba) => ba.buildingId === buildingId
                );
                const hasBuildingAuth = buildingAuthorizations.some((a) =>
                  a.authorities.some(
                    (bat: BuildingAuthorityType) => bat.toString() === BuildingAuthorityType.MANAGE_BUILDING.toString()
                  )
                );

                const hasUserAuth = buildingAuthorizations.some((a) =>
                  a.authorities.some(
                    (bat: BuildingAuthorityType) => bat.toString() === BuildingAuthorityType.MANAGE_USERS.toString()
                  )
                );

                if (route.routeConfig.path === 'building') {
                  if (hasBuildingAuth) {
                    return true;
                  } else if (hasUserAuth) {
                    return createUrlTreeFromSnapshot(route, ['../users']);
                  }
                } else {
                  if (hasBuildingAuth || hasUserAuth) {
                    return true;
                  } else {
                    const queryParams =
                      building.floors.length === 0 ? { buildingNameWithNoFloors: building.name } : null;
                    return createUrlTreeFromSnapshot(route, ['/buildings'], queryParams);
                  }
                }
              })
            );
          }
        ],
        children: [
          {
            path: '',
            redirectTo: 'building',
            pathMatch: 'full'
          },
          { path: 'building', component: AdministrationBuildingComponent },
          { path: 'service-information', component: ServiceLevelComponent },
          { path: 'gateways', component: AdministrationGatewaysComponent },
          { path: 'floors', component: FloorsComponent },
          { path: 'users', component: AdministrationUsersComponent },
          { path: 'tokens', component: TokensComponent },
          { path: 'mobile-tokens', component: MobileTokenComponent },
          { path: 'roles', component: RolesComponent },
          { path: 'tenants', component: TenantsComponent },
          { path: 'calendar', component: AdministrationCalendarComponent },
          { path: 'ddl', component: DdlComponent },
          {
            path: 'emergency-lighting',
            component: AdministrationEmergencyLightingComponent
          },
          {
            path: 'integrations',
            component: IntegrationsComponent,
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'areas'
              },
              {
                path: 'areas',
                component: IntegrationsComponent
              }
            ]
          }
        ]
      },
      {
        path: 'emergency-lighting',
        component: EmergencyLightingComponent,
        children: [
          {
            path: '',
            redirectTo: 'schedules',
            pathMatch: 'full'
          },
          { path: 'schedules', component: EmergencyLightingSchedulesComponent },
          { path: 'reports', component: EmergencyLightingReportsComponent },
          { path: 'email-schedules', component: EmailSchedulesComponent },
          { path: ':floorId/manual-tests', component: EmergencyLightingManualTestsComponent }
        ]
      },
      {
        path: 'circadian-curves',
        component: CircadianCurveComponent,
        children: []
      },
      {
        path: 'schedules',
        component: SchedulesComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [
          (route: ActivatedRouteSnapshot) => {
            const buildingId = Number(route.params.buildingId);
            const securityService = inject(SecurityService);
            return securityService.isRoleAuthorizedForBuilding(
              [BuildingAuthorityType.VIEW_DASHBOARD_INSIGHTS, BuildingAuthorityType.VIEW_DASHBOARD_ANALYTICS],
              buildingId
            );
          }
        ],
        canActivateChild: [metricActivationGuard()],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'general'
          },
          {
            path: 'general',
            component: GeneralComponent
          },
          {
            path: 'energy',
            component: EnergyComponent
          },
          {
            path: 'occupancy',
            component: OccupancyComponent
          },
          {
            path: 'maintenance',
            component: MaintenanceComponent
          },
          {
            path: 'metrics',
            component: MetricsComponent
          }
        ]
      },
      {
        path: ':floorId',
        children: [
          {
            path: 'sensor-nodes',
            component: SensorNodesComponent
          },
          {
            path: 'beacons',
            component: BeaconsComponent
          },
          {
            path: 'lighting-configuration',
            component: LightingConfigurationComponent
          },
          {
            path: 'heatmap',
            component: AnalyticsComponent,
            canActivate: [
              (route: ActivatedRouteSnapshot) => {
                const buildingId = Number(route.params.buildingId);
                const securityService = inject(SecurityService);
                return securityService.isAuthorizedForBuilding(BuildingAuthorityType.ANALYTICS.value, buildingId);
              }
            ],
            children: []
          },
          {
            path: 'map-nodes',
            component: MapNodesComponent
          }
        ]
      }
    ]
  }
];
