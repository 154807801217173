<div class="flex flex-row w-full justify-between pt-4">
  <button
    class='w-1/2 mx-1'
    mat-raised-button
    matTooltip="Reset"
    (click)="reset()"
    [disabled]="disableReset"
    [attr.data-cy]="'btn-reset-config-' + dataCy">
    <mat-icon>refresh</mat-icon>
    <span class='text-sm'>Reset</span>
  </button>
  <button
    class='w-1/2 xl:mx-1'
    mat-raised-button
    [disabled]='disableSubmit'
    [matTooltip]="submitLabel === 'Generate' ? 'Generate' : 'Update Settings'"
    color="primary"
    (click)='update()'
    [attr.data-cy]="'btn-generate-' + dataCy">
    <mat-icon fontIcon="task_alt" />
    <span class="text-sm">{{ submitLabel }}</span>
  </button>
</div>
