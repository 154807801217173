import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GridsterComponent, GridsterConfig, GridsterItemComponent } from 'angular-gridster2';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { Building } from '@app/shared/models/building.interface';
import { DashboardService } from '@services/dashboard.service';
import { SharedComponentsModule } from '@app/shared/shared-components.module';
import { WidgetItem } from '@app/dashboard/model/widget-item';
import { DashboardGridOptions } from '@app/dashboard/dashboard-config';
import { RefreshPageComponent } from '@app/shared/components/refresh-page/refresh-page.component';
import { CountWidgetComponent } from '@app/dashboard/widgets/count-widget/count-widget.component';
import { ChartWidgetComponent } from '@app/dashboard/widgets/chart-widget/chart-widget.component';
import { DashboardStateService } from '../dashboard-filter/dashboard-state.service';

@Component({
  selector: 'app-general',
  imports: [
    GridsterComponent,
    GridsterItemComponent,
    MatIcon,
    MatIconButton,
    SharedComponentsModule,
    RefreshPageComponent,
    CountWidgetComponent,
    ChartWidgetComponent
  ],
  templateUrl: './general.component.html',
  styleUrl: './general.component.scss'
})
export class GeneralComponent implements OnInit {
  gridOptions: GridsterConfig;
  dashboard: Array<WidgetItem>;
  building: Building;
  @Output() refreshEvent = new EventEmitter<WidgetItem>();

  constructor(private dashboardService: DashboardService, private stateService: DashboardStateService) {}

  ngOnInit(): void {
    this.setupDashboard();
    this.stateService.dashboardFilterState$.subscribe({
      next: () => this.refreshWidget()
    });
  }

  private setupDashboard(): void {
    this.gridOptions = {
      ...DashboardGridOptions
    };
    this.dashboard = this.dashboardService.getDashboardForGeneral();
  }

  refreshWidget($event?: MouseEvent | TouchEvent, item?: WidgetItem): void {
    $event?.preventDefault();
    $event?.stopPropagation();
    this.refreshEvent.emit(item);
  }
}
