import { Point } from '@app/shared/utils/coordinate';

export interface Grid {
  points: Point[];
  distance: number;
}

export interface GridProvider {
  compute(width: number, height: number): Grid;
}

export class RegularGridProvider implements GridProvider {
  constructor(private minPointDistance: number, private maxPoints: number) {}

  compute(width: number, height: number): Grid {
    const dist = this.pointDistance(width, height);
    const pts = [];
    for (let x = dist / 2; x < width; x += dist) {
      for (let y = dist / 2; y < height; y += dist) {
        pts.push({ x, y });
      }
    }
    return {
      points: pts,
      distance: dist
    };
  }

  public pointDistance(width: number, height: number): number {
    /*
     * The below eqn. would be correct if we were laying out the nodes in a grid, which we are not.
     *
     * However doing much better is hard and doesn't seem productive to do better on (the reason being that the
     * number of nodes that we do place depends on the number of rows and whether it's even/odd).
     */
    const distanceForMaxPoints = Math.sqrt((width * height) / this.maxPoints);
    return Math.max(this.minPointDistance, distanceForMaxPoints);
  }
}

export class HoneycombGridProvider implements GridProvider {
  // Multiple of the x offset, such that the radii touch
  public static Y_OFFSET_CIRCLE_MULTIPLIER = (1 + Math.sin(Math.PI / 4)) / 2;
  public static Y_OFFSET_HEXAGON_MULTIPLIER = 3.0 / 4.0;

  constructor(private minPointDistance: number, private maxPoints: number, private yOffsetMultiplier: number) {}

  public compute(width: number, height: number): Grid {
    const [pointDistance, pointLocations] = this.pointLocations(width, height);

    const points = pointLocations.map((pt) => {
      return {
        x: pt.x,
        y: pt.y
      };
    });
    return {
      points,
      distance: pointDistance
    };
  }

  private pointLocations(width: number, height: number): [number, Point[]] {
    const roughPointDistance = new RegularGridProvider(this.minPointDistance, this.maxPoints).pointDistance(
      width,
      height
    );

    const cols = Math.floor(width / roughPointDistance);
    const pointDistanceX = width / cols;
    const pointDistanceY = this.yOffsetMultiplier * pointDistanceX;
    const rows = Math.ceil(height / pointDistanceY); // would rather have too many than too few, hence ceil
    const firstRowOffsetY = (height - (rows - 1) * pointDistanceY) / 2;

    const points: Point[] = [];
    for (let row = 0; row < rows; row++) {
      const isEvenRow: boolean = row % 2 === 0;
      const nodesInThisRow = isEvenRow ? cols : cols - 1;
      for (let i = 0; i < nodesInThisRow; i++) {
        points.push({
          x: Math.round(isEvenRow ? i * pointDistanceX + pointDistanceX / 2 : (i + 1) * pointDistanceX),
          y: Math.round(row * pointDistanceY + firstRowOffsetY)
        });
      }
    }

    return [pointDistanceX, points];
  }
}
