import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { SecurityService } from '@services/security.service';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';

export function metricActivationGuard(): (route: ActivatedRouteSnapshot) => Observable<true | UrlTree> | true {
  return (route: ActivatedRouteSnapshot) => {
    const router = inject(Router);
    const securityService = inject(SecurityService);
    const buildingId = Number(route.params.buildingId);
    if (route.routeConfig.path !== 'metrics') {
      return securityService
        .hasOnlyAnalyticsViewCapability(buildingId)
        .pipe(
          map((hasOnlyAnalyticsViewCapability) =>
            !hasOnlyAnalyticsViewCapability
              ? true
              : router.createUrlTree([`/buildings/${buildingId}/dashboard/metrics`])
          )
        );
    } else {
      return true;
    }
  };
}

export function hashRedirectGuard(): (route: ActivatedRouteSnapshot) => boolean | UrlTree {
  return (_route: ActivatedRouteSnapshot) => {
    const router = inject(Router);
    const location = inject(Location);
    const currentUrl = window.location.href;
    if (currentUrl.includes('#!/')) {
      const newUrl = currentUrl.replace('#!/', '');
      const parsedUrl = new URL(newUrl);
      location.replaceState(parsedUrl.pathname + parsedUrl.search + parsedUrl.hash);
      return router.parseUrl(parsedUrl.pathname + parsedUrl.search + parsedUrl.hash);
    }
    return true;
  };
}
