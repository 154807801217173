<div id="orAdministrationUsers" class="administration-users">
  <div class="tile medium">
    @if (userDataSource?.loading$ | async) {
      <div class="mat-spinner spinner-container" data-cy="users-spinner-icon">
        <mat-spinner></mat-spinner>
      </div>
    }
    <div class="tile-header mat-elevation-z8">
      <h2 style="font-size: 15px;">Users</h2>
    </div>
    <table
      aria-label="List of Users"
      mat-table
      matSort
      [dataSource]="userDataSource"
      class="users-table mat-elevation-z8">
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> # </th>
        <td mat-cell *matCellDef="let userRole">{{userRole.user.id}}</td>
      </ng-container>
      <!-- User Avatar -->
      <ng-container matColumnDef="avatar">
        <th mat-header-cell *matHeaderCellDef> Avatar </th>
        <td mat-cell class="avatar-cell" *matCellDef="let userRole">
          @if (userRole.user.pictureUrl) {
            <img alt="user_logo" class="or-icon-userpic or-rounded" src="{{userRole.user.pictureUrl}}" />
          }
        </td>
      </ng-container>
      <!-- User Name -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="name"> Name </th>
        <td mat-cell class="name-cell" *matCellDef="let userRole">{{userRole.user.name}}</td>
      </ng-container>
      <!-- Email address -->
      <ng-container matColumnDef="userEmailAddress">
        <th mat-header-cell *matHeaderCellDef> Email Address </th>
        <td mat-cell class="emailAddress-cell" *matCellDef="let userRole">{{userRole.user.emailAddress}}</td>
      </ng-container>
      <!-- Roles -->
      <ng-container matColumnDef="roles">
        <th mat-header-cell *matHeaderCellDef> Roles </th>
        <td mat-cell class="roles-cell" *matCellDef="let userRole">
          @for (role of userRole.roles; track $index) {
            <mat-chip-listbox>
              <mat-chip-option>{{role.name}}</mat-chip-option>
            </mat-chip-listbox>
          }
        </td>
      </ng-container>
      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell class="actions-cell" *matCellDef="let userRole">
          <button data-cy="edit-user-button" class="action-button edit" mat-mini-fab (click)="editUserRole(userRole)"
            matTooltip="Edit user details"
            matTooltipClass="mat-tooltip"
            matTooltipPosition="above"
            aria-label="Button to edit user details">
            <mat-icon>edit</mat-icon>
          </button>
          <button data-cy="delete-user-button" class="action-button delete" mat-mini-fab color="warn" (click)="removeUserRole(userRole)"
            matTooltip="Delete this user"
            matTooltipClass="mat-tooltip"
            matTooltipPosition="above"
            aria-label="Button to delete user">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="userDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: userDisplayedColumns"></tr>
    </table>
    @if (userDataSource) {
      <mat-paginator class="mat-elevation-z8"
                     #userPaginator
                     [length]="userDataSource.counter$ | async"
                     [pageSizeOptions]="[usersPageSize]"
                     [pageSize]="usersPageSize"
                     (page)='userDataSource.loadUsers($event.pageIndex, $event.pageSize)'
                     showFirstLastButtons
                     aria-label="Select page for Users">
      </mat-paginator>
    }
  </div>
  <div class="tile medium">
    <div class="tile-header mat-elevation-z8">
      <h2 style="font-size: 15px;">Invitations</h2>
    </div>
    @if (invitationDataSource?.loading$ | async) {
      <div class="mat-spinner spinner-container" data-cy="invitations-spinner-icon">
        <mat-spinner></mat-spinner>
      </div>
    }
    <table
      aria-label="List of invitations sent"
      mat-table
      [dataSource]="invitationDataSource"
      class="mat-elevation-z8 invitations-table">
      <!-- Invitation Email Address -->
      <ng-container matColumnDef="invitationEmailAddress">
        <th mat-header-cell *matHeaderCellDef>Email Address</th>
        <td mat-cell class="emailAddress-cell" *matCellDef="let invitation">{{invitation.emailAddress}}</td>
      </ng-container>
      <!-- Token -->
      <ng-container matColumnDef="token">
        <th mat-header-cell *matHeaderCellDef>Token</th>
        <td mat-cell class="token-cell" *matCellDef="let invitation">{{invitation.token}}</td>
      </ng-container>
      <!-- Tenant -->
      <ng-container matColumnDef="tenantName">
        <th mat-header-cell *matHeaderCellDef>Tenant</th>
        <td mat-cell class="tenant-cell" *matCellDef="let invitation">{{invitation.tenantName}}</td>
      </ng-container>
      <!-- Creation Datetime -->
      <ng-container matColumnDef="creationTime">
        <th mat-header-cell *matHeaderCellDef>Creation Time</th>
        <td mat-cell class="token-cell" *matCellDef="let invitation">{{dateInLocale(invitation.creationTime)}}</td>
      </ng-container>
      <!-- Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell class="actions-cell" *matCellDef="let invitation">
          @if (showRefreshIcon(invitation)) {
            <button
              matTooltip="Refresh this invitation"
              matTooltipClass="mat-tooltip"
              matTooltipPosition="above"
              aria-label="Button to refresh this invitation"
              data-cy="refresh-invitation-button"
              class='action-button refresh'
              mat-mini-fab
              (click)="refreshInvitation(invitation)">
              <mat-icon>send</mat-icon>
            </button>
          }
          <button
            matTooltip="Delete this invitation"
            matTooltipClass="mat-tooltip"
            matTooltipPosition="above"
            aria-label="Button to delete this invitation"
            data-cy="delete-invitation-button"
            class="action-button delete"
            mat-mini-fab color="warn"
            (click)="removeInvitation(invitation)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <!-- Rows -->
      <tr mat-header-row *matHeaderRowDef="invitationDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: invitationDisplayedColumns;"></tr>
    </table>
    @if (invitationDataSource) {
      <mat-paginator class="mat-elevation-z8"
                     #invitationPaginator
                     [length]="invitationDataSource.counter$ | async"
                     [pageSizeOptions]="[invitesPageSize]"
                     [pageSize]="invitesPageSize"
                     (page)="invitationDataSource.loadInvitations(buildingId, '', $event.pageIndex, $event.pageSize)"
                     showFirstLastButtons
                     aria-label="Select page for Invitation">
      </mat-paginator>
    }
    <div class='under-table'>
      <form autocomplete="off" class="form-filter-invite">
        <mat-form-field class="filter-input" appearance="fill">
          <mat-label>Filter invitation by email address</mat-label>
          <input
            id="filterEmail"
            data-cy="filter-invites-input"
            matInput
            (keyup)="filterKeyUp.next($event)"
            placeholder="john.doe@example.com"
            autocomplete="off"/>
        </mat-form-field>
      </form>
      <div class="invite-button">
        <button
          matTooltip="Invite users to this building"
          matTooltipClass="mat-tooltip font-15"
          matTooltipPosition="above"
          aria-label="Button to invite users to this building"
          mat-fab
          (click)="inviteUsers()"
          class="mat-elevation-z8"
          color="primary">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
