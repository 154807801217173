<div
  [attr.data-cy]="'node-' + address16"
  class="sensor-node"
  (click)="onClick()"
  [ngClass]="produceClassForSensorNode()"
  [ngStyle]="produceNodeStyle()">
  <div>
    <svg
      tagOutline
      [tags]="details.tags"
      [isPassiveNode]="details.isPassiveNode"
      class="test-in-progress-animate sensor-node-tags">
    </svg>
    <div>
      <span
        class="test-in-progress-animate sensor-node-icon or-icon or-small"
        [ngClass]="produceClassForSensorNodeIcon()">
      </span>
    </div>
    @if (enableGatewayMode) {
      <div class='sensor-node-gateway-properties'>
        <div class='sensor-node-gateway-property sensor-node-device-address'>{{address16}}</div>
      </div>
    }
  </div>
  @if (details.address != null) {
    <div class="sensor-node-properties">
      <div class="sensor-node-properties-header">
        <h1 class="sensor-node-properties-id">{{getNodeIdText()}}</h1>
        <span class="sensor-node-properties-address">{{address16}}</span>
        <span class="sensor-node-properties-driver">{{details.luminaireDrivers?.length || 0}}</span>
        <span class="or-icon or-inverse or-icon-emergency or-tiny"></span>
        <span class="sensor-node-properties-driver">{{details.emDrivers?.length || 0}}</span>
        @if(lampTypeName) {
          <span class="luminaire-driver-properties-lamp-type">{{ lampTypeName }}</span>
        }
      </div>
      @if (details.tags.length > 0) {
        <div class="sensor-node-properties-tags">
          @for (tag of details.tags; track tag.id) {
            <div class="sensor-node-properties-tag">
              <span class="sensor-node-properties-tag-color" [ngStyle]="{ 'background-color': '#' + tag.color}"></span>
              <span class="sensor-node-properties-tag-name">{{tag.name}}</span>
            </div>
          }
        </div>
      }
      @if (isLightingConfigPage) {
        <div class="sensor-node-properties-value">
          <div class="lightlevel-left-col-text py-2">Zone: {{ lightingConfigurationService.convertZoneIdToName(details.lightingConfiguration?.zone) }}</div>
        </div>
        <div class="sensor-node-properties-value">
          <div class="lightlevel-left-col-text py-2">Max Light: {{ lightingConfigurationService.convertMaxLightIdToName(details.lightingConfiguration?.maxLightLevel) }}</div>
        </div>
        <div class="sensor-node-properties-value">
          <div class="lightlevel-left-col-text py-2">Low Light: {{ lightingConfigurationService.convertLowLightIdToName(details.lightingConfiguration?.lowLightLevel) }}</div>
        </div>
        <div class="sensor-node-properties-value">
          <div class="lightlevel-left-col-text py-2">Min Light: {{ lightingConfigurationService.convertMinLightIdToName(details.lightingConfiguration?.minLightLevel) }}</div>
        </div>
        <div class="sensor-node-properties-value">
          <div class="lightlevel-left-col-text py-2">Dwell Time: {{ lightingConfigurationService.convertTimeIdToName(details.lightingConfiguration?.dwellTime) }}</div>
        </div>
        <div class="sensor-node-properties-value">
          <div class="lightlevel-left-col-text py-2">Low Light Time: {{ lightingConfigurationService.convertTimeIdToName(details.lightingConfiguration?.lowLightTime) }}</div>
        </div>
        <div class="sensor-node-properties-value">
          <div class="lightlevel-left-col-text py-2">Scene: {{ lightingConfigurationService.convertSceneIdToName(details.lightingConfiguration?.scene?.value) }}</div>
        </div>
      }
      @if (isHeatMapPage) {
        <div class="sensor-node-properties-value">
          <span class="or-icon or-inverse or-small" [ngClass]="'or-icon-' + dataTypeString"></span>
          <div class="lightlevel-left-col-text">{{ metricFormattedValue }}</div>
        </div>
        <div class="sensor-node-properties-value">
          <div class="lightlevel">
            <div class="lightlevel-left-col">
              <span class="or-icon or-inverse or-small or-icon-light-bulb"></span>
              <div class="lightlevel-left-col-text">Current light level: {{ formatLightLevel() + '%' }}</div>
            </div>
            <div class="lightlevel-right-col">
              <div class="lightlevel-right-col-bullet" [ngClass]="formatLightLevel() > 0 ? 'lightlevel-right-col-bullet-on' : 'lightlevel-right-col-bullet-off'"></div>
              <div class="lightlevel-right-col-text">{{formatLightLevel() > 0 ? 'ON' : 'OFF'}}</div>
            </div>
          </div>
        </div>
        <div class="sensor-node-properties-value">
          <span class="or-icon or-inverse or-icon-scene or-small sensor-node-scene-icon"></span>
          <div class="lightlevel-left-col-text">Scene: {{ scene || "None" }}</div>
        </div>
        <div class="sensor-node-properties-value">
          <span class="or-icon or-inverse or-icon-presence or-small sensor-node-presence-icon"></span>
          <div class="lightlevel-left-col-text">Presence detected: {{ formatPresenceLevel() + '%' }}</div>
        </div>
      }
      <div class="sensor-node-properties-value node-group">
        <span class="or-icon or-inverse or-icon-group or-small sensor-node-group-icon"></span>
        <div class="lightlevel-left-col-text">Group id: {{ details.groupId != null ? details.groupId :  "No Group" }}</div>
      </div>
      <div class="sensor-node-properties-value">
        <span class="or-icon or-inverse or-icon-ble-on or-small sensor-node-ble-scanning-icon"></span>
        <div class="lightlevel-left-col-text">BLE Scanning: {{ details.bleScanning ?  details.bleScanning : 'Unknown, please query' }}</div>
      </div>
      @if (alerts != null && alerts.length > 0) {
        <div class="sensor-node-properties-alerts">
          @for (alert of alerts; track alert.value) {
            <div class="sensor-node-properties-alert">
              <span class="sensor-node-properties-alert-icon icon small inverse icon-alert"></span>
              @if (alert.link != null) {
                <a href="{{alert.link}}"><span class="sensor-node-properties-alert-message">{{alert.value}}</span></a>
              } @else {
              <span class="sensor-node-properties-alert-message">{{alert.value}}</span>
              }
            </div>
          }
        </div>
      }
    </div>
  }
</div>
@if (enableDrivers) {
  @for (driver of details.emDrivers; track driver.id) {
    <app-em-driver
      [driver]='driver'
      [tags]="details.tags"
      [node]='details'>
    </app-em-driver>
  }
  @for (luminaireDriver of details.luminaireDrivers; track luminaireDriver.id) {
    <app-luminaire-driver
      [driver]='luminaireDriver'
      [tags]="details.tags"
      [node]='details'>
    </app-luminaire-driver>
  }
}
