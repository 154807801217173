import { AuthMethod } from './auth-method';
import { BuildingAuthorization } from './building-authorization.interface';
import { GlobalAuthority } from './global-authority';

export class User {
  id: number;
  name: string;
  pictureUrl: string;
  emailAddress: string;
  authKey: string;
  authMethod: AuthMethod;
  managingCompanyId: number;
  globalAuthorities: GlobalAuthority[];
  buildingAuthorizations: BuildingAuthorization[];
  headline: string;
  joinedAt: string;
  tenantIds: number[];

  constructor(
    id: number,
    name: string,
    pictureUrl: string,
    emailAddress: string,
    authKey: string,
    authMethod: AuthMethod,
    managingCompanyId: number,
    globalAuthorities: GlobalAuthority[],
    buildingAuthorizations: BuildingAuthorization[],
    headline: string,
    joinedAt: string,
    tenantIds: number[]
  ) {
    this.id = id;
    this.name = name;
    this.pictureUrl = pictureUrl;
    this.emailAddress = emailAddress;
    this.authKey = authKey;
    this.authMethod = authMethod;
    this.managingCompanyId = managingCompanyId;
    this.globalAuthorities = globalAuthorities;
    this.buildingAuthorizations = buildingAuthorizations;
    this.headline = headline;
    this.joinedAt = joinedAt;
    this.tenantIds = tenantIds;
  }
}
