<div class='or-nodes-list-control-items or-inline-items w-full'>
  <mat-card class='p-4'>
    <mat-card-header>Filter by: </mat-card-header>
    <form [formGroup]='filterForm'>
      <div class='flex flex-row'>
        @if (this.showFields.includes('id')) {
        <mat-form-field class='mx-2'>
          <mat-label>ID</mat-label>
          <input
            matInput
            data-cy='input-filter-by-id'
            type='text'
            placeholder='Id'
            formControlName='id' (keyup)='changeId($event.target.value)' />
        </mat-form-field>
        }
        @if (this.showFields.includes('address')) {
        <mat-form-field class='mx-2'>
          <mat-label>address</mat-label>
          <input
            matInput
            data-cy='input-filter-by-address'
            type='text'
            placeholder='Address'
            formControlName='address' (keyup)='changeAddress($event.target.value)' />
        </mat-form-field>
        }
        @if (this.showFields.includes('nodeType')) {
        <mat-form-field class='mx-2'>
          <mat-label>type</mat-label>
          <mat-select
            data-cy='select-filter-by-node-type'
            formControlName='nodeType'
            (selectionChange)='changeNodeType($event.value)'>
            <mat-option [value]="''" [attr.data-cy]="'filter-node-type-all'">ALL</mat-option>
            @for (nodeType of nodeTypes; track nodeType) {
              <mat-option [value]='nodeType'  [attr.data-cy]="'filter-node-type-' +  nodeType ">{{ nodeType }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        }
        @if(sortByFields){
        <mat-form-field class='mx-2'>
          <mat-label>Sort by fields</mat-label>
          <mat-select data-cy='select-sort-by-fields' formControlName='sortBy' (selectionChange)='changeSortBy()'>
            @for (sort of Object.entries(sortByFields); track sort[0]) {
              <mat-option [value]='sort[0]'  [attr.data-cy]="'field-sort-' +  sort[0] ">{{ sort[1] }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <div class='or-nodes-list-control-item or-form-item or-inline-item or-nodes-list-sort-reverse'>
          <button mat-button [matTooltip]="'Sort Order'" class='or-button or-iconic' (click)='toggleSortOrder()'>
            @if (this.filterForm.value.sortAsc) {
              <span class='or-icon or-small or-icon-sort'></span>
            } @else {
              <span class='or-icon or-small or-icon-sort-inverse'></span>
            }
          </button>
        </div>
        }
        @if (this.showFields.includes('copyToClipboard')) {
        <div class='or-nodes-list-control-item or-form-item or-inline-item or-nodes-list-copy-to-clipboard'>
          <button mat-button [matTooltip]="'Copy nodes to clipboard'" class='or-button or-iconic' (click)='copyToClipboard()'>
            <span class='or-small-to-tiny or-icon or-small or-icon-copy'></span>
          </button>
        </div>
        }
        <div class='or-nodes-list-control-item or-form-item or-inline-item or-nodes-list-copy-to-clipboard'>
          <button mat-button [matTooltip]="'Reload sensor nodes'" class="or-button or-iconic" (click)="refreshNodeList()">
            <span class="or-icon or-small or-icon-reload"></span>
          </button>
        </div>
      </div>
    </form>
  </mat-card>
</div>
