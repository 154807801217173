export class DayOfTheWeek {
  constructor(public value: DayOfTheWeek_, public ordinal: number) {}

  static MONDAY = new DayOfTheWeek('MONDAY', 1);
  static TUESDAY = new DayOfTheWeek('TUESDAY', 2);
  static WEDNESDAY = new DayOfTheWeek('WEDNESDAY', 3);
  static THURSDAY = new DayOfTheWeek('THURSDAY', 4);
  static FRIDAY = new DayOfTheWeek('FRIDAY', 5);
  static SATURDAY = new DayOfTheWeek('SATURDAY', 6);
  static SUNDAY = new DayOfTheWeek('SUNDAY', 7);

  static all(): DayOfTheWeek[] {
    return [
      DayOfTheWeek.MONDAY,
      DayOfTheWeek.TUESDAY,
      DayOfTheWeek.WEDNESDAY,
      DayOfTheWeek.THURSDAY,
      DayOfTheWeek.FRIDAY,
      DayOfTheWeek.SATURDAY,
      DayOfTheWeek.SUNDAY
    ];
  }

  static fromOrdinal(ordinal: number): DayOfTheWeek {
    return DayOfTheWeek.all()[ordinal - 1];
  }

  shortName(): string {
    return this.value.substr(0, 3);
  }

  toString(): string {
    return this.value;
  }
}

type DayOfTheWeek_ = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
